<div class="container bg-image">
  <mat-card class="view-content" style="padding:0px;">
    <mat-card-header>

    </mat-card-header>
    <mat-card-content class="text-center fx-row fx-col-sm fx-center" >


      <div class="image-container fx-justify-center fx-align-end fx-center-sm" >
        <img class="pipcall-logo"  src="https://pipcallportalstorage.blob.core.windows.net/images/pip_logo_solo_white_sm.png"
        alt="PiPcall logo">
      </div>

      <div class="verify-message">

        <ng-container *ngIf="loading;else loaded">
          <span id="firstMsg">
            <div class="loader-spinner"></div> It’s business time! Stand by while we retrieve your details.
          </span>
        </ng-container>
        <ng-template #loaded>

          <ng-container *ngIf="success;else failed">

            <span class="subtitle-12">Verify Your Device</span>
            <span class="text-container">
              <!-- <p>Hi {{invitation.first_name}},</p> -->
              <p> We have sent a verification code to {{this.normalizedNumber}} <i style="font-size:12px;">(this is your primary PiPcall device
                  and login ID).</i></p>
              <ul>
                <li>Enter the verification code below and click 'Verify'</li>
                <li>Do you want to use another number for PiPcall instead? You can still change it by clicking <span [style.color]="'var(--c-url)'"
                    [style.cursor]="'pointer'" (click)="displayUpdateMobile=true">here.</span></li>
              </ul>
            </span>

            <ng-container *ngIf="!displayUpdateMobile;else updateMobile">
              <verify-mobile *ngIf="id" [id]="id" [verifyRoute]="'invitations'" [cliOverride]="invitation.cli_override"
                (triggerSMS)='triggerSMS($event)' [mobile]="invitation.mobile" [darkMode]=false></verify-mobile>
            </ng-container>

            <ng-template #updateMobile>
              <update-cart-mobile *ngIf="id && displayUpdateMobile" [id]="id" [mobile]="invitation.mobile"
                [darkMode]=false [verifyRoute]="'invitations'" (updateMobileClose)="updateMobileClose($event)">
              </update-cart-mobile>
            </ng-template>



          </ng-container>
          <ng-template #failed>
            <h5>Well, that doesn’t look right.</h5>
            <p [style.margin-top.px]="'30'">It looks like your token is expired or incorrect. Let us help fix it by
              reaching out to <a href="mailto:support@pipcall.com" target="_blank">PiPcall Support</a> or call us on 0330 094 8080
              if you need help.
            </p>
          </ng-template>

        </ng-template>
      </div>

    </mat-card-content>
  </mat-card>
</div>
