<div class="section-wrapper">

  <tab-title-lined>
    PBX Tenant
  </tab-title-lined>


  <div class="section-content">

    <div class="fx-row fx-width-100 fx-col-sm fx-gap-2">

      <div class="fx-col fx-width-60 fx-width-100">

        <s-grid-items-container>
          <s-grid-item>
            <div role="label">Host</div>
            <div role="Value">{{number?.sip_host_name}}</div>
          </s-grid-item>
        </s-grid-items-container>

        <app-ff-tenant-search-select-v2 *ngIf="selectedPBXHost?.host" [floatLabel]="'always'"
          [readonly]="selectedPBXHost?.host ? false : true"
          [placeholder]="selectedPBXHost?.host ? 'Tenant ID from PBX' : 'Select PBX Host first'"
          [filterToPBX]="selectedPBXHost?.id" [readonly]="true"
          [preselected_id]="number?.sip_user_name ? number.sip_user_name.slice(0, 3) : null"
          (_selectedValueUpdate)="updateSelectedTenant($event)"></app-ff-tenant-search-select-v2>

      </div>


      <div class="fx-col fx-width-40 fx-width-100-sm"  style="padding-top: 40px;">
      </div>

    </div>




  </div>


</div>



<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>