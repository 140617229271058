//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, collapseAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subject , Observable} from "rxjs";
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { OrganisationDirectory, DirectoryService} from '@app/services/pipcall/directory.service';
import { Subscription } from 'rxjs';
import { FirstPaymentModal } from '@app/components/modals/first-payment-modal/first-payment-modal.component';
import { BusinessnumberValidator } from '@app/validators/businessnumberValidator.directive';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { MatBottomSheet,    MatBottomSheetRef, } from '@angular/material/bottom-sheet';
import { UserProfileBottomSheetComponent} from '@app/components/bottom-sheet-modal/userprofile-bsheet-modal/userprofile-bsheet-modal.component';
import {MatAccordion} from '@angular/material/expansion';
import { ProductContactsModal } from './product-contact-modal/product-contact-modal.component'
@Component({
    selector: 'app-org-tabs-home',
    templateUrl: './org-home.component.html',
    styleUrls: ['./org-home.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation(),
        collapseAnimation()
    ]
})
export class OrgTabHomeomponent {


    @Input() viewAs: string;
    @Input() organisationProfile: OrganisationModel //optional ?
    @Input() organisation_id: string;


    @Output() updateParent = new EventEmitter();
    @Output() changeTab = new EventEmitter();


    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isLoadingDirectory: false
    }

    panelOpenState = false;

    @ViewChild(MatAccordion) accordion: MatAccordion;


    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private directoryService: DirectoryService,
        private organisationService: OrganisationService,
        public dialog: MatDialog,
        private appInsightService: AppInsightService,
        private ref: ChangeDetectorRef,
        private bottomSheet: MatBottomSheet,
        private activatedRoute: ActivatedRoute
    ) {


    }

    ngOnChanges(changes: SimpleChanges) {

    }



    ngOnInit() {

    }



    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }


    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }


    navigateToExternal(url: string) {
        window.open(url, '_blank');
    }

    navigateByUrl(url, tab? : string) {
        this.router.navigateByUrl(url);
    }

    navigateToTab(tab: string) {

        if (tab === 'esim' && this.organisationProfile.is_esim_enabled || (tab === 'extensions' && this.organisationProfile.is_pipxt_enabled)) {
            this.changeTab.emit(tab);
        } else {

            if (tab === 'extensions') { tab = 'mobile-plus'; } //rename for email format

            const dialogRef = this.dialog.open(ProductContactsModal, {
                panelClass: '',
                autoFocus: false,
                data: {product: tab, id: this.organisation_id}
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    //set a cookie or something to remember the user has sent us an email for this product
                }
            });
        }


    }

    triggerUpdate() {
        this.updateParent.emit(true);
        this.ref.detectChanges();
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
