<ng-container *ngIf="user_id || userProfile; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">

    <div [@fadeInOnEnter] class="tab-child-view-container">

      <div class="section-wrapper" *ngIf="activeUserId === userProfile?.id || viewAs === 'sysAdmin'">
        <tab-title-lined>Name</tab-title-lined>

        <tab-section-action [isHidden]="userNameForm?.pristine">


          <button mat-flat-button *ngIf="!userNameForm?.pristine" style="margin-right: 4px;"
            (click)="cancelForm()">Cancel</button>

          <button mat-flat-button aria-label="Save changes" class="save-button"
            [disabled]="userNameForm?.pristine ||  pageStatus.isSubmitting || userNameForm.invalid"
            (click)="saveUserName(userNameForm)">
            <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save changes</ng-container>
          </button>

        </tab-section-action>

        <div class="section-content">

          <div class="user-profile-avatar-name-container">

            <!-- <ng-container *ngTemplateOutlet="userAvatar;context:{'userProfile': userProfile}"></ng-container>  -->

            <form [formGroup]='userNameForm' class="fx-col" style="width: calc(60%-98px)"
              [ngClass]="{'readonly-block': pageStatus.isSubmitting }">


              <div class="name-form-wrapper">

                <mat-form-field appearance="outline" class="ff-style-outline"  style="width: 48%;" 
                  [ngClass]="{'readonly-block': (activeUserId !== userProfile?.id && viewAs !== 'sysAdmin')}">
                  <input autocomplete="off" type="text" matInput placeholder="" formControlName="first_name">
                  <mat-label>First Name</mat-label>
                  <mat-error
                    *ngIf="userNameForm?.controls.first_name?.touched && userNameForm?.controls.first_name?.errors?.pattern">
                    Invalid character</mat-error>
                  <mat-error
                    *ngIf="userNameForm?.controls.first_name?.touched && userNameForm?.controls.first_name?.errors?.maxLength">
                    max length 18 characters</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 48%;" 
                  [ngClass]="{'readonly-block': (activeUserId !== userProfile?.id && viewAs !== 'sysAdmin')}"
                  class="ff-style-outline" >
                  <input autocomplete="off" type="text" matInput placeholder="" formControlName="last_name">
                  <mat-label>Last Name</mat-label>
                  <mat-error
                    *ngIf="userNameForm?.controls.last_name?.touched && userNameForm?.controls.last_name?.errors?.pattern">
                    Invalid character</mat-error>
                  <mat-error
                    *ngIf="userNameForm?.controls.last_name?.touched && userNameForm?.controls.last_name?.errors?.maxLength">
                    max length 18 characters</mat-error>

                </mat-form-field>
              </div>

            </form>


          </div>

          <div class="information-banner warning fx-row fx-justify-start fx-align-center fx-width-100"  style="margin-top: 48px;"
            *ngIf="userProfile?.organisation_list[0].removal_requested === true">
            <div>
              <mat-icon svgIcon="warning"></mat-icon>
            </div>
            <div class="message-body">User has been flagged for removal
            </div>
          </div>

        </div>



      </div>

      <div class="section-wrapper">
        <tab-title-lined>Email&nbsp;<mat-icon style="margin-left: 12px;" svgIcon="email_v2">
          </mat-icon></tab-title-lined>

        <div class="section-content">

          <div class="s-flex-row-wrapper">
            <div class="s-content-flex">

              <s-grid-items-container>
                <s-grid-item>
                  <div role="value">
                    {{userProfile?.email}}&nbsp;&nbsp;
                    <mat-icon matSuffix svgIcon="copy" (click)="copyInputToClipboard(userProfile?.email)"
                      class="clickable action-icon" matTooltipPosition="below" matTooltipShowDelay="200"
                      matTooltip="Copy">
                    </mat-icon>
                  </div>
                </s-grid-item>
              </s-grid-items-container>
            </div>

            <div class="s-help-text-flex">

              <span>Contact the <a href="https://www.pipcall.com/support" target="_blank">Support Team</a> if you need
                to change
                this email address.</span>

            </div>

          </div>
        </div>
      </div>


      <div class="section-wrapper" *ngIf="organisationProfile?.is_pipmobile_enabled || organisationProfile?.is_pipxt_enabled">
        <tab-title-lined>Handset&nbsp;<mat-icon style="margin-left: 8px;" svgIcon="device"></mat-icon>&nbsp;
          <span style="font-size: 0.8em;" *ngIf="userProfile?.os">
            <mat-icon *ngIf="userProfile?.os === 'iOS'"
              style="height: 16px;width:16px;margin-right: 4px;vertical-align: top;color:grey"
              svgIcon="apple"></mat-icon>
            <mat-icon *ngIf="userProfile?.os === 'Android'"
              style="height: 16px;width:16px;margin-right: 4px;vertical-align: top;color:green"
              svgIcon="android"></mat-icon>
            {{userProfile?.os}} {{userProfile?.os_version}}</span></tab-title-lined>


        <div class="section-content" *ngIf="userProfile && userProfile?.mobile; else noMobileView" >

          <div class="s-flex-row-wrapper">
            <div class="s-content-flex">

              <s-grid-items-container>
                <s-grid-item>
                  <div role="label">Device:</div>
                  <div role="value">** ****** {{userProfile?.mobile | slice:10}}</div>
                </s-grid-item>

                <ng-container *ngIf="userProfile?.os || userProfile?.carrier || userProfile?.pipcall_version">
                  <s-grid-item>
                    <div role="label">OS:</div>
                    <div role="value">{{userProfile?.os}}</div>
                  </s-grid-item>
                  <s-grid-item>
                    <div role="label">Carrier:</div>
                    <div role="value">{{userProfile?.carrier}}</div>
                  </s-grid-item>
                  <s-grid-item>
                    <div role="label">PiPall ver.:</div>
                    <div role="value" matTooltip="Pipcall version and build">{{userProfile?.pipcall_version}}
                      {{userProfile?.pipcall_build}}</div>
                  </s-grid-item>
                  <s-grid-item>
                    <div role="label">Last updated:</div>
                    <div role="value">{{userProfile?.device_modified_at | date:'yyyy-MM-dd HH:mm'}}</div>
                  </s-grid-item>
                </ng-container>
              </s-grid-items-container>

            </div>


            <div class="s-help-text-flex">

              <span>The handset is the number of the device on which you are using PiPcall. This number is your username
                for passwordless logon into the app. If you need to change this number, please contact <a
                  href="https://www.pipcall.com/support" target="_blank">PiPcall Support</a>.</span>

            </div>

          </div>
        </div>

        <ng-template #noMobileView>
          <!-- show only when no mobile -->

          <div class="section-content">


            <div class="s-flex-row-wrapper">

              <div class="s-content-flex">

                <!-- this section needs a review !-->

                <!-- only show this in trial -->
                <ng-container
                  *ngIf="(viewAs === 'isAdmin' || viewAs === 'sysAdmin')  && (!organisationProfile?.is_pipxt_enabled && organisationProfile?.is_pipmobile_enabled && !userProfile.organisation_list[0].invitation_id)">
                  <button style="margin-bottom: 10px;" *ngIf="organisationProfile?.state === 'Trial'&& !userProfile.mobile" mat-flat-button
                    style="width: 330px;" class="blue-button" matTooltip="Activate a PiPcall license for this user."
                    [disabled]="pageStatus?.isSubmitting || (activeUserId !== userProfile?.id && viewAs !== 'sysAdmin') ||  organisationProfile?.owner_user_id !== userProfile?.id"
                    (click)="activatePipuser(userProfile)">Activate handset</button>
                  <div *ngIf="organisationProfile?.state !== 'Trial'"><i>No handset. user is an Admin only</i></div>
                </ng-container>

                <ng-container
                  *ngIf="(viewAs === 'isAdmin' || viewAs === 'sysAdmin') && organisationProfile?.is_pipxt_enabled && !userProfile.organisation_list[0].invitation_id">
                  <button style="margin-bottom: 10px;"  mat-flat-button style="width: 330px;" class="blue-button"
                    matTooltip="Activate a PiPcall license for this user."
                    [disabled]="pageStatus?.isSubmitting || (activeUserId !== userProfile?.id && viewAs !== 'sysAdmin') ||  organisationProfile?.owner_user_id !== userProfile?.id"
                    (click)="activatePipXTuser(userProfile)">Activate handset</button>
                </ng-container>

                <!-- no mobile and user has an active invitation but not yet accepted -->
                <div
                  *ngIf="userProfile?.organisation_list[0]?.invitation_id && userProfile?.organisation_list[0]?.is_invitation_accepted === false">
                  <i>Check email to verify PiPcall account </i>
                </div>

                <!-- no mobile , active invitation present and invitation accepted -->
                <ng-container
                  *ngIf="userProfile.organisation_list[0].invitation_id && userProfile.organisation_list[0].is_invitation_accepted === true">
                  <span class="problem-detected-msg"> <i>There was a problem detected. Please contact
                      support&#64;pipcall.com</i></span>
                </ng-container>
              </div>

              <div class="s-help-text-flex">

                <span>A handset has not yet been registered for this user. In order to use PiPcall an activated device
                  is required.</span>


              </div>

            </div>

          </div>

        </ng-template>

      </div>



      <div class="fx-row fx-col-sm fx-gap-1" *ngIf="organisationProfile?.is_pipmobile_enabled || organisationProfile?.is_pipxt_enabled">


        <div class="section-wrapper fx-col fx-width-50 fx-width-100-sm" >

          <tab-title-lined>Organisation</tab-title-lined>

          <div class="section-content">

            <div class="fx-row fx-col-sm fx-width-100 fx-gap-2">

              <div *ngFor="let org of userProfile?.organisation_list">

                <div class="container-box fx-row fx-justify-start fx-align-center fx-gap-2">


                  <div style="width: 48px;">

                    <ng-container *ngIf="org?.id; else noorg">
                      <div class="item-container-circle activeorg s48 fx-col fx-center">
                        <div>
                          <mat-icon class="icon-size-24" svgIcon="business" style="margin-top: 2px;"></mat-icon>
                        </div>
                      </div>
                    </ng-container>

                    <ng-template #noorg>
                      <div class="item-container-circle s48 fx-col fx-center">
                        <div style="font-size: 10px;">Org</div>
                      </div>
                    </ng-template>


                  </div>

                  <div class="fx-col" style="width: 250px;">
                    <ng-container>
                      <div><span class="overflow-ellipsis">{{org?.name}}</span></div>

                      <ng-container *ngIf="org?.id === organisationProfile?.id">

                        <div [matTooltip]="organisationProfile?.state" class="fx-row fx-justify-start fx-align-center"
                          *ngIf="organisationProfile?.state"><span
                            class="overflow-ellipsis">Status:&nbsp;{{organisationProfile?.state}}&nbsp;</span>

                          <div [hidden]="organisationProfile?.state !== 'Active'" class="status-icon">
                            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-accept)'></mat-icon>
                          </div>

                          <div [hidden]="organisationProfile?.state!== 'Trial'" class="status-icon">
                            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-warn)'></mat-icon>
                          </div>

                          <div [hidden]="organisationProfile?.state !== 'In Arrears'" class="status-icon">
                            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-error)'></mat-icon>
                          </div>

                        </div>

                      </ng-container>

                    </ng-container>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>



        <div class="section-wrapper fx-width-50 fx-width-100">
          <tab-title-lined>
            <span *ngIf="organisationProfile?.is_pipmobile_enabled">Numbers</span>
            <span
              *ngIf="organisationProfile?.is_pipxt_enabled && organisationProfile?.is_pipmobile_enabled">&nbsp;&&nbsp;</span>
            <span *ngIf="organisationProfile?.is_pipxt_enabled">Extensions</span>
          </tab-title-lined>

          <div class="section-content">

            <div class="fx-col fx-width-100 fx-gap-1">

              <ng-container *ngIf="userProfile?.organisation_list[0]?.ddi_list?.length > 0 && userProfile?.organisation_list[0]?.ddi_list[0]?.number != null; else noNumbers">


                <div *ngFor="let nm of userProfile?.organisation_list[0]?.ddi_list" class="fx-col">

                  <div class="container-box fx-row fx-justify-start fx-align-center fx-gap-1">

                    <div style="width: 48px;">
                      <div class="s48 item-container-circle activenumber fx-col fx-center">
                        <div style="height:24px;">
                          <ng-container *ngIf="nm?.pip_variant === 'pipmobile'">
                            <mat-icon class="icon-size-24" svgIcon="phone"></mat-icon>
                          </ng-container>

                          <ng-container *ngIf="nm?.pip_variant === 'pipxt'">
                            <mat-icon class="icon-size-24" svgIcon="connect"></mat-icon>
                          </ng-container>

                        </div>
                      </div>
                    </div>

                    <div class="fx-col" style="width: 250px;">

                      <ng-container *ngIf="nm?.pip_variant === 'pipmobile'">
                        <div>Number</div>
                        <div>{{nm?.number | normalizeToInternational}}</div>
                      </ng-container>

                      <ng-container *ngIf="nm?.pip_variant === 'pipxt'">
                        <div>Extension</div>
                        <div>{{nm?.sip_extension_cli | normalizeToInternational}}</div>
                      </ng-container>
                    </div>
                  </div>


                </div>

              </ng-container>

              <ng-template #noNumbers>

                <div class="container-box fx-row fx-justify-start fx-align-center fx-gap-08">
                  <div style="width: 48px;" *ngIf="userProfile?.mobile">
                    <div class="item-container-circle s48 fx-col fx-center">
                      <div class="item-container-circle s48 fx-col fx-center">
                        <div>
                          <mat-icon style="color: var(--c-dividing-lines);margin-top:4px;" class="icon-size-24"
                            svgIcon="phone">
                          </mat-icon>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="fx-col" style="width: 250px;">
                    <ng-container>
                      <div *ngIf="userProfile?.mobile">There are no numbers linked to user.</div>

                      <div *ngIf="!userProfile?.mobile" class="fx-row fx-justify-start fx-align-center">
                        <mat-icon style="color: var(--c-warn);margin-right: 16px;" class="icon-size-48"
                          svgIcon="warning">
                        </mat-icon>
                        No registered handset.
                      </div>
                    </ng-container>
                  </div>
                </div>

              </ng-template>

            </div>
          </div>
        </div>

      </div>

      <div class="section-wrapper" *ngIf="userProfile?.mobile && organisationProfile?.is_pipmobile_enabled || organisationProfile?.is_pipxt_enabled" >
        <tab-title-lined>Permissions</tab-title-lined>
        <div class="section-content">
          <div>
            <mat-slide-toggle
              *ngIf="organisationProfile?.state === 'Trial' || organisationProfile?.state === 'Declined'" disabled
              checked=false>International Calling</mat-slide-toggle>
          </div>

          <div>
            <mat-slide-toggle #internationalEnabled
              [hidden]="organisationProfile?.state === 'Trial' || organisationProfile?.state === 'Declined'"
              (change)="setSettings(userProfile, $event.checked, smsEnabled._checked)"
              [checked]="userProfile?.organisation_list[0]?.can_dial_international"
              [disabled]="viewAs !== 'sysAdmin' && viewAs !== 'isAdmin' ">
              International Calling
            </mat-slide-toggle>
          </div>

          <div>
            <mat-slide-toggle #smsEnabled
              (change)="setSettings(userProfile, internationalEnabled._checked, $event.checked)"
              [checked]="userProfile?.organisation_list[0]?.can_use_sms"
              [disabled]="viewAs !== 'sysAdmin' && viewAs !== 'isAdmin' ">
              SMS
            </mat-slide-toggle>
          </div>

        </div>
      </div>


      <div class="section-wrapper" *ngIf="organisationProfile?.is_pipmobile_enabled || organisationProfile?.is_pipxt_enabled">
        <tab-title-lined>Licence&nbsp;<span
            *ngIf="organisationProfile?.state !== 'Active'">&nbsp;({{organisationProfile?.state}})</span>

          <mat-icon style="margin-left: 12px;" svgIcon="key"></mat-icon></tab-title-lined>

        <div class="section-content">

          <div class="fx-row fx-col-sm fx-gap-2 fx-width-100">


            <div class="fx-col fx-width-60 fx-width-100-sm">


              <ng-container *ngIf="currentLicense?.id; else nolicence">
                <div class="licence-title">{{currentLicense?.name}}</div>
                <div>{{currentLicense?.contract_name}} <span
                    *ngIf="viewAs === 'sysAdmin'">v{{currentLicense?.version_number}}</span></div>
                <div>Licensed at {{currentLicense?.valid_from | date: 'dd/MM/yyyy'}}</div>


                <ng-container *ngIf="viewAs === 'sysAdmin' && currentLicense?.contract_type === '1y_up-front'">

                  <ng-container
                    *ngIf="user?.organisation_list[0]?.allow_renewal && user?.organisation_list[0]?.next_renewal_date !== null; else notRenew">
                    <div>Renews on {{user?.organisation_list[0]?.next_renewal_date | date:'dd/MM/yyyy'}}</div>
                  </ng-container>

                  <ng-template #notRenew>Will not auto renew</ng-template>

                </ng-container>


              </ng-container>

              <ng-template #nolicence>
                <ng-container *ngIf="organisationProfile?.state === 'Trial'">
                  <div>
                    <span class="d-inline">
                      <div *ngIf="trialDays > 0">Trial ends in {{trialDays}} days.</div>
                      <div *ngIf="trialDays === 0">Trial ends today.</div>
                      <div *ngIf="trialDays < 0">Trial</div>
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="organisationProfile?.state !== 'Trial'">
                  <p>There is no licence linked to this user.</p>
                </ng-container>

              </ng-template>

            </div>



            <div class="fx-col fx-width-40 fx-width-100-sm">

            </div>

          </div>
        </div>
      </div>

      <ng-container *ngIf="viewAs === 'isAdmin' || viewAs === 'sysAdmin'">

        <div class="section-wrapper">

          <div class="section-content">

            <div class="fx-row fx-col-sm fx-width-100 fx-gap-2 fx-center" >


              <ng-container *ngIf="organisationProfile?.owner_user_id === userProfile?.id;else notOwner">


              </ng-container>

              <ng-template #notOwner>
                <button *ngIf="organisationProfile?.state === 'Trial'" mat-flat-button
                  style="background: var(--c-error); color: white;width: 330px;"
                  (click)="removeUserFromOrg(userProfile?.id, userProfile.first_name)"
                  [disabled]="pageStatus?.isSubmitting">Delete User</button>



                <ng-container *ngIf="organisationProfile?.state !== 'Trial'">

                  <button *ngIf="userProfile?.organisation_list[0].removal_requested === false" mat-flat-button
                    style="background: var(--c-error); color: white;width: 330px;"
                    (click)="requestRemoveUserFromOrg(userProfile.id, userProfile.first_name)"
                    [disabled]="pageStatus?.isSubmitting">Request User Removal</button>
                  <button *ngIf="userProfile?.organisation_list[0].removal_requested === true" mat-stroked-button
                    (click)="cancelRemoveUserFromOrg(userProfile.id)" [disabled]="pageStatus?.isSubmitting">Cancel
                    Removal
                    Request</button>

                </ng-container>
              </ng-template>


            </div>
          </div>
        </div>
      </ng-container>

    </div>


  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div style="margin-top:2px;margin-bottom: 2px;" class="loader-spinner"></div>
  </div>
</ng-template>

<ng-template #userAvatar let-userProfile="userProfile">
  <div class="user-view-avatar-container">
    <div class="usr-avatar-98 clickable" style="position: relative;" (click)="changeProfilePicture()">
      <div class="edit-me-on-hover">
        <p style="text-align: center;padding-top: 26px;color:white;">Change Picture</p>
      </div>
      <img appGravatar [firstname]="userProfile?.first_name" [lastname]="userProfile?.last_name"
        [email]="userProfile?.email">
    </div>
  </div>

</ng-template>