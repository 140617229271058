<ng-container *ngIf="invoice; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">

    <div [@fadeInOnEnter] class="tab-child-view-container">

      <ng-container *ngIf="invoice?.notes && viewAs==='sysAdmin'">
        <div class="section-wrapper">
          <div class="section-title">

            <div class="fx-row fx-align-start" >Sys Admin Note</div>

          </div>
          <div class="section-content">
            {{invoice?.notes}}
          </div>
        </div>
        <div class="section-divider"></div>
      </ng-container>



      <div class="section-wrapper">

        <div class="section-title fx-row fx-align-start" >
          <div class="fx-row fx-align-start fx-justify-center">{{invoice.date | date: 'dd MMMM yyyy'}}
          </div>
        </div>



        <div class="section-content">

          <div class="fx-row fx-col-sm fx-gap-1">


            <div class="fx-col flex-55">
              <span *ngIf="invoice?.state != 'Open'" class="subtype-title">{{invoice.subtype}}</span>
              <span style="margin-bottom: 10px;" *ngIf="invoice?.state === 'Open'" class="subtype-title">Open</span>
              <div>
                <span class="vat-notice">
                  <span *ngIf="invoice.subtype === 'Pro forma'">This is not a VAT invoice.</span>
                </span>
              </div>




              <div *ngIf="invoice?.state === 'Open' && invoice?.type === 'Interim'">This is an open,
                mid-month invoice for the current day, listing all licence purchases and upgrades for your subscription.
                Further
                transactions will be added as required. Payment will then be collected from your credit card overnight.
                If the
                final total is a refund, however, this will be held-over and offset against your next monthly invoice
              </div>
            </div>

            <div class="fx-col fx-grow fx-justify-start fx-align-end">

              <span>Date</span>
              <div style="margin-bottom: 10px;">{{invoice.date | date: 'dd MMMM yyyy'}}</div>

              <ng-container *ngIf="invoice.is_paid;else unpaidDueDate">
                <span>Paid at:</span>
                <div style="margin-bottom: 10px;">{{invoice.paid_at | date: 'dd MMMM yyyy'}}</div>
              </ng-container>

              <ng-template #unpaidDueDate>
                <span>Due date:</span>
                <div style="margin-bottom: 10px;">{{invoice.due_date | date: 'dd MMMM yyyy'}}</div>
              </ng-template>


              <span>Type / State</span>
              <div style="margin-bottom: 10px;">{{invoice.type}} / {{invoice.state}} </div>

              <span>Reference</span>
              <div style="margin-bottom: 10px;"><span *ngIf="!invoice.reference">-</span>{{invoice.reference}}</div>


              <span>Invoice ID</span>
              <div style="margin-bottom: 10px;" class="clickable" (click)="copyInputToClipboard(invoice.id)">{{invoice.id}}</div>


              <div class="clickable download-button" style="margin-bottom: 10px;"><button mat-button
                  *ngIf="invoice?.reference?.startsWith('INV')" tabindex="-1" title="download
                invoice" (click)="downloadPdf(invoice?.reference)">
                <span *ngIf="invoice.subtype === 'Final'">Download VAT Invoice</span>
                <span *ngIf="invoice.subtype !== 'Final'">Download Pro Forma Invoice</span>
              </button></div>


            </div>
          </div>
        </div>

      </div>

      <!-- //this view is not a vat invoice , click button on the right -->

      <div class="section-divider"></div>

      <div class="text-center" style="background: #cecece;color: white;"  *ngIf="invoice.subtype === 'Final'">This view is not a VAT invoice</div>

      <div class="section-wrapper">
        <div class="section-content">

          <div class="fx-col fx-grow fx-gap-08"  *ngIf="_invoiceLicenseItems?.length > 0" >
            
            <div class="fx-row">
              <span class=" invoice-item-header title-color" fxFill>Licence items</span>
            </div>

            <div class="invoice-line-item fx-row fx-justify-space-between fx-align-center" *ngFor="let item of _invoiceLicenseItems">
              <div style="width: 70%" >{{item.license_name}} {{item.license_contract_name}}</div>
              <div style="width: 15%" class="text-right">x{{item.quantity}}</div>
              <div style="width: 15%" class="text-right">
                {{( item.price * item.quantity )/ 100  | currency:'GBP':'symbol':'1.2-2'}}
              </div>
            </div>
          </div>

          <div class="fx-col fx-gap-08"  fxFill *ngIf="addOnPackageItems" >
            <div class="fx-row">
              <span class="invoice-item-header  title-color" fxFill>Add on packages</span>
            </div>
            <div class="invoice-line-item fx-row fx-justify-space-between fx-align-center"  *ngFor="let item of addOnPackageItems">
              <div style="width: 70%">{{item.package_name}}</div>
              <div style="width: 15%"  class="text-right">x{{item.quantity}}</div>
              <div style="width: 15%"  class="text-right">{{item.net_amount/100 | currency:'GBP':'symbol':'1.2-2'}}</div>
            </div>
          </div>

          <div class="fx-col fx-gap-08" fxFill *ngIf="licenseStubItems" >
            <div class="fx-row">
              <span class="invoice-item-header title-color" fxFill>licence upgrade stub payments</span>
            </div>
            <div class="invoice-line-item fx-row fx-justify-space-between fx-align-center" *ngFor="let item of licenseStubItems">
              <div style="width: 70%">Pro rata adjustment - upgrade to {{item.license_name}} {{item.license_contract_name}}
              </div>
              <div style="width:15%" class="text-right">x1</div>
              <div style="width: 15%" class="text-right">{{item.net_amount/100 | currency:'GBP':'symbol':'1.2-2'}}</div>
            </div>
          </div>

          <div class=" fx-col fx-gap-08" fxFill *ngIf="adHocItems">
            <div class="fx-row">
              <span class="invoice-item-header title-color" fxFill>Ad hoc charges</span>
            </div>
            <div class="invoice-line-item fx-row fx-justify-space-between fx-align-center" *ngFor="let item of adHocItems">
              <div style="width: 70%">{{item.description}}</div>
              <div style="width:15%" class="text-right">x1</div>
              <div style="width:15%"  class="text-right">{{item.net_amount/100 | currency:'GBP':'symbol':'1.2-2'}}</div>
            </div>
          </div>

          <div class="divider"></div>

          <div class="total-container fx-col fx-grow fx-gap-08" >

            <div class="fx-row fx-grow fx-justify-end">
              <div>Net:&nbsp;</div>
              <div class="text-right" style="width: 150px;">{{invoice?.total_net/100 | currency:'GBP':'symbol':'1.2-2'}}</div>
            </div>

            <div class="fx-row fx-grow fx-justify-end">
              <div>VAT ({{invoice.vat_rate | percent}}):&nbsp;</div>
              <div class="text-right" style="width: 150px;">{{invoice.total_vat/100 | currency:'GBP':'symbol':'1.2-2'}}</div>
            </div>

            <div class="fx-row fx-grow fx-justify-end">
              <div class="invoice-total-wrapper">
                <div style="line-height: 1.2em;text-align: end;">Total:</div>
                <div style="line-height: 1.2em;width: 150px;" class="text-right">{{invoice.total_gross/100 | currency:'GBP':'symbol':'1.2-2'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>






    </div>


  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div style="margin-top:2px;margin-bottom: 2px;" class="loader-spinner"></div>
  </div>
</ng-template>
