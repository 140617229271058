import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module';
//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';

import { OrgTabEsimComponent } from './org-esim.component';
import { DataEsimForOrgComponent  } from './data-esims-for-org/data-esims-for-org.component';
import { EsimTelnaService  } from '@app/services/pipcall/esim_telna.service';

import { DataEsimDetailsModalModule  } from '@app/components/modals/data-esim-details-modal/data-esim-details-modal.module';

//component

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        UniversalComponentsModule,
        DataEsimDetailsModalModule
    ],
    declarations: [OrgTabEsimComponent, DataEsimForOrgComponent],
    exports: [OrgTabEsimComponent, DataEsimForOrgComponent],
    providers: [AppInsightService, OrganisationService, EsimTelnaService],
    entryComponents: [DataEsimForOrgComponent]
})
export class OrgTabEsimModule { }
