

// interface IDdi {
//     number:string;
//     pbx_platform_id:string;
//     provider:string;
//     is_pip_number:boolean;
//     is_reserved:boolean;

// }


// export class DdiModel implements IDdi {
//     number:string;
//     pbx_platform_id:string;
//     provider:string;
//     is_pip_number:boolean;
//     is_reserved:boolean;
// }


export class DdiProvisioningRequest {
    number: string;
    pbx_platform_id: string;
    provider: string;
    is_reserved?: boolean;
    is_sms_enabled: boolean;
}


export class SipProvisioningRequest {
    number: string;
    organisation_id: string;
    sip_extension_number: string;
    sip_user_name: string;
    sip_password: string;
    sip_host_name: string;
    sip_extension_cli: string;
    sip_voicemail_number: string;
}

export class DdiAllocationRequest {
    number = '';
    organisation = '';
    force?: boolean = false;
}

export class DdiMovePbxRequest {
    number: string;
    pbx_platform_id: {
        from: string;
        to: string;
    };
    force?: boolean;
}


export class PbxPlatform {
    id: string;
    environment: string;
    region: string;
}

export class Providers {
    id: string;
    name: string;
}

export class SipUpdate {
    //customer
    label: string;
    sip_extension_number: string;
    sip_user_name: string;
    sip_password: string;
    sip_host_name: string;
    sip_extension_cli: string;
    sip_voicemail_number: string;
}

export class DdiUpdate {
    pbx_platform_id: string;
    provider: string;
    is_reserved: boolean;
    is_blocked: boolean;
    is_sms_enabled: boolean;
    no_answer: string;
    cli_override: string;
    notes: string;
    label: string;
    sort?: string;
}

export class DdiCapacity {
    id: string;
    code: string;
    name: string;
    total_count: number;
    reserved_count: number;
    used_count: number;
    remaining_count: number;
    capacity_percent: number;
    status_code: number
}


// sysAdmin
export class PiPxtUpdate {
    sip_extension_number:  string;
    sip_extension_cli:  string;
    sip_user_name:  string;
    sip_password:  string;
    sip_host_name:  string;
    sip_voicemail_number: string;
    label: string;
    sort: string;
    notes: string;
    is_blocked: boolean;
    is_pipxt_extensionless: boolean;
};


//====================================

interface IDdiNumber {
    number: string;
    pbx_platform_id: string;
    organisation_id: string;
    provider: string;
    pip_variant: string;
    state: string;
    is_reserved: boolean;
    is_blocked: boolean;
    is_sms_enabled: boolean;
    sip_extension_number: string;
    sip_extension_cli: string;
    sip_user_name: string;
    sip_host_name: string;
    sip_voicemail_number: string;
    pbx_id?: string
    pbx_tenant_id?: string
    pbx_tenant_code?: string;
    is_pipxt_extensionless: boolean;
    notes: string;
    event_source: string;
    event_type: string;
    event_code: string;
    event_at: string;
    event_data: string;
    dnd: any;
    label: string;
    cli_override: string;
    no_answer: string;
    user_id: string;
    user_first_name: string;
    user_last_name: string;
    user_full_name: string;
    user_email: string;
    organisation_name: string;
    organisation_state: string;
    cli_override_request_id: string;
    invitation_id: string;
    invitation_email: string;
    id?: string;
    created_at?: Date;
    created_by?: string;
    modified_at?: Date;
    modified_by?: string;
}
//used by table search
export class DdiNumber implements IDdiNumber {
    number: string;
    pbx_platform_id: string;
    organisation_id: string;
    provider: string;
    state: string;
    is_reserved: boolean;
    is_blocked: boolean;
    is_sms_enabled: false;
    pip_variant: string;
    sip_extension_number: string;
    sip_extension_cli: string;
    sip_user_name: string;
    sip_password?: string;
    sip_host_name: string;
    sip_voicemail_number: string;
    pbx_id?: string
    pbx_tenant_id?: string
    pbx_tenant_code?: string;
    is_pipxt_extensionless: boolean;
    notes: string;
    event_source: string;
    event_type: string;
    event_code: string;
    event_at: string;
    event_data: string;
    dnd: boolean;
    label: string;
    cli_override: string;
    no_answer: string;
    sort: string;
    user_id: string;
    user_first_name: string;
    user_last_name: string;
    user_full_name: string;
    user_email: string;
    organisation_name: string;
    organisation_state: string;
    cli_override_request_id: string;
    invitation_id: string;
    invitation_email: string;
    id?: string;
    created_at?: Date;
    created_by?: string;
    modified_at?: Date;
    modified_by?: string;
}
