<ng-container *ngIf="licence; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">

    <div [@fadeInOnEnter] class="tab-child-view-container">


      <div class="section-wrapper">

        <div class="section-title fx-col">

          <div class="fx-row fx-justify-start">Licence
          </div>

        </div>

        <div class="section-content">

          <div class="fx-row fx-gap-2 fx-grow fx-col-sm" >

            <div class="fx-col fx-grow"  style="overflow: hidden;">

              <div class="container-box fx-row fx-gap-1 fx-justify-start">

                <div style="width: 74px;" >
                  <div class="s74 item-container-circle activeorg fx-col fx-center">
                    <div style="height:24px;">
                      <mat-icon class="icon-size-24" svgIcon="business"></mat-icon>
                    </div>
                  </div>

                </div>

                <div class="fx-col" style="width: 250px;">
                  <ng-container>
                    <div><strong>Organisation</strong></div>
                    <div>{{organisationProfile?.name}}</div>
                    <div [matTooltip]="organisationProfile?.state" class="fx-row fx-justify-start" 
                      *ngIf="organisationProfile?.state"><span
                        class="overflow-ellipsis">Status:&nbsp;{{organisationProfile?.state}}&nbsp;</span>

                      <div [hidden]="organisationProfile?.state !== 'Active'" class="status-icon">
                        <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-accept)'></mat-icon>
                      </div>

                      <div [hidden]="organisationProfile?.state!== 'Trial'" class="status-icon">
                        <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-warn)'></mat-icon>
                      </div>

                      <div [hidden]="organisationProfile?.state !== 'In Arrears'" class="status-icon">
                        <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-error)'></mat-icon>
                      </div>

                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="vertical-line-divider-container">
                <div class="vertical-line-divider"></div>
              </div>


              <div class="container-box fx-row fx-justify-start fx-gap-1">

                <div style="height:74px;width: 74px;">

                  <ng-container
                    *ngIf="(licence?.licensed_user_type === 'User' || licence?.licensed_user_type === 'Invitation'); else nouser">
                    <div class="usr-avatar">
                      <img class="s74" appGravatar [firstname]="licence?.first_name" [lastname]="licence?.last_name"
                        [email]="licence?.email">
                    </div>
                  </ng-container>

                  <ng-template #nouser>

                    <div class="s74 item-container-circle fx-col fx-center">
                      <div>User</div>
                    </div>
                  </ng-template>

                </div>

                <div class="fx-col" style="width: 250px;">
                  <ng-container>
                    <div><strong>User</strong></div>
                    <div [matTooltip]="number?.invitation_email" class="fx-row fx-justify-start"
                      *ngIf="number?.invitation_email">
                      <mat-icon svgIcon="email" style="margin-right: 10px;"></mat-icon><span
                        class="overflow-ellipsis">{{number?.invitation_email}}</span>
                    </div>
                    <div *ngIf="licence?.first_name" [matTooltip]="licence?.first_name + ' ' + licence?.last_name"
                      class="overflow-ellipsis">{{licence?.first_name}} {{licence?.last_name}}</div>
                    <div [matTooltip]="licence?.email" class="overflow-ellipsis">{{licence?.email}}</div>
                  </ng-container>
                </div>

              </div>


              <ng-container
                *ngIf="licence?.licensed_user_type === 'User' && userProfile && userProfile?.organisation_list[0]?.ddi_list?.length > 0 && userProfile?.organisation_list[0]?.ddi_list[0]?.number != null; else noNumbers">


                <div *ngFor="let nm of userProfile?.organisation_list[0]?.ddi_list">

                  <div class="vertical-line-divider-container">
                    <div class="vertical-line-divider"></div>
                  </div>

                  <div class="container-box fx-row fx-justify-start fx-gap-1">


                    <div style="width: 74px;">
                      <div class="s74 item-container-circle activenumber fx-col fx-center">
                        <div style="height:24px;">
                          <ng-container *ngIf="nm?.pip_variant === 'pipmobile'">
                            <mat-icon class="icon-size-24" svgIcon="phone"></mat-icon>
                          </ng-container>

                          <ng-container *ngIf="nm?.pip_variant === 'pipxt'">
                            <mat-icon class="icon-size-24" svgIcon="connect"></mat-icon>
                          </ng-container>

                        </div>
                      </div>
                    </div>

                    <div class="fx-col" style="width: 250px;">

                      <ng-container *ngIf="nm?.pip_variant === 'pipmobile'">
                        <div><strong>Number</strong></div>
                        <div>{{nm?.number | normalizeToInternational}}</div>
                      </ng-container>

                      <ng-container *ngIf="nm?.pip_variant === 'pipxt'">
                        <div><strong>Extension</strong></div>
                        <div>{{nm?.sip_extension_cli | normalizeToInternational}} ({{nm?.sip_extension_number}})</div>
                      </ng-container>
                    </div>
                  </div>


                </div>

              </ng-container>

              <ng-template #noNumbers>

                <div class="vertical-line-divider-container">
                  <div class="vertical-line-divider"></div>
                </div>

                <div class="container-box fx-row fx-justify-start fx-gap-1">
                  <div style="width: 74px;">

                    <div class="item-container-circle s74 fx-col fx-center">
                      <div style="height:24px;">
                        <mat-icon style="color: var(--c-dividing-lines)" class="icon-size-24" svgIcon="phone">
                        </mat-icon>
                      </div>
                    </div>


                  </div>

                  <div class="fx-col" style="width: 250px;">
                    <ng-container>
                      <div>
                        <span *ngIf="licence?.licensed_user_type === 'User'">There are no numbers linked to this
                          user</span>
                        <span *ngIf="licence?.licensed_user_type === 'Invitation'">Number pending acceptance of
                          invitation</span>
                      </div>
                    </ng-container>
                  </div>
                </div>

              </ng-template>

            </div>


            <div class="fx-col fx-grow" >
              <s-grid-items-container>

                <s-grid-item [labelWidthpx]="140">
                  <div role="label">Name</div>
                  <div role="value">{{licence?.license_name}}</div>
                </s-grid-item>

                <s-grid-item [labelWidthpx]="140">
                  <div role="label">Contract</div>
                  <div role="value">{{licence?.license_contract_name}} {{licence?.license_version_number}}</div>
                </s-grid-item>

                <s-grid-item [labelWidthpx]="140">
                  <div role="label">Licensed At</div>
                  <div role="value">{{licence?.licensed_at | date: 'dd/MM/yyyy HH:mm'}}</div>
                </s-grid-item>

                <s-grid-item [labelWidthpx]="140">
                  <div role="label">Renew Date</div>
                  <div role="value">{{licence?.next_renewal_date | date: 'dd/MM/yyyy HH:mm'}}</div>
                </s-grid-item>

                <s-grid-item [labelWidthpx]="140">
                  <div role="label">Auto Renew</div>
                  <div role="value">{{licence?.allow_renewal}}</div>
                </s-grid-item>

                <s-grid-item [labelWidthpx]="140">
                  <div role="label">Price</div>
                  <div role="value"> {{licence?.license_price/100 | currency: 'GBP': 'symbol': '1.2-2'}}</div>
                </s-grid-item>

                <s-grid-item [labelWidthpx]="140">
                  <div role="label">Payment Status</div>
                  <div role="value">
                    <span *ngIf="licence?.is_paid" style="color: var(--c-accept)">Paid</span>
                    <span *ngIf="!licence?.is_paid" style="color: var(--c-error)">Payment due</span>
                  </div>
                </s-grid-item>

              </s-grid-items-container>
            </div>

          </div>

        </div>

      </div>

    </div>

    <div class="section-divider"></div>

    <div class="section-wrapper" *ngIf="viewAs === 'sysAdmin' || viewAs === 'isAdmin'">
      <div class="fx-col" style="margin-top: 10px;">
        <div>Renewal</div>
        <div>
          If you would like to cancel the renewal of this licence, please contact&nbsp;<a href="mailto:support@pipcall.com" target="_blank">PiPcall Support</a>.
        </div>
      </div>
    </div>

 


  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div style="margin-top:2px;margin-bottom: 2px;" class="loader-spinner"></div>
  </div>
</ng-template>