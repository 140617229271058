<div class="sidenav-container fx-col fx-justify-space-between fx-align-start">

  <div class="main-nav-items fx-col fx-justify-space-between fx-align-start">

    <div class="nav-image">

    </div>

    <div style="width:100%" class="left-nav-menu">

      <!-- <p class="text-center"  *ngIf="viewAs != 'sysAdmin'">{{this.organisationProfile.name}}</p> -->
      <!-- <p class="text-center"   *ngIf="viewAs === 'sysAdmin'">Sys Admin</p> -->
      <!-- add width and elipis -->
      <mat-accordion class="accordian-navmenu expansionsidenav">

        <ng-container *ngFor="let navitem of sideNavMenuConfig_">

          <ng-container *ngIf="navitem?.customClass !== 'upgrade-link'; else specialItem">

            <mat-expansion-panel *ngIf="
        navitem.type==='link' && navitem.showIfRole.includes(viewAs) && (viewAs === 'sysAdmin' || (navitem.variant.includes('pipmobile') && this.organisationProfile.is_pipmobile_enabled || navitem.variant.includes('pipxt') && this.organisationProfile.is_pipxt_enabled)
        )" [@fadeInOut] hideToggle [ngClass]="navitem?.customClass ? navitem?.customClass: ''">
              <mat-expansion-panel-header (click)="$event.stopPropagation();" routerLinkActive="menu-item-active"
                [routerLink]="navitem.route" class="clickable">
                <mat-panel-title (click)="$event.stopPropagation();">
                  <div class="nav-icon">
                    <mat-icon svgIcon="{{navitem.svgIcon}}"></mat-icon>
                  </div>
                  <div class="nav-title"><a class="normalize-style" [routerLink]="navitem.route">{{navitem.title}}</a>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
            </mat-expansion-panel>

            <!-- //Menu style with child links  -->
            <mat-expansion-panel *ngIf="navitem.type==='menu' && navitem.showIfRole.includes(viewAs)" [@fadeInOut]
              [ngClass]="navitem?.customClass ? navitem?.customClass: ''" [expanded]="navitem.isStartsExpanded">
              <mat-expansion-panel-header class="clickable" routerLinkActive="menu-item-active">
                <mat-panel-title>
                  <div class="nav-icon">
                    <mat-icon svgIcon="{{navitem.svgIcon}}"></mat-icon>
                  </div>
                  <div class="nav-title">{{navitem.title}}</div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <a class="clickable normalize-style child-link" *ngFor="let child of navitem.child"
                href="javascript:void(0);" routerLinkActive="menu-item-active"
                [routerLink]="child.route" [title]="child?.title">{{child.title}}</a>
            </mat-expansion-panel>
          </ng-container>

          <ng-template #specialItem>
            <!-- //upgrade Link -->
            <mat-expansion-panel *ngIf="(organisationProfile?.state === 'Trial' || organisationProfile?.state === 'Declined') && !organisationProfile.is_pipxt_enabled && navitem.type==='link' && navitem.showIfRole.includes(viewAs) && (viewAs === 'sysAdmin' || (navitem.variant.includes('pipmobile') && this.organisationProfile.is_pipmobile_enabled || navitem.variant.includes('pipxt') && this.organisationProfile.is_pipxt_enabled)
    )" [@fadeInOut] hideToggle [ngClass]="navitem?.customClass ? navitem?.customClass: ''">
              <mat-expansion-panel-header (click)="$event.stopPropagation();">

                <mat-panel-title class="clickable" (click)="openFirstPayment()">
                  <div class="nav-icon">
                    <mat-icon svgIcon="{{navitem.svgIcon}}"></mat-icon>
                  </div>
                  <div class="nav-title">{{navitem.title}}</div>
                </mat-panel-title>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </ng-template>



        </ng-container>

      </mat-accordion>


    </div>

  </div>



  <div *ngIf="organisationProfile?.state==='Trial' && trialDays > -1" class="trial-indicator fx-col fx-center" >
    <div>
      <span class="d-inline">
        <div *ngIf="trialDays > 0">Trial ends in {{trialDays}} days</div>
        <div *ngIf="trialDays === 0">Trial ends today</div>
        <div *ngIf="trialDays < 0">Trial has ended</div>
      </span>
    </div>
  </div>


  <div class="secondary-nav-items fx-col fx-justify-space-between fx-align-start">
    <!-- <div [@fadeInOut] *ngIf="organisationProfile.is_pipmobile_enabled" class="nav-secondary-item"><a class="normalize-style"
        href="javascript:void(0);" [routerLink]="['/pricing']">Pricing</a></div>
    <div [@fadeInOut] *ngIf="organisationProfile.is_pipmobile_enabled" class="nav-secondary-item"><a class="normalize-style"
        href="javascript:void(0);" href="https://www.pipcall.com/policies" target="_blank">Terms & Conditions</a></div>

    <div [@fadeInOut] class="nav-secondary-item"><a class="normalize-style" href="https://www.pipcall.com/about-us/"
        target="_blank">About PiPcall</a></div>
    <div [@fadeInOut] class="nav-secondary-item"><a class="normalize-style" href="javascript:void(0);"
        (click)="openDownloadLink()">Download App</a></div>
    <div [@fadeInOut] class="nav-secondary-item"><a class="normalize-style" href="https://www.pipcall.com/support"
        target="_blank">Support</a></div> -->
    <!-- <div [@fadeInOut] *ngIf="viewAs==='sysAdmin'" class="nav-secondary-item"><a class="normalize-style"
        href="javascript:void(0);" [routerLink]="['/release']">Release notes</a></div>
    <div [@fadeInOut] *ngIf="viewAs==='sysAdmin'" class="nav-secondary-item"><a class="normalize-style"
        href="javascript:void(0);" [routerLink]="['/help']">Help centre</a></div> -->
  </div>
</div>
