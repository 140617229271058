<ng-container [ngSwitch]="version">

  <!-- new implemenation -->
  <ng-container *ngSwitchCase="1">
    <div  class="cto-row-container fx-row fx-justify-start fx-align-center fx-gap-08">
      <div class="avatar-container av-size-30" *ngIf="organisation_name">
        <div class="avatar-icon-background fx-col fx-center"> 
          <mat-icon style="fill: white;height: 18px;width: 18px;" svgIcon="business"></mat-icon>
        </div>
      </div>

      <div class="fx-col fx-justify-center fx-align-start">

        <div class="cto-top-row-wrapper hover-to-show clickable">
          
          <a href="/admin/organisation/{{organisation_id}}/profile" class="overflow-elipsis cto-max-width"
            matTooltipPosition="below" matTooltipShowDelay="200"
            matTooltip="Open {{organisation_name}}">{{organisation_name}}</a>


          <mat-icon (click)="openOrgDetails(organisation_name, organisation_id, viewAs)" matSuffix svgIcon="view"class="clickable action-icon" matTooltipPosition="below"
            matTooltipShowDelay="200" matTooltip="Quick view"></mat-icon>


          <mat-icon *ngIf="organisation_admin_userid" (click)="impersonateUser(organisation_admin_userid)" matSuffix svgIcon="impersonate"
          (click)="$event.stopPropagation()" class="clickable action-icon" matTooltipPosition="below"
          matTooltipShowDelay="200" matTooltip="Impersonate organisation admin"></mat-icon>
            <!-- //stop propagation xaused issue on other pages?  -->
        </div>

        <div class="cto-bottom-row-wrapper" *ngIf="organisation_name && organisation_state && !hide_detail">

          <span class="overflow-elipsis cto-bottom-row-content cto-max-width cto-wd-230" [matTooltip]="">
            {{organisation_state}}&nbsp;
          </span>

          <div *ngIf="organisation_state === 'Active'" class="status-icon">
            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-accept)'></mat-icon>
            <mat-icon *ngIf="is_blocked" style="color: var(--c-error);vertical-align: middle; margin-left:4px;"
              class="icon-14" svgIcon="block"></mat-icon>
          </div>

          <div *ngIf="organisation_state === 'Trial'" class="status-icon">
            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-warn)'></mat-icon>
            <mat-icon *ngIf="is_blocked" style="color: var(--c-error);vertical-align: middle; margin-left:4px;"
              class="icon-14" svgIcon="block"></mat-icon>
          </div>

          <div *ngIf="organisation_state=== 'In Arrears'" class="status-icon">
            <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-error)'></mat-icon>
            <mat-icon *ngIf="is_blocked" style="color: var(--c-error);vertical-align: middle; margin-left:4px;"
              class="icon-14" svgIcon="block"></mat-icon>
          </div>

        </div>


      </div>

    </div>
  </ng-container>


  <!-- legacy implementation -->
  <ng-container *ngSwitchDefault>
 
  </ng-container>

</ng-container>