import { Injectable} from '@angular/core';
import { CookieService} from 'ngx-cookie-service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { switchMap, filter, catchError, debounceTime, tap, finalize, takeUntil, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CartOutputDataFormat } from '@app/pages/shop/data-esim/data-esim-cart.service';
export class MyEvent {
    event: string;
    eventCategory?: string;
    eventAction?: string;
    eventLabel?: string;
    product?: string;
    value?: number;
    currency?: string;
    items?: any[];
}

export class EcommerceItem {
    item_name: string;
    item_id:  string;  // The product ID or SKU
    quantity:  number;  // The quantity purchased
    price:  number; // Optional: the price per item
}

@Injectable()
export class GA4Service {

    constructor(private cookieService: CookieService,  private router: Router, private http: HttpClient) {
    }

    sign_up_start(product: 'pipmobile' | 'pipxt' | 'esim') {
        const event: MyEvent = {
            'event': 'create_account_start',
            'eventCategory': 'signup',
            'eventAction': 'Form touched',
            'product': product,
            'eventLabel': 'Create Account form touched'
        }
        this.sendEvent(event);
    }


    // ==========esim signup events================This new one should replace the old one
    checkout_sign_up_start() {
        const event: MyEvent = {
            'event': 'create_new_account_start',
            'eventCategory': 'signup',
            'eventAction': 'Form Submit',
            'product': 'esim',
            'eventLabel': 'Create Account started'
        }
        this.sendEvent(event);
    }

    checkout_sign_up_verified() {
        const event: MyEvent = {
            'event': 'create_new_account_verified',
            'eventCategory': 'signup',
            'eventAction': 'Form Submit',
            'product': 'esim',
            'eventLabel': 'Verified Email'
        }
        this.sendEvent(event);
    }

    //================================================================================================

    sign_up_pipcall_stage1_complete() {
        const event: MyEvent = {
            'event': 'pipcall_create_account_stage1_complete',
            'eventCategory': 'signup',
            'eventAction': 'Form Submit',
            'product': 'esim',
            'eventLabel': 'Email form Stage 1 Complete',
        }
        this.sendEvent(event);
    }

    sign_up_pipcall_stage2_complete() {
        const event: MyEvent = {
            'event': 'pipcall_create_account_stage2_complete',
            'eventCategory': 'signup',
            'eventAction': 'Form Submit',
            'product': 'esim',
            'eventLabel': 'verify email Stage 2 Complete',
        }
        this.sendEvent(event);
    }

    // ==========pipmobile signup events================
    sign_up_pipmobile_stage1_complete() {
        const event: MyEvent = {
            'event': 'pipmobile_create_account_stage1_complete',
            'eventCategory': 'signup',
            'eventAction': 'Form Submit',
            'product': 'pipmobile',
            'eventLabel': 'Your details Stage 1 Complete',
        }
        this.sendEvent(event);
    }

    sign_up_pipmobile_stage2_complete() {
        const event: MyEvent = {
            'event': 'pipmobile_create_account_stage2_complete',
            'eventCategory': 'signup',
            'eventAction': 'Form Submit',
            'product': 'pipmobile',
            'eventLabel': 'login credetails Stage 2 Complete',
        }
        this.sendEvent(event);
    }

    sign_up_pipmobile_stage3_complete() {
        const event: MyEvent = {
            'event': 'pipmobile_create_account_stage3_complete',
            'eventCategory': 'signup',
            'eventAction': 'Form Submit',
            'product': 'pipmobile',
            'eventLabel': 'verify email Stage 3 Complete',
        }
        this.sendEvent(event);
    }

    sign_up_pipmobile_stage4_started() {
        const event: MyEvent = {
            'event': 'pipmobile_create_account_stage4_started',
            'eventCategory': 'signup',
            'eventAction': 'Form Submit',
            'product': 'pipmobile',
            'eventLabel': 'Verify handset started',
        }
        this.sendEvent(event);
    }

    // ==========pipxt signup events================

    sign_up_pipxt_stage1_complete() {
        const event: MyEvent = {
            'event': 'pipxt_create_account_stage1_complete',
            'eventCategory': 'signup',
            'eventAction': 'Form Submit',
            'product': 'pipxt',
            'eventLabel': 'Your details Stage 1 Complete',
        }
        this.sendEvent(event);
    }

    sign_up_pipxt_stage2_complete() {
        const event: MyEvent = {
            'event': 'pipxt_create_account_stage2_complete',
            'eventCategory': 'signup',
            'eventAction': 'Form Submit',
            'product': 'pipxt',
            'eventLabel': 'verify email Stage 2 Complete',
        }
        this.sendEvent(event);
    }
    // sign_up_pipxt_stage3_complete() {
    //     const event: MyEvent = {
    //         'event': 'pipxt_create_account_stage3_complete',
    //         'eventCategory': 'signup',
    //         'eventAction': 'Form Submit',
    //         'product': 'pipxt',
    //         'eventLabel': 'state 3 - onboarding add a user Complete',
    //     }
    //     this.sendEvent(event);
    // }
    //================================================================================================
    // ==========new - v5 signup events================

    //

    sign_up_success(product: 'PiPmobile' | 'PiPxt' | 'eSIM') {
        const event: MyEvent = {
            'event': 'create_account_success',
            'eventCategory': 'signup',
            'eventAction': 'Form Submit',
            'product': product,
            'eventLabel': 'Create Account Success',
        }
        this.sendEvent(event);
    }

    //================================================================================================
    esim_data_view_valid_cart() {
        //new unverified user views a valid cart
        const event: MyEvent = {
            'event': 'esim_data_view_valid_cart',
            'eventCategory': 'ecommerce',
            'eventAction': 'view',
            'product': 'eSIM_data',
            'eventLabel': 'View valid cart'
        }
        this.sendEvent(event);
    }
    checkout_success( invoice_total: number) {
        const event: MyEvent = {
            'event': 'subscription_checkout_success',
            'eventCategory': 'ecommerce',
            'eventAction': 'purchase',
            'product': 'PiPmobile',
            'value': invoice_total,
            'eventLabel': 'Subscription purchase',
            'currency': 'GBP'
        }
        this.sendEvent(event);
    }


    esim_data_checkout_success( invoice_total: number, cart: CartOutputDataFormat[]) {

        const items: EcommerceItem[] = [];
        cart.forEach(item => {
            const itemObj: EcommerceItem = {
                item_name: item.product.name,
                item_id:  item.license_id,
                quantity:  item.quantity,
                price:  item.product.price
            }
            items.push(itemObj);
        })

        const event: MyEvent = {
            'event': 'purchase', //renamed from  'event': 'singlepayment_checkout_success',
            'eventCategory': 'ecommerce',
            'eventAction': 'purchase',
            'product': 'eSIM_data',
            'value': invoice_total,
            'eventLabel': 'eSIM for travel purchase',
            'currency': 'GBP',
            'items': items
        }
        this.sendEvent(event);
    }

    contact_us_form_submit(product) {
        const event: MyEvent = {
            'event': 'contact_us_about_product_form_submit',
            'eventCategory': 'contact_us',
            'eventAction': 'Form Submit',
            'product': product,
            'eventLabel': 'Contact us about product form submit'
        }
        this.sendEvent(event);
    }
    sendEvent(event: MyEvent) {
        if (!event?.event) {
            return;
        }
        window.dataLayer = window.dataLayer ?? [];
        window.dataLayer.push(event);
    }
}
