<div class="page-container">
  <div class="content-wrapper">

    <div class="content-page-title-wrapper">
      <h1>Demo</h1>
      <h2>Sample Page</h2>
  </div>
  <div>
    <button mat-flat-button color="accent">Action Button</button>
  </div>




    <div class="content-body" >
      <div fxLayout="row" class="color-circles">
        <div title="primary" class="color-circle c-primary"></div>
        <div title="secondary" class="color-circle c-secondary"></div>
        <div title="tertiary" class="color-circle c-tertiary"></div>
        <div title="warn" class="color-circle c-warn"></div>
        <!-- <div title="grey" class="color-circle c-grey"></div> -->
        <div title="lightgrey" class="color-circle c-lightgrey"></div>
        <div title="gradient" class="color-circle c-purple-gradient"></div>
        <div title="gradient-2" class="color-circle c-orange-gradient"></div>
      </div>
      <div class="section" fxLayout="column">
        <button mat-flat-button color="accent" title=" mat-flat-button color='accent'" (click)="testit()">fire
          debug</button>&nbsp;
        <button mat-flat-button color="primary" (click)="openPackageSelect()">select Package</button>
      </div>
      <!-- //original contents -->
      <div class="section" fxLayout="column">
        <p>Font:</p>
        <div fxLayout="row" fxLayoutAlign="space-evenly center">
          <h1>h1 title</h1>
          <h2>h2 title</h2>
          <h3>h3 title</h3>
          <h4>h4 title</h4>
          <h5>h5 title</h5>
          <h6>h6 title</h6>
        </div>
        <p><a href="./demo">default url</a></p>

        <p class="subtitle-1">this is a subtitle 1</p>
        <p>P content default size</p>

        <p class="subtitle-2">this is a subtitle 2</p>
        <p>P content default size</p>

      </div>
      <div class="section">
        <h3>Icons</h3>
        <div fxLayour="row" fxLayoutGap="10px">
          <mat-icon class="clickable" title="more_vert">more_vert</mat-icon>
          <mat-icon class="clickable" title="save_alt">save_alt</mat-icon>
          <mat-icon class="clickable" title="pageview">pageview</mat-icon>
          <mat-icon class="clickable" title="phone">phone</mat-icon>
          <mat-icon class="clickable" title="build">build</mat-icon>
          <mat-icon class="clickable" title="credit_card">credit_card</mat-icon>
          <mat-icon class="clickable" title="vpn_key">vpn_key</mat-icon>
          <mat-icon class="clickable" title="poll">poll</mat-icon>

          <i [style.font-size.px]="16" title="i class='fas fa-spinner'" class="fas fa-spinner"></i> <i
            [style.font-size.px]="16" title="i class='fas fa-spinner fa-spin'" class="fas fa-spinner fa-spin"></i>
          <i [style.font-size.px]="16" title="i class='fas fa-sync'" class="fas fa-sync"></i><i
            [style.font-size.px]="16" title="i class='fas fa-sync fa-spin'" class="fas fa-sync fa-spin"></i>
        </div>
      </div>

      <div class="section">
        <h3>Notifications and Modals</h3>
        <button [style.margin-right.px]="10" (click)="displayDemoSnackbar()" mat-raised-button
          class="custom-button-base small-btn">Snackbar</button>
        <button [style.margin-right.px]="10" (click)="openExampleModal()" mat-raised-button
          class="custom-button-base small-btn">Open
          Modal</button>

        <button [style.margin-right.px]="10" (click)="openDragableExampleModal()" mat-raised-button
          class="custom-button-base small-btn">Open
          Dragable Modal</button>
<!-- 
         <button [style.margin-right.px]="10" [routerLink]="['/demo', {outlets: {side:['demorightmenu']}}]"
          mat-raised-button class="custom-button-base small-btn">RH nav menu (namedRouter)</button>  -->

        <button [style.margin-right.px]="10" mat-raised-button class="custom-button-base small-btn"
          (click)="openRHMenu()">Open
          RH menu (cdkPortal)</button>
      </div>


      <div class="section">
        <h3>Drop down Menu</h3>
        <button mat-button color="primary" title="mat-button color='primary'">Show normal</button>
        <button mat-button color="primary" title="mat-button color='primary'">Show small</button>
      </div>


      <div class="section">
        <h3>Buttons</h3>


        <div [style.margin-top.px]="10">
          <!-- //Hyperlinks -->
          <a href="" class="no-underline">Hyperlinks</a>
        </div>


        <div [style.margin-top.px]="10" (click)="copyInputToClipboard('button mat-stroked-button')">
          <!-- //regular -->
          <button mat-stroked-button title="mat-stroked-button">Regular</button> &nbsp;
          <button disabled mat-stroked-button title="mat-stroked-button">Disabled</button>
        </div>


        <div [style.margin-top.px]="10">
          <!-- //Hero -->
          <button mat-flat-button color="accent" title=" mat-flat-button color='accent'"
            (click)="copyInputToClipboard('button color=accent')">Accent</button> &nbsp;
          <button disabled mat-flat-button color="accent" title=" mat-flat-button color='accent'">Disabled</button>
        </div>



        <div [style.margin-top.px]="10">
          <!-- //Hero Link -->
          <button mat-button color="accent" (click)="copyInputToClipboard('mat-button color=accent')">Accent
            Basic</button> &nbsp;
          <button disabled mat-button color="accent" (click)="copyInputToClipboard('mat-button color=accent')">Accent
            Basic Disabled </button>
        </div>

        <div [style.margin-top.px]="10">
          <!-- //Arrow button -->
          <button mat-icon-button color="accent" title="mat-icon-button"
            (click)="copyInputToClipboard('mat-icon-button color=accent &rarr;')">
            <span style="font-size:32px;">&rarr;</span>
          </button>
        </div>

        <div [style.margin-top.px]="10">
          <!-- //Regular alternative -->
          <button mat-flat-button color="primary" title=" mat-flat-button color='primary'"
            (click)="copyInputToClipboard('mat-flat-button color=primary')">Regular alternative</button> &nbsp;
          <button disabled mat-flat-button color="primary" title=" mat-flat-button color='primary'"
            (click)="copyInputToClipboard('mat-flat-button color=primary')">Disabled</button>
        </div>

        <div [style.margin-top.px]="10">
          <!-- //Regular action -->
          <button mat-flat-button class="accent-alt-button " title=" mat-flat-button"
            (click)="copyInputToClipboard('mat-flat-button accent-alt-button')">Action</button> &nbsp;
          <button disabled mat-flat-button title=" mat-flat-button"
            (click)="copyInputToClipboard('mat-flat-button accent-alt-button ')">Disabled</button>
        </div>

        <div [style.margin-top.px]="10">
          <button mat-button title="mat-button" (click)="copyInputToClipboard('mat-button')">Back</button>&nbsp;
          <button disabled=true mat-button color="primary" title="mat-button color='primary'">disabled</button>
        </div>

        <div [style.margin-top.px]="10">
          <!-- //Size  regular , btn-sm and btn-lg -->
        </div>


        <!-- 
      <div [style.margin-top.px]="10">
        <button mat-stroked-button title="mat-stroked-button">mat-stroked-button</button>&nbsp;
        <button disabled=true mat-stroked-button title="mat-stroked-button">mat-stroked-button disabled</button>
      </div>

      <div [style.margin-top.px]="10">
        <button mat-flat-button color="accent" title=" mat-flat-button color='accent'">mat-flat-button</button>&nbsp;
        <button disabled=true mat-flat-button color="accent"
          title=" mat-flat-button color='accent'">mat-flat-button disabled</button>
      </div>

      <div [style.margin-top.px]="10">
        <button mat-flat-button color="primary" title=" mat-flat-button color='primary'">mat-flat-button</button>&nbsp;
        <button disabled=true mat-flat-button color="primary"
          title=" mat-flat-button color='priary'">mat-flat-button disabled</button>
      </div>

      <div [style.margin-top.px]="10">
        <button mat-mini-fab color="primary" title="mat-mini-fab color='warn'">
          <mat-icon svgIcon="clear"></mat-icon>
        </button>

        <button mat-icon-button title="mat-icon-button ">
          <mat-icon>more_vert</mat-icon>
        </button>

      </div> -->


      </div>

      <div class="section">
        <h3>Phone number Region and Validation</h3>

        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" [style.width.px]="400">
            <input #testnumber type="text" matInput placeholder="Enter Phone Number"
              (keyup)="returnAreaCode($event.target.value)">
            <mat-label>Number</mat-label>
          </mat-form-field>

          <div [style.margin-left.px]="30">
            display as: {{testnumber.value | normalizeToInternational}}
          </div>
        </div>
        <br>
        <div>
          <div><b>Valid mobile <mat-icon class="google-blue" *ngIf="validMobile">check_circle</mat-icon></b></div>
          <div>permitted region: {{areacode?.signupAllowed}}</div>
          <div>valid format: {{validMobile}}</div>
        </div>
        <br>
        <div>
          <div><b>Valid landline <mat-icon class="google-blue" *ngIf="validLandline">check_circle</mat-icon></b></div>
          <div>permitted region: {{areacode?.signupAllowed}}</div>
          <div>valid Format: {{validLandline}} </div>
        </div>

        <br>
        <div>
          <div><b>Returned Areacode</b></div>
          <div>code: {{areacode?.code}}</div>
          <div>name: {{areacode?.name}}</div>
          <div>viewValue: {{areacode?.viewValue}}</div>
          <div>region: {{areacode?.region}}</div>
          <div>outofcountrycode: {{areacode?.outOfCountryCode}}</div>
          <div>internationCode: {{areacode?.internationalCode}}</div>
          <div>mobileFormat: <span *ngFor="let ac of areacode?.internationalMobile">{{ac}},</span></div>
          <div>displayFormat: {{areacode?.displayFormat}}</div>
          <div>signupAllowed: {{areacode?.signupAllowed}}</div>
        </div>
      </div>

      <div class="section">
        <h3>Reactive Form Validations</h3>
        <form [formGroup]="demoForm">

          <mat-form-field appearance="outline">
            <input type="text" matInput placeholder="mobile" autocomplete="mobile" formControlName="mobile">

            <mat-label>UK mobile</mat-label>
            <mat-error *ngIf="demoForm?.controls.mobile?.touched && demoForm?.controls.mobile?.errors?.required">
              Mobile is required</mat-error>
            <mat-error class="errormsg" *ngIf="demoForm.get('mobile').status === 'PENDING'">
              checking ...
            </mat-error>
            <mat-error *ngIf="demoForm.get('mobile').touched && demoForm.get('mobile').errors?.mobileTaken">
              Mobile already registered with an account.
            </mat-error>
            <mat-error *ngIf="demoForm.get('mobile').touched && demoForm.get('mobile').errors?.valid">
              {{demoForm?.controls.mobile?.errors?.valid}}
            </mat-error>
            <status-icons *ngIf="demoForm?.controls.mobile?.touched" [iconStatus]="demoForm.get('mobile').status">
            </status-icons>
          </mat-form-field>

        </form>

      </div>

      <div>
        <div *ngFor="let number of displayNumbers">
          {{number | normalizeToInternational}}
        </div>
      </div>

      <div class="section form-field-custom" [style.margin-top.px]="50" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field class="custom-form-field" floatLabel="always" appearance="outline">
          <input type="text" matInput placeholder="First Name">
          <mat-label>First Name</mat-label>
        </mat-form-field>

        <mat-form-field class="custom-form-field" floatLabel="always">
          <input type="text" matInput>
          <mat-label>Last Name</mat-label>
        </mat-form-field>

        <mat-form-field class="custom-form-field">
          <input type="text" matInput placeholder="Email">
          <mat-label>Email</mat-label>
        </mat-form-field>
      </div>

      <div class="section form-field-custom" [style.margin-top.px]="50" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field class="custom-form-field" floatLabel="always">
          <input type="text" matInput placeholder="First Name" disabled=true>
          <mat-label>First Name</mat-label>
        </mat-form-field>

        <mat-form-field class="custom-form-field" floatLabel="always">
          <input type="text" matInput disabled=true value="Haque">
          <mat-label>Last Name</mat-label>
        </mat-form-field>

        <mat-form-field class="custom-form-field">
          <input type="text" matInput placeholder="Email" disabled=true>
          <mat-label>Email</mat-label>
        </mat-form-field>
      </div>


      <div class="section">
        <div [style.margin-bottom.px]="20">
          <mat-button-toggle-group [(ngModel)]="formState" name="fontStyle" aria-label="Font Style">
            <mat-button-toggle value="normal">Normal</mat-button-toggle>
            <mat-button-toggle value="disable">Disable</mat-button-toggle>
            <mat-button-toggle value="readonly">Readonly</mat-button-toggle>
          </mat-button-toggle-group>
          <!-- 
      <button (click)="readonly = true" mat-flat-button>Make Readonly</button>
      <button (click)="readonly = false" mat-flat-button>Make Readoable</button> -->
        </div>
        <div>
          Select -> value selected
          <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'readonly-block': formState==='readonly'}">
            <mat-select placeholder="placeholder" value="test" [disabled]="formState==='disable'">
              <mat-option value="test">Option value
              </mat-option>
            </mat-select>
            <mat-label>mat-label</mat-label>
          </mat-form-field>
        </div>


        <div>
          Select -> label visible
          <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'readonly-block': formState==='readonly'}">
            <mat-select placeholder="placeholder" [disabled]="formState==='disable'">
              <mat-option value="test">Option value
              </mat-option>
            </mat-select>
            <mat-label>mat-label</mat-label>
          </mat-form-field>
        </div>

        <div>
          Mat input -> placeholder visible
          <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'readonly-block': formState==='readonly'}">
            <input type="tel" matInput placeholder="Placeholder" [disabled]="formState==='disable'">
            <!-- <mat-label>Readonly Input</mat-label> -->
          </mat-form-field>
        </div>

        <div>
          Mat input -> value visible
          <mat-form-field appearance="outline" fxFlex="100" [ngClass]="{'readonly-block': formState==='readonly'}">
            <input type="tel" matInput placeholder="placeholder" value="something here"
              [disabled]="formState==='disable'">
            <mat-label>mat-label</mat-label>
          </mat-form-field>
        </div>

        <div>
          <mat-checkbox labelPosition="before" [ngClass]="{'readonly-block': formState==='readonly'}"
            [disabled]="formState==='disable'">
            Is blocked
          </mat-checkbox>
        </div>

        <div>
          Mat input -> value visible
          <mat-form-field fxFlex="260px" [ngClass]="{'readonly-block': formState==='readonly'}">
            <input type="tel" matInput placeholder="placeholder" value="something here"
              [disabled]="formState==='disable'">
            <mat-label>mat-label</mat-label>
          </mat-form-field>
        </div>


      </div>

      <div class="section">
  
  
        <div fxLayout="column" fxLayoutAlign="start center" fxFlex="100%">
          <div>
            <h3>custom-form-field-v3</h3>
          </div>
      
          <div style="margin-top: 40px;" fxLayoutAlign="start">
            <h5>Read Only</h5>
          </div>
      
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px">
      
            <mat-form-field appearance="outline" class="custom-form-field-v3">
              <input type="text" matInput placeholder="placeholder" readonly>
              <mat-label>label</mat-label>
              <mat-hint>Read only. No value.</mat-hint>
            </mat-form-field>
      
            <mat-form-field appearance="outline" class="custom-form-field-v3">
              <input type="text" matInput placeholder="placeholder" value="Value" readonly>
              <mat-label>label</mat-label>
              <mat-hint>Read only. Has value.</mat-hint>
            </mat-form-field>
      
      
            <mat-form-field appearance="outline" class="custom-form-field-v3">
              <input type="text" matInput placeholder="placeholder" readonly>
              <!-- <mat-label>label</mat-label> -->
              <mat-hint>Read only no label. No value</mat-hint>
            </mat-form-field>
      
          </div>
      
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px">
      
            <mat-form-field appearance="outline" class="custom-form-field-v3 readonly-block">
              <input type="text" matInput placeholder="placeholder" >
              <mat-label>label</mat-label>
              <mat-hint>Read only block method. No value.</mat-hint>
            </mat-form-field>
      
            <mat-form-field appearance="outline" class="custom-form-field-v3 readonly-block">
              <input type="text" matInput placeholder="placeholder" value="Value" readonly>
              <mat-label>label</mat-label>
              <mat-hint>Read only block method. Has value.</mat-hint>
            </mat-form-field>
      
      
            <mat-form-field appearance="outline" class="custom-form-field-v3 readonly-block">
              <input type="text" matInput placeholder="placeholder" readonly>
              <!-- <mat-label>label</mat-label> -->
              <mat-hint>only block method. no label. No value</mat-hint>
            </mat-form-field>
      
          </div>
      
      
          <div style="margin-top: 40px;" fxLayoutAlign="start">
            <h5>Disabled</h5>
          </div>
      
      
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px" >
      
            <mat-form-field appearance="outline" class="custom-form-field-v3">
              <input type="text" matInput placeholder="placeholder" disabled>
              <mat-label>label</mat-label>
              <mat-hint> disabled. No value.</mat-hint>
            </mat-form-field>
      
            <mat-form-field appearance="outline" class="custom-form-field-v3">
              <input type="text" matInput placeholder="placeholder" value="Value" disabled>
              <mat-label>label</mat-label>
              <mat-hint> disabled. Has value.</mat-hint>
            </mat-form-field>
      
      
            <mat-form-field appearance="outline" class="custom-form-field-v3">
              <input type="text" matInput placeholder="placeholder" disabled>
              <!-- <mat-label>label</mat-label> -->
              <mat-hint>disabledl. No value</mat-hint>
            </mat-form-field>
      
          </div>
      
          <div style="margin-top: 40px;" fxLayoutAlign="start">
            <h5>Standard Input</h5>
          </div>
      
      
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px" >
      
            <mat-form-field appearance="outline" class="custom-form-field-v3">
              <input type="text" matInput placeholder="placeholder">
              <mat-label>label</mat-label>
              <mat-hint>No value.</mat-hint>
            </mat-form-field>
      
            <mat-form-field appearance="outline" class="custom-form-field-v3">
              <input type="text" matInput placeholder="placeholder" value="Value">
              <mat-label>label</mat-label>
              <mat-hint> Has value.</mat-hint>
            </mat-form-field>
      
            <mat-form-field appearance="outline" class="custom-form-field-v3">
              <input type="text" matInput placeholder="placeholder" >
              <!-- <mat-label>label</mat-label> -->
              <mat-hint>No Label. No value</mat-hint>
            </mat-form-field>
      
          </div>

          <div style="margin-top: 40px;" fxLayoutAlign="start">
            <h5>Mobile Number Input</h5>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px" >
      
            <mat-form-field appearance="outline" fxFlex="100" class="custom-form-field-v3 outline-simple-ff number-code-select">
              <mat-select disabled value="+44">
                <mat-option value="+44">+44</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="custom-form-field-v3">
              <input type="text" matInput placeholder="Enter mobile">
            </mat-form-field>
    
      
          </div>

          <div style="margin-top: 40px;" fxLayoutAlign="start">
            <h5>Select Box</h5>
          </div>
      
      
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px" >
      
            <mat-form-field appearance="outline" fxFlex="100" class="custom-form-field-v3 readonly-block">
              <mat-select placeholder="placeholder">
                <mat-option value="test">Option 1
                </mat-option>
              </mat-select>
              <mat-label>mat-label</mat-label>
              <mat-hint>readonly block no value selected</mat-hint>
            </mat-form-field>


            <mat-form-field appearance="outline" fxFlex="100" class="custom-form-field-v3 readonly-block">
              <mat-select placeholder="placeholder" value="test" >
                <mat-option value="test">Option 1
                </mat-option>
              </mat-select>
              <mat-label>mat-label</mat-label>
              <mat-hint>readonly block value selected</mat-hint>
            </mat-form-field>

            
            <mat-form-field appearance="outline" fxFlex="100" class="custom-form-field-v3" >
              <mat-select placeholder="placeholder" disabled >
                <mat-option value="test">Option 1
                </mat-option>
              </mat-select>
              <mat-label>mat-label</mat-label>
              <mat-hint>disabled no value selected</mat-hint>
            </mat-form-field>

                        
            <mat-form-field appearance="outline" fxFlex="100" class="custom-form-field-v3" >
              <mat-select placeholder="placeholder" value="test" disabled >
                <mat-option value="test">Option 1
                </mat-option>
              </mat-select>
              <mat-label>mat-label</mat-label>
              <mat-hint>disabled  value selected</mat-hint>
            </mat-form-field>
      
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px" >
      
            <mat-form-field appearance="outline" fxFlex="100" class="custom-form-field-v3">
              <mat-select placeholder="placeholder" value="test" >
                <mat-option value="test">Option value
                </mat-option>
              </mat-select>
              <mat-label>mat-label</mat-label>
              <mat-hint>regular selected</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100" class="custom-form-field-v3">
              <mat-select placeholder="placeholder"  >
                <mat-option value="test">Option value
                </mat-option>
                <mat-option value="test1">Option value
                </mat-option>
              </mat-select>
              <mat-label>mat-label</mat-label>
              <mat-hint>regular nothing selected</mat-hint>
            </mat-form-field>

      

      
          </div>

          <div style="margin-top: 40px;" fxLayoutAlign="start">
            <h5>Outlined-white-input</h5>
          </div>
      
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px" >

            <mat-form-field appearance="outline" class="custom-form-field-v3 outline-simple-ff">
              <input type="text" matInput placeholder="placeholder">
              <mat-label>label</mat-label>
              <mat-hint>Regular</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="custom-form-field-v3 outline-simple-ff">
              <input type="text" matInput placeholder="placeholder" readonly>
              <mat-label>label</mat-label>
              <mat-hint>readonly default</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="custom-form-field-v3 outline-simple-ff readonly-block">
              <input type="text" matInput placeholder="placeholder">
              <mat-label>label</mat-label>
              <mat-hint>readonly block</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="custom-form-field-v3 outline-simple-ff">
              <input type="text" matInput placeholder="placeholder" disabled>
              <mat-label>label</mat-label>
              <mat-hint>disabled</mat-hint>
            </mat-form-field>


          </div>


          <div style="margin-top: 40px;" fxLayoutAlign="start">
            <h5>Select Box -  outline-simple-ff</h5>
          </div>
      
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px">

            <mat-form-field appearance="outline" fxFlex="100" class="custom-form-field-v3 outline-simple-ff">
              <mat-select placeholder="placeholder">
                <mat-option value="test">Option 1
                </mat-option>
              </mat-select>
              <mat-label>mat-label</mat-label>
              <mat-hint>regular no value selected</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100" class="custom-form-field-v3 readonly-block outline-simple-ff">
              <mat-select placeholder="placeholder">
                <mat-option value="test">Option 1
                </mat-option>
              </mat-select>
              <mat-label>mat-label</mat-label>
              <mat-hint>readonly block no value selected</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100" class="custom-form-field-v3 outline-simple-ff">
              <mat-select placeholder="placeholder" disabled>
                <mat-option value="test">Option 1
                </mat-option>
              </mat-select>
              <mat-label>mat-label</mat-label>
              <mat-hint>disabled no value selected</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100" class="custom-form-field-v3 outline-simple-ff">
              <mat-select placeholder="placeholder" disabled value="test">
                <mat-option value="test">Option 1
                </mat-option>
              </mat-select>
              <mat-label>mat-label</mat-label>
              <mat-hint>disabled value selected</mat-hint>
            </mat-form-field>
            
          </div>

          <div style="margin-top: 40px;" fxLayoutAlign="start">
            <h5>Suffix</h5>
          </div>
      
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px">

            <mat-form-field appearance="outline" class="custom-form-field-v3">
              <input type="text" matInput placeholder="placeholder">
              <mat-label>label</mat-label>
              <mat-hint>regular -No value.</mat-hint>
              <mat-icon class="clickable" matSuffix svgIcon="copy" (click)="copyInputToClipboard('test')"></mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class="custom-form-field-v3 readonly-block">
              <input type="text" matInput placeholder="placeholder">
              <mat-label>label</mat-label>
              <mat-hint>readonly blocked.</mat-hint>
              <mat-icon class="clickable" matSuffix svgIcon="copy" (click)="copyInputToClipboard('test')"></mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class="custom-form-field-v3 ">
              <input type="text" matInput placeholder="placeholder" disabled>
              <mat-label>label</mat-label>
              <mat-hint>Disabled.</mat-hint>
              <mat-icon class="clickable" matSuffix svgIcon="copy" (click)="copyInputToClipboard('test')"></mat-icon>
            </mat-form-field>

          </div>

          <div style="margin-top: 40px;" fxLayoutAlign="start">
            <h5>Preffix</h5>
          </div>
      
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px">

            <mat-form-field appearance="outline" class="custom-form-field-v3">
              <input type="text" matInput placeholder="placeholder">
              <mat-label>label</mat-label>
              <mat-hint>regular -No value.</mat-hint>
              <mat-icon  matPrefix svgIcon="search" ></mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class="custom-form-field-v3 readonly-block">
              <input type="text" matInput placeholder="placeholder">
              <mat-label>label</mat-label>
              <mat-hint>readonly blocked.</mat-hint>
              <mat-icon  matPrefix svgIcon="search" ></mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class="custom-form-field-v3 ">
              <input type="text" matInput placeholder="placeholder" disabled>
              <mat-label>label</mat-label>
              <mat-hint>Disabled.</mat-hint>
              <mat-icon  matPrefix svgIcon="search" ></mat-icon>
            </mat-form-field>

          </div>

          <div style="margin-top: 40px;" fxLayoutAlign="start">
            <h5>display-only-ff</h5>
          </div>
      
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px">

            <mat-form-field appearance="outline" class="custom-form-field-v3 display-only-ff readonly-block">
              <input type="text" matInput placeholder="placeholder" value="An ID Value">
              <!-- <mat-label>label</mat-label> -->
              <mat-icon class="clickable" matSuffix svgIcon="copy" (click)="copyInputToClipboard('test')"></mat-icon>
              <mat-hint>Read only. No background</mat-hint>
            </mat-form-field>
          </div>

        </div>
      </div>

      <div class="section">
        <div>
          <button mat-flat-button (click)="refreshTokenTest()">RefreshToken Test</button>
        </div>
      </div>


      <div class="section">
        <div>
          ID Token test
        </div>
        <div>
          <div>Name: {{userIdentification.name}}</div>
          <div>Email: {{userIdentification.email}}</div>
          <div>Email Verified: {{userIdentification.email_verified}}</div>
          <div>User_id: {{userIdentification.user_id}}</div>
          <div>Org: {{userIdentification.org}}</div>
          <div>is_system_admin: {{userIdentification.roles?.includes('system_admin')}}</div>
          <div>is_customer_admin: {{userIdentification.roles?.includes('customer_admin')}}</div>
          <div>expiration date: {{userIdentification.expirationDate}}</div>
        </div>
      </div>

      
    </div>
  </div>
</div>




