import { NgModule } from '@angular/core';

//requirements
import { MaterialModule } from '@app/material/material.module';
import { CommonModule } from '@angular/common';
// import { ReactiveFormsModule, FormsModule } from '@angular/forms';


// declartation
import { FormBasicControlsComponent } from './form-basic-controls.component';

@NgModule({
    imports: [
        MaterialModule,
        CommonModule
    ],
    declarations: [FormBasicControlsComponent],
    exports: [FormBasicControlsComponent],
    providers: [],
    entryComponents: [FormBasicControlsComponent]
})
export class FormBasicControlsModule { }