<div class="payment-container fx-row fx-wrap fx-col-sm">

  <button mat-mini-fab style="background-color:#ababab;" class="close-modal" (click)="closeModal(false)"
    *ngIf="formState!='success'" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>

  <ng-container>
    <div class="fx-width-100 fx-center fx-col"  [hidden]="formState !== 'success'">
      <div>
        <mat-icon [@tadaOnEnter] *ngIf="formState==='success'" class="sc-message-icon">check_circle_outline</mat-icon>
      </div>
      <div class="sc-message">Payment successful. You may now close this window.</div>
      <button mat-stroked-button (click)="closeModal(true)">Close</button>
    </div>
  </ng-container>


  <div class="layout-left-panel fx-width-50 fx-width-100-md" [hidden]="formState==='success'">

    <div class="left-panel-content" [ngClass]="{'height-fitcontent': formState != 'cart' && this.screen === 'mobile'}">

      <ng-container>
        <div [hidden]="formState!='cart'">

          <div class="modal-title">
            <h5 class="title-color">Apply licences and contracts </h5>
            <div class="fx-col">
              <div [ngClass]="{'mobile-font-10' : this.screen === 'mobile'}">
                <p>Assign a licence to each of your PiP users. You can mix and match licences and contracts according to
                  the needs of each person. <a (click)="openPaymentTerms()" href="javascript:void(0)">Read more
                    payment terms</a></p>
              </div>
            </div>

            <div class="text-center selections-confirmed">
              Selections confirmed {{cart?.length}} / {{unlicensedList?.length}}
            </div>

            <div [style.margin-top.px]="10" class="terms-conditions-mobile fx-row fx-justify-space-evenly fx-align-center fx-hide fx-show-sm">
              <button mat-button (click)="openTermsConditionsModal()">Terms and Conditions</button>
            </div>

          </div>

          <ng-template #spinning>
            <span class="loading-spinner">
              <div class="loading-spinner-1" style="width:90px;margin-top:20px;">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
                <div class="bounce4"></div>
              </div>
            </span>
          </ng-template>

          <ng-container *ngIf="!pageStatus.isLoading;else spinning">

            <app-payment-user-list *ngFor="let user of unlicensedList" [user]="user" [disableForm]="disableForm"
              [applyToAllEvent]="applyToAllEvent" [licenseList]="licenseList" (_selectedLicense)="updateCart($event)">
            </app-payment-user-list>

          </ng-container>
        </div>

      </ng-container>

      <ng-container *ngTemplateOutlet="finalInvoiceTemplate"></ng-container>
      <div #paymentMobileView></div>
      <!-- <div class="padding-bottom-250"></div> -->
    </div>

  </div>

  <div class="layout-right-panel fx-col fx-justify-center fx-align-end fx-width-50 fx-hide fx-show-md" [hidden]="formState==='success'">


    <div #paymentDesktopView>

      <div id="paymentContainer" #paymentContainer class="layout-right-bottom- fx-col fx-justify-end fx-justify-center fx-align-end" [hidden]="unlicensedList.length < 1"
        [ngClass]="{showborder: cart.length === unlicensedList.length && cart.length != 0}"
        >

        <ng-container *ngTemplateOutlet="checkoutBanner"></ng-container>

        <div class="payment-logos fx-col fx-center">

          <div>
            <span class="payment-logo"><img src="/assets/img//payment-logo/1.png"></span>
            <span class="payment-logo"><img src="/assets/img//payment-logo/2.png"></span>
            <span class="payment-logo"><img src="/assets/img//payment-logo/22.png"></span>
          </div>
        </div>
      </div>

    </div>

  </div>



  <ng-template #finalInvoiceTemplate>
    <div class="invoice-summary fx-col fx-center fx-width-100" [hidden]="formState!='pay'">

      <div class="invoice-licenses">
        <span class="subtitle-16">Summary</span>
        <ul>
          <li *ngFor="let item of invoice"  class="fx-row fx-justify-end fx-align-center" >
            <div>
              <h6 class="title-color"><span>{{item.quantity}} x </span>{{item.displayString}} ({{item.billingCycle}})
              </h6>
            </div>
            <div class="cost">{{(item.quantity * item.total)/100 | currency:'GBP':'symbol':'1.2-2'}}</div>
          </li>

        </ul>
      </div>

      <ng-container *ngIf="includeAddOns">

        <div class="invoice-packages">
          <ul>
            <li class="fx-row fx-justify-end fx-align-center"  *ngFor="let item of addOnPackageList">
              <div>
                <span>Shared {{item.name}}</span><br>
                <span [style.font-size.px]="12" style="color:var(--c-placeholder);">per {{item.quantity}} at
                  {{ item.price/100 | currency:'GBP':'symbol':'1.2-2'}} per package</span>
                </div>
                <div class="cost">{{ 0 | currency:'GBP':'symbol':'1.2-2'}}</div>
              </li>
            </ul>
          </div>
        </ng-container>




    </div>
  </ng-template>

  <ng-template #checkoutBanner>

    <div class="total-cost-container">
      <div>Subtotal: £{{((cartTotalPrice.total))}}</div>
      <div>Estimated Tax: £{{((cartTotalPrice.total * 0.2 | number:'1.2-2'))}}</div>
      <div>Monthly Subscription <span style="font-size: 0.7em;">(inc VAT)</span>: £{{subscriptionTotalPrice.total * 1.2  | number:'1.2-2'}}</div>
      <div *ngIf="formState=== 'cart'" class="tcc-4">Total: £{{cartTotalPrice.total * 1.20  | number:'1.2-2'}}</div>
      <div *ngIf="formState=== 'pay' || formState=== 'error'" class="tcc-4">Total: {{checkoutResponse.invoice_total/100 | currency:'GBP':'symbol':'1.2-2'}}</div>
      
    </div>
    


    <div class="stripe-element-container" style="font-size: 16px;">
      <app-payment-stripe-payment [hidden]="formState != 'pay'" [hideTitle]="true" [@slideInUpOnEnter]
        [@slideInUp] [@slideInDown] [checkoutResponse]="checkoutResponse" (checkoutEvent)="checkoutSucess($event)"
        (cancelCheckout)="formState='cart'">
      </app-payment-stripe-payment>
    </div>

    <div class="paymentbuttons" [hidden]="formState!='cart'">
      <button mat-button class="fx-hide-md" (click)="closeModal(false)">
        Cancel
      </button>
      <button class="accent-alt-button" [@heartBeat]="cart.length === unlicensedList.length && cart.length != 0"
        mat-flat-button (click)="continueCheckout()"
        [disabled]="!termsconditions || !cart || cart.length === 0 || unlicensedList.length === 0 || cart.length != unlicensedList.length">
        <ng-container *ngIf="checkoutIsLoading===true;else continueOption"><i class="fas fa-spinner fa-spin"></i>
        </ng-container>
        <ng-template #continueOption>Continue</ng-template>
      </button>

      <div class="corporate-contract-box">
        <div>This purchase is bound by the terms set out in our <a class="no-margin" target="_blank" href="https://www.pipcall.com/policies/corporatecustomercontract">Corporate Customer Contract</a>. </div>
        <mat-checkbox labelPosition="before"  [(ngModel)]="termsconditions"></mat-checkbox>
        <!-- <mat-checkbox labelPosition="before"  [(ngModel)]="termsconditions"
          style="font-size: 14px;" class="corporate-contract-box">
          This purchase is bound by the terms set out in our <a class="no-margin" target="_blank" href="https://www.pipcall.com/policies/corporatecustomercontract">Corporate Customer Contract</a>. <span class="no-margin"  style="font-weight:700;">I agree</span>
        </mat-checkbox> -->
      </div>

    </div>


  </ng-template>
