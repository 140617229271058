import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable, throwError } from 'rxjs';
import { Router, ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { UserService } from '@app/services/pipcall/user.service';
import { UserProfile, OrganisationUser } from '@app/models/user-profile.model';
import { OrganisationModel, OrganisationDdiList } from '@app/models/organisation.model';
import { AuthService } from '@app/services/auth-service/auth.service';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { AppTitleService } from '@app/services/helpers/update-title.service'
import { map, takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { CookieService } from 'ngx-cookie-service';
import { AppInsightService} from '@app/services/helpers/app-insights.service';
import { UpdateUserProfilePictureModalComponent} from '@app/components/modals/update-userprofile-picture-modal/update-userprofile-picture-modal.component';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatDialog } from '@app/material/material-essentials.module';
import { UserSessionService } from '@app/services/user-session.service';
import { OrgSessionService } from '@app/services/organisation-session.service';

@Component({
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    public userProfile: UserProfile;
    public organisationProfile: OrganisationModel;
    public organisation_id: string;


    //public organisationProfile: OrganisationModel;
    //public organisation_id: string; //org id from route param


    public viewAs = 'user' //owner,admin or user. set to owner if matches on return of data.


    public _subsciption: Subscription;

    //tuturial
    public tuturialCompleted = false;
    public onBoardingStage = 0;

    public pageStatus = {
        activeTab: 'overview',
        isLoading: false,
        noContent: false
    }


    _destroy$ = new Subject<void>(); //handles unsubscribing events

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private authService: AuthService,
        private snackBar: MatSnackBar,
        private appTitleService: AppTitleService,
        private cookieService: CookieService,
        private ref: ChangeDetectorRef,
        public dialog: MatDialog,
        private userSessionService: UserSessionService,
        private orgSessionService: OrgSessionService
    ) {
        this.pageStatus.isLoading = true;
        this.userProfile = new UserProfile();
        this.appTitleService.setTitle('PiPcall: profile');
    }

    innit() {

    }
    ngOnInit() {

        this._subsciption = this.userSessionService.userProfileAsObservable
            .pipe(
                tap(() => this.pageStatus.isLoading = true)
            ).subscribe((resp) => {
                this.userProfile = resp;
                this.pageStatus.isLoading = false;

            })

        this.orgSessionService.organisationProfileAsObservable
            .pipe(
                takeUntil(this._destroy$)
            ).subscribe((resp) => {
                this.organisationProfile = resp;
                this.organisation_id = this.organisationProfile.id;
                // this.organisationProfileList = resp;
            })
    }


    ngAfterViewInit() {

        // setTimeout(() => {
        //     this.userProfile?.id ? null : this.setContentNotFound();
        // }, 5000);
    }


    changeTab(tab: string) {
        console.log("CHANGE TAB..............", tab);
        this.pageStatus.activeTab = tab;
        this.ref.detectChanges();
    }

    changeProfilePicture() {
        const dialogRef = this.dialog.open(UpdateUserProfilePictureModalComponent, {
            panelClass: 'pipcall-modal-container',
        });

        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                this.refreshData(true)
            }

        });
    }

    refreshData(event) {
        this.userSessionService.updateUserProfile();
        this.orgSessionService.updateOrganisationProfile();
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    updateProfileData() {
        this.orgSessionService.updateOrganisationProfile();
        this.userSessionService.updateUserProfile();
    }

    ngOnDestroy(): void {

        this._subsciption ? this._subsciption.unsubscribe() : '';

        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }


}
