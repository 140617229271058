import { Injectable } from '@angular/core';
import { HttpProxy } from '../http-proxy.service';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { responseModel } from '../../models/response.model';
import { map, tap, filter, catchError, mergeMap, finalize, distinctUntilChanged } from 'rxjs/operators';
import { PortalApiV2HelperService, QueryParams } from '@app/services/helpers/portal-api-v2-helper';
import { escape, unescape} from 'safe-string-literal';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { delay } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { Data } from '@angular/router';

//response model

export interface Cartitem {
    license_id: string;
    quantity: number;
}

export interface EsimCartResponse {
    invoice_total: number;
    stripe_client_secret: string;
}
export interface  Country {
    id: string;
    name: string;
    code: string;
    tags: string; //comma separated
}
export interface Region {
    id: string;
    name: string;
    countries: Country[];
}

//for list view
export interface BundleRegionItem {
    id: string; //id
    name: string; //name
    license_name: string;
    country_count: number;
}

export interface TelnaProduct {
    id: string; //region or country id
    name: string; //region or country name
    price: number;
    license_id: string;
    license_name: string; //use this for display
    license_contract_name: string;
    activation_time_days: number;
    data_allowance: number;
    duration_days: number;
    country_tags?: string[];
    country_code?: string;
}

export interface OrganisationDataEsims {
    id: string;
    organisation_id: string;
    license_id: string;
    license_name: string;
    contract_name: string;
    state: 'NEW' | 'PURCHASED' | 'PENDING' | 'READY' | 'INSTALLED' |'ACTIVE' | 'PENDING' | 'EXPIRED';
    telna_state: string;
    purchased_at: string;
    expires_at: string;
    user_email: string
    customer_notes: string;
    data_allowance: string;
    duration_days: string;
    is_bundle: boolean;
    is_topup: boolean;
    country_id: string;
    region_id: string;
    country_code: string;
    iccid: string;
    top_ups?: OrganisationDataEsims[];
}

export interface DataEsim {
    id: string;
    license_id: string;
    license_name: string;
    contract_name: string;
    state: 'NEW' | 'PURCHASED' | 'PENDING' | 'READY' | 'INSTALLED' |'ACTIVE' | 'PENDING' | 'EXPIRED';
    telna_state: 'NOT_ACTIVE' | 'ACTIVE' | 'TERMINATED';
    euicc_profile_state: string;
    iccid: string;
    purchased_at: string;
    expires_at: string;
    user_email: string;
    customer_notes: string;
    sim_profile: string;
    data_allowance: number; //in gb
    duration_days: number;
    country_id: string;
    region_id: string;
    is_topup: boolean;
    is_bundle: boolean,
    data_usage_remaining: number;
    data_usage_total: number; //in bytes
    reuse_max: number;
    reuse_remaining: number;
    activated_date: number;
    window_activation_start: number;
    window_activation_end: number;
    provisioned_date: number;
    sim_status: string; //maps to sim status
    countries: Country[];
    top_ups?: DataEsim[];
}



@Injectable()
export class EsimTelnaService {


    public ApiBaseUrl: string;
    public code = `?code=${environment.apicode}`;
    public options;

    private mockData: DataEsim = {
        "id": "6ae584e0-2ed4-ef11-88f8-0022483f3252",
        "license_id": "c633a8a0-4891-ef11-88cd-000d3a7ef68b",
        "license_name": "United Kingdom",
        "contract_name": "1 GB 5 Days",
        "state": "ACTIVE",
        "telna_state": "ACTIVE",
        "euicc_profile_state": "ENABLED",
        "purchased_at": "2025-01-16T17:26:10.7633432+00:00",
        "expires_at": "2025-01-27T11:03:42+00:00",
        "iccid": "8931440400000000000",
        "user_email": "john.mcarthur@pipcall.com",
        "customer_notes": null,
        "sim_profile": "LPA:1$consumer.e-sim.global$TN202405151216376D76FD",
        "data_allowance": 1,
        "duration_days": 5,
        "country_id": "1fbdbc91-4891-ef11-88cd-000d3a7ef68b",
        "region_id": null,
        "is_bundle": false,
        "is_topup": false,
        "data_usage_remaining": 1073741824,
        "data_usage_total": 1073741824,
        "reuse_max": 5,
        "reuse_remaining": 5,
        "activated_date": 1737543812000,
        "window_activation_start": 1737048375000,
        "window_activation_end": 1768584375000,
        "provisioned_date": 1729777859000,
        "sim_status": "in_service",
        "countries": [
            {
                "id": "1fbdbc91-4891-ef11-88cd-000d3a7ef68b",
                "name": "United Kingdom",
                "code": "GBR",
                "tags": "United Kingdom"
            }
        ],
        "top_ups": [
            {
                "id": "6ae584e0-2ed4-ef11-88f8-0022483g45621",
                "license_id": "c633a8a0-4891-ef11-88cd-000d3a7ef68b",
                "license_name": "United Kingdom",
                "contract_name": "10 GB 10 Days",
                "state": "INSTALLED",
                "telna_state": "NOT_ACTIVE",
                "euicc_profile_state": "ENABLED",
                "purchased_at": "2025-01-26T17:26:10.7633432+00:00",
                "expires_at": "2026-01-07T11:28:45+00:00",
                "iccid": "8931440400000000000",
                "user_email": "john.mcarthur@pipcall.com",
                "customer_notes": null,
                "sim_profile": "LPA:1$consumer.e-sim.global$TN202405151216376D76FD",
                "data_allowance": 10,
                "duration_days": 10,
                "country_id": "1fbdbc91-4891-ef11-88cd-000d3a7ef68b",
                "region_id": null,
                "is_bundle": false,
                "is_topup": true,
                "data_usage_remaining": 1073741824,
                "data_usage_total": 1767785325000,
                "reuse_max": 5,
                "reuse_remaining": 5,
                "activated_date": 1737543812000,
                "window_activation_start": 1737048375000,
                "window_activation_end": 1768584375000,
                "provisioned_date": 1729777856000,
                "sim_status": "pre_service",
                "countries": [
                    {
                        "id": "1fbdbc91-4891-ef11-88cd-000d3a7ef68b",
                        "name": "United Kingdom",
                        "code": "GBR",
                        "tags": "United Kingdom"
                    }
                ],
            },
            {
                "id": "6ae584e0-2ed4-ef11-88f8-0022483g456451",
                "license_id": "c633a8a0-4891-ef11-88cd-000d3a7ef68b",
                "license_name": "United Kingdom",
                "contract_name": "1 GB 5 Days",
                "state": "INSTALLED",
                "telna_state": "NOT_ACTIVE",
                "euicc_profile_state": "ENABLED",
                "purchased_at": "2025-01-26T17:26:10.7633432+00:00",
                "expires_at": "2026-01-07T11:28:45+00:00",
                "iccid": "8931440400000000000",
                "user_email": "john.mcarthur@pipcall.com",
                "customer_notes": null,
                "sim_profile": "LPA:1$consumer.e-sim.global$TN202405151216376D76FD",
                "data_allowance": 1,
                "duration_days": 5,
                "country_id": "1fbdbc91-4891-ef11-88cd-000d3a7ef68b",
                "region_id": null,
                "is_bundle": false,
                "is_topup": true,
                "data_usage_remaining": 1073741824,
                "data_usage_total": 1767785325000,
                "reuse_max": 5,
                "reuse_remaining": 5,
                "activated_date": 1737543812000,
                "window_activation_start": 1737048375000,
                "window_activation_end": 1768584375000,
                "provisioned_date": 1729777856000,
                "sim_status": "pre_service",
                "countries": [
                    {
                        "id": "1fbdbc91-4891-ef11-88cd-000d3a7ef68b",
                        "name": "United Kingdom",
                        "code": "GBR",
                        "tags": "United Kingdom"
                    }
                ],
            }
        ]
    }

    constructor(private httpProxy: HttpProxy, private portalApiV2HelperService: PortalApiV2HelperService) {
        this.ApiBaseUrl = environment.ApiBaseUrl;
    }

    public getRegionFlag(name: string): string {
        //possible strings
        //Latin America, Asia bundle 1 , Asia bundle 2, North America, North America 2 bundle , europe bundle

        const flags = [
            {'name': 'Europe', 'flag': 'europe'},
            {'name': 'Asia', 'flag': 'asia'},
            {'name': 'Africa', 'flag': 'africa'},
            {'name': 'North America', 'flag': 'north_america'},
            {'name': 'Latin America', 'flag': 'latin_america'},
            {'name': 'Latin Amercia', 'flag': 'latin_amercia'},
            {'name': 'Caribbean', 'flag': 'caribbean'},
            {'name': 'Oceania', 'flag': 'oceania'},
            {'name': 'Middle East', 'flag': 'middle_east'},
        ];

        // Remove any numbers and the word "bundle", then trim spaces and convert to lowercase
        const _name = name.replace(/[0-9]/g, '').replace(/\bbundle\b/gi, '').trim().toLowerCase();

        // Find the matching flag (case-insensitive)
        const flagItem = flags.find(f => f.name.toLowerCase() === _name);

        const flag = flagItem ? flagItem.flag : 'missing'; // Fallback if no mat

        return flag;
    }

    public getProductsByCountryId(country_id: string): Observable<HttpResponse<TelnaProduct[]>> {
        return this.httpProxy.get(this.ApiBaseUrl + `/countries/${country_id}/telna_licenses${this.code}`, 'response', false);
    }

    public getProductsByRegionId(region_id: string): Observable<HttpResponse<TelnaProduct[]>> {
        return this.httpProxy.get(this.ApiBaseUrl + `/regions/${region_id}/telna_licenses${this.code}`, 'response', false);
    }

    public getCountryById(country_id: string): Observable<HttpResponse<Country>> {
        return this.httpProxy.get(this.ApiBaseUrl + `/countries/${country_id}${this.code}`, 'response', false);
    }

    public getRegionById(id: string): Observable<HttpResponse<Region>> {
        return this.httpProxy.get(this.ApiBaseUrl + `/regions/${id}${this.code}`, 'response', false);
    }


    public getCountryList(has_telna_product: boolean): Observable<HttpResponse<Country[]>> {
        return this.httpProxy.get(this.ApiBaseUrl + `/countries${this.code}&has_telna_license=${has_telna_product}`, 'response', false);
    }

    public getRegionList(has_telna_product: boolean): Observable<HttpResponse<BundleRegionItem[]>> {
        return this.httpProxy.get(this.ApiBaseUrl + `/regions${this.code}&has_telna_license=${has_telna_product}`, 'response', false);
    }

    public getDataEsimsByID(org_id: string, esim_id: string): Observable<HttpResponse<DataEsim>> {

        //return of(new HttpResponse({ status: 200, body: this.mockData })).pipe(delay(1000));
        return this.httpProxy.get(this.ApiBaseUrl + `/organisations/${org_id}/telna_esims/${esim_id}${this.code}`, 'response', true);
    }

    public getDataEsimsForOrg(org_id: string): Observable<HttpResponse<OrganisationDataEsims[]>> {
        return this.httpProxy.get(this.ApiBaseUrl + `/organisations/${org_id}/telna_esims${this.code}`, 'response', true);
        // &includeArchived=true to show archived esims
    }

    public postTelnaEsimOrder(organisation_id: string, cart: Cartitem[]): Observable<HttpResponse<EsimCartResponse>> {
        const body = {
            cart
        }
        console.log("[[api]] -> post new esims order");
        return this.httpProxy.post(this.ApiBaseUrl + `/organisations/${organisation_id}/telna_esims/order${this.code}`, body, 'response', true);
    }

    public patchEsimNote(organisation_id: string, esim_id: string, customer_note: string): Observable<HttpResponse<any>> {
        console.log("[[api]] -> patch esim note");
        const body = {
            customer_notes: customer_note
        }
        return this.httpProxy.patch(this.ApiBaseUrl + `/organisations/${organisation_id}/telna_esims/${esim_id}/customer_notes${this.code}`, body, 'response');
    }

    public postSendEmailToInstalleSIM(organisation_id: string, esim_id: string, email: string): Observable<HttpResponse<any>> {
        console.log("[[api]] -> post send email");
        const body = {
            email: email
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/organisations/${organisation_id}/telna_esims/${esim_id}/email${this.code}`, body, 'response', true);
    }

    public deleteExpiredEsim(organisation_id: string, esim_id: string): Observable<HttpResponse<any>> {
        console.log("[[api]] -> delete expired esim");
        return this.httpProxy.delete(this.ApiBaseUrl + `/organisations/${organisation_id}/telna_esims/${esim_id}${this.code}`, 'response', true);
    }

    public postTopupEsim(organisation_id: string, org_telna_esim_id: string, license_id: string): Observable<HttpResponse<EsimCartResponse>> {
        console.log("[[api]] -> post topup esim");
        const body = {
            license_id: license_id
        }
        return this.httpProxy.post(this.ApiBaseUrl + `/organisations/${organisation_id}/telna_esims/${org_telna_esim_id}/top-up${this.code}`, body, 'response', true);
    }

}
