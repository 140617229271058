<div class="background-wrapper">
    <ng-container *ngIf="pageStatus.isLoading">
        <div class="full-page-view fx-col fx-center" >
            <div>
                <ng-container [ngTemplateOutlet]="loading">
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="pageStatus.stage === 0 && pageStatus.isLoading === false">
        <div class="full-page-view pipwave-background fx-col fx-justify-start fx-align-center">

            <div class="inner-container fx-col fx-justify-start fx-align-center" >

                <div class="pipcall-logo-container">
                    <img src="assets/brand/pip_logo_white.svg" alt="logo">
                </div>


                <div class="signup-form-container fx-col">
                    <div class="title-1">Sign up for a free trial</div>
                    <form class="fx-col fx-width-100"  [formGroup]="expoSignupForm" [ngClass]="{'readonly-block': pageStatus.isSubmitting}">

                        <div class="form-section-label">About you:</div>
                        <div class="fx-row fx-col-xs fx-width-100 fx-justify-space-between fx-gap-1">
                            <mat-form-field appearance="outline"
                                class="custom-form-field-v3 fx-width-40 fx-width-100-xs">
                                <input autocomplete="off" autocorrect="off" spellcheck="false" type="text" matInput
                                    placeholder="First name" [readonly]="pageStatus?.isSubmitting"
                                    formControlName="first_name">
                                <mat-error
                                    *ngIf="expoSignupForm?.controls.first_name?.touched && expoSignupForm?.controls.first_name?.errors?.required">
                                    First name
                                    is required</mat-error>
                                <status-icons *ngIf="expoSignupForm.controls.first_name?.touched"
                                    [iconStatus]="expoSignupForm.get('first_name').status">
                                </status-icons>
                            </mat-form-field>

                            <mat-form-field appearance="outline" 
                                class="custom-form-field-v3 fx-width-40 fx-width-100-xs">
                                <input autocomplete="off" autocorrect="off" spellcheck="false" type="text" matInput
                                    placeholder="Last name" [readonly]="pageStatus?.isSubmitting"
                                    formControlName="last_name">
                                <mat-error
                                    *ngIf="expoSignupForm?.controls.last_name?.touched && expoSignupForm?.controls.last_name?.errors?.required">
                                    Last name
                                    is required</mat-error>
                                <status-icons *ngIf="expoSignupForm.controls.last_name?.touched"
                                    [iconStatus]="expoSignupForm.get('last_name').status">
                                </status-icons>
                            </mat-form-field>
                        </div>

                        <div class="fx-width-100">
                            <mat-form-field appearance="outline"
                                class="custom-form-field-v3 fx-width-100">
                                <input autocomplete="off" autocorrect="off" spellcheck="false" type="text" matInput
                                    placeholder="Email" [readonly]="pageStatus?.isSubmitting" formControlName="email">
                                <mat-error
                                    *ngIf="expoSignupForm?.controls.email?.touched && expoSignupForm?.controls.email?.errors?.required">
                                    Required
                                    field</mat-error>
                                <mat-error
                                    *ngIf="expoSignupForm?.controls.email?.touched && expoSignupForm?.controls.email?.errors?.email">
                                    Not a valid email</mat-error>
                                <mat-error *ngIf="expoSignupForm.get('email').status === 'PENDING'">
                                    checking ...
                                </mat-error>
                                <mat-error
                                    *ngIf="expoSignupForm.get('email').errors && expoSignupForm.get('email').errors.emailTaken">
                                    Email already in use.
                                </mat-error>
                                <status-icons *ngIf="expoSignupForm?.controls.email?.touched"
                                    [iconStatus]="expoSignupForm.get('email').status"></status-icons>
                            </mat-form-field>
                        </div>
                        <div class="fx-width-100">
                            <mat-form-field appearance="outline"
                                class="custom-form-field-v3 fx-width-100">
                                <input autocomplete="off" autocorrect="off" spellcheck="false" type="text" matInput
                                    placeholder="Organisation name" [readonly]="pageStatus?.isSubmitting"
                                    formControlName="organisation_name">
                                <mat-error
                                    *ngIf="expoSignupForm?.controls.email?.touched && expoSignupForm?.controls.email?.errors?.required">
                                    Required
                                    field</mat-error>
                                <status-icons *ngIf="expoSignupForm?.controls.organisation_name?.touched"
                                    [iconStatus]="expoSignupForm.get('organisation_name').status"></status-icons>
                            </mat-form-field>
                        </div>


                        <div class="form-section-label">Your device:</div>
                        <div class="fx-row fx-col-sm fx-justify-start fx-align-center fx-gap-1 fx-width-100" >

                            <mat-form-field appearance="outline"
                                class="custom-form-field-v3 number-code-select force-dark readonly-block fx-width-80 fx-width-80-xs">
                                <mat-select  formControlName="region">
                                    <mat-option value="0044" selected>+44</mat-option>
                                </mat-select>
                                <status-icons *ngIf="expoSignupForm?.controls.mobile?.dirty"
                                    [iconStatus]="expoSignupForm.get('mobile').status" fxHide.lt-sm></status-icons>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="custom-form-field-v3 right-only"
                                style="width:100%;">
                                <status-icons *ngIf="expoSignupForm?.controls.mobile?.dirty" fxHide.gt-xs
                                [iconStatus]="expoSignupForm.get('mobile').status"></status-icons>
                                <input type="text" matInput placeholder="Handset number" formControlName="mobile">
                                <mat-error
                                    *ngIf="expoSignupForm?.controls.mobile?.touched && expoSignupForm?.controls.mobile?.errors?.required">
                                    Mobile is required</mat-error>
                                <mat-error class="errormsg" *ngIf="expoSignupForm.get('mobile').status === 'PENDING'">
                                    checking ...
                                </mat-error>
                                <mat-error
                                    *ngIf="expoSignupForm.get('mobile').touched && expoSignupForm.get('mobile').errors?.mobileTaken">
                                    Mobile already registered with an account.
                                </mat-error>
                                <mat-error
                                    *ngIf="expoSignupForm.get('mobile').touched && expoSignupForm.get('mobile').errors?.valid">
                                    {{expoSignupForm.get('mobile').errors?.valid}}
                                </mat-error>

                            </mat-form-field>

                        </div>

                        <button class="submit-button" [disabled]="!expoSignupForm.valid" mat-flat-button color="accent" (click)="$event.preventDefault()" (click)="submitForm(expoSignupForm)">
                            <ng-container *ngIf="!pageStatus.isSubmitting; else spinner">SUBMIT</ng-container>
                        </button>

                        <div class="text-center" style="width: 100%;">
                            <button mat-button class="reset-button"  (click)="$event.preventDefault()" (click)="resetForm()">Reset form</button> 
                            <button mat-button class="reset-button"  (click)="$event.preventDefault()" (click)="triggerVerificationScreen()" *ngIf="isDev">(debug) Show Verification</button>
                            <button mat-button  (click)="$event.preventDefault()" (click)="this.pageStatus.stage = 2" *ngIf="isDev">(debug) final screen</button>
                        </div>

                    </form>
                </div>

<!-- 

            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="pageStatus.stage === 1 && pageStatus.isLoading === false">
        <div class="full-page-view fx-col fx-center">

            <div class="inner-container page2 fx-col fx-center">

                <div>
                    <div class="title-2">Verify your number</div>
                    <div class="text-center">Please <strong>verify your phone number</strong> to complete signup. <br>  Scan the QR code below to automatically compose an SMS message on your phone and then send it please.  This will activate your trial.</div>
                    <div class="QR-code-container">
                        <img [src]="signupConfig?.qrCode" alt="qr code" style="width: 200px;">
                    </div>
                    <div class="title-2" style="font-size: 22px;">1. SCAN CODE &nbsp;&nbsp; 2. PRESS SEND</div>

                    <div class="text-center">Alternatively, you can simply send an SMS message with any content to:</div>
                    <div class="text-center" style="font-size: 28px;font-weight: 600; color: var(--c-yellow); margin-top: 28px;">{{signupConfig?.verificationNumber}}</div>
                </div>



                <button mat-flat-button color="accent" class="start-again-button" (click)="resetForm()">Start again</button>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="pageStatus.stage === 2 && pageStatus.isLoading === false">
        <div class="full-page-view fx-col fx-center" >

            <div class="inner-container page2 fx-col fx-center">

                <div>
                    <div class="title-2">Success!</div>
                    <div class="text-center">Your number has been verified.  You will now receive a text from us with details about how to download the PiPcall app. You will also receive an email with the same information, along with instructions on how you can invite a colleague to your PiPcall trial.</div>

                </div>



                <button mat-flat-button color="accent" class="start-again-button" (click)="resetForm()">Start again</button>
            </div>
        </div>
    </ng-container>
</div>



<!-- <mat-menu #adminMenu="matMenu" class="v3-mat-menu" xPosition="before">

    <ng-template matMenuContent>

        <button mat-menu-item matTooltip="Exit Signup mode" (click)="exitSignup()">
            <mat-icon svgIcon="open_in_new"></mat-icon>
            <span class="matmenu-btn-label">Exit Signup Mode</span>
        </button>


    </ng-template>
</mat-menu> -->



<ng-template #loading>
    <div class="fx-col fx-center">
      <div class="loading-spinner-1" style="width:90px;margin-top:20px;">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
        <div class="bounce4"></div>
      </div>
    </div>
  </ng-template>
  

  <ng-template #spinner>
    <div>
        <span class="loading-icon"><div class="loader-spinner"></div></span>
    </div>
  </ng-template>
  