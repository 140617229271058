//core
import { Component, OnInit, Inject, isDevMode, ViewChild , EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { HttpClient } from '@angular/common/http';
import { ExpoService, ExpoDataModel } from '@app/services/pipcall/expo.service';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { SharedService} from '@app/services/shared-data.service/shared-data.service';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
@Component({
    templateUrl: './dashboard-modal.component.html',
    styleUrls: ['./dashboard-modal.component.scss'],
    animations: [
    ]
})
export class ExpoDashboardModal {


    @Output() updateEmit = new EventEmitter();

    // dateChanged = false;

    _updateNote: FormGroup;
    _salesPerson: FormGroup;
    pageStatus = {
        isSubmitting: false
    }
    currentUser: any;

    constructor(
        private dialogRef: MatDialogRef<ExpoDashboardModal>,
        private http: HttpClient,
        private fb: FormBuilder,
        private ref: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private expoService: ExpoService,
        private modalService: ModalService,
        private sharedService: SharedService,
        @Inject(MAT_DIALOG_DATA)
        public data: {expoData: ExpoDataModel}, //contains data injected into modal.
    ) {
        dialogRef.disableClose = true;
        this._salesPerson = this.fb.group({
            user_id: ['', [Validators.required]],
            id: ['', [Validators.required]],
        });

        this._updateNote = this.fb.group({
            note: ['', []],
            id: ['', [Validators.required]],
        })
    }

    get d() {return this.data?.expoData};

    ngOnInit() {
        this.setFormValues(this.data?.expoData);

        this.sharedService.userProfileAsObservable.subscribe(userProfile => {

            this.currentUser = userProfile;
            this.setFormValue(this._salesPerson, 'user_id', userProfile.id);
        });
    }



    setFormValues(expoData: ExpoDataModel) {
        this.setFormValue(this._updateNote, 'Id', expoData.id);
        this.setFormValue(this._updateNote, 'note', expoData.notes);
        this.setFormValue(this._salesPerson, 'Id', expoData.id);
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
        console.log("form", form);
        this.ref.detectChanges();
    }
    saveNote(f: FormGroup) {
        if (!f.valid) {return; }
        this.pageStatus.isSubmitting = true;


        this.expoService.patchExpoNotes(f.value.id, f.value.note)
            .pipe(
                tap((resp) => {
                    this.pageStatus.isSubmitting = true;
                }),
                finalize(() => {
                    this.pageStatus.isSubmitting = false;
                })
            )
            .subscribe((res) => {
                console.log("Note saved", res);
                this.openSnackBar('Saved', 'Dismiss');
                this._updateNote.markAsPristine();
                this.emitChange();
                // this.dialogRef.close(true);
            }, (err) => {
                console.log(err);
                this.openSnackBar('Error saving note', 'Dismiss');
            });
    }

    confirmForceMigrate() {

        const titlecopy = 'Force migration';
        const data: ModalConfirmData = {
            title: titlecopy,
            content: 'Are you sure you want to force migrate this user?',
            confirmButtonLabel: "Confirm",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: true
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.forceMigration(this.data?.expoData.mobile);
                return;
            }
            console.log('answer was No');
        });
    }


    confirmSalesPerson() {

        const titlecopy = 'Salesperson';
        const user = this.currentUser?.first_name + ' ' + this.currentUser?.last_name;
        const data: ModalConfirmData = {
            title: titlecopy,
            content: `Are you sure you want to claim this as ${user} ?`,
            confirmButtonLabel: "Claim",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: true
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.patchSalesPerson(this._salesPerson);
                return;
            }
            console.log('answer was No');
        });
    }

    forceMigration(mobile: string) {
        this.expoService.patchExpoState(mobile)
            .pipe(
                tap((resp) => {
                    this.pageStatus.isSubmitting = true;
                }),
                finalize(() => {
                    this.pageStatus.isSubmitting = false;
                })
            )
            .subscribe((resp) => {
                console.log("forceMigration response", resp);
                this.openSnackBar("Saved", "dismiss");
                this.dialogRef.close(true);
            }, (err) => {
                console.log(err);
                this.openSnackBar("Error forcing migration", "dismiss");
            });
    }

    patchSalesPerson(f: FormGroup) {
        console.log("patch salesperson", f.value);
        if (!f.valid) {return; }
        this.expoService.patchSalesUser(f.value.id, f.value.user_id)
            .pipe(
                tap((resp) => {
                    this.pageStatus.isSubmitting = true;
                }),
                finalize(() => {
                    this.pageStatus.isSubmitting = false;
                })
            )
            .subscribe((resp) => {
                console.log("Salesperson updated", resp);
                this.openSnackBar("Saved", "dismiss");
                this.emitChange();
                this.d.sales_user_name = this.currentUser?.first_name + ' ' + this.currentUser?.last_name;
            }, (err) => {
                console.log(err);
                this.openSnackBar("Update failed", "dismiss");
            });
    }

    cancelNote() {
        this._updateNote.reset();
        this.setFormValues(this.data?.expoData);
    }

    openOrganisation(id: string) {
        //Open organisation profile in new window
        window.open(`/admin/organisation/${id}/profile`, '_blank');
    }

    emitChange() {
        this.updateEmit.emit(true);
    }

    closeModal() {
        this.dialogRef.close();
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    copyInputToClipboard(value) {
        //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    ngOnDestroy() {

    }
}



