

//core requirements
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

//modules - core
import { GravatarDirectiveModule} from '@app/directives/gravatar/gravatar.module';
import { MaterialModule } from '@app/material/material.module';
import { CustomPipesModule } from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
//services
import { AppInsightService } from '@app/services/helpers/app-insights.service';

//declare components
import { CardContainerTemplateComponent } from './card-container-template.component';


import { CCFloatingCardModule } from '@app/components/cc-floating-card/cc-floating-card.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        RouterModule,
        GravatarDirectiveModule,
        CustomPipesModule,
        StatusIconsModule,
        CCFloatingCardModule
    ],
    declarations: [CardContainerTemplateComponent],
    providers: [HttpProxy, AppInsightService],
    entryComponents: []
})
export class CardContainerTemplateModule {
}

