import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';

//create modal components
import {DownloadLinksModalComponent} from './download-links-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        MaterialModule,
        DragDropModule
    ],
    declarations: [DownloadLinksModalComponent],
    exports: [DownloadLinksModalComponent],
    providers: [AppInsightService],
    entryComponents: [DownloadLinksModalComponent]
})
export class DownloadLinksModalModule { }
