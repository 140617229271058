<div class="pip-modal-content">
  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>
  <div class="pip-modal-header">
    <span class="pip-modal-title">Invite user</span>
    <span class="pip-modal-subtitle" style="line-height: 1.4em;">Assign PiP extension to a new user</span>
  </div>
  <div id="pipmodalbody" class="pip-modal-body pip-scroll-shadow fx-col" (scroll)="scrollEvent($event)" >
    
    <div *ngIf="existingPiPxt.length === 0" class="add-extension-msg"> First add a PiP extension</div>

    <div>

    </div>
    <form [formGroup]="inviteUserForm">


      <div class="fx-col fx-width-100">

        <div  class="section-container fx-row fx-justify-start fx-align-center fx-width-100">


          <div class="fx-col" style="margin-bottom: 10px;">


            <div class="line-header fx-row fx-width-100 fx-justify-start">EXTENSION</div>

            <div [style.position]="'relative'" class="fx-row fx-grow">

              <ng-container *ngIf="preallocated_number; else selectOption">
                <ng-container *ngIf="preallocated_number?.is_pipxt_extensionless; else extension">{{preallocated_number?.number | normalizeToInternational}}</ng-container>
                <ng-template #extension>
                  <span class="preallocated-number">{{preallocated_number?.sip_extension_cli | normalizeToInternational}}
                    ({{preallocated_number?.sip_extension_number}})</span>
                </ng-template>

              </ng-container>

              <ng-template #selectOption>

                 <mat-form-field class="smaller-form-field" appearance="outline">
                  <mat-select
                    formControlName="preallocated_number" [ngClass]="{ 'readonly-wrapper' : pageStatus.isSubmitting || pageStatus.isSuccess}">

                      <mat-option *ngFor="let number of existingPiPxt" [value]="number.number">
                        {{number.sip_extension_cli | normalizeToInternational}} ({{number.sip_extension_number}})
                      </mat-option>
                  </mat-select>

                  <mat-label>
                    <span *ngIf="existingPiPxt.length > 0">Select PiPxt extension</span>
                    <span *ngIf="existingPiPxt.length == 0">There are no available numbers</span>
                  </mat-label>

                  <status-icons
                    *ngIf="inviteUserForm?.controls.preallocated_number?.touched"
                    [iconStatus]="inviteUserForm.controls.preallocated_number.status">
                  </status-icons>
                </mat-form-field> 

              </ng-template>

            </div>

          </div>

        </div>

        <div class="section-container fx-row fx-justify-start fx-align-center fx-width-100">


          <div class="form-container fx-col fx-grow">
            <div class="fx-row fx-width-100 fx-justify-start" class="line-header">USER</div>

            <div class="fx-row fx-col-sm fx-grow"  [style.position]="'relative'"
              formGroupName="user_details" >


              <mat-form-field appearance="outline" class="custom-form-field-v3" floatLabel='never' style="margin-top: -14px;">
                <input type="email" matInput placeholder="Email" autocomplete="email" formControlName="email"
                  [readonly]="pageStatus.isSubmitting || pageStatus.isSuccess">
                <mat-icon svgIcon="email" matSuffix></mat-icon>
                <mat-error
                  *ngIf="inviteUserForm?.controls.user_details.get('email')?.touched && inviteUserForm?.controls.user_details.get('email')?.errors?.required">
                  Required
                  Field</mat-error>
                <mat-error
                  *ngIf="inviteUserForm?.controls.user_details.get('email')?.touched && inviteUserForm?.controls.user_details.get('email')?.errors?.email">
                  not a valid email</mat-error>
                <mat-error *ngIf="inviteUserForm.controls.user_details.get('email').status === 'PENDING'">
                  checking ...
                </mat-error>
                <mat-error
                  *ngIf="inviteUserForm?.controls.user_details.get('email').errors && inviteUserForm?.controls.user_details.get('email').errors.emailTaken">
                  User is an Existing PiP user. Contact us on 0330 094 8080 and we can help migrate this user to your
                  organisation.
                </mat-error>
                <!-- <mat-label>Email</mat-label> -->

                <status-icons *ngIf="inviteUserForm?.controls.user_details.get('email')?.touched"
                  [iconStatus]="inviteUserForm.controls.user_details.get('email').status">
                </status-icons>
              </mat-form-field>

            </div>
          </div>

        </div>


        <div  class="section-container fx-row fx-justify-start fx-align-center fx-width-100">


          <div class="form-container fx-col fx-grow">
            <div class="line-header fx-row fx-width-100 fx-justify-start" [style.padding-bottom.px]="10">
              INVITATION</div>

            <div class="fx-row fx-grow" [style.position]="'relative'">

              <ng-container *ngIf="!pageStatus.isSuccess; else showSuccess">
                <button [style.width.px]="150" [disabled]="!inviteUserForm?.valid ||  pageStatus.isSubmitting"
                  mat-flat-button color="accent" (click)="submitForm(inviteUserForm)">
                  <ng-container *ngIf="!pageStatus.isSubmitting; else loadingSpinner">Send Invite</ng-container>
                  <ng-template #loadingSpinner><i class="fas fa-spinner fa-spin"></i></ng-template>
                </button>
              </ng-container>
              <ng-template #showSuccess>
                <div [style.color]="'grey'">Email has been sent</div>
              </ng-template>

            </div>
          </div>

        </div>


      </div>
    </form>
  </div>


  <div class="pip-modal-footer">

  </div>

</div>

