//core
import { Component, OnInit, Inject, isDevMode, HostListener, ViewChild, ElementRef, ChangeDetectorRef, EventEmitter, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { trigger, transition, animate, style, useAnimation } from '@angular/animations'
import { FormGroup, NgForm, FormBuilder, Validators } from "@angular/forms";
import { map, tap, filter, catchError, mergeMap, finalize } from 'rxjs/operators';
import { StripeService, Elements, Element as StripeElement, ElementsOptions, StripeFactoryService, StripeInstance } from "ngx-stripe";
import { AppInsightService} from '@app/services/helpers/app-insights.service';
import { slideInUpOnEnterAnimation } from 'angular-animations';
import { PaymentsService } from '@app/services/pipcall/payments.service';

@Component({
    templateUrl: './update-card-modal.component.html',
    styleUrls: ['./update-card-modal.component.scss'],
    animations: [
        slideInUpOnEnterAnimation()
    ]
})
export class UpdateCardModalComponent {


    @ViewChild(UpdateCardModalComponent) updateCardModalComponent: UpdateCardModalComponent;

    public organisation_id: string;

    //stripe config
    elements: Elements;
    card: StripeElement;
    stripeForm: FormGroup;

    // optional parameters
    elementsOptions: ElementsOptions = {
        locale: 'en'
    };

    @Output() checkoutEvent = new EventEmitter(); //boolean, true if successfull checkout
    @Output() cancelCheckout = new EventEmitter(); //cancel event emit
    @Output() paymentMethodId = new EventEmitter(); // the new PMethod id.


    pageStatus = {
        isLoading: false,
        isError: false,
        errorMessage: '',
        success: false
    }
    constructor(
        private dialogRef: MatDialogRef<UpdateCardModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {organisation_id: string}, //contains data injected into modal.
        private paymentsService: PaymentsService,
        private snackBar: MatSnackBar,
        private cd: ChangeDetectorRef,
        private stripeService: StripeService,
        private fb: FormBuilder,
        private stripeFactory: StripeFactoryService,
        private appInsightService: AppInsightService
    ) {

        //assign data to organisationId
        this.organisation_id = data.organisation_id;
    }


    ngOnInit() {
        this.stripeForm = this.fb.group({
            name: ['', [Validators.required]]
        });

        this.stripeService.elements(this.elementsOptions)
            .subscribe(elements => {
                this.elements = elements;
                // Only mount the element the first time
                if (!this.card) {
                    this.card = this.elements.create('card', {
                        style: {
                            base: {
                                iconColor: '#808080',
                                color: '#000000',
                                lineHeight: '40px',
                                fontWeight: 500,
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                '::placeholder': {
                                    color: '#808080'
                                }
                            },
                            invalid: {
                                iconColor: '#a972a5',
                                color: '#f31717',
                            }
                        }
                    });
                    this.card.mount('#card-element');
                }
            });
    }

    updateCard() {
        this.pageStatus.isError = false;
        this.pageStatus.errorMessage = '';
        this.pageStatus.isLoading = true;

        this.stripeService.createPaymentMethod('card', this.card)
            .subscribe((resp) => {
                console.log("RESP: ", resp);

                if (resp?.error) {
                    //show an error now
                    console.log("ERROR", resp?.error['message']);
                    this.pageStatus.errorMessage = resp?.error['message'];
                    this.pageStatus.isError = true;
                    this.pageStatus.isLoading = false;
                    this.appInsightService.logEvent('Stripe:Update payment card invalid', { 'organisationId': this.organisation_id });
                } else {
                    this.appInsightService.logEvent('Stripe:Update payment card valid', { 'organisationId': this.organisation_id });
                    this.updateDefaultCard(resp.paymentMethod?.id);
                    return;
                }
                return;
                //switcchMap API call
            }, (err) => {
                //display error Message
                console.log("ERROR", err);
                this.appInsightService.logEvent('Stripe:Update payment card rejected', { 'organisationId': this.organisation_id, 'err': err });
                this.pageStatus.errorMessage = "something went wrong, no change has been made."; //check api for status codes toflesh out error message
                this.pageStatus.isError = true;
                this.pageStatus.isLoading = false;
            })

    }

    //call api to provide PaymentMethodId to the backend
    updateDefaultCard(paymentMethodId) {
        this.pageStatus.isLoading = true;
        this.paymentsService.postUpdateDefaultCard(this.organisation_id, paymentMethodId)
            .pipe(
                finalize(() => {this.pageStatus.isLoading = false})
            )
            .subscribe((resp) => {
                this.pageStatus.success = true;

                setTimeout(() => {
                    this.closeModal();
                }, 2000);

                this.appInsightService.logEvent('API: payment card updated', { 'organisationId': this.organisation_id });
                // this.closeModal();
            }, (err) => {
                //snackbar here for error?
                this.appInsightService.logEvent('API: payment card update error', { 'organisationId': this.organisation_id, 'err': err });
                this.pageStatus.errorMessage = "Something went wrong, we could not save your card at this time.";
                this.pageStatus.isError = true;
            })
    }


    closeModal() {
        this.dialogRef.close(this.pageStatus?.success);
    }


    // updateCardEvent(event) {
    //     event ? this.closeModal() : null;
    // //consider opensnackbar if event false;
    // }

    ngOnDestroy() {

    }

}
