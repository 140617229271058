<div class="bottom-sheet-content-container">

  <div class="bottom-sheet-action-row">
    
    <div class="close-button-wrapper" >
      <ng-container *ngIf="viewAs==='sysAdmin'">

        <button class="impersonate-button" mat-button-icon mat-button  matTooltip="Impersonate organisation admin" (click)="impersonateAdmin(organisationProfile?.owner_user_id)">
          <mat-icon svgIcon="impersonate"></mat-icon>
        </button>
    
    
        <div class="go-url clickable fx-row fx-justify-center fx-align-center" >
            <span [matTooltip]="organisationUrl"  (click)="copyInputToClipboard(organisationUrl)">{{organisationUrl}}</span>
            <button matTooltip="open url" mat-flat-button class="open-in-button" mat-button (click)="openProfileURL(organisation_id)"><mat-icon style="color:var(--c-title)"svgIcon="open_in_new"></mat-icon></button>
        </div>
      
      </ng-container>
      <button class="bs-toolbar-close-btn" mat-flat-button color="primary" (click)="dismiss()" mat-button>Close</button>
    </div>
  </div>

  <div class="page-container">
    <div class="m-content-container bottom-sheet-container">

      <div class="content-page-title-wrapper">
        <div  (click)="copyInputToClipboard(organisationProfile?.id)" matTooltip="Copy to clipboard">{{organisationProfile?.id}}</div>
        <h2>
          {{organisationProfile?.name}}
          <span
            *ngIf="(organisationProfile?.state==='Trial' && trialDays < 0) || organisationProfile?.state==='Declined' "
            class="trial-period">[Trial has expired]</span>
          <span *ngIf="organisationProfile?.is_blocked===true" class="trial-period">[Blocked]</span>
          <span *ngIf="organisationProfile?.state==='Cancelled'|| organisationProfile?.state==='Closed'"
            class="trial-period">[{{organisationProfile?.state}}]</span>
        </h2>
      </div>

      <div class="content-body">

        <div class="fx-width-100">
          <div class="tabs-header no-overflow" >

            <div [ngClass]="{'active':   pageStatus.activeTab === 'system',  'disabledTab' : viewAs!=='sysAdmin'}" (click)=" changeTab('system')">System</div>
<!-- 
            <div class="action-button"  matTooltip="Impersonate the organisation admin"  (click)="impersonateAdmin(organisationProfile?.owner_user_id)">Impersonate Admin</div>

            <div class="action-button" matTooltip="Enable automatic billing" >Billing enabled <mat-slide-toggle></mat-slide-toggle>
            </div>

            <div class="action-button" matTooltip="Block the organisation" (click)="toggleSlide()" >Blocked <mat-slide-toggle  (click)="$event.stopPropagation()"  [(ngModel)]="isBlocked" #slideToggle></mat-slide-toggle>
            </div> -->


            <!-- <div [ngClass]="{'active':  pageStatus.activeTab === 'home' }"
              (click)=" changeTab('home')">Home</div> -->

              <!-- <div [ngClass]="{'active':   pageStatus.activeTab === 'users'}"
              (click)="changeTab('users')">
              Users
            </div> -->


<!-- 
            <div *ngIf="organisationProfile?.is_pipmobile_enabled"
              [ngClass]="{'active':   pageStatus.activeTab === 'numbers'}"
              (click)="changeTab('numbers')">
              Numbers
            </div> -->

            <!-- <div *ngIf="organisationProfile?.is_esim_enabled"
            [ngClass]="{'active':   pageStatus.activeTab === 'esim'}"
            (click)="changeTab('esim')">Esim
          </div>

            <div *ngIf="organisationProfile?.is_pipxt_enabled"
              [ngClass]="{'active':   pageStatus.activeTab === 'extensions'}"
              (click)="changeTab('extensions')">Extensions
            </div>

            <div *ngIf="(viewAs === 'sysAdmin' || viewAs === 'isAdmin')"
              [ngClass]="{'active':   pageStatus.activeTab === 'billing'}"
              (click)="changeTab('billing')"> Billing
            </div>

            <div *ngIf="(viewAs === 'sysAdmin' || viewAs === 'isAdmin') && organisationProfile?.is_pipmobile_enabled"
              [ngClass]="{'active':   pageStatus.activeTab === 'licences' }"
              (click)=" changeTab('licences')"> Licences
            </div>

            <div [ngClass]="{'active':  pageStatus.activeTab === 'settings' }"
              (click)=" changeTab('settings')">Settings</div> -->


            <matmenu-organisation-admin-controls *ngIf="viewAs==='sysAdmin' && organisationProfile?.name" [organisation_id]="organisation_id" [is_blocked]="organisationProfile?.is_blocked" [organisation_admin_userid]="organisationProfile?.owner_user_id" [is_auto_billing_enabled]="organisationProfile?.is_auto_billing_enabled" (_refreshData)="refreshData()" (closeModal)="dismiss()"></matmenu-organisation-admin-controls>
          </div>


          <div class="m-content-body-wrapper">

            <ng-container *ngIf="!this.pageStatus.isLoading && !this.pageStatus.noContent; else loading">

              <ng-container *ngIf="pageStatus.activeTab === 'system'">
                <app-org-tabs-system *ngIf="viewAs === 'sysAdmin'" [organisation_id]="organisation_id" [organisationProfile]="organisationProfile"
                  [viewAs]="viewAs" (updateParent)="refreshData()"></app-org-tabs-system>
              </ng-container>
<!-- 
              <ng-container *ngIf="pageStatus.activeTab === 'home'">
                <app-org-tabs-home [organisation_id]="organisation_id" [organisationProfile]="organisationProfile"
                  [viewAs]="viewAs" (updateParent)="refreshData()"></app-org-tabs-home>
              </ng-container> -->


              <!-- <ng-container *ngIf="pageStatus.activeTab === 'billing'">
                <app-org-tabs-billing *ngIf="(viewAs === 'sysAdmin' || viewAs === 'isAdmin')" [organisation_id]="organisation_id" [organisationProfile]="organisationProfile"
                  [viewAs]="viewAs" (updateParent)="refreshData()"></app-org-tabs-billing>
              </ng-container>

              <ng-container *ngIf="pageStatus.activeTab === 'licences'">
                <app-org-licences *ngIf="(viewAs === 'sysAdmin' || viewAs === 'isAdmin') && organisationProfile?.is_pipmobile_enabled" [organisation_id]="organisation_id" [organisationProfile]="organisationProfile"
                  [organisationUserList]="organisationUserList" [viewAs]="viewAs" (updateParent)="refreshData()">
                </app-org-licences>
              </ng-container>


              <ng-container *ngIf="pageStatus.activeTab === 'numbers' && organisationProfile.is_pipmobile_enabled">
                <app-org-tabs-numbers [organisation_id]="organisation_id" [organisationProfile]="organisationProfile"
                  [viewAs]="viewAs" (updateParent)="refreshData()">
                </app-org-tabs-numbers>
              </ng-container>

              <ng-container *ngIf="pageStatus.activeTab === 'esim' && organisationProfile.is_esim_enabled">
                <app-org-tabs-esim [organisation_id]="organisation_id" [organisationProfile]="organisationProfile"
                  [viewAs]="viewAs" (updateParent)="refreshData()">
                </app-org-tabs-esim>
              </ng-container>


              <ng-container *ngIf="pageStatus.activeTab === 'extensions' && organisationProfile.is_pipxt_enabled">
                <app-org-tabs-extensions [organisation_id]="organisation_id" [organisationProfile]="organisationProfile"
                  [viewAs]="viewAs" (updateParent)="refreshData()">
                </app-org-tabs-extensions>
              </ng-container>

              <ng-container *ngIf="pageStatus.activeTab === 'users'">
                <app-org-users-list [organisation_id]="organisation_id" [organisationProfile]="organisationProfile"
                  [organisationUserList]="organisationUserList" [viewAs]="viewAs" (updateParent)="refreshData()">
                </app-org-users-list>
              </ng-container>

              <ng-container *ngIf="pageStatus.activeTab === 'settings'">
                <app-org-tabs-settings [organisation_id]="organisation_id" [organisationProfile]="organisationProfile"
                  [viewAs]="viewAs" (updateParent)="refreshData()"></app-org-tabs-settings>
              </ng-container> -->

            </ng-container>

            <ng-container *ngIf="!this.pageStatus.isLoading && this.pageStatus.noContent">
              No user found
            </ng-container>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>

<ng-template #loading>
  <div class="fx-row fx-justify-center fx-align-center">
    <div class="loader-wave"></div>
 </div>
</ng-template>
