import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


import {DebugModalModal} from './debug-modal.component';
import {VersionCheckService} from '@app/services/version-check.service';
@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    declarations: [DebugModalModal],
    exports: [DebugModalModal],
    providers: [VersionCheckService],
    entryComponents: [DebugModalModal]
})
export class DebugModalModalModalModule { }