import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import 'rxjs/add/operator/filter';
import * as auth0 from 'auth0-js';
import * as jwt_decode from "jwt-decode";
import { BaseUrl } from '../return-base-url..service';
import { myConfig } from './auth.conf';
import { HttpProxy } from '../http-proxy.service';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { UserService } from '../pipcall/user.service';
import { CookieService } from 'ngx-cookie-service';
import { TokenService } from '@app/services/token.service';
import { of } from 'rxjs/observable/of';

@Injectable({
    providedIn: 'root'
})
export class AuthService {


    auth0 = new auth0.WebAuth({
        clientID: myConfig.clientID,
        domain: myConfig.domain,
        responseType: 'token id_token',
        audience: myConfig.audience,
        redirectUri: this.getCallbackUrl(),
        leeway: 30,
        scope: myConfig.scope,
        title: 'Voxspan - PiPcall',
        theme: {
            logo: '/assets/cyan_logo64.png',
            primaryColor: '#b81b1c'
        }
    });

    constructor(
        private router: Router,
        private baseUrl: BaseUrl,
        private httpProxy: HttpProxy,
        private userService: UserService,
        private cookieService: CookieService,
        private tokenService: TokenService
    ) {
    }

    getCallbackUrl(): string {
        return this.baseUrl.url + '/callback';
    }

    //legacy login
    public login(): void {
        this.auth0.authorize();
    }

    public setSession (access_token: string, refresh_token?: string, id_token?: string): Observable<any> {
        return of(this.tokenService.setSession(access_token, refresh_token, id_token));
    }



    public logout(): void {
    // Remove tokens and expiry time
        this.tokenService.clearAllStorage(this.router.navigateByUrl('/'));
        setTimeout(() => {
            window.location.reload();
        }, 200);
    }

    public clearCookieData(cb?: any) {
        return this.tokenService.clearCookie(cb);
    }

    public isAuthenticated(): boolean {
        const expiresAt = this.returnTokenValue("exp");
        const now = (new Date().getTime() / 1000);
        const audienceValid = this.isAudienceValid();
        return (expiresAt && audienceValid === true) ? now < expiresAt : false;

    }

    private isAudienceValid(): boolean {
        const audience = this.returnTokenValue("aud");
        return audience?.indexOf(environment.auth0_audience) > -1 ? true : false;
    }


    private returnTokenValue(field?: string) {
        if (!field) {return null; }
        //return any parameter from accessToken
        if (this.cookieService.check('access_token') && this.cookieService.get('access_token') !== '') {
            const accessToken = this.cookieService.get('access_token');
            const decodedToken = this.getDecodedAccessToken(accessToken);
            const value = decodedToken[field];
            return value;
        } else {
            //no access token, return false.
            return null;
        }
    }

    public is_customer_admin(): boolean {
        //check for id token custom claim instead
        const permissions = this.returnPermissions();
        return permissions?.indexOf('admin:user') > -1 ? true : false;
    }

    public is_system_admin(): boolean {
        //check for id token custom claim instead
        const permissions = this.returnPermissions();
        return permissions?.indexOf('admin:user') > -1 ? true : false;
    }

    public isSysAdmin(): boolean {
        const permissions = this.returnPermissions();
        return permissions?.indexOf('admin:user') > -1 ? true : false;
    }

    private getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    private returnTokenPayload(token) {
        return this.getDecodedAccessToken(token);
    }

    private returnScopes() {
        const scopes = this.returnTokenValue('scope')
        const filteredScopes = scopes?.split(' ').filter(function (x) {
            return x.indexOf(':') > 0;
        });
        return filteredScopes;
    }

    public returnPermissions() {
        const permissions = this.returnTokenValue('permissions');
        return permissions;
    }

    public getUserId(): string {
        let impersonateUser = '';

        if (this.cookieService.check('impersonateUser')) {
            impersonateUser = this.cookieService.get('impersonateUser');
        }

        if (impersonateUser && this.isSysAdmin()) {
            return impersonateUser;
        } else {
            const token = this.cookieService.get('access_token') !== '' ? this.cookieService.get('access_token') : '';

            // console.log("||auth service||   check access token", token);
            if (token) {
                const decodedToken = this.getDecodedAccessToken(token);
                return decodedToken ? decodedToken['sub'] ? decodedToken['sub'] : null : null;
            } else if (this.cookieService.check('user_id') && this.cookieService.check('refresh_token')) {
                // console.log("||auth service|| token retrieved from cookie - needs refresh");
                return this.cookieService.get('user_id');
            } else {
                // console.log("||auth service||   NO access token return null xxxxxxxxxxxx");
                return null;
            }
        }

    }

    public checkCookieItem(value) {
        return this.cookieService.check(value) ? true : false;
    }



    postRefreshToken() {
        console.log("[[api]] -> Post refresh token from auth service");

        const token = this.cookieService.get('refresh_token');
        const body = {
            grant_type: 'refresh_token',
            client_id: myConfig.clientID,
            refresh_token: token
        }
        return this.httpProxy.post(`https://${myConfig.domain}/oauth/token`, body, 'response', false);
    }

}



// looks for the result of authentication in the URL hash. Then, the result is processed with the
// public handleAuthentication(): void {
//     this.auth0.parseHash((err, authResult) => {
//         if (authResult?.accessToken) {

//             console.log("|///////////////////////////////==================================================authResult", authResult)
//             //id token in authResult?.idToken
//             const url: string = sessionStorage.getItem('redirect_url') ? sessionStorage.getItem('redirect_url') : '/redirect' //if redirect url exists, otherwise default to dashboard

//             this.setSession(authResult.accessToken) ? this.navigateToUrl(300, url) : this.navigateToUrl(2000, url);
//         } else if (err) {
//             console.log("error with authentication", err);
//             this.router.navigateByUrl('/');
//         }
//     });
// }

// private navigateToUrl(waittime: number, url: string) {
//     setTimeout(() => {
//         this.router.navigateByUrl(url);
//     }, waittime);
// }


// public _setSession(access_token: string, refresh_token?: string, expires_in?: number) {

//     //clear it first
//     this.cookieService.set('access_token', '', -1, '/'); //clear token
//     this.cookieService.set('_userProfile', '', -1, '/');
//     this.cookieService.set('refresh_token', '', -1, '/'); //clear token
//     this.cookieService.set('expires_at', '', -1, '/');
//     localStorage.clear();
//     sessionStorage.clear();


//     const decodedACToken = this.returnTokenPayload(access_token);
//     const user_id = decodedACToken.sub;
//     const expiresAt = decodedACToken.exp; //epoch time in seconds



//     //set date to expire access_token cookie in correct format
//     const expiresAtCookie = new Date(0);
//     expiresAtCookie.setUTCSeconds(expiresAt);



//     // const now = (new Date().getTime() / 1000);
//     // const expiresInHours = Math.round((expiresAt - now) / 3600); //seconds to hours //bug: this is not working

//     this.cookieService.set('access_token', access_token, expiresAtCookie, '/');
//     this.cookieService.set('isLoggedIn', 'true', expiresAtCookie, '/');
//     this.cookieService.set('expires_at', expiresAt, expiresAtCookie, '/');

//     //handle Refresh Token
//     this.cookieService.set('refresh_token', refresh_token, 30, '/'); //30 days


//     return true;
// }

//can remove
// public getProfile(cb): void {
//   console.log("auth.getProfile======THIS FIRED=======");
//   if (!this.cookieService.check('access_token')) {
//     //dont do anything
//     console.log("==No Profile Found==")
//   } else {
//     this.auth0.client.userInfo(this.cookieService.get('access_token'), (err, profile) => {
//       console.log("RETURN PROFILE:", profile);
//       let self = '';
//       if (profile) {
//         self = profile;
//       }
//       cb(err, profile);
//     });
//   }
// };




// public scheduleRenewal() {
//   if (!this.isAuthenticated()) return;  //check if expired
//   this.unscheduleRenewal();

//   const expiresAt = localStorage.getItemItem('expires_at');

//   const source = Observable.of(expiresAt).flatMap(
//     expiresAt => {

//       const now = Date.now();

//       // Use the delay in a timer to
//       // run the refresh at the proper time
//       return Observable.timer(Math.max(1, Date.parse(expiresAt) - now));
//     });

//   // Once the delay time from above is
//   // reached, get a new JWT and schedule
//   // additional refreshes
//   this.refreshSubscription = source.subscribe(() => {
//     this.renewTokens();
//     this.scheduleRenewal();
//   });
// }

// public unscheduleRenewal() {
//   if (!this.refreshSubscription) return;
//   this.refreshSubscription.unsubscribe();
// }

// https://auth0.com/docs/quickstart/spa/angular2/05-token-renewal
// public renewTokens(): void {
//     console.log("renew token called");
//     this.auth0.checkSession({}, (err, authResult) => {
//         console.log("check session result", authResult);
//         if (authResult && authResult.accessToken && authResult.idToken) {
//             this.setSession(authResult);
//         } else if (err) {
//             alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
//             this.logout();
//         }
//     });
// }


// public renewToken(): void {
//     this.auth0.checkSession({}, (err, authResult) => {
//         if (authResult && authResult.accessToken && authResult.idToken) {
//             console.log("renew token");
//             this.setSession(authResult);
//         } else if (err) {
//             console.log("err:", err);
//             alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
//             this.logout();
//         }
//     });
// }
