import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
//create modal components

import {DragableExampleModalComponent} from './dragable-example-modal.component'

@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        MaterialModule,
        DragDropModule,
    ],
    declarations: [DragableExampleModalComponent],
    exports: [DragableExampleModalComponent],
    providers: [],
    entryComponents: [DragableExampleModalComponent]
})
export class DragableExampleModalModule { }
