<div [ngSwitch]="data.product" style="position:relative;">
    <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
        <mat-icon svgIcon="clear"></mat-icon>
    </button>

    <div *ngSwitchCase="'mobile-plus'">
        <h1 mat-dialog-title class="title-font title-color">Mobile+ Advanced Call Features</h1>
        <div mat-dialog-content>
            <H2>Get a quote</H2>
            <ng-container *ngIf="!pageStatus.isSuccess; else successMessage">
                <p>
                    Our most advanced business phone system. It's perfect for businesses that need a
                    high-quality native calls with an extensive range of business features, all delivered through an
                    app.
                </p>

                <p>Mobile+ gives you all the functionality of a full UCaaS office phone system on your mobile, without
                    compromise on call resilience. We give you the tools to set up call flows suited to your business,
                    giving you the freedom to scale without ever worrying about missed calls.</p>


                <p style="margin-top:2rem;"><a href="https://www.pipcall.com/solution/mobileplus" target="_blank">Learn
                        more</a>

                    <ng-container *ngTemplateOutlet="contactMessage; context {'product': data.product}"></ng-container>

            </ng-container>

        </div>
    </div>

    <div *ngSwitchCase="'uk-voice-and-data'">
        <h1 mat-dialog-title class="title-font title-color">UK Voice & Data eSIM</h1>
        <div mat-dialog-content>
            <H2>Portal coming soon</H2>
            <ng-container *ngIf="!pageStatus.isSuccess; else successMessage">
                <p>
                    Our eSIM for Data, Calls, and Text plan provides everything your team need to stay connected.
                    Replace physical SIMs in your eSIM compliant company-owned devices or offer to employees who prefer
                    to use their own devices.
                </p>

                <p>Our portal is coming soon, but you can purchase plans right away from our wide range of UK network
                    providers.</p>

                <p style="margin-top:2rem;"><a href="https://www.pipcall.com/solution/esim/uk-voice-and-data"
                        target="_blank">See pricing</a></p>
                <ng-container *ngTemplateOutlet="contactMessage; context {'product': data.product}"></ng-container>
            </ng-container>
        </div>
    </div>
    <div *ngSwitchCase="'overseas-staff'">
        <h1 mat-dialog-title class="title-font title-color">eSIM for Overseas Staff</h1>
        <div mat-dialog-content>
            <H2>Get a quote</H2>
            <ng-container *ngIf="!pageStatus.isSuccess; else successMessage">
                <p>
                    For businesses with employees working full-time in another country, the International eSIM provides
                    a hassle-free solution. This eSIM removes the complexity of sourcing and managing international
                    services, ensuring your overseas staff can get connected quickly and easily.
                </p>

                <!-- <p style="margin-top:2rem;"><a href="https://www.pipcall.com/solution/esim/multi-network-iot-esim" target="_blank">See pricing</a></p> -->
                <ng-container *ngTemplateOutlet="contactMessage; context {'product': data.product}"></ng-container>
            </ng-container>
        </div>
    </div>
    <div *ngSwitchCase="'IoT'">
        <h1 mat-dialog-title class="title-font title-color">IoT Unsteered Multi-Network eSIM</h1>
        <div mat-dialog-content>
            <H2>Get a quote</H2>
            <ng-container *ngIf="!pageStatus.isSuccess; else successMessage">
                <p>
                    In today's rapidly evolving digital landscape, the Internet of Things (IoT) has become increasingly
                    prevalent in various industries, revolutionizing business operations and how devices interact with
                    their environment.
                </p>

                <p>
                    As IoT devices continue to grow in number and complexity, the need for reliable connectivity
                    solutions has become more crucial than ever. Our embedded SIM (eSIM) technology is the perfect
                    partner for your
                    IoT growth.
                </p>
                <p style="margin-top:2rem;"><a href="https://www.pipcall.com/solution/esim/multi-network-iot-esim"
                        target="_blank">See pricing</a></p>

                <ng-container *ngTemplateOutlet="contactMessage; context {'product': data.product}"></ng-container>
            </ng-container>

        </div>
    </div>
</div>


<div mat-dialog-actions *ngIf="!pageStatus.isSuccess">
    <form [formGroup]="contactForm" class="action-row fx-row fx-width-100 fx-col-xs">

        <mat-form-field appearance="outline" class="contact-form">
            <mat-label>Contact number</mat-label>
            <input matInput placeholder="Contact number" formControlName="contact_phone_number">
        </mat-form-field>

        <button (click)="submitForm()" class="call-me-button" mat-flat-button color="accent"
            [disabled]="!contactForm.valid || pageStatus.isSubmitting"><ng-container
                *ngIf="!pageStatus.isSubmitting; else loadingWave">Call me back</ng-container></button>
    </form>

</div>




<ng-template #loadingWave>
    <div class="loader-wave is-button"></div>
</ng-template>
<!-- <div class="fx-row fx-justify-center fx-align-center">
 </div> -->


<ng-template #successMessage>
    <p>Thank you, we have recieved your request, one of our sales expert will be in touch with you as soon as we can.
    </p>
    <p>You may now close this window.</p>
</ng-template>

<ng-template #contactMessage let-product="product">
    <p style="margin-top:2rem;">To purchase <strong>{{product}}</strong>, either contact <a
            href="sales@pipcall.com">sales&#64;pipcall.com</a>, ring us on <a href="tel:+443300948080">0330
            094 8080</a> or request a call back by tapping the button below. </p>
</ng-template>