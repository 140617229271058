<div class="pip-modal-content">
  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>

  <div class="pip-modal-header">

    <div>
      <span class="subtitle-16">Set up PiPcall user</span>
      <h5 style="color:var(--c-title)">{{data?.userProfile?.first_name}}&nbsp;{{data?.userProfile?.last_name}}</h5>
    </div>

  </div>

  <div class="pip-modal-body fx-col fx-gap-08"  style="min-height: 115px;">

      <app-admin-upgrade-invitation-to-pipxt [organisation_id]="data?.organisation_id" [organisationProfile]="data?.organisationProfile" [userProfile]="data?.userProfile" (updateParent)="closeModal($event)"></app-admin-upgrade-invitation-to-pipxt>

  </div>

  <div class="pip-modal-footer">
    <!-- <button mat-flat-button color="primary">Test</button> -->
  </div>
</div>

<ng-template #spinner>
  <span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>
</ng-template>
