<ng-container *ngIf="organisation_id; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">

    <div [@fadeInOnEnter] class="tab-child-view-container">


      <div *ngIf="organisationProfile?.state === 'Closed' || organisationProfile?.state === 'Cancelled'"
        class="section-info-bar" fxLayoutAlign="space-between center" fxLayout="row"
        style="background:var(--c-error);color:white;height: 50px;">Account is closed</div>
      <div *ngIf="organisationProfile?.state === 'Declined'" class="section-info-bar"
        fxLayoutAlign="space-between center" fxLayout="row" style="background:var(--c-warn);color:white;height: 50px;">
        <div>Trial has now ended</div>
        <!-- <div>Contact us at <a href="mailto:sales@pipcall.com">sales&#64;pipcall.com</a> to purchase PiPcall.</div> -->
      </div>



      <div class="section-wrapper"
        *ngIf="organisationProfile?.is_pipmobile_enabled && is_auto_billing_enabled === false && organisationProfile?.state !== 'Trial'">
        <div class="section-content" style="margin-bottom: 4px;;">
          <div class="information-banner warning" fxLayoutAlign="start center" fxFlex="100%">
            <div>
              <mat-icon svgIcon="warning"></mat-icon>
            </div>
            <div class="message-body"
              matTooltip="No payment will be taken at the next billing date until automatic billing is resumed."
              [matTooltipClass]="'tooltipcustom tooltipmultiline'">Automatic billing has been disabled
            </div>
          </div>
        </div>
      </div>
      <div class="section-wrapper" *ngIf="organisationProfile?.state === 'Trial'">



        <div class="section-content" fxLayout="column" style="gap: 20px;"
          *ngIf="organisationProfile?.state === 'Trial'">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">
            <div class="copy-highlighted-background" fxFlex="100%" style="margin-bottom: 20px;">
              <p>Your organisation is currently in a <strong style="color: var(--c-pink)">Trial</strong>. During this
                time, you can freely invite users to your organisation try out PiPcall features. <span
                  *ngIf="organisationProfile?.trial_end_date">Your trial will end on <span
                    style="color: var(--c-pink)">{{organisationProfile?.trial_end_date | date:
                    'dd-MM-yyyy'}}</span></span>. <span *ngIf="trialDays > 0">You have {{trialDays}} day<span
                    *ngIf="trialDays != 1">s</span> left.</span> <span *ngIf="trialDays === 0">Your trial ends
                  today.</span> <span *ngIf="organisationProfile?.is_pipxt_enabled"><br> Contact us at <a
                    href="mailto:sales@pipcall.com">sales&#64;pipcall.com</a> to purchase PiPcall.</span> </p>
            </div>
          </div>

          <div fxLayout="column" [style.width.px]="180">
            <button *ngIf="organisationProfile?.is_pipmobile_enabled" style="width:100%;" mat-flat-button color="accent"
              (click)="openFirstPayment()">Upgrade</button>
          </div>

        </div>


      </div>


      <div class="section-wrapper">
        <tab-title-lined>Organisation Detail</tab-title-lined>

        <tab-section-action [isHidden]="organisationNameForm?.pristine">

          <ng-container *ngIf="viewAs === 'sysAdmin' || viewAs === 'isAdmin'">

            <button (click)="cancelForm()" mat-flat-button *ngIf="!organisationNameForm?.pristine"
              style="margin-right: 4px;">Cancel</button>

            <button mat-flat-button aria-label="Save changes" class="save-button"
              [disabled]="organisationNameForm?.pristine ||  pageStatus.isSubmitting || organisationNameForm.invalid"
              (click)="SaveOrganisationName(organisationNameForm)">
              <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
            </button>
          </ng-container>
        </tab-section-action>


        <div class="section-content">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <form [formGroup]='organisationNameForm' fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%"
              fxFlexOrder.lt-md="2" [ngClass]="{'readonly-block': pageStatus.isSubmitting}">

              <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">
                <s-grid-items-container>
                  <s-grid-item [labelWidthpx]="120">
                    <div role="label">Status:</div>
                    <div role="value">
                      {{organisationProfile.state}}&nbsp;
                      <ng-container
                        *ngTemplateOutlet="statusIndicator;context:{'organisationProfile':organisationProfile}"></ng-container>
                    </div>
                  </s-grid-item>

                  <!-- <s-grid-item [labelWidthpx]="120">
                    <div role="label">Id:</div>
                    <div role="value">
                      {{organisationNameForm.getRawValue().id}}&nbsp;
                      <mat-icon matSuffix svgIcon="copy"
                        (click)="copyInputToClipboard(organisationNameForm?.getRawValue().id)"
                        class="clickable action-icon" matTooltipPosition="below" matTooltipShowDelay="200"
                        matTooltip="Copy"></mat-icon>
                    </div>
                  </s-grid-item> -->


                  <s-grid-item [labelWidthpx]="120">
                    <div role="label">Created at:</div>
                    <div role="value">
                      {{organisationProfile.created_at | date: 'yyyy-MM-dd HH:mm'}}
                    </div>
                  </s-grid-item>
                </s-grid-items-container>
              </div>

              <div class="tab-form-field-wrapper">

                <mat-form-field appearance="outline" class="custom-form-field-v3 inner-shadow"
                  [ngClass]="{'readonly-block': pageStatus.isSubmitting || (viewAs !== 'sysAdmin' && viewAs !== 'isAdmin')}">
                  <input autocomplete="off" type="text" matInput placeholder="" formControlName="name">
                  <mat-label>Organisation Name*</mat-label>
                </mat-form-field>
              </div>

            </form>


            <div fxLayout="column" fxFlex="40%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="1">

              <p *ngIf="organisationProfile?.state === 'Closed'" style="margin-top: 48px;">This account is closed. If
                you would like to rejoin the PiPcall service, please get in touch at <a
                  href="mailto:support@pipcall.com" target="_blank">PiPcall Support</a></p>
            </div>

          </div>
        </div>
      </div>



      <div class="section-wrapper">
        <tab-title-lined>Contact Info</tab-title-lined>


        <tab-section-action [isHidden]="organisationContactNumberForm?.pristine">
          <ng-container *ngIf="viewAs === 'sysAdmin' || viewAs === 'isAdmin'">
            <button (click)="cancelForm()" mat-flat-button *ngIf="!organisationContactNumberForm?.pristine"
              style="margin-right: 4px;">Cancel</button>

            <button mat-flat-button aria-label="Save changes" class="save-button"
              [disabled]="organisationContactNumberForm?.pristine ||  pageStatus.isSubmitting || organisationContactNumberForm.invalid"
              (click)="SaveOrganisationDetails(organisationContactNumberForm)">
              <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
            </button>
          </ng-container>
        </tab-section-action>


        <div class="section-content">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <form [formGroup]='organisationContactNumberForm' fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%"
              fxFlexOrder.lt-md="2" [ngClass]="{'readonly-block': pageStatus.isSubmitting}">

              <div class="tab-form-field-wrapper">

                <div style="display: flex;flex-direction: row;gap: 8px; align-items: center;">

                  <mat-form-field style="flex-grow:1" appearance="outline" floatLabel="always" class="custom-form-field-v3 inner-shadow"
                  [ngClass]="{'readonly-block': pageStatus.isSubmitting || (viewAs !== 'sysAdmin' && viewAs !== 'isAdmin')}">
                  <input autocomplete="off" type="text" matInput placeholder="" formControlName="contact_phone_number"
                  placeholder="Contact number">
                  <mat-label>Contact Phone Number*</mat-label>
                  <mat-icon matSuffix svgIcon="copy"
                  (click)="copyInputToClipboard(organisationProfile?.contact_phone_number)"
                  class="clickable action-icon" matTooltipPosition="below" matTooltipShowDelay="200"
                  matTooltip="Copy"></mat-icon>
                </mat-form-field>
                <mat-icon svgIcon="info" matTooltip="A number where we can contact you regarding the account."></mat-icon>
              </div>

              </div>


              <!-- <div fxLayout="column" fxFlex="40%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="1" style="padding-top:20px;">
                A number where we can contact you regarding the account.
              </div> -->

            </form>
          </div>
        </div>
      </div>

      <!-- <div class="section-divider"></div> -->
      <div class="section-wrapper">
        <tab-title-lined>Admin</tab-title-lined>

        <div class="section-content">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <div fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="2">

              <ng-container *ngIf="organisationProfile?.owner_user_id;else noAdminUser">

                <div class="clickable" style="color: var(--c-url)" (click)="openAdminUser(organisationProfile?.owner_user_id)">{{organisationProfile?.owner_full_name}}</div>

                <!-- <div fxLayout="row" fxLayoutAlign="start center" class="user-profile-card"
                  (click)="openAdminUser(organisationProfile?.owner_user_id)">

                  <div class="usr-avatar-md">
                    <img appGravatar [firstname]="organisationProfile?.owner_first_name"
                      [lastname]="organisationProfile.owner_last_name">
                  </div>

                  <div fxLayout="column" class="user-profile-wrapper">
                    <div class="user-title-row">{{organisationProfile?.owner_full_name}}&nbsp;
                    </div>
                  </div>

                </div> -->


              </ng-container>
              <ng-template #noAdminUser>
                <div style="color: var(--c-error);">No admin user found</div>
              </ng-template>

            </div>
            <div fxHide.lt-md fxLayout="column" fxFlex="40%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="1"
              style="padding-top:20px;">

            </div>
          </div>

        </div>

      </div>

      <!-- <div class="section-divider"></div> -->
      <div class="section-wrapper">
        <tab-title-lined>Product</tab-title-lined>

        <div class="section-content">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <div fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="2">
              <ng-container *ngIf="organisationProfile.is_pipmobile_enabled">
                <div>
                  PiPmobile
                  <mat-icon *ngIf="organisationProfile?.state === 'Trial' || organisationProfile?.state === 'Active'"
                    svgIcon="check" class="icon-20" style="color: var(--c-accept);vertical-align: middle;"></mat-icon>
                  <mat-icon [matTooltip]="'Organisation state is ' + organisationProfile?.state"
                    *ngIf="organisationProfile?.state === 'Closed' || organisationProfile?.state === 'Declined'  || organisationProfile?.state === 'Cancelled'"
                    svgIcon="warning" class="icon-20" style="color: var(--c-error);vertical-align: middle;"></mat-icon>
                  <mat-icon [matTooltip]="'Organisation is in arrears'"
                    *ngIf="organisationProfile?.state === 'In Arrears'" svgIcon="warning" class="icon-20"
                    style="color: var(--c-warn);vertical-align: middle;"></mat-icon>
                </div>
              </ng-container>

              <ng-container *ngIf="organisationProfile.is_pipxt_enabled">
                <div>
                  Mobile+
                  <mat-icon *ngIf="organisationProfile?.state === 'Trial' || organisationProfile?.state === 'Active'"
                    svgIcon="check" class="icon-20" style="color: var(--c-accept);vertical-align: middle;"></mat-icon>
                  <mat-icon [matTooltip]="'Organisation state is ' + organisationProfile?.state"
                    *ngIf="organisationProfile?.state === 'Closed' || organisationProfile?.state === 'Declined'  || organisationProfile?.state === 'Cancelled'"
                    svgIcon="warning" class="icon-20" style="color: var(--c-error);vertical-align: middle;"></mat-icon>
                </div>
              </ng-container>

              <ng-container *ngIf="organisationProfile.is_esim_enabled">
                <div>
                  eSIM
                  <mat-icon *ngIf="organisationProfile?.state === 'Trial' || organisationProfile?.state === 'Active'"
                    svgIcon="check" class="icon-20" style="color: var(--c-accept);vertical-align: middle;"></mat-icon>
                  <mat-icon [matTooltip]="'Organisation state is ' + organisationProfile?.state"
                    *ngIf="organisationProfile?.state === 'Closed' || organisationProfile?.state === 'Declined'  || organisationProfile?.state === 'Cancelled'"
                    svgIcon="warning" class="icon-20" style="color: var(--c-error);vertical-align: middle;"></mat-icon>
                </div>
              </ng-container>

            </div>


            <div fxLayout="column" fxFlex="40%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="2"
              *ngIf="organisationProfile.is_pipmobile_enabled && !organisationProfile.is_pipxt_enabled">
              For enhanced features such as call recording and shared contact directories, please contact us at <a
                style="display: contents;" href="mailto:support@pipcall.com">PiPcall Support</a>
            </div>
          </div>

        </div>

      </div>

      <ng-container
        *ngIf="organisationProfile?.is_pipxt_enabled || (directoryConnections && directoryConnections?.length > 0)">


        <!-- <div class="section-divider"></div> -->
        <div class="section-wrapper" *ngIf="viewAs === 'isAdmin' || viewAs === 'sysAdmin'">
          <tab-title-lined>Shared Directories</tab-title-lined>

          <div class="section-content">

            <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

              <div fxLayout="column" fxFlex="60%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="2">

                <ng-container *ngIf="!pageStatus?.isLoadingDirectory; else loadingSpinner">


                  <ng-container
                    *ngIf="(directoryConnections !== null || directoryConnections !== undefined) && directoryConnections?.length > 0; else directoryError">
                    <div class="value" *ngFor="let connection of directoryConnections">
                      {{connection?.directory_type_name}} <mat-icon svgIcon="check" class="icon-20"
                        style="color: var(--c-accept);vertical-align: middle;"></mat-icon>
                    </div>

                  </ng-container>

                </ng-container>


                <ng-template #directoryError>

                  <div class="value" *ngIf="directoryConnections === null || directoryConnections === undefined"
                    style="color: var(--c-error);">
                    There was a problem getting connections
                  </div>

                  <div class="value"
                    *ngIf="directoryConnections != null && directoryConnections?.length < 1 && directoryConnections !== undefined">
                    No connections
                  </div>

                </ng-template>


              </div>


              <div fxLayout="column" fxFlex="40%" fxFlex.lt-md="100%" fxFlexOrder.lt-md="1">
                If a shared directory exists, the PiPcall contact search results will include contacts from the
                directory. Contact <a style="display: contents;" href="mailto:support@pipcall.com"
                  target="blank">PiPcall Support</a> if you require one to be set up.
              </div>

            </div>
          </div>
        </div>
      </ng-container>



    </div>

    <!-- <div class="section-divider"></div> -->
  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>


<ng-template #statusIndicator let-organisationProfile="organisationProfile">
  <div [hidden]="organisationProfile.state !== 'Active'" class="status-icon">
    <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-accept)'></mat-icon>
  </div>

  <div [hidden]="organisationProfile.state !== 'Trial'" class="status-icon">
    <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-warn)'></mat-icon>
  </div>

  <div [hidden]="organisationProfile.state !== 'In Arrears'" class="status-icon">
    <mat-icon class="icon-14" svgIcon="circle-filled" style='color: var(--c-error)'></mat-icon>
  </div>
</ng-template>