import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { Routes, RouterModule } from '@angular/router'
import { MaterialModule } from '@app/material/material.module'
import { CustomPipesModule } from '@app/pipes/pipes.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ValidatorsModule } from '@app/validators/validators.module';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { environment } from '../../../environments/environment';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { HttpClientModule } from '@angular/common/http';



//declarations
import { CardSignupRequestComponent } from './signup-request/card_signup-request/card_signup-request.component';
import { CardVerifySignupComponent } from './verify-signup/card_verify_signup/card_verify_signup.component';
import { SignupRequestComponent } from './signup-request/signup.component';
import { VerifySignupComponent } from './verify-signup/verify-signup.component';

//recaptcha
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { GravatarDirectiveModule } from "@app/directives/gravatar/gravatar.module";
import { CodeInputModule } from 'angular-code-input';

//services
import { CookieService } from 'ngx-cookie-service';
import { SignupService } from '@app/services/pipcall/signup.service';
import { GA4Service } from '@app/services/google-analytics-service';
import { BlacklistService} from '@app/services/helpers/domain-blacklist.service';
import { SessionService } from '@app/services/session.service';
import { SharedService } from '@app/services/shared-data.service/shared-data.service';
//routing
import { SignupRouting } from './signup.routing';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        ValidatorsModule,
        CustomPipesModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        StatusIconsModule,
        GravatarDirectiveModule,
        RouterModule,
        SignupRouting,
        HttpClientModule,
        CodeInputModule
    ],
    declarations: [SignupRequestComponent, VerifySignupComponent, CardSignupRequestComponent, CardVerifySignupComponent],
    providers: [ AppInsightService, SignupService, HttpProxy, GA4Service, BlacklistService, CookieService, SessionService, SharedService, {
        provide: RECAPTCHA_SETTINGS,
        useValue: {
            siteKey: environment.recaptchaSiteKey,
        } as RecaptchaSettings,
    }],
    exports: [SignupRequestComponent, VerifySignupComponent, CardSignupRequestComponent, CardVerifySignupComponent],
    entryComponents: []
})
export class SignupModule {
}
