<ng-container *ngIf="organisation_id; else notFound">

  <ng-container *ngIf="pageStatus.isLoading">
    <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
      <div style="font-size: 4px;" class="loader-spinner"></div>loading...
    </div>
  </ng-container>

  <ng-container [hidden]="pageStatus.isLoading">
    <div *ngIf="noContent  === true">Error getting content</div>

    <div [@fadeInOnEnter] class="tab-child-view-container" *ngIf="noContent  === false">

      <div class="section-wrapper">

        <!-- <tab-title-lined>Shop</tab-title-lined> -->

        <!-- <div class="see-how-it-works">
          <a href="https://www.pipcall.com/solution/esim/how-it-works" target="_blank">See how it works →</a>
        </div> -->

        <div class="section-content fx-row fx-justify-end">
          <div class="cta-btn-wrapper" *ngIf="viewAs !== 'sysAdmin'">
            <button mat-flat-button class="purchase-esim-button" color="accent" (click)="navigatetoShopDataEsim()">+Buy eSIM for worldwide travel</button>
          </div>
          <div class="cta-btn-wrapper" *ngIf="viewAs === 'sysAdmin'">
            !! View as customer to see Buy options.
          </div>
        </div>
      </div>


      <div class="section-wrapper" style="margin-top: 0;">

        <tab-title-lined>Your eSIMs</tab-title-lined>

        <div class="section-content" *ngIf="organisationProfile">
          <app-data-esims-for-org (continueToShop)="navigatetoShopDataEsim()" [organisationProfile]="organisationProfile"></app-data-esims-for-org>
        </div>
      </div>

    </div>

  </ng-container>


</ng-container>



<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>

<ng-template #savingSpinner>
  <div style="height: 2em;">
    <div class="loader-spinner"></div>
  </div>
</ng-template>