
//requirements
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomPipesModule } from '@app/pipes/pipes.module';

//declarations
import { DataEsimDetailsModal } from './data-esim-details-modal.component';

//services
import { EsimTelnaService } from '@app/services/pipcall/esim_telna.service';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        CustomPipesModule
    ],
    declarations: [DataEsimDetailsModal],
    exports: [DataEsimDetailsModal],
    providers: [EsimTelnaService],
    entryComponents: [DataEsimDetailsModal]
})
export class DataEsimDetailsModalModule {}
