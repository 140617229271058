<div class="pip-modal-content" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>


  <div id="pipmodalbody" class="pip-modal-body fx-row" style="width: 100%;">

    <div  class="content-right fx-col fx-center">

      <div class="phone-image">
        <img src="assets/img/twin-phones-v3.png" alt="pipcall app">
      </div>

    </div>

    <div  class="content-right fx-col fx-center">
      <div class="download-title">
        Download the PiPcall app
      </div>
      <div class="download-links"  class="content-right fx-col fx-center">
        <div class="text-center" >
          <img src="assets/img/oneclick_appdownload.svg" alt="App Store QR link" >
        </div>

        <div style="padding: 10px;">
          <img src="assets/img/appstore-sm.png" alt="App Store" target="_blank" 
            class="clickable" (click)="downloadiosApp()">
        </div>

        <div style="padding: 10px;">
          <img src="assets/img/googleplay-sm.png" alt="Play Store" class="clickable second-option"
            (click)="downloadAndroidApp()">
        </div>

      </div>



    </div>



  </div>
