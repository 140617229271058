<ng-container *ngIf="organisation_id; else notFound">

  <ng-container *ngIf="!pageStatus.isLoading ; else loadingSpinner">
    <!-- [@fadeOutOnLeave] -->
    <div [@fadeInOnEnter] class="tab-child-view-container">


      <div *ngIf="organisationProfile?.state === 'Closed' || organisationProfile?.state === 'Cancelled'"
        class="section-info-bar" fxLayoutAlign="space-between center" fxLayout="row"
        style="background:var(--c-error);color:white;height: 50px;">Account is closed</div>
      <div *ngIf="organisationProfile?.state === 'Declined'" class="section-info-bar"
        fxLayoutAlign="space-between center" fxLayout="row" style="background:var(--c-warn);color:white;height: 50px;">
        Trial has now ended</div>


      <div class="section-wrapper">
        <div class="section-action">

          <div class="copy-highlighted-background" style="font-size: 14px;flex-grow:1">
            <p style="padding-right: 20px;">A list of extensions available for use by your organisation. Contact
              sales&#64;pipcall.com to add more extensions.</p>
          </div>

          <div *ngIf="viewAs === 'sysAdmin'" class="register-button-container">
            <button style="width:100%;" mat-flat-button color="accent" (click)="openRegisterSip()">Register Extension
              (sysAdmin)</button>
          </div>
        </div>
      </div>

      <ng-container *ngIf="orgDdiList">
        <app-org-pending-pipoffice [orgDdiList]="orgDdiList" [organisation_id]="organisation_id" [viewAs]="viewAs"
          (updateParent)="triggerUpdate()"></app-org-pending-pipoffice>

        <app-org-no-extension-pipoffice [availableUserList]="availableUserList" [orgDdiList]="orgDdiList"
          [organisation_id]="organisation_id" [viewAs]="viewAs"
          (updateParent)="triggerUpdate()"></app-org-no-extension-pipoffice>
      </ng-container>



      <!-- <div class="section-divider"></div> -->


      <div class="section-wrapper">

        <tab-title-lined>Extensions&nbsp;<span
          style="font-size: 0.8em;">({{dataSource?.filteredData.length}})</span></tab-title-lined>
    


        <div class="section-content">

          <div fxLayout="column" fxFlex="100%"
            *ngIf="dataSource?.data?.length === 0 || dataSource?.data?.length === null">
            <div class="information-banner warning" fxLayoutAlign="start center" fxFlex="100%">
              <div>
                <mat-icon svgIcon="warning"></mat-icon>
              </div>
              <div class="message-body">No numbers exist for this organisation
              </div>
            </div>

          </div>

          <div fxLayout="column" fxFlex="100%" [hidden]="!(dataSource?.data?.length > 0)">


            <div fxLayout="row" fxFlex="100%" fxLayoutAlign="end" fxLayoutAlign.lt-md="center"
              style="margin-bottom: 20px;">
              <mat-form-field appearance="outline" class="simple-input-v2" fxFlex.lt-md="100%">
                <input role="search" autocomplete="off" matInput placeholder="Search..." aria-label="search"
                  maxlength="150" tabindex="1" (keyup)="applyFilter($event.target.value)">
              </mat-form-field>
            </div>

            <table mat-table class="table tab-table-basic clean-spaced clean-add-row-border" [dataSource]="dataSource"
              matSort
              [ngClass]="{'disable-actions': viewAs !== 'sysAdmin' && (organisationProfile?.state === 'Closed' || organisationProfile?.state === 'Cancelled' || organisationProfile?.state === 'Declined')}">



              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef ></th>
                <td mat-cell *matCellDef="let row" [style.width.px]="10" >

                  <!-- error -->
                  <mat-icon *ngIf="!row.user_id && row.state === 'Error'" class="status-circle reject-col"
                    [matTooltipClass]="'tooltipcustom tooltipmultiline'" matTooltip="{{row.event_data}}"
                    svgIcon="circle">
                  </mat-icon>

                  <mat-icon *ngIf="row.user_id && row.state === 'Error'" class="status-circle reject-col"
                    [matTooltipClass]="'tooltipcustom tooltipmultiline'" matTooltip="{{row.event_data}}"
                    svgIcon="circle-checked">
                  </mat-icon>


                  <!-- warning -->
                  <mat-icon *ngIf="row.state === 'Unknown'" class="status-circle warning-col"
                    [matTooltipClass]="'tooltipcustom tooltipmultiline'"
                    matTooltip="SIP credentials are pending a systems check." svgIcon="circle"></mat-icon>


                  <!-- green -->
                  <mat-icon *ngIf="!row.user_id && row.state === 'OK'" class="status-circle accept-col"
                    [matTooltipClass]="'tooltipcustom tooltipmultiline'"
                    matTooltip="SIP credentials check was a success. Please assign a user." svgIcon="circle">
                  </mat-icon>


                  <mat-icon *ngIf="row.user_id && row.state === 'OK'" class="status-circle accept-col"
                    [matTooltipClass]="'tooltipcustom tooltipmultiline'" matTooltip="SIP is active."
                    svgIcon="circle-checked">

                  </mat-icon>


                </td>
              </ng-container>

              <ng-container matColumnDef="sip_extension_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Extension </th>
                <td mat-cell *matCellDef="let row">
                  <div fxLayout="row" fxLayoutAlign="start center" class="no-wrap">
                    <mat-icon *ngIf="row.is_blocked" style="color: var(--c-error)" svgIcon="block"></mat-icon>

                    <div class="table-icon-btn-wrapper" *ngIf="!row.is_blocked"><button (click)="$event.stopPropagation()" style="cursor: default" mat-icon-button
                      matTooltip="Extension"><mat-icon svgIcon="connect"></mat-icon></button>&nbsp;
                  </div> 
                    <!-- <mat-icon *ngIf="!row.is_blocked" class="icon-size-16" svgIcon="connect"></mat-icon> -->

                    &nbsp;<span (click)="openExtensionProfile(row)" class="clickable"
                      style="color: var(--c-url)">{{row.sip_extension_number}}</span>
                  </div>

                </td>
              </ng-container>


              <ng-container matColumnDef="sip_user_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tenant </th>
                <td mat-cell *matCellDef="let row">
                  {{(row?.sip_user_name && row?.sip_user_name?.length>3)? (row?.sip_user_name | slice:0:3):'' }}
                </td>
              </ng-container>


              <ng-container matColumnDef="sip_extension_cli">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Caller Identity </th>
                <td mat-cell *matCellDef="let row">
                  {{row.sip_extension_cli | normalizeToInternational}}
                </td>
              </ng-container>


              <ng-container matColumnDef="sip_host_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> PBX Hostname </th>
                <td mat-cell *matCellDef="let row">
                  {{row.sip_host_name}}
                </td>
              </ng-container>



              <ng-container matColumnDef="user_full_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let row">

                  <ng-container *ngIf="row.user_full_name || row.invitation_email; else unassigned">
                    <div fxLayout="row" fxLayoutAlign="start center">


                      <ng-container *ngIf="row?.user_full_name === 'pending..'">
                        <div>Pending...</div>
                      </ng-container>

                      <ng-container *ngIf="row.user_id">


                        <div class="usr-avatar-md" >
                          <img appGravatar [firstname]="row.user_first_name" [lastname]="row.user_last_name"
                            [email]="row.user_email">
                        </div>

                        <div  class="linked-user-wrapper">
                          <div class="row 1 clickable overflow-elipsis" style="color: var(--c-url);"
                            (click)="openUserProfile(row?.user_id)">{{row.user_first_name}} {{row.user_last_name}}</div>
                          <div class="row2 overflow-elipsis">{{row.user_email}}</div>

                        </div>

                      </ng-container>

                      <ng-container *ngIf="!row.user_id && row.invitation_email">


                        <!-- <div class="usr-avatar-md">
                          <div class="empty-user-avatar email-icon-avatar" fxLayout="column"
                            fxLayoutAlign="center center">
                            <mat-icon svgIcon="email"></mat-icon>
                          </div>
                        </div> -->

                        <div class="table-icon-btn-wrapper"><button (click)="$event.stopPropagation()" style="cursor: default" mat-icon-button
                          matTooltip="Email invitation has been sent to {{row.invitation_email}}"><mat-icon svgIcon="email_v2"></mat-icon></button>&nbsp;
                      </div> 

                        <div  class="pending-invitation-wrapper">
                          <div class="row1">Pending Invitation</div>
                          <div class="row2 overflow-elipsis" [matTooltip]="row.invitation_email">{{row.invitation_email}}</div>
                        </div>

                      </ng-container>

                    </div>

                  </ng-container>

                  <ng-template #unassigned>
                    <div fxLayout="row" fxLayoutAlign="start center">

                      <div class="usr-avatar-md" fxLayout="column" fxLayoutAlign="center center">
                        <div class="empty-user-avatar">
                        </div>
                      </div>

                      <div class="link-user-wrapper">
                        <div *ngIf="viewAs !== 'isAdmin' && viewAs !== 'sysAdmin'" style="color:var(--c-placeholder)">
                          -Unassigned-</div>
                        <div *ngIf="viewAs === 'isAdmin' || viewAs === 'sysAdmin'" [matMenuTriggerData]="{row: row}"
                          [matMenuTriggerFor]="addUser" class="vertical-algin: middle;" class="adduser clickable">
                          <button mat-flat-button class="blue-button btn-md">Link user</button>
                        </div>

                      </div>
                    </div>

                    <!-- 
                    <div style="height: 40px" fxLayout="row" fxLayoutAlign="start center">
                      
                      <span *ngIf="viewAs !== 'isAdmin'" style="color:var(--c-placeholder)">-unassigned-</span>

                      <span *ngIf="viewAs === 'isAdmin'" [matMenuTriggerData]="{row: row}" [matMenuTriggerFor]="addUser"  class="vertical-algin: middle;" class="adduser clickable">Assign user <span class="vertical-algin: middle;"><mat-icon svgIcon="add"></mat-icon></span>
                    </span>
    
                    </div> -->

                  </ng-template>

                </td>
              </ng-container>

              <ng-container matColumnDef="is_reserved">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Reserved </th>
                <td mat-cell *matCellDef="let row">
                  <ng-container *ngIf="row?.is_reserved">
                    <div class="check-container">
                      <mat-icon class="blue-check">check_circle</mat-icon>
                    </div>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="settings">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let row">


                  <div fxLayout="column" class="list-item-3-dot-wrapper" *ngIf="viewAs === 'isAdmin' || viewAs === 'sysAdmin'">
                    <button mat-icon-button class="more-menu" [matTooltipClass]="'tooltipcustom tooltipmultiline'"
                      matTooltip="Edit"  [matMenuTriggerData]="{row: row}" [matMenuTriggerFor]="editSip"><mat-icon svgIcon="more_vert"></mat-icon></button>
                  </div>

                </td>
              </ng-container>


              <tr class="header-row" mat-header-row *matHeaderRowDef="displayCols"></tr>

              <tr mat-row *matRowDef="let row; columns: displayCols;">
              </tr>

            </table>


            <div class="no-data" *ngIf="dataSource.filteredData && dataSource.filteredData.length === 0">No results
              returned</div>
            <mat-paginator itemsPerPageLabel="'items'" [pageSizeOptions]="[20, 35, 50]" [showFirstLastButtons]="true">
            </mat-paginator>



          </div>


        </div>
      </div>


    </div>

    <!-- <div class="section-divider"></div> -->
  </ng-container>


</ng-container>


<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>



<mat-menu #editSip="matMenu" class="v3-mat-menu">

  <ng-template matMenuContent let-row="row">

    <button mat-menu-item title="Edit settings for this extension" (click)="openExtensionProfile(row)">
      <mat-icon svgIcon="settings"></mat-icon>
      <span>Edit Settings</span>
    </button>

    <button class="red-item red-item-full" *ngIf="row.user_id"
      [disabled]="viewAs !== 'sysAdmin' && viewAs !== 'isAdmin'" mat-menu-item title="Unlink the user"
      (click)="user_unlink(row?.number)">
      <mat-icon svgIcon="unlink"></mat-icon>
      <span>Unlink User</span>
    </button>


    <button class="red-item red-item-full" *ngIf="row.invitation_id && (viewAs === 'isAdmin' || viewAs === 'sysAdmin')"
      mat-menu-item title="Revoke invitation for this extension" (click)="alertRevokeInvitation(row.invitation_id)">
      <mat-icon svgIcon="trash"></mat-icon>
      <span>Revoke Invitation</span>
    </button>


    <ng-container *ngIf="viewAs === 'sysAdmin'">

      <!-- <hr class="divider"> -->

      <div class="divider">Sys Admin</div>

      <div (click)="$event.stopPropagation();" matTooltip="toggle is reserved state" mat-menu-item><mat-slide-toggle
          (change)="updateIsReservedState(row, $event)" [checked]="row?.is_reserved">Is Reserved</mat-slide-toggle>
      </div>

      <button class="red-item red-item-full" [disabled]="row?.invitation_id || row?.user_id"
        *ngIf="viewAs === 'sysAdmin'" mat-menu-item title="Delete this extension" (click)="deleteNumber(row)">
        <mat-icon svgIcon="trash"></mat-icon>
        <span>Delete Extension</span>
      </button>

    </ng-container>


  </ng-template>
</mat-menu>


<mat-menu #addUser="matMenu" class="v3-mat-menu" xPosition="before">

  <ng-template matMenuContent let-row="row">
    <button mat-menu-item title="Invite a user to be assigned number" (click)="openInviteModal(row)">
      <mat-icon>person_add</mat-icon>
      <span>Invite New User</span>
    </button>

    <button mat-menu-item title="Link with existing user" [matMenuTriggerFor]="availableUsers"
      [matMenuTriggerData]="{row: row}" *ngIf="availableUserList.length > 0">
      <mat-icon>swap_horiz</mat-icon>
      <span>Link Existing</span>
    </button>


  </ng-template>
</mat-menu>

<mat-menu #availableUsers="matMenu" class="v3-mat-menu">

  <ng-template matMenuContent let-row="row">
    <div mat-menu-item *ngIf="availableUserList.length === 0">There are no available users</div>

    <ng-container *ngFor="let user of availableUserList">
      <button mat-menu-item title="Link number with user"
        (click)="assignToUser(row, user.id, user.first_name, row.organisation_id)">
        <span>{{user.first_name}} {{user.last_name}}</span>
      </button>
    </ng-container>

  </ng-template>
</mat-menu>