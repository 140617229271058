<div style="display:flex;flex-direction: column;width: 100%;">
  <!-- [hidden]="!(dataSource?.data?.length > 0)" -->

  <div style="display:flex;flex-direction: row;width: 100%; margin-bottom: 8px;justify-content: end;">

    <mat-form-field appearance="outline" class="simple-input-v2" fxFlex.lt-md="100%">
      <input role="search" autocomplete="off" matInput placeholder="Search..." aria-label="search" maxlength="150"
        tabindex="1" (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="table-container-2 no-border">
    <table mat-table class="table tab-table-basic clean-spaced clean-add-row-border" [dataSource]="dataSource" matSort>


      <!-- <td mat-cell *matCellDef="let row" [style.width.px]="10" > -->

      <ng-container matColumnDef="license_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">
          <div class="esim-name-container" [matTooltip]="row?.licence_name">
            <div class="flag-container" *ngIf="!row?.is_bundle">
              <img class="country-flag sm"
                [src]="'https://pipcallportalstorage.blob.core.windows.net/flags-of-the-world/svg/' + row?.country_code + '.svg'"
                (error)="($event.target.src = 'https://pipcallportalstorage.blob.core.windows.net/flags-of-the-world/svg/missing.svg')"
                [alt]="'Flag of ' + row?.license_name" />
            </div>

            <div class="flag-container" *ngIf="row.is_bundle">
              <img class="country-flag sm"
                [src]="'https://pipcallportalstorage.blob.core.windows.net/flags-of-the-world/svg/' + getFlag(row?.license_name) + '.svg'"
                (error)="($event.target.src = 'https://pipcallportalstorage.blob.core.windows.net/flags-of-the-world/svg/missing.svg')"
                [alt]="'Flag of ' + row?.license_name" />
            </div>

            <div class="licence-name">
              {{row?.license_name}}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="contract_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
        <td mat-cell *matCellDef="let row">

          <ng-container  *ngIf="row?.state === 'ACTIVE' && row?.data_usage_remaining;else dataAllowance">

            <div style="width: 120px;">

              <span [innerHTML]="getDataUsed(row?.data_usage_remaining, row?.data_allowance | gbToBytes) | byteFormat"></span>&nbsp;/ <span>{{row?.data_allowance}}</span>&nbsp;<span style="font-size:0.8em;">GB</span>
            
            <div>
              <mat-progress-bar color="primary" mode="determinate" [value]="getPercentageUsed(row?.data_usage_remaining, row?.data_allowance)"></mat-progress-bar>
            </div>

          </div>
          </ng-container>

          <ng-template #dataAllowance>
            <span style="font-weight: 600;color:var(--c-title);white-space: nowrap;">{{row?.data_allowance}}&nbsp;GB</span>
            <span style="white-space: nowrap;">&nbsp;{{row?.duration_days}} days</span>
          </ng-template>

          

        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let row">
          {{row?.state}}
        </td>
      </ng-container>

      <ng-container matColumnDef="user_email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row?.user_email">{{row?.user_email}}</span>
          <button *ngIf="!row?.user_email && row?.state === 'READY'" mat-button class="send-btn">Send QR code</button>
        </td>
      </ng-container>

      <ng-container matColumnDef="purchased_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 140px;">Purchased at</th>
        <td mat-cell *matCellDef="let row">
         <span style="white-space: nowrap;">{{row?.purchased_at | date: 'yyyy-MM-dd HH:mm'}}</span> 
        </td>
      </ng-container>

      <!-- <tr class="header-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (class)="clickable" style="cursor: pointer;" [ngClass]="{'mark-as-expired-row':row['state'] === 'EXPIRED'}"
        (click)="openRowItem(row?.id)"></tr>

      </table>
      <div  class="loading-data" *ngIf="pageStatus.isLoading">loading...</div>
      <div class="no-data" *ngIf="dataSource.filteredData && dataSource.filteredData.length === 0 && !pageStatus.isLoading">
        <ng-container *ngIf="dataSource.data.length === 0;else noresults">Click buy to add your first eSIM</ng-container>
        <ng-template #noresults>No results returned</ng-template></div>
        
  </div>

  <mat-paginator itemsPerPageLabel="'items'" [pageSizeOptions]="[20, 35, 50]" [showFirstLastButtons]="true">
  </mat-paginator>



</div>