<mat-toolbar>
  <mat-toolbar-row class="fx-row fx-align-center" style="justify-content: space-between">

    <div class="fx-row fx-align-center">
      <button *ngIf="viewAs==='sysAdmin'" type="button" aria-label="Toggle sidenav" mat-icon-button
        (click)="toggleNavBar()">
        <mat-icon aria-label="Side nav toggle icon" svgIcon="menu"></mat-icon>
      </button>

      <div class="toolbar-left">
        <div class="brand-logo-container fx-col fx-align-center fx-justify-center">
          <img src="https://pipcallportalstorage.blob.core.windows.net/images/pip_logo_solo_white_sm.png"
            class="main-brand-logo clickable pip-size" alt="PiPcall logo" [routerLink]="['/redirect']">
        </div>

      </div>
    </div>


    <div class="toolbar-right fx-row fx-align-center" style="gap: 12px;">
      <!-- 
      <div class="menu-item" fxLayoutAlign="center" *ngIf="viewAs === 'sysAdmin'">
        <button style="min-width: unset;" mat-button (click)="openHelpCenter()">
          <mat-icon svgIcon="help"></mat-icon> <span fxHide.lt-md></span>
        </button>
      </div> -->

      <!-- //need to add rules to hide on small view -->
      <div class="search-input-box" *ngIf="viewAs==='sysAdmin'">

        <mat-form-field appearance="outline" class="simple-input-v2 search-white-background">
          <input type="text" matInput placeholder="Search..." [(ngModel)]="searchInput"
            (keyup.enter)="search(searchInput)" (keyup.escape)="clearSearch()">
          <mat-icon class="clickable icon-size-18" matSuffix svgIcon="search" (click)="search(searchInput)"></mat-icon>
        </mat-form-field>
      </div>


      <div class="profile-container fx-row" *ngIf="userProfile">
        <ng-container *ngTemplateOutlet="userProfileTemplate"></ng-container>
      </div>
    </div>


  </mat-toolbar-row>
  <div [@fadeIn] *ngIf="impersonateMode" class="impersonate-container clickable" [matMenuTriggerFor]="impersonateMenu">
    Impersonating</div>

</mat-toolbar>





<mat-menu #impersonateMenu="matMenu" class="small-mat-menu dark-mode">
  <button mat-menu-item (click)="existImpersonate()">
    <mat-icon>exit_to_app</mat-icon>
    <span>Exit impersonate mode</span>
  </button>
</mat-menu>


<ng-template #userProfileTemplate>

  <div class="user-profile-container clickable fx-row" matTooltip="Account" [matMenuTriggerFor]="usermenu">
    <!-- 
    <div class="profile-name-container">
      <p class="profile-name overflow-elipsis">{{userProfile?.first_name}} {{userProfile?.last_name}}</p>

      <span class="profile-info overflow-elipsis">
        <ng-container *ngIf="viewAs === 'sysAdmin'; else customerView">
          <span style="color: #bdbdbd;">Sys admin</span>
        </ng-container>
        <ng-template #customerView>
          {{activeOrg.name}}
        </ng-template>
      </span>


    </div> -->

    <div class="profile-image"><img mat-card-avatar appGravatar [firstname]="userProfile?.first_name"
        [lastname]="userProfile?.last_name" [email]="userProfile?.email? userProfile.email:''"></div>
  </div>



</ng-template>



<mat-menu #usermenu="matMenu" class="v3-mat-menu large-mat-menu">


  <ng-template matMenuContent>

    <div class="user-email-container">{{userProfile?.email}}</div>
    <hr class="divider">
    <button mat-menu-item [routerLink]="['/profile']">
      <span>My Account</span>
    </button>

    <!-- <button mat-menu-item (click)="navigateToSettings()">
      <span>Organisation Settings</span>
    </button>
     -->

    <hr class="divider">
    <!-- <button mat-menu-item [routerLink]="['/manage/organisation', activeOrg.id]">
      <span>{{activeOrg.name}}<span *ngIf="viewAs === 'isAdmin'" class="customer-admin-icon ">&nbsp;(Admin)</span></span>
    </button> -->

    <ng-container>
      <button *ngIf="activeOrg?.is_pipmobile_enabled" mat-menu-item title="Pricing" (click)="openPricingModal()">
        <span>Pricing</span>
      </button>

      <button mat-menu-item (click)="openDownloadLink()"
        *ngIf="activeOrg?.is_pipmobile_enabled || activeOrg?.is_pipxt_enabled">
        <span>Download App</span>
      </button>
    </ng-container>

    <button mat-menu-item title="View Terms and conditions"
      (click)="navigateToURL('https://www.pipcall.com/policies', true)">
      <span>Terms and Conditions</span>
      <mat-icon style="float:right;" svgIcon="open_link"></mat-icon>
    </button>

    <button mat-menu-item title="About PiPcall" (click)="navigateToURL('https://www.pipcall.com/about-us', true)">
      <span>About Us</span>
      <mat-icon style="float:right;" svgIcon="open_link"></mat-icon>
    </button>

    <button mat-menu-item title="Get support" (click)="navigateToURL('https://www.pipcall.com/support', true)">
      <span>Get Support</span>
      <mat-icon style="float:right;" svgIcon="open_link"></mat-icon>
    </button>

    <ng-container *ngIf="isSysAdmin">
      <div class="divider">Sys Admin</div>

      <button *ngIf="viewAs  !=  'sysAdmin'" mat-menu-item (click)="switchToView('sysAdmin')">
        <span>Switch to <strong>Sys Admin View</strong></span>
      </button>

      <button *ngIf="viewAs === 'sysAdmin'" mat-menu-item (click)="switchToView('user')">
        <span>Switch to <strong>customer</strong> view</span>
      </button>
    </ng-container>

    <hr class="divider">

    <button mat-menu-item (click)="signOut()">
      <mat-icon svgIcon="logout-2"></mat-icon>
      <span>Sign out</span>
    </button>

  </ng-template>


</mat-menu>