import { NgModule } from '@angular/core';

//requirements
import { MaterialModule } from '@app/material/material.module';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
//create modal components

import { ExampleModal } from './example-modal.component'

@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        MaterialModule,
        DragDropModule
    ],
    declarations: [ExampleModal],
    exports: [ExampleModal],
    providers: [],
    entryComponents: [ExampleModal]
})
export class ExampleModalModule { }
