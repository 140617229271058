<div class="container bg-image">
  <mat-card class="view-content" style="padding:0px;">

    <mat-card-header>
    </mat-card-header>
    <mat-card-content class="text-center  fx-row fx-col-sm fx-center" >

      <div class="image-container fx-row fx-justify-end fx-align-end fx-center-sm">
        <img class="pipcall-logo"  src="https://pipcallportalstorage.blob.core.windows.net/images/pip_logo_solo_white_sm.png"
        alt="PiPcall logo">
      </div>

      <div class="verify-message">

        <ng-container *ngIf="loading;else loaded">
          <span id="firstMsg">
            <div class="loader-spinner"></div> It’s business time! Stand by while we retrieve your details.
          </span>
        </ng-container>

        <ng-template #loaded>

          <ng-container *ngIf="success;else failed">

            <span class="subtitle-12">PiPcall registration</span>
            <p>
              Verify your details and press continue.
            </p>
            <br>

            <div class="accept-invite-form fx-col">
              <form [formGroup]="invitationForm" class="fx-col">

                <mat-form-field appearance="outline" class="readonly-block custom-form-field-v3">
                  <input type="email" matInput placeholder="Email" autocomplete="email" formControlName="email"
                    readonly>
                  <mat-icon svgIcon="email" matSuffix></mat-icon>
                  <mat-label>Email</mat-label>
                  <status-icons [iconStatus]="invitationForm.get('email').status">
                  </status-icons>
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="invitationForm.get('cli_override').value" class="custom-form-field-v3 readonly-block">
                  <input type="text" matInput placeholder="Business Number" autocomplete="off"
                    formControlName="cli_override" readonly>
                  <mat-icon matSuffix svgIcon="phone"></mat-icon>
                  <mat-label>Business number</mat-label>
                  <status-icons [iconStatus]="invitationForm.get('cli_override').status">
                  </status-icons>
                </mat-form-field>

                <div class="fx-row fx-gap-1 fx-col-sm fx-justify-space-between fx-align-center"  [hidden]="hideNameFields">

                  <mat-form-field appearance="outline" style="min-width: 50px" class="custom-form-field-v3 fx-row fx-grow fx-width-100-sm">
                    <input type="text" matInput placeholder="First name" autocomplete="email"
                      formControlName="first_name">
                    <mat-label>First Name</mat-label>
                    <mat-error class="errormsg"
                      *ngIf="invitationForm?.controls.first_name?.touched && invitationForm?.controls.first_name?.errors?.required">
                      first
                      name is required</mat-error>
                    <status-icons *ngIf="invitationForm.get('first_name').touched" [iconStatus]="invitationForm.get('first_name').status">
                    </status-icons>
                  </mat-form-field>

                  <mat-form-field appearance="outline" style="min-width: 50px" class="custom-form-field-v3 fx-row fx-grow fx-width-100-sm"  >
                    <input type="text" matInput placeholder="Last name" autocomplete="email"
                      formControlName="last_name">

                    <mat-label>Last Name</mat-label>
                    <mat-error class="errormsg"
                      *ngIf="invitationForm?.controls.last_name?.touched && invitationForm?.controls.last_name?.errors?.required">
                      first
                      name is required</mat-error>
                    <status-icons *ngIf="invitationForm.get('last_name').touched"  [iconStatus]="invitationForm.get('last_name').status">
                    </status-icons>
                  </mat-form-field>
                </div>

                  <div class="fx-row fx-justify-start fx-align-center"  style="width: 100%;">
                  <mat-form-field appearance="outline" class="custom-form-field-v3 number-code-select readonly-block">
                    <mat-select formControlName="region">
                      <mat-option value="0044">+44</mat-option>
                    </mat-select>
                
                    <mat-label>UK mobile</mat-label>
                    <status-icons *ngIf="invitationForm?.controls.mobile?.touched"
                    [iconStatus]="invitationForm.get('mobile').status">
                  </status-icons>
                  </mat-form-field>
      
                  <mat-form-field appearance="outline" class="custom-form-field-v3 fx-grow">
                    <input type="text" matInput placeholder="Enter mobile" autocomplete="mobile" formControlName="mobile">
                    <mat-error
                    *ngIf="invitationForm?.controls.mobile?.touched && invitationForm?.controls.mobile?.errors?.required">
                    Mobile is required</mat-error>
                  <mat-error class="errormsg" *ngIf="invitationForm.get('mobile').status === 'PENDING'">
                    checking ...
                  </mat-error>
                  <mat-error
                    *ngIf="invitationForm.get('mobile').touched && invitationForm.get('mobile').errors?.mobileTaken">
                    Mobile already registered with an account.
                  </mat-error>
                  <mat-error
                  *ngIf="invitationForm.get('mobile').touched && invitationForm.get('mobile').errors?.valid">
                  {{invitationForm.get('mobile').errors?.valid}}
                </mat-error>
                <mat-icon matSuffix svgIcon="info" matTooltip="This is the number for the UK handset you will install PiPcall on. You will need to verify you own this number."></mat-icon>
                  </mat-form-field>
                </div> 



                <div style="text-align: right; margin-top: 10px; margin-bottom:10px;">

                  <mat-checkbox labelPosition="before" [(ngModel)]="termsconditions" [ngModelOptions]="{standalone: true}"
                  style="font-size: 14px;">
                  <span style="white-space: pre-wrap;">I agree to the <a target="_blank" href="https://www.pipcall.com/policies/endusertermsandconditions">End-User Terms and Conditions</a></span></mat-checkbox>
                </div>

                <button mat-raised-button color="accent" type="button" [style.width.%]="'100'"
                  [style.margin-top.px]="20" (click)="SubmitForm(invitationForm)" [disabled]="!invitationForm.valid || !termsconditions">
                  <ng-container *ngIf="!submitted;else spinner">Continue</ng-container>
                  <ng-template #spinner><div class="loader-spinner"></div></ng-template>
                </button>

                <div style="text-align: right; margin-top: 18px;">
                  <a style="text-decoration: none;color:grey;" target="_blank" href="https://www.pipcall.com/policies/enduserprivacypolicy">End-User Privacy Policy</a>
                </div>
              </form>


            </div>
          </ng-container>


          <ng-template #failed>
            <h5>Well, that doesn’t look right.</h5>
            <p [style.margin-top.px]="'30'">It looks like your token is expired or incorrect. Let us help fix it by
              reaching out to <a href="mailto:support@pipcall.com" target="_blank">support@pipcall.com</a> or call us on 0330 094 8080
              if you need help.
            </p>
          </ng-template>
        </ng-template>

      </div>
    </mat-card-content>

  </mat-card>
</div>
