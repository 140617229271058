//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel, OrgMetaData } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { Subject , Observable, Subscription} from "rxjs";
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { ModalService } from '@app/services/modal-service/modal.service';

import { MatBottomSheet,  MatBottomSheetRef, } from '@angular/material/bottom-sheet';
import { EsimTelnaService, OrganisationDataEsims  } from '@app/services/pipcall/esim_telna.service';

@Component({
    selector: 'app-org-tabs-esim',
    templateUrl: './org-esim.component.html',
    styleUrls: ['./org-esim.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})

export class OrgTabEsimComponent {


    @Input() viewAs: string;
    @Input() organisationProfile: OrganisationModel //optional ?
    @Input() organisation_id: string; //required
    @Output() updateParent = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false
    }


    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private appInsightService: AppInsightService,
        private modalService: ModalService,
        public dialog: MatDialog,
        private ref: ChangeDetectorRef,
        private bottomSheet: MatBottomSheet,
        private esimTelnaService: EsimTelnaService
    ) {
    }



    ngOnInit() {

        if ( !this.organisationProfile ) {
            this.getOrganisationDetails();
        } else {
            // if (!this.organisationProfile?.is_esim_enabled) {
            //     //redirect if it is not enabled
            //     this.router.navigate([`/manage/organisation/${this.organisation_id}`]);
            // }
            // this.getEsimsForOrganisation(this.organisationProfile?.id);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue ?   this.setFormValues(this.organisationProfile) : null;
    }

    setFormValues(organisationProfile: OrganisationModel) {
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
        form.markAsUntouched();
    }

    // getEsimsForOrganisation(organisation_id) {
    //     this.esimTelnaService.getDataEsimsForOrg(organisation_id).subscribe((resp) => {
    //         console.log("esims", resp);
    //     }, (err) => {
    //         console.log(err);
    //         this.openSnackBar(`An error occured getting esims [${err.status}]`, "dismiss");
    //     });
    // }

    getOrganisationDetails() {
        //get profile if one was not passed
        this.pageStatus.isLoading = true;
        this.organisationService.getOrganisationById(this.organisation_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false }),
                takeUntil(this._destroy$)
            )
            .subscribe((resp) => {
                this.ref.detectChanges();
                if (resp.status === 200 ) {
                    this.organisationProfile = resp.body;
                    // if (!this.organisationProfile?.is_esim_enabled) {
                    //     //     //redirect if it is not enabled
                    //     //     this.router.navigate([`/manage/organisation/${this.organisation_id}`]);
                    //     // }
                    // this.getEsimsForOrganisation(this.organisationProfile?.id);
                    this.ref.detectChanges();
                }
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured getting organisation details [${err.status}] `, "dismiss");
            })

    }


    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }

    navigatetoShopDataEsim() {
        console.log('org-esim - navigatetoShopDataEsim');
        this.router.navigate([`/organisation/${this.organisation_id}/order/data_esim`]);
    }
    // navigatetoShopBusinessEsim() {
    //     this.router.navigate([`/manage/organisation/${this.organisation_id}/orde r/esim_for_business`]);
    // }

    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    triggerUpdate() {
        this.updateParent.emit(true);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
