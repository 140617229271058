import {Component, TemplateRef, ViewChild, Inject} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { UserProfile } from '@app/models/user-profile.model';
import { OrganisationModel } from '@app/models/organisation.model';
import { UserService } from '@app/services/pipcall/user.service';
import { MatSnackBar } from '@app/material/material-essentials.module';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription, Observable, throwError, Subject } from 'rxjs';
import {
    MAT_BOTTOM_SHEET_DATA,
    MatBottomSheet,
    MatBottomSheetRef,
    MatBottomSheetConfig
} from '@angular/material/bottom-sheet';

import {fadeInOnEnterAnimation} from 'angular-animations';


@Component({
    selector: 'bs-organisationprofile-bsheet-modal',
    templateUrl: './organisationprofile-bsheet-modal.component.html',
    styleUrls: ['./organisationprofile-bsheet-modal.component.scss'],
    animations: [
        fadeInOnEnterAnimation()
    ]
})
export class OrganisationProfileBottomSheetComponent {


    public viewAs: string;
    public isloading = true;
    public notFound: boolean = null;
    public userProfile: UserProfile;
    public user_id: string;
    public organisation_id: string;
    public organisationProfile: OrganisationModel;
    public organisationUserList: Array<UserProfile>;
    public _subsciption: Subscription;
    public _orgsubsciption: Subscription;
    public trialDays: number;

    public organisationUrl = window.location.origin;

    public pageStatus = {
        activeTab: '',
        isLoading: true,
        noContent: false,
        dataChanged: false
    }
    _destroy$ = new Subject<void>();

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: {viewAs: string, organisationProfile: OrganisationModel, organisation_id: string},
        private _bottomSheetRef: MatBottomSheetRef<OrganisationProfileBottomSheetComponent>,
        private ref: ChangeDetectorRef,
        private organisationService: OrganisationService,
        private snackBar: MatSnackBar,
        public router: Router,
    ) {

        console.log("DATA:", this.data);

        this.viewAs = data?.viewAs;
        this.organisationProfile = data?.organisationProfile ? data?.organisationProfile : null;
        this.organisation_id = data?.organisation_id;
        this.organisationUrl = window.location.origin + '/admin/organisation/' + this.organisation_id + '/profile';

        this.organisationUserList = new Array<UserProfile>();
        this.organisationUserList = null;
        this.viewAs === 'sysAdmin' ? this.pageStatus.activeTab = 'system' : this.pageStatus.activeTab = 'home';
    }

    ngOnInit() {
        //handle missing data
        // this.first_name = this.userProfile.first_name;
        // this.last_name = this.userProfile.last_name;

        this.pageStatus.isLoading = true;

        if (!this.organisationProfile) {
            console.log("NO Organisation PROFILE . GO AND GET IT.");
            this.getData();
        } else {
            console.log("THERE IS A PROFILE. LOADING IS FALSE NOW", this.organisationProfile);
            this.pageStatus.isLoading = false; }

        //if no Organisation. Get it now.
    }

    getData() {
        console.log("[extensions bottomsheet].getData()")
        if (!this.organisation_id) {this.pageStatus.noContent = true; this.pageStatus.isLoading = false; return; }

        this._orgsubsciption = this.organisationService.getOrganisationById(this.organisation_id)
            .switchMap((resp) => {
            resp?.body?.id ? this.organisationProfile = resp?.body :  this.handleNoOrganisationId();
            this.trialDays = this.setTrialDate(this.organisationProfile.trial_end_date);
            return this.getOrganisationUsers(this.organisation_id);
            })
            .subscribe((resp) => {
                this.organisationUserList = resp.body.user_list;
                this.pageStatus.isLoading = false;
                this.pageStatus.noContent = false;
                this.ref.detectChanges();
            }),
        (err) => {
            this.pageStatus.isLoading = false;
            console.log(err);
            this.handleNoOrganisationId();
            this.openSnackBar("Error getting content, returned to organisations", "dismiss");
        };
    }

    setTrialDate(trialEndDate: string) {
        const today = new Date();
        const trialEnds = new Date(trialEndDate);
        trialEnds.setHours(23, 59, 59, 59); //set timestamp to midnight

        const diff = trialEnds.getTime() - today.getTime();
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24)) - 1; //added minus 1 to adjust for first day partial.
        return diffDays;

    }

    getOrganisationUsers(id: string) {
        console.log("GET ORG Users IN PARENT============")
        return this.organisationService.getOrgUsersById(id);
    }

    changeTab(tab: string) {
        console.log("CHANGE TAB..............", tab);
        this.pageStatus.activeTab = tab;
        this.ref.detectChanges();
    }

    handleNoOrganisationId() {
        this.openSnackBar("No Organisation id, returned to all organisations", "dismiss");
        setTimeout(() => {
            this.dismiss();
        }, 1500);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }


    updateParentEvent(event) {
        console.log("EVENT:", event);
        if (event === 'close') {this.dismiss()}
    }

    refreshData(event) {
        console.log("refresh data EVENT:", event);
        this.getData();
        this.pageStatus.dataChanged = true;
    }

    dismiss() {
        console.log("dismiss it now");
        this._bottomSheetRef.dismiss(this.pageStatus?.dataChanged);
    }

    openProfileURL(organisation_id: string) {
        this._bottomSheetRef.dismiss();
        this.router.navigateByUrl('/admin/organisation/' + organisation_id + '/profile');
    }

    copyInputToClipboard(value) {
        //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}` , "dismiss");
    }
}

