<div class="pip-modal-content">
  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>

  <div class="pip-modal-header fx-row fx-center">
    <span class="pip-modal-title">Select a licence</span>
  </div>

  <div id="pipmodalbody" class="pip-modal-body">

    <div class="tarrif-content-body">

      <app-view-licence-tariff [isModeSelect]=true (selectedLicense)="selectLicence($event)"
        [numberType]="data.numberType"></app-view-licence-tariff>

      <!-- <app-view-add-on-tariff></app-view-add-on-tariff> -->

      <div  class="extra-info fx-row fx-justify-end fx-align-end">
        <div><i>All prices exclude VAT</i></div>
      </div>
      
      <div class="extra-info fx-row fx-justify-end fx-align-end" *ngIf="showApplyToAll">
        <mat-checkbox [(ngModel)]="applyToAll">Apply licence to all <span *ngIf="data.numberType != 'mobile'">landline numbers</span><span *ngIf="data.numberType === 'mobile'">talk & text numbers</span></mat-checkbox>
      </div>
    </div>

  </div>
