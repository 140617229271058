//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel, OrgMetaData } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { Subject , Observable, Subscription} from "rxjs";
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { PaymentsService, DefaultCard} from '@app/services/pipcall/payments.service';
import { AuthService } from '@app/services/auth-service/auth.service';
import { InvoiceLicenseItem, AddOnPackageItem, StubItem, AdHocItem, InvoiceListModel } from '@app/models/invoice.model';
import { InvoiceService, PdfResponse } from '@app/services/pipcall/invoice.service';
import * as FileSaver from 'file-saver';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { InvoiceDeleteModalComponent } from '@app/components/modals/delete-invoice-modal/delete-invoice-modal.component';
import { RestoreDeletedInvoiceModalComponent } from '@app/components/modals/restore-deleted-invoice-modal/restore-deleted-invoice-modal.component';
import { UpdateCardModalComponent } from '@app/components/modals/update-card-modal/update-card-modal.component';
import { FirstPaymentModal } from '@app/components/modals/first-payment-modal/first-payment-modal.component';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { ModalService } from '@app/services/modal-service/modal.service';
import {InvoiceBottomSheetComponent} from '@app/components/bottom-sheet-modal/invoice-bsheet-modal/invoice-bsheet-modal.component';
import { MatBottomSheet,  MatBottomSheetRef, } from '@angular/material/bottom-sheet';
import { escape, unescape} from 'safe-string-literal';

// export class CardDetails {
//     brand: string;
//     exp_month: number;
//     exp_year: number;
//     id: string;
//     last_4_digits: string;
// }

@Component({
    selector: 'app-org-tabs-billing',
    templateUrl: './org-billing.component.html',
    styleUrls: ['./org-billing.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})

export class OrgTabBillingComponent {


    @Input() viewAs: string;
    @Input() organisationProfile: OrganisationModel //optional ?
    @Input() organisation_id: string; //required


    @Output() updateParent = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        loadingCard: true,
        loadingInvoice: true
    }


    public carddetails: DefaultCard

    //forms not in use. original solution
    // billingContactForm: FormGroup;

    organisationBillingDetails: FormGroup;

    //invoices
    public invoiceList: InvoiceListModel[];

    //subscriptions
    _cardSubscription: Subscription;

    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private paymentsService: PaymentsService,
        private invoiceService: InvoiceService,
        private appInsightService: AppInsightService,
        private modalService: ModalService,
        public dialog: MatDialog,
        private ref: ChangeDetectorRef,
        private bottomSheet: MatBottomSheet
    ) {
        this.invoiceList = new Array<InvoiceListModel>();

        this.organisationBillingDetails = this._formBuilder.group({
            organisation_id: ['', [Validators.required]],
            company_number: [''],
            billing_contact_email: ['', [Validators.email]],
            registered_address: ['']
        });
        console.log('Billing - constructor');

    }

    // convenience getters for easy access to form fields
    get f() { return this.organisationBillingDetails.controls; }



    ngOnInit() {
        console.log("LOAD COMPONENT>>>>>>BILLING COMPONENT", this.organisationProfile)
        // this.getDebugCrdDetails()

        if ( !this.organisationProfile ) {
            this.getOrganisationDetails();
        } else {
            if (this.organisationProfile?.is_pipmobile_enabled) {
                this.organisationProfile.state !== 'Trial' ? this.getCardDetails() : null;
                // this.organisationProfile.state !== 'Trial' ? this.getInvoiceList() : null;
            }
            if (this.organisationProfile?.is_esim_enabled) {
                this.getCardDetails();
            }
            this.getInvoiceList()
        }
        setTimeout(() => {
            this.organisationBillingDetails.updateValueAndValidity();
        }, 1000);
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("Billing CHANGES", changes);
        // changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue ?   this.setOrganisationNameFormValues(this.organisationProfile) : null;
        changes['organisation_id']?.currentValue !== changes['organisation_id']?.previousValue ?   this.setFormValue(this.organisationBillingDetails, 'organisation_id', changes['organisation_id']?.currentValue) : null;
        changes['organisationProfile']?.currentValue !== changes['organisationProfile']?.previousValue ?   this.setFormValues(this.organisationProfile) : null;
    }

    setFormValues(organisationProfile: OrganisationModel) {
        this.setFormValue(this.organisationBillingDetails, 'organisation_id', organisationProfile?.id);
        let _meta;
        let parseddata;
        try {
            _meta =  unescape(organisationProfile?.metadata);
            parseddata = JSON.parse(_meta);
        } catch (error) {
            // Handle the error here
            parseddata = null;
            console.error("Error parsing JSON or it was null");
        }
        const meta: OrgMetaData = organisationProfile?.metadata ? parseddata : null;

        if (meta?.billing_details) {
            meta.billing_details?.company_number ? this.setFormValue(this.organisationBillingDetails, 'company_number', meta.billing_details?.company_number) : null;
            meta.billing_details?.billing_contact_email ? this.setFormValue(this.organisationBillingDetails, 'billing_contact_email', meta.billing_details?.billing_contact_email) : null;
            meta.billing_details?.registered_address ? this.setFormValue(this.organisationBillingDetails, 'registered_address', meta.billing_details?.registered_address) : null;
        }
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
        form.markAsUntouched();
    }

    getInvoiceList() {
        console.log("Get invoice list")
        this.pageStatus.loadingInvoice = true;
        this.invoiceService.getInvoiceByOrg(this.organisation_id)
            .pipe(
                finalize( () => {this.pageStatus.loadingInvoice = false})
            )
            .subscribe((resp) => {
                if (resp.status === 200) {
                    this.invoiceList = resp.body;
                    this.pageStatus.loadingInvoice = false;
                    this.ref.detectChanges();
                }
            }, (err) => {
                console.log(err)
                this.openSnackBar('There was an error getting invoices', "Dismiss");
                this.pageStatus.loadingInvoice = false;
                this.invoiceList = null;
                this.ref.detectChanges();
            })
    }

    saveBillingForm(f: FormGroup) {
        console.log("[org-billing] saveBillingForm()", f);
        if (f.invalid) { return; }

        this.appInsightService.logEvent('Welcome | update billing details', { 'organisation_id': this.organisation_id });
        this.pageStatus.isSubmitting = true;



        const meta: OrgMetaData = this.organisationProfile?.metadata ? JSON.parse(unescape(this.organisationProfile?.metadata)) : new OrgMetaData();


        // Initialize billing if it doesn't exist
        meta.billing_details = {
            company_number: f.value.company_number,
            billing_contact_email: f.value.billing_contact_email,
            registered_address: f.value.registered_address
        };

        console.log("[org-billing] pre patchOrganisationMetadata()", meta);
        this.organisationService.patchOrganisationMetadata(f.value.organisation_id, meta)
            .subscribe(resp => {
                this.openSnackBar('Saved', 'dismiss');
                this.pageStatus.isSubmitting = false;
                this.organisationProfile.metadata = escape(JSON.stringify(meta));
                this.organisationBillingDetails.markAsPristine();
                this.organisationBillingDetails.markAsUntouched();
                this.ref.detectChanges();
                //NEXT ACTION
            }, (err) => {
                console.log(err);
            })
    }

    cancelForm(f) {
        this.setFormValues(this.organisationProfile);
        //implement billing form reset.
    }

    // getDebugCrdDetails() {
    //     this.pageStatus.loadingCard = true;
    //     setTimeout(() => {
    //         this.carddetails = { "id": "CF5D9D98-4B0D-EB11-96F5-00155D07B832",
    //             "brand": "mastercard",
    //             "exp_month": 2,
    //             "exp_year": 2025,
    //             "last_4_digits": "1998"
    //         }
    //         this.pageStatus.loadingCard = false

    //     }, 1000);
    // }

    getCardDetails() {
        this.pageStatus.loadingCard = true;
        this._cardSubscription = this.paymentsService.getDefaultCard(this.organisation_id).pipe(
            finalize(() => { this.pageStatus.loadingCard = false; })
        ).subscribe((resp) => {

            if (resp.status === 200) {
                this.carddetails = resp.body;
                this.ref.detectChanges();
            } else {
                this.carddetails = null;
                this.ref.detectChanges();
            }
        }, (err) => {
            this.carddetails = null;
            this.pageStatus.loadingCard = false;
            this.ref.detectChanges();
            //catach message includes customer ID is unknown
            console.log(err)
        })
    }



    setOrganisationNameFormValues(organisationProfile: OrganisationModel) {
        //set the existing values here If any.
        // this.setFormValue(this.organisationNameForm, 'id', organisationProfile.id);
        // this.setFormValue(this.organisationNameForm, 'name', organisationProfile.name);
    }


    getOrganisationDetails() {
        //get profile if one was not passed
        this.pageStatus.isLoading = true;
        this.organisationService.getOrganisationById(this.organisation_id)
            .pipe(
                finalize(() => { this.pageStatus.isLoading = false }),
                takeUntil(this._destroy$)
            )
            .subscribe((resp) => {
                this.ref.detectChanges();
                if (resp.status === 200 ) {
                    this.organisationProfile = resp.body;
                    // this.setOrganisationNameFormValues(this.organisationProfile);
                    this.getInvoiceList()
                    this.ref.detectChanges();
                }
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured getting organisation details [${err.status}] `, "dismiss");
            })

    }


    saveBillingContact(f: FormGroup) {
        if (f.valid) {
            // this.submitForm(this.organisationService.patchOrganisationName(f.getRawValue().id, f.getRawValue().name));
        }
    }


    downloadPdf(id: string, event: Event) {
        event.stopPropagation();
        console.log('DOWNLOAD:', id);
        this.invoiceService.getInvoicePdf(this.organisation_id, id).subscribe((resp) => {
            console.log('get invoice pdf:', resp);
            resp.success ? FileSaver.saveAs(resp.fileUrl) : this.openSnackBar(resp?.message ? resp?.message : "sorry, we ran into problem", "dismiss");
        }, (err) => {
            console.log(err);
            this.openSnackBar(`Sorry, could not get this pdf at this time. Please try later [${err.status}]`, "dismiss")
        })
    }


    openInvoice(invoice_id: string, viewAs: string) {
        const data = {invoice_id: invoice_id, viewAs: this.viewAs, organisation_id: this.organisation_id}

        const dialogRef = this.bottomSheet.open(InvoiceBottomSheetComponent, {
            panelClass: 'basic-bottom-sheet-wide',
            data: data
        });

        dialogRef.afterDismissed().subscribe((resp) => {
            console.log("AFTER DISMISS OF Invoice BOTTOM SHEET-----j", resp);
            if (resp) {
            }
        });
    }


    deleteInvoice(invoice: InvoiceListModel): void {
        console.log("INVOICE: ", invoice);
        const data = {
            invoice: invoice,
            viewAs: this.viewAs
        }

        const dialogRef = this.dialog.open(InvoiceDeleteModalComponent, {
            panelClass: 'pipcall-modal-container',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            //refresh the view if needed
            if (result) {
                this.updateParent.emit(true);
            }
        });

    }

    restoreDeletedInvoice(organisation_name?: string): void {

        const data = { "organisation_name": organisation_name, "viewAs": this.viewAs};
        //set any data defaults.

        const dialogRef = this.dialog.open(RestoreDeletedInvoiceModalComponent, {
            panelClass: ['pipcall-modal-container'],
            // width: '500px',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.updateParent.emit(true);
                return;
            } else {
                console.log("no change");
            }

        });
    }

    openUpdateForm() {

        const data = {
            organisation_id: this.organisation_id
        }

        const dialogRef = this.dialog.open(UpdateCardModalComponent, {
            panelClass: 'pipcall-modal-container',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                //do action on modal close
                this.ngOnInit(); //update data
            }
        });
    }

    openFirstPayment() {
        const dialogRef = this.dialog.open(FirstPaymentModal, {
            panelClass: 'pipcall-fullscreen-modal-container',
            data: {organisation_id: this.organisation_id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.updateParent.emit(true);
                window.location.reload();
            }
        });
    }

    retryInvoice(invoice_id: string) {
        this.confirmUpgrade('This will change the state of the invoice and another payment attempt will be made overnight. Are you sure ?', invoice_id);
    }

    confirmUpgrade(message: string, invoice_id: string) {

        const data: ModalConfirmData = {
            title: 'Confirm update',
            content: message,
            confirmButtonLabel: "Confirm",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: true
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.patchInvoiceState(invoice_id);
                return;
            }
        });

    }

    patchInvoiceState(invoice_id: string) {
        this.invoiceService.patchInvoiceState(invoice_id)
            .subscribe(resp => {
                if (resp.status === 200 || resp.status === 201) {
                    //refresh the page
                    this.triggerUpdate();
                    this.openSnackBar("Invoice state was updated", "dismiss");
                }
            }, (err) => {
                this.openSnackBar("Something went wrong when trying to update this invoice", "dismiss");
            })

    }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }


    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }

    triggerUpdate() {
        this.updateParent.emit(true);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._cardSubscription) {this._cardSubscription.unsubscribe()};
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
