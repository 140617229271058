import { NgModule } from '@angular/core';

//requirements
import { MaterialModule} from '@app/material/material.module';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { RouterModule } from '@angular/router';
import { CodeInputModule } from 'angular-code-input';

//services
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { VerifyService } from '@app/services/pipcall/verify.service';
import { UserService} from '@app/services/pipcall/user.service';
//create modal components
import {VerifyMobileV3ModalComponent } from './verify-mobile-v3-modal.component'

@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CustomPipesModule,
        StatusIconsModule,
        RouterModule,
        CodeInputModule
    ],
    declarations: [VerifyMobileV3ModalComponent],
    exports: [VerifyMobileV3ModalComponent],
    providers: [HttpProxy, VerifyService, AppInsightService, UserService],
    entryComponents: [VerifyMobileV3ModalComponent]
})
export class VerifyMobileV3ModalModule { }

