import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '../../services/http-proxy.service';
import { MaterialModule } from '../../material/material.module'
import { CustomPipesModule } from '../../pipes/pipes.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ValidatorsModule } from '../../validators/validators.module';

//components
// import { CartIdVerifyComponent } from './cart-id-verify/cart-id-verify.component';
import { InvitationComponent } from './invitation-verify/invitation-verify.component';
import { InvitationDetailsUpdateComponent } from './invitation-update-details/invitation-verify-details.component';

import { VerifiedWelcomeComponent } from './verified/welcome/verified-welcome.component';

//modules
import { VerifyRouting } from './verify.routing';
import { VerifyComponent } from './verify.component';
import { VerifyMobileModule } from '../../components/verify-mobile/verify-mobile.module';
import { StatusIconsModule } from '../../components/status-icons/status-icons.module';
//services
import { InvitationService } from '../../services/pipcall/invitation.service';
import { AppInsightService } from '../../services/helpers/app-insights.service';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        ValidatorsModule,
        CustomPipesModule,
        VerifyRouting,
        VerifyMobileModule,
        StatusIconsModule
    ],
    declarations: [
        VerifyComponent,
        InvitationComponent,
        InvitationDetailsUpdateComponent,
        VerifiedWelcomeComponent],
    providers: [InvitationService, AppInsightService
    ],
    exports: [],
    entryComponents: []
})
export class VerifyModule {
}
