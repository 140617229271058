//core
import { Component, Inject, NgZone, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subscription, Observable, throwError, Subject } from 'rxjs';

import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray, MaxLengthValidator } from '@angular/forms';
import { environment } from '@src/environments/environment';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
@Component({
    templateUrl: './product-contact-modal.component.html',
    styleUrls: ['./product-contact-modal.component.scss'],
    animations: [
    ]
})
export class ProductContactsModal {


    public pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isSuccess: false
    }

    contactForm: FormGroup;





    constructor(
        private dialogRef: MatDialogRef<ProductContactsModal>,
        private fb: FormBuilder,
        private http: HttpClient,
        private ref: ChangeDetectorRef,
        private _fb: FormBuilder,
        private snackBar: MatSnackBar,
        private organisationService: OrganisationService,
        @Inject(MAT_DIALOG_DATA)
        public data: {product: string, id: string}, //contains data injected into modal.
    ) {
        const regex = new RegExp('^\\+?(\\d{1,3})?[-.\\s]?\\(?\\d{1,4}\\)?[-.\\s]?((\\d{3}[-.\\s]?\\d{4})|(\\d{2}[-.\\s]?\\d{2}[-.\\s]?\\d{3,4}))$');


        this.contactForm = this._fb.group({
            product: ['', [Validators.required]],
            id:  ['', [Validators.required]],
            contact_phone_number: ['', [Validators.required, Validators.pattern(regex)]],
        });

        //this ensures clicks on backdrop to close modal, also runs closeModal() method.
        dialogRef.backdropClick().subscribe(() => {
            this.closeModal()
        })
    }


    ngOnInit() {
        this.contactForm.controls.product.setValue(this.data.product);
        this.contactForm.controls.id.setValue(this.data.id);
    }

    setFormValues(any) {
        this.setFormValue(this.contactForm, 'id', any?.id);
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    submitForm() {

        if (this.contactForm.invalid) { return; }
        this.pageStatus.isSubmitting = true;

        let contact = this.contactForm.value.contact_phone_number.trim();
        contact = contact.replace(/\s/g, '');

        this.organisationService.organisationCallBackRequest(contact, this.contactForm.value.product, this.contactForm.value.id).subscribe((resp) => {
            this.openSnackBar("Request sent", "X");
            this.pageStatus.isSubmitting = false;
            this.pageStatus.isSuccess = true;
        }, (err) => {
            this.pageStatus.isSubmitting = false;
            this.openSnackBar("Error sending request" , "X");
        }
        )



    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    closeModal() {
        this.dialogRef.close();
    }
    onDestory() {

    }
}
