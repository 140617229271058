import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
//modules (imports)
import { CustomPipesModule } from '@app/pipes/pipes.module'
import { MaterialModule } from '@app/material/material.module';
import { Routes, RouterModule } from '@angular/router';
import { HelpCenterSearchComponent} from './help-center-search.component';
import { HelpCenterContentService, FAQS } from '@app/services/help-center-content.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule
    ],
    declarations: [HelpCenterSearchComponent],
    exports : [HelpCenterSearchComponent],
    providers: [HttpProxy, HelpCenterContentService],
    entryComponents: [HelpCenterContentService]
})
export class HelpCenterSearchModule {
}
