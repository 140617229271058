<ng-container *ngIf="article || mode === 'new';else noArticle">

  <div class="fx-col fx-grow">

    <div *ngIf="toolbarLocation === 'top'">
      <ng-container *ngTemplateOutlet="toolbarButtons"></ng-container>
    </div>
 

    <form class="fx-col fx-grow" [formGroup]="articleForm"
      [ngClass]="{'readonly-block': pageStatus.isSubmitting}">

      <div class="fx-row fx-justify-space-between fx-align-center fx-gap-2">

        <mat-form-field appearance="outline" class="custom-form-field-v3 readonly-block fx-width-20">
          <input formControlName="RowKey" type="text" matInput placeholder="">
          <mat-label>Id</mat-label>
          <!-- <mat-hint>No value.</mat-hint> -->
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-form-field-v3 fx-grow" style="color: var(--c-body);">
          <input formControlName="title" type="text" matInput placeholder="">
          <mat-label>Article title</mat-label>
          <!-- <mat-hint>No value.</mat-hint> -->
        </mat-form-field>

      </div>


      <mat-form-field appearance="outline" class="custom-form-field-v3">
        <input formControlName="description" type="text" matInput placeholder="Description for search box">
        <mat-label>Description</mat-label>
        <!-- <mat-hint>Description will appear when searching</mat-hint> -->
      </mat-form-field>


      <div class="fx-row fx-justify-space-between fx-align-center fx-gap-2">
 
        <mat-form-field appearance="outline" class="custom-form-field-v3 fx-width-20" >
          <input formControlName="min_portal_version" type="text" matInput placeholder="">
          <mat-label>Min portal ver</mat-label>
          <!-- <mat-hint>Article will only appear for versions equal or newer</mat-hint> -->
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-form-field-v3 fx-grow" >
          <input formControlName="category" type="text" matInput placeholder="Existing or enter new"
            [matAutocomplete]="auto">
          <mat-label>Category</mat-label>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredCategories | async" [value]="option">
              {{option | lowercase}}
            </mat-option>
          </mat-autocomplete>
          <!-- <mat-hint>Select existing or create new category</mat-hint> -->
        </mat-form-field>


      </div>

      <mat-form-field floatLabel="always" appearance="outline" class="custom-form-field-v3">
        <mat-label>Search tags</mat-label>
        <mat-chip-list #chipList aria-label="Tags" formControlName="tags">
          <mat-chip *ngFor="let tag of tags" [selectable]="true" [removable]="true" (removed)="removeTag(tag)">
            {{tag}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input placeholder="" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addTag($event)">
        </mat-chip-list>
        <!-- <mat-hint>Comma seperated search tags</mat-hint> -->
        
      </mat-form-field>


      <mat-form-field floatLabel="always" appearance="outline" class="custom-form-field-v3">
        <mat-label>Context Links</mat-label>
        <mat-select [value]="contextListFormControl" multiple placeholder="Optional contextual link" (selectionChange)="linkSelected($event)">
          <mat-option *ngFor="let link of faqList?.contextList" [value]="link">{{link}}</mat-option>
        </mat-select>
      </mat-form-field>

      


      <!-- <h4 [style.color]="'lightgrey'">CONTENT</h4> -->
      <div class="NgxEditor__Wrapper editor">
        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
        <ngx-editor [enabled]="!pageStatus?.isSubmitting" [editor]="editor" formControlName="content" ></ngx-editor>
      </div>



      <mat-form-field floatLabel="always" appearance="outline" class="custom-form-field-v3 fx-grow"  style="margin-top:20px;">
        <textarea formControlName="sysadmin_content" type="text" matInput
          placeholder="Only visible to system admins"></textarea>
        <mat-label>sysAdmin note</mat-label>
      </mat-form-field>

    </form>

    <div class="text-right author">Author: {{this.articleForm.value?.updated_by}}</div>

    <div *ngIf="toolbarLocation === 'bottom' || toolbarLocation === undefined || !toolbarLocation">
      <ng-container *ngTemplateOutlet="toolbarButtons"></ng-container>
    </div>
 



  </div>



</ng-container>

<ng-template #toolbarButtons>
  <div class="fx-row fx-justify-end fx-align-center fx-gap-2" style="margin-top: 30px;margin-bottom: 40px;">
    <button (click)="back()" mat-flat-button>Cancel</button>
    <button style="width: 250px;" mat-flat-button color="accent"
      [disabled]="articleForm.invalid || pageStatus.isSubmitting" (click)="saveEdit()">
      <div><ng-container *ngIf="pageStatus.isSubmitting === false;else savingSpinner">Save</ng-container></div>
    </button>
  </div>
</ng-template>

<ng-template #noArticle>
  <div>
    Error getting article details
  </div>

</ng-template>

<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>
