import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'byteFormat'
})
export class ByteFormatPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {}

    //format the bytes to human readable format in GB or MB

    transform(value: number): SafeHtml {
        if (value === 0) {return '0'; }

        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

        const i = Math.floor(Math.log(value) / Math.log(k)); // Determine the size index

        const convertedValue = value / Math.pow(k, i); // Calculate the value for the unit

        // Formatting rules:
        // - No decimals for MB or smaller units
        // - Show decimals for GB or larger units unless it's exactly .00
        let displayValue: number | string;
        if (sizes[i] === 'MB' || i < 2) {
            displayValue = Math.round(convertedValue); // No decimals for MB and smaller units
        } else {
            displayValue = parseFloat(convertedValue.toFixed(2)) % 1 === 0
                ? Math.round(convertedValue)
                : convertedValue.toFixed(2); // Decimals for GB and larger if needed
            // displayValue = convertedValue % 1 === 0 ? convertedValue : convertedValue.toFixed(2); // Decimals for GB and larger
        }
        // Return sanitized HTML with the unit size styled
        return this.sanitizer.bypassSecurityTrustHtml(`${displayValue} <span style="font-size: 0.8em;">${sizes[i]}</span>`);

        // return `${displayValue} ${sizes[i]}`;
    }
}

@Pipe({
    name: 'gbToBytes'
})
export class GbToBytesPipe implements PipeTransform {
    transform(value: number): number {
        return value * 1024 * 1024 * 1024; // Convert GB to bytes
    }
}

@Pipe({
    name: 'bytesToGb'
})
export class BytesToGbPipe implements PipeTransform {
    transform(value: number): number {
        return value / (1024 * 1024 * 1024); // Convert bytes to Gb
    }
}

// @Pipe({
//     name: 'byteFormat_v2'
// })
// export class ByteFormatPipe implements PipeTransform {

//     // <!-- Hide label -->
//     // <div {{esim?.data_allowance | ByteFormatPipe: false }} ></div>

//     transform(value: number, showLabel = true): string {
//         if (value === 0) {return '0 Bytes'; }

//         const k = 1024;
//         const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

//         const i = Math.floor(Math.log(value) / Math.log(k)); // Determine the size index

//         const convertedValue = value / Math.pow(k, i); // Calculate the value for the unit

//         // Formatting rules:
//         // - No decimals for MB or smaller units
//         // - Show decimals for GB or larger units unless it's exactly .00
//         let displayValue;
//         if (sizes[i] === 'MB' || i < 2) {
//             displayValue = Math.round(convertedValue); // No decimals for MB and smaller units
//         } else {
//             displayValue = convertedValue % 1 === 0 ? convertedValue : convertedValue.toFixed(2); // Decimals for GB and larger
//         }

//         // Prepare the output
//         let output = `${displayValue}`;
//         if (showLabel) {
//             output += ` ${sizes[i]}`;
//         }

//         return output;
//     }
// }
