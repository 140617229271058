<ng-container *ngIf="organisation_id; else notFound">

  <ng-container *ngIf="pageStatus.isLoading">
    <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
      <div style="font-size: 4px;" class="loader-spinner"></div>loading...
    </div>
  </ng-container>

  <ng-container *ngIf="!pageStatus.isLoading">

    <div class="tab-child-view-container">

      <div class="section-wrapper">

        <tab-title-lined>Get Started</tab-title-lined>
        <div class="section-content">

          <div style="margin-bottom: 18px;">Select your product</div>
          <div class="product-cards-container fx-row fx-col-xs">

            <div class="product-card-wrapper fx-col clickable"
              matTooltip="eSIM for business travel in 180+ worldwide destinations"  
              (click)="navigateToTab('extensions')"
              [ngStyle]="{ 'order': organisationProfile?.is_pipxt_enabled ? -1 : 'initial' }">
              <div class="p-status title-font" [ngClass]="{'visiblity-none':!organisationProfile?.is_pipxt_enabled}">
                ACTIVE</div>
              <div class="p-title title-font">Mobile+<br>Advanced Call Features</div>
              <img src="/assets/img/portal-mobile-plus-product.jpg" alt="eSIM for Business" class="product-card-img"
                [ngClass]="{'desaturate': !organisationProfile?.is_pipxt_enabled}">
              <img src="/assets/img/portal-mobile-plus-product-m.jpg" alt="eSIM for Business"
                class="product-card-img mobile-img" [ngClass]="{'desaturate': !organisationProfile?.is_pipxt_enabled}">
            </div>


            <div class="product-card-wrapper fx-col clickable" matTooltip="Mobile+ - Advanced business call features"
              (click)="navigateToTab('esim')"
              [ngStyle]="{ 'order': organisationProfile?.is_esim_enabled ? -1 : 'initial' }">
              <div class="p-status title-font" [ngClass]="{'visiblity-none':!organisationProfile?.is_esim_enabled}">
                ACTIVE</div>
              <div class="p-title title-font">eSIM<br>for travel</div>
              <img src="/assets/img/portal-esim-product.jpg" alt="eSIM for Business" class="product-card-img"
                [ngClass]="{'desaturate': !organisationProfile?.is_esim_enabled}">
              <img src="/assets/img/portal-esim-product-m.jpg" alt="eSIM for Business"
                class="product-card-img mobile-img" [ngClass]="{'desaturate': !organisationProfile?.is_esim_enabled}">
            </div>

            <div class="product-card-wrapper fx-col clickable" matTooltip="UK Voice & Data eSIM plans"
            (click)="navigateToTab('uk-voice-and-data')">
              <div class="p-status title-font desat" [ngClass]="{'visiblity-none':false }"></div>
              <div class="p-title title-font">UK Voice & Data</div>
              <img src="/assets/img/portal-esim-businss-product.jpg" alt="eSIM for Business" class="product-card-img"
                [ngClass]="{'desaturate': true}">
              <img src="/assets/img/portal-esim-businss-product-m.jpg" alt="eSIM for Business"
                class="product-card-img mobile-img" [ngClass]="{'desaturate': true}">
            </div>

            <div class="product-card-wrapper fx-col clickable" matTooltip="International plans at local rates for overseas staff"
            (click)="navigateToTab('overseas-staff')">
            <div class="p-status title-font desat" [ngClass]="{'visiblity-none':false }"></div>
            <div class="p-title title-font">Overseas Staff</div>
            <img src="/assets/img/portal-esim-international-product.jpg" alt="eSIM for Business" class="product-card-img"
              [ngClass]="{'desaturate': true}">
            <img src="/assets/img/portal-esim-international-product-m.jpg" alt="eSIM for Business"
              class="product-card-img mobile-img" [ngClass]="{'desaturate': true}">
          </div>

          <div class="product-card-wrapper fx-col clickable" matTooltip="IoT unsteered multi-network eSIMs"
          (click)="navigateToTab('IoT')">
          <div class="p-status title-font desat" [ngClass]="{'visiblity-none':false }"></div>
          <div class="p-title title-font">IoT Multi-network</div>
          <img src="/assets/img/portal-esim-iot-product.jpg" alt="eSIM for Business" class="product-card-img"
            [ngClass]="{'desaturate': true}">
          <img src="/assets/img/portal-esim-iot-product-m.jpg" alt="eSIM for Business"
            class="product-card-img mobile-img" [ngClass]="{'desaturate': true}">
        </div>


          </div>
        </div>
      </div>

      <div class="section-wrapper">

        <tab-title-lined>Help & Info</tab-title-lined>

        <div class="section-content">
          <ng-container *ngIf="organisationProfile?.is_pipxt_enabled">
            <div class="mat-panel-section-title title-font" >Mobile+</div>
            <mat-accordion>
              <mat-expansion-panel  expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Add your first user
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Get started with using Mobile+ by first adding a user. We'll give you a few business numbers to get started with your Trial so you can set up yourself and a colleague to try the app.</p>
                <ul>
                  <li><span class="list-number">1.</span> You can either head over to the user tab and select <b>Invite user</b></li>
                  <li><span class="list-number">2.</span> Alternatively if you want to add yourself, you can select the <b>Activate handset</b> on your user.</li>
                  <li><span class="list-number">3.</span> Complete the details, providing a valid email address and select the extension you want to link.</li>
                  <li><span class="list-number">4.</span> The user will need to click on the invitation and provide a handset number. This is the handset they wish to use the app on.</li>
                  <li><span class="list-number">5.</span> The user will be asked to verify ownership of this number and then their PiPcall account will be created.</li>
                  <li><span class="list-number">6.</span> The PiPcall app can now be downloaded from the app store and the user can log in, using the handset number verified in step 5.</li>
                  <li><span class="list-number">7.</span> Calls can immediately be made and recieved with the new business number.</li>

                  <br>
                  <li><span class="list-number">Alternative.</span> You can also send an invitation by first selecting the extension from the extensions tab and then clicking on <b>link</b>.</li>
                </ul>
              </mat-expansion-panel>

              <mat-expansion-panel expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Completing a PiPuser invitation
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>To set up the PiPcall account of a user and invitation will be sent by email.</p>
                <ul>
                  <li><span class="list-number">1.</span>Enter your name and Handset number. This will be the UK phone number of the device on which you wish to use the App. This is not your new business number.</li>
                  <li><span class="list-number">2.</span>Next, verify this number via the SMS code we send to the device.</li>
                  <li><span class="list-number">3.</span>Download the PiPcall app from the app store.</li>
                  <li><span class="list-number">4.</span>Sign in using the handset number you provided in step 1.</li>
                  <li><span class="list-number">5.</span>If your admin has already linked a business number to your account, you are ready to make and recieve phone calls.</li>
                </ul>

              </mat-expansion-panel>

              <mat-expansion-panel  expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Getting more business numbers
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>If you require more extensions, please contact <a href="mailto:sales@pipcall.com">Sales</a>.</p>
              </mat-expansion-panel>

              <mat-expansion-panel  expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Setting up advanced business call features
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>To set up advanced call features on your account, please contact <a href="mailto:sales@pipcall.com">Sales</a>.</p>
              </mat-expansion-panel>

              <mat-expansion-panel  expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    I want a specific areacode as my business number
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>A full list of available area codes and destinations is available <a href="https://www.pipcall.com/resource/uk-geographic-numbers" target="_blank">here</a>.</p>
                <p>Contact our team if you would like to purchase a specific areacode not already available.</p>
              </mat-expansion-panel>

            </mat-accordion>
          </ng-container>

          <ng-container *ngIf="organisationProfile?.is_esim_enabled">

            <div class="mat-panel-section-title title-font" >eSIM for business travel</div>
            <mat-accordion>
              <mat-expansion-panel  expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Purchasing your first eSIM
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <ul>
                  <li><span class="list-number">1.</span> Navigate to our shop from the eSIM tab, by clicking on <b>Buy Data Only Travel eSIM</b></li>
                  <li><span class="list-number">2.</span> Browse through over 180 destinations or regions with multiple destinations.</li>
                  <li><span class="list-number">3.</span> Click on any destination to see pricing and details on available plans.</li>
                  <li><span class="list-number">4.</span> Build your cart and select view cart when you are happy.</li>
                  <li><span class="list-number">5.</span> We take secure card payments through stripe.</li>
                  <li><span class="list-number">6.</span> We will start processing your order after payment and they will become available very shortly after.</li>
                </ul>

              </mat-expansion-panel>

              <mat-expansion-panel expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How do I send the eSIM to my employee
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <p>Select the plan from your list in the eSIM tab, if the state is READY then it is available to use. You can now enter an email address to send the QR code.</p>
                <p>Note once the user has installed the eSIM, the email can no longer be changed.</p>

              </mat-expansion-panel>

              <mat-expansion-panel expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    What is the notes field for?
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <p>Use the notes field freely as you wish, to track information about the intended use of the eSIM, dates of intended trips or additional information about the employee who requires the eSIM. </p>

              </mat-expansion-panel>

              <mat-expansion-panel expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How do I install an eSIM?
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <p>instructions are available <a href="https://www.pipcall.com/blog/how-to-install-and-set-up-an-esim" target="_blank">here</a>.</p>

              </mat-expansion-panel>

              <mat-expansion-panel  expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How do I activate the eSIM?
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <p>After you purchase an eSIM, it will be available for use when you see the READY state. You can then send the QR code to a user to install on their device.</p>
                <p>The plan only becomes ACTIVE once the user lands in the destination country.</p>
                <p>You can ensure that you are ready in advance of your trip by following our <a href="https://www.pipcall.com/blog/how-to-activate-your-worldwide-travel-esim" target="_blank">guide</a>.</p>

              </mat-expansion-panel>

              <mat-expansion-panel  expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                   How can I check the data usage on the eSIM?
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <p>The eSIM tab shows the data plans you own and who they have been assigned to. You can click on an individual row to see more information about the eSIM and the current data usage.</p>

              </mat-expansion-panel>

              <mat-expansion-panel  expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                   Can I move a travel data eSIM to a new device?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>A travel data eSIM can not be transfered to a new device, once it is installed it can only be reinstalled on the same device.</p>
              </mat-expansion-panel>

              <mat-expansion-panel  expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                   Can I buy my eSIMS in advance ?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Travel eSIMS activate when in the destination country. So you can order many eSIMS in advance of trips and you have 365 to use them. Users can install them on their devices ahead of their trips, ready for when they arrive in the destination country. </p>
              </mat-expansion-panel>


              <mat-expansion-panel  expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                   How long do I have to activate the eSIM?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>You have 365 days to activate an eSIM from purchase. Activation happens when the user lands in the destination country.</p>
              </mat-expansion-panel>
            </mat-accordion>

          </ng-container>

          <ng-container *ngIf="organisationProfile?.is_pipmobile_enabled">
            <div class="mat-panel-section-title title-font" >PiPcall</div>
            <mat-accordion>
              <mat-expansion-panel  expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How can I add more users to my organisation?
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <p>On the users tab, select <strong>Invite user</strong> and choose a number, note that the subscription takes effect as soon as you send the invitation.</p>

              </mat-expansion-panel>

              <mat-expansion-panel expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How can I get a specific area code for my business number?
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <p>If an areacode is not already available in the list, reach out to our sales team to purchase one. The full list of area codes we can source is available <a href="https://www.pipcall.com/resource/uk-geographic-numbers" target="_blank">here</a>.</p>
              </mat-expansion-panel>

              <mat-expansion-panel  expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    A employee has left my business, what do I need to do?
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <p>You can flag a user for removal, by clicking the 3-dot-menu on the user and selecting <strong>Request Removal</strong></p>
                <p>Our support team, will arrange to settle any outstanding payments or subscriptions before removing the user.</p>

              </mat-expansion-panel>

              <mat-expansion-panel  expanded=false (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    How do I get enhanced business call features?
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <p>Speak to our <a href="mailto:sales@pipcall.com">Sales</a> team to upgrade to Mobile+, our complete business calling solution.</p>

              </mat-expansion-panel>

            </mat-accordion>
          </ng-container>

        </div>
      </div>

    </div>

  </ng-container>


</ng-container>



<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>

<ng-template #savingSpinner>
  <div style="height: 2em;">
    <div class="loader-spinner"></div>
  </div>
</ng-template>