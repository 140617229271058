//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel, OrgMetaData } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil, distinctUntilChanged, map } from 'rxjs/operators';
import { Subject , Observable, Subscription} from "rxjs";
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { ModalService } from '@app/services/modal-service/modal.service';

import { MatBottomSheet,  MatBottomSheetRef, } from '@angular/material/bottom-sheet';
import { EsimTelnaService, OrganisationDataEsims, DataEsim  } from '@app/services/pipcall/esim_telna.service';
import { DataEsimDetailsModal  } from '@app/components/modals/data-esim-details-modal/data-esim-details-modal.component';
import { HttpResponse } from '@angular/common/http';
import { GbToBytesPipe } from '@app/pipes/bytesConvert.pipe';

export interface ExtendedOrganisationDataEsim extends OrganisationDataEsims {
    data_usage_remaining?: number; // Optional field
}

@Component({
    selector: 'app-data-esims-for-org',
    templateUrl: './data-esims-for-org.component.html',
    styleUrls: ['./data-esims-for-org.component.scss'],
    animations: [
    ]
})

export class DataEsimForOrgComponent {

    // @Input() viewAs: string;
    // @Input() organisation_id: string; //required

    @Input() organisationProfile: OrganisationModel //required
    @Output() updateParent = new EventEmitter();
    @Output() continueToShop = new EventEmitter();
    // @Output() changeTab = new EventEmitter();

    // organisationDataEsims: OrganisationDataEsims[] = [];
    dataSource: MatTableDataSource<ExtendedOrganisationDataEsim>;
    displayedColumns: string[] = ['license_name', 'contract_name', 'state', 'user_email', 'purchased_at'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    noContent = false;
    _destroy$ = new Subject<void>();

    pageStatus = {
        isLoading: false,
        isSubmitting: false
    }

    updateIndividualeSIM = true; //set to false to stop individual updates

    // navigateAction: string = null;

    constructor(
        private snackBar: MatSnackBar,
        private router: Router,
        private _formBuilder: FormBuilder,
        private organisationService: OrganisationService,
        private appInsightService: AppInsightService,
        private modalService: ModalService,
        public dialog: MatDialog,
        private ref: ChangeDetectorRef,
        private bottomSheet: MatBottomSheet,
        private esimTelnaService: EsimTelnaService,
        private gBtoBytePipe: GbToBytesPipe,
        private activatedRoute: ActivatedRoute
    ) {
        this.dataSource = new MatTableDataSource();
    }



    ngOnInit() {
        this.dataSource.data = [];
        this.getEsimsForOrganisation(this.organisationProfile?.id);
        //read params
        // this.navigateAction = this.activatedRoute.snapshot.queryParams['action'] ? this.activatedRoute.snapshot.queryParams['action'] : null;
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.sort.sort({ id: 'purchased_at', start: 'desc', disableClear: false });
    }

    setFormValues(organisationProfile: OrganisationModel) {
    }

    setDataSource( esims: Array<ExtendedOrganisationDataEsim> ) {
        console.log('esims set datasource', esims);
        if ( !esims || esims.length === 0 ) {
            return this.pageStatus.isLoading = false;
        }
        this.dataSource.data = esims;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageStatus.isLoading = false;
        this.updateDataForActiveSims()
        this.ref.detectChanges();
    }
    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
        form.markAsUntouched();
    }

    getEsimsForOrganisation(organisation_id) {
        this.pageStatus.isLoading = true;
        this.esimTelnaService.getDataEsimsForOrg(organisation_id)
            .pipe(
                map((resp) => this.filterOutNew(resp))
            )
            .subscribe((esims) => {
                console.log("esims returned", esims);
                this.setDataSource(esims);
                // if (esims.length === 0) {
                //     if (this.navigateAction === 'back') {
                //         this.changeTab.emit('home');
                //     } else {
                //         this.continueToShop.emit(true);
                //     }
                // }
                // console.log('esims - CONTINUE FORWARD TO ESIMS');
                // this.continueToShop.emit(true);
            }, (err) => {
                console.log(err);
                this.openSnackBar(`An error occured getting esims [${err.status}]`, "dismiss");
            });

    }

    updateDataForActiveSims() {
        if (this.updateIndividualeSIM === false) {return; }
        this.dataSource.data.forEach((esim: any) => {
            if (esim.state === 'ACTIVE') {
                this.getEsimDetails(esim.id);
            }
        });
    }

    getEsimDetails(id: string) {
        // Logic to fetch and process eSIM details based on the ID
        console.log(`Fetching eSIM details for ID: ${id}`);
        this.esimTelnaService.getDataEsimsByID(this.organisationProfile?.id, id)
            .subscribe((resp: HttpResponse<DataEsim>) => {
                //do stuff here
                if (resp.body) {
                // Find the entry in dataSource.data that matches the response ID
                    const entry = this.dataSource.data.find(item => item.id === resp.body.id);

                    if (entry) {
                        entry.state = resp.body.state;
                        entry.user_email = resp.body.user_email;
                        entry.data_usage_remaining = resp.body.data_usage_remaining;
                    }
                }

            }, (err) => {
                console.log('error', err);
            });
    }

    getDataUsed(remaining: number, total: number): number {
        return total - remaining;
    }
    getPercentageUsed(remaining: number, total: number) {
        const used = this.gBtoBytePipe.transform(total) - remaining;
        return( (used / this.gBtoBytePipe.transform(total) ) * 100);
    }
    filterOutNew(resp: HttpResponse<ExtendedOrganisationDataEsim[]>) {

        if (resp.status !== 200 || resp.body === null) {
            return [];
        }

        const esims = [...resp.body];
        const filtered = esims.filter((esim) => esim.state !== 'NEW');

        return filtered;
    }


    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    getFlag(license_name: string) {
        return this.esimTelnaService.getRegionFlag(license_name);
    }

    openRowItem(id: string) {
        console.log('openRowItem', id);

        const dialogRef = this.dialog.open(DataEsimDetailsModal, {
            panelClass: 'basic-modal-container',
            autoFocus: false,
            data: {id: id, organisation_id: this.organisationProfile.id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const esim = this.dataSource.data.find((_esim) => _esim.id === result?.id);
                //update with value from the DataEsim
                if ((esim.state !== result.state || esim.user_email !== result.email) || result.state === 'ACTIVE') {
                    esim.state = result.state;
                    esim.user_email = result.user_email;
                    esim.data_usage_remaining = result.data_usage_remaining;
                    // this.getEsimDetails(esim.id);
                }
            }
        });

    }

    // submitForm(form: Observable<any>) {
    //     this.pageStatus.isSubmitting = true;
    //     form
    //         .pipe(
    //             finalize(() => { this.pageStatus.isSubmitting = false }),
    //         )
    //         .subscribe((resp) => {
    //             console.log("success", resp);
    //             this.openSnackBar('Saved', 'dismiss');
    //             this.triggerUpdate();
    //         },
    //         (err) => {
    //             console.log(err);
    //             this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
    //         }
    //         );
    // }


    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    triggerUpdate() {
        this.updateParent.emit(true);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
