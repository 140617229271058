import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '../../services/http-proxy.service';
import { MaterialModule } from '../../material/material.module'
import { CustomPipesModule } from '../../pipes/pipes.module';

import {ProfileComponent} from './profile.component';

//forms
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ValidatorsModule } from '@app/validators/validators.module';

import {PageNotFoundModule} from '@app/components/page-not-found/page-not-found.module';
import {AppInsightService} from '@app/services/helpers/app-insights.service';

import { UserProfileTabOverviewModule } from '@app/components/tabs/user-profile-overview/user-profile-overview.module';


import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';
//services
import { DirectoryService, OrganisationDirectory} from '@app/services/pipcall/directory.service';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { CookieService } from 'ngx-cookie-service';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        ValidatorsModule,
        CustomPipesModule,
        PageNotFoundModule,
        GravatarDirectiveModule,
        UserProfileTabOverviewModule
    ],
    declarations: [ProfileComponent],
    providers: [HttpProxy, AppInsightService, NormalizeNumberService, DirectoryService, CookieService, OrganisationService],
    exports: [],
    entryComponents: []
})
export class ProfileModule {
}
