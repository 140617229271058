<div class="pip-modal-content">
  <button *ngIf="!data?.disableClose" class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>

  <div class="pip-modal-header">

    <div>
      <span class="subtitle-16">Select Your New Business Number</span>
      <h5 style="color:var(--c-title)">{{data?.userProfile?.first_name}}&nbsp;{{data?.userProfile?.last_name}}</h5>
    </div>

  </div>

  <div class="pip-modal-body fx-col fx-gap-08">


    <app-admin-upgrade-to-pipuser [organisation_id]="data?.organisation_id"
      [organisationProfile]="data?.organisationProfile" [userProfile]="data?.userProfile" [mobile]="data?.mobile" (verified)="updateStatus($event)"
      (updateParent)="handsetRegisterReturn($event)"></app-admin-upgrade-to-pipuser>

  </div>

  <div class="pip-modal-footer">
    <!-- <button mat-flat-button color="primary">Test</button> -->
  </div>
</div>

<ng-template #spinner>
  <span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>
</ng-template>
