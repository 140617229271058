import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
//modules (defaults)
import { MaterialModule } from '@app/material/material.module';
import { CustomPipesModule } from '@app/pipes/pipes.module';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';

//modules components
import { ClickToOpentModule } from '@app/components/click-to-open/click-to-open-module';
import { TableToolbarModule } from '@app/components/advanced-search/table-toolbar-v2/table-toolbar.module';
import { PageNotFoundModule } from '@app/components/page-not-found/page-not-found.module';
import { ExpoDashboardModalModule } from './dashboard-modal/dashboard-modal.module';

//components (declarations)
import { ExpoDashboardComponent } from './expo-dashboard.component';

//services (providers)
import { ExpoService } from '@app/services/pipcall/expo.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        CustomPipesModule,
        MaterialModule,
        PageNotFoundModule,
        GravatarDirectiveModule,
        ClickToOpentModule,
        TableToolbarModule,
        ExpoDashboardModalModule
    ],
    declarations: [ExpoDashboardComponent],
    providers: [HttpProxy, ExpoService],
    entryComponents: []
})
export class ExpoDashboardModule {
}
