<div class="section-wrapper">

  <tab-title-lined>Extension Configuration (legacy)</tab-title-lined>
  <tab-section-action [isHidden]="ddiUpdate?.pristine">
    <button mat-flat-button *ngIf="!ddiUpdate?.pristine" style="margin-right: 4px;"
    (click)="cancelForm()">Cancel</button>

  <button mat-flat-button aria-label="Save changes" class="save-button"
    [disabled]="ddiUpdate?.pristine ||  pageStatus.isSubmitting || ddiUpdate.invalid"
    (click)="saveExtension(ddiUpdate)">
    <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
  </button>
  </tab-section-action>
  

  <div class="section-content">

    <form class="fx-col" [formGroup]="ddiUpdate"
      [ngClass]="{'readonly-block': pageStatus.isSubmitting || !(viewAs === 'sysAdmin' || viewAs === 'isAdmin')}">

      <div class="information-banner fx-row fx-justify-start fx-align-center fx-width-100" *ngIf="number?.state !== 'OK'"
        [ngClass]="{'warning': number.state === 'Unknown', 'error': number.state === 'Error'}"
        style="margin-bottom: 40px;">
        <div>
          <mat-icon svgIcon="warning"></mat-icon>
        </div>
        <div class="message-body">
          <ng-container *ngIf="number?.event_data; else defaultmessage">{{number?.event_data}}</ng-container>
          <ng-template #defaultmessage>{{number?.state}}</ng-template>
        </div>
      </div>

      <div class="fx-row fx-width-100 fx-col-sm fx-gap-2"
        [hidden]="number?.is_pipxt_extensionless">

        <div class="fx-row fx-width-60 fx-width-100-sm fx-gap-2 fx-justify-space-between" >

          <mat-form-field appearance="outline"  class="custom-form-field-v3 inner-shadow fx-width-30">
            <input autocomplete="off" type="text" matInput placeholder="Extension"
              formControlName="sip_extension_number">
            <mat-label>Extension</mat-label>
          </mat-form-field>

          <mat-form-field appearance="outline" class="custom-form-field-v3 inner-shadow fx-width-70">
            <input autocomplete="off" type="text" matInput placeholder="e.g. 00441234567890" formControlName="sip_extension_cli">
            <mat-label>Caller Identity</mat-label>
          </mat-form-field>

        </div>

        <div clas="fx-col fx-width-40 fx-width-100-sm" style="padding-top:10px">

          Caller identity is the number displayed by this extension, when making an outbound call. This must match your PBX
          settings.

        </div>

      </div>


      <div class="fx-row fx-width-100 fx-col-sm fx-gap-2" >

        <div class="fx-col fx-width-60 fx-width-100-sm">

          <mat-form-field appearance="outline" class="custom-form-field-v3 inner-shadow"
            [hidden]="number?.is_pipxt_extensionless">
            <input autocomplete="off" type="text" matInput placeholder="SIP Username"
              formControlName="sip_user_name">
            <mat-label>SIP Username</mat-label>
          </mat-form-field>

          <mat-form-field appearance="outline" floatLabel="always" class="custom-form-field-v3 inner-shadow"
            [hidden]="number?.is_pipxt_extensionless">
            <input autocomplete="off" spellcheck="false" class="obfuscate-key" type="text" matInput
              placeholder="SIP Password" formControlName="sip_password">
            <mat-label>SIP Password</mat-label>
            <mat-hint>Max length 30 characters</mat-hint>
            <mat-error *ngIf="ddiUpdate?.controls.sip_password?.hasError('maxlength')">Maximum length 30 characters exceeded</mat-error>
          </mat-form-field>


          <mat-form-field appearance="outline" class="custom-form-field-v3 inner-shadow">
            <input autocomplete="off" type="text" matInput placeholder="e.g host.example.com"
              formControlName="sip_host_name">
            <mat-label>PBX Hostname</mat-label>
          </mat-form-field>


          <mat-form-field appearance="outline" class="custom-form-field-v3 inner-shadow">
            <input autocomplete="off" type="text" matInput placeholder="e.g. *123"
              formControlName="sip_voicemail_number">
            <mat-label>PBX Voicemail Number</mat-label>
          </mat-form-field>

          <ng-template #noorganisationattached>
            <div>Number is not linked to an organisation</div>
          </ng-template>

        </div>

        <div class="fx-col fx-width-40 fx-width-100-sm">

          <div *ngIf="number?.is_pipxt_extensionless" style="margin-top: 20px;">
            This is an extensionless variant.
          </div>
        </div>

      </div>


    </form>

  </div>

</div>



<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>
