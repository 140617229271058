<div class="pip-modal-content">
  <div class="pip-modal-header">
    <span class="pip-modal-close" (click)="closeModal()">X</span>
    <span class="subtitle-14" style="color:#6f7189">payment details</span>
    <h5 class="title-color" style="color: black;">Update payment card</h5>
  </div>


  <div id="pipmodalbody" class="pip-modal-body" fxLayout="row">

    <div fxLayout="column" fxFlex="100%" [hidden]="pageStatus.success">

        <div class="form-container" fxLayout="column">
          <div style="color:#6f7189">Card Number</div>
          <form novalidate [formGroup]="stripeForm"  fxLayout="column" class="stripe-container">
            <div id="card-element" class="fieldx" fxFlex="100%"></div>
          </form>
          <div class="error-message-update" *ngIf="!pageStatus.isLoading">{{pageStatus.errorMessage}}</div>
        </div>
  
  
        <div style="display: flex;flex-direction: row;justify-content: center;">
          <img src="/assets/img/stripe-trust-badge.png" alt="secure and guaranteed payments through stripe"
          style="width: 100%;max-width: 400px;">
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" style="margin-top: 20px;margin-bottom: 20px;">
  
            <button mat-flat-button color="accent" class="update-button" (click)="updateCard()"
              [disabled]="pageStatus.isLoading">
              <ng-container *ngIf="!pageStatus.isLoading;else _isLoadingSpinner">Save</ng-container>
              <ng-template #_isLoadingSpinner><i class="fas fa-spinner fa-spin"></i></ng-template>
            </button>

  
        </div>
  
    </div>


    <div fxLayout="column" fxFlex="100%" [hidden]="!pageStatus.success">
      Card has been updated successfully...
    </div>




  </div>

</div>
