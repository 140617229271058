import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { ToolbarV2Component  } from './toolbar-v2/toolbar-v2.component';
import { MaterialModule } from '../material/material.module';
import { MatBadgeModule} from '@angular/material/badge';
// import { BrowserModule } from '@angular/platform-browser';
// import { BrowserModule} from '@angular/platform-browser';
// import { NoopAnimationsModule } from '@angular/platform-browser/animations';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SideNavRightModule } from './sidenav-right/sidenav-right.module';
import { GravatarDirectiveModule} from '@app/directives/gravatar/gravatar.module';
import { FirstPaymentModalModule} from '@app/components/modals/first-payment-modal/first-payment-modal.module';
import { SharedDataServiceModule} from '@app/services/shared-data.service/shared-data.service.module';
import { FlexLayoutModule } from '@angular/flex-layout'
import { DownloadLinksModalModule} from '@app/components/modals/download-links-modal/download-links-modal.module';
import { CookieService } from 'ngx-cookie-service';
import { DebugModalModalModalModule} from '@app/components/modals/debug-modal/debug-modal.module';
import { TermsAndConditionsModalModule } from '@app/components/modals/terms-and-conditions-modal/terms-and-conditions-modal.module';
import { PricingModalModule} from '@app/components/modals/pricing-modal/pricing-modal.module';
import { HelpCenterRightMenuModule } from '@app/components/right-menu-overlay/help-center-right-menu/help-center-right-menu.module';

@NgModule({
    declarations: [
        SidenavComponent,
        ToolbarComponent,
        FooterComponent,
        ToolbarV2Component
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        MatBadgeModule,
        RouterModule,
        SideNavRightModule,
        GravatarDirectiveModule,
        FirstPaymentModalModule,
        SharedDataServiceModule,
        FlexLayoutModule,
        DownloadLinksModalModule,
        DebugModalModalModalModule,
        TermsAndConditionsModalModule,
        PricingModalModule,
        HelpCenterRightMenuModule
    ],
    entryComponents: [],
    exports: [
        SidenavComponent,
        ToolbarComponent,
        FooterComponent,
        ToolbarV2Component
    ],
    providers: [
        CookieService
    ]
})
export class TemplateModule { }
