<ng-container *ngIf="organisation_id; else notFound">

  <div class="section-wrapper">
    <tab-title-lined>PBX</tab-title-lined>

    <div class="section-content">

      <div *ngIf="!currentPBXhost && !pageStatus.isSubmitting && !pageStatus.isLoading">
        <i>No extension found to be able to check current PBX</i>
      </div>

      <form class="fx-row fx-col-sm fx-justify-start fx-align-center fx-gap-1"   [formGroup]="pbxSettingForm" *ngIf="currentPBXhost">
       
        <div class="fx-row fx-justify-start fx-align-center fx-gap-05">
          <mat-form-field appearance="outline" floatLabel="always" class="custom-form-field-v3 readonly-block" style="min-width: 300px;">
            <input placeholder="No current PBX found" matInput formControlName="currentPBXhost">
            <mat-label>Current PBX</mat-label>
          </mat-form-field>

          <mat-form-field appearance="outline" floatLabel="always" class="custom-form-field-v3 readonly-block" style="width: 100px;">
            <input placeholder="No tenant found" matInput formControlName="currentTenant">
            <mat-label>Tenant</mat-label>
          </mat-form-field>

          <mat-icon svgIcon="info"
            matTooltip="The current PBX and Tenant has been derived from checking the first extension in this organisation"></mat-icon>
        </div>

        <button (click)="migratePBXOptions()" *ngIf="state === 'Trial'" mat-flat-button mat-button color="accent" style="height:46px;" matTooltip="Migrate the tenant and extensions to a different PBX.">Migrate to another PBX</button>
      </form>

      <div class="fx-row fx-col-sm fx-width-100 fx-justify-start">

        <ng-container *ngIf="pageStatus?.isLoading">
          <div>
            <div class="loader-spinner"></div>
          </div>
        </ng-container>

      </div>


    </div>
  </div>




</ng-container>


<ng-template #notFound>


  <div class="section-wrapper">
    <div class="section-title fx-col fx-justify-space-between fx-align-start">

      <div>Migrate PBX</div>

    </div>

    <div class="section-content fx-col">
      Error getting data
    </div>
  </div>


</ng-template>


<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>


<ng-template #savingSpinner>
  <div>
    <div class="loader-spinner"></div>
  </div>
</ng-template>