<div class="cc-v-page-wrap">


  <cc-floating-card [showCopyRight]="true" [showBrandLogo]="true">

      <ng-container *ngIf="pageStatus?.isLoading;else pageLoaded">
        <div class="loading" style="text-align: center;position: relative;top: 180px;">
          <div>
            <span class="loading-icon">
              <div class="loader-spinner"></div>
            </span>
          </div>
        </div>
      </ng-container>

      <ng-template #pageLoaded>

        <h4 class="sign-in-card-title ">{{displayCopy}}</h4>
        <ng-container *ngIf="pageStatus.step === 0">
          <div>Enter the email address you signed up with</div>
        </ng-container>
        <ng-container *ngIf="pageStatus.step === 1">
          <div>Enter the verification code we emailed you and set a new password</div>
        </ng-container>
        <ng-container *ngIf="pageStatus.step === 2">
          <div>Signing into portal...! <ng-container *ngTemplateOutlet="spinner"></ng-container></div>
        </ng-container>


        <div class="main-content">
          <ng-container *ngIf="pageStatus.step === 0">
            <div style="display:flex;flex-direction: column;">
              <form [formGroup]="forgotPWForm" class="fx-col fx-gap-1" >
                <mat-form-field appearance="outline" class="ff-style-outline fx-width-100">
                  <input type="text" matInput placeholder="Email" autocomplete="email" formControlName="email"
                    (keyup.enter)="forgotPWForm.valid ? submitForm(forgotPWForm.get('email').value): null;">
                  <mat-icon matSuffix svgIcon="email_v2"></mat-icon>
                  <mat-error
                    *ngIf="forgotPWForm?.controls.email?.touched && forgotPWForm?.controls.email?.errors?.required">
                    email is required</mat-error>
                  <mat-error
                    *ngIf="forgotPWForm?.controls.email?.touched && forgotPWForm?.controls.email?.errors?.email">
                    not a valid email</mat-error>

                  <!-- <mat-label>Email</mat-label> -->

                </mat-form-field>
              </form>

              <div class="error-response-box" *ngIf="pageStatus?.isError"
                [@enter]="{ value: '', params: { duration: 150, delay: 0 } }"
                [@fadeOutLeftOnLeave]="{ value: '', params: { duration: 100, delay: 0, translate: '50%' } }">
                <mat-icon class="clickable" (click)="pageStatus.isError = false">cancel</mat-icon>
                {{pageStatus?.errorMessage}}
              </div>

            </div>
            <div class="button-tray fx-col">
              <button class="lg-button" mat-flat-button color="accent"
                [disabled]="pageStatus.isSubmitting || !forgotPWForm.valid"
                (click)="submitForm(forgotPWForm.get('email').value)" style="width: 100%;">
                <ng-container *ngIf="!pageStatus.isSubmitting;else spinning">Continue</ng-container>
                <ng-template #spinning><span class="loading-spinner"><i
                      class="fas fa-spinner fa-spin"></i></span>
                </ng-template>
              </button>
            </div>
          </ng-container>

          <ng-container *ngIf="pageStatus.step === 1">
            <div style="display:flex;flex-direction: column;">
              <form [formGroup]="verificationForm" class="fx-col fx-gap-1">


                <div style="width:100%;">
                  <code-input [isCodeHidden]="false" [isFocusingOnLastByClickIfFilled]="true" [codeLength]="6" [initialFocusField]="0"
                    [disabled]="pageStatus.isSubmitting" (codeChanged)="onCodeChanged($event)"
                    (codeCompleted)="onCodeCompleted($event)">
                  </code-input>
                </div>

                <!-- <mat-form-field appearance="outline" class="custom-form-field-v3" >
                <input matInput type="tel" pattern="[0-9]*" inputmode="numeric" placeholder="Enter code"
                  formControlName="verificationCode">
                <mat-label>Verification Code</mat-label>

                <status-icons *ngIf="verificationForm?.controls.verificationCode?.touched"
                  [iconStatus]="verificationForm.get('verificationCode').status">
                </status-icons>

              </mat-form-field> -->

                <mat-form-field appearance="outline" class="custom-form-field-v3">

                  <input type="{{pwType}}" matInput placeholder="Set a new password" autocomplete="new-password"
                    formControlName="password"
                    title="Password strength: minimum length 6 characters and must include lower case (a-z), upper case (A-Z) and a number (0-9)">
                  <mat-icon matSuffix class="clickable" *ngIf="pwType != 'password'"
                    (click)="pwType = 'password'" title="hide password" (click)="$event.stopPropagation()"
                    svgIcon="view"></mat-icon>
                  <mat-icon matSuffix class="clickable" *ngIf="pwType === 'password'" (click)="pwType = 'text'"
                    title="show password" (click)="$event.stopPropagation()" svgIcon="view_off"></mat-icon>

                  <mat-error
                    *ngIf="verificationForm?.controls.password?.touched && verificationForm?.controls.password?.errors?.required">
                    Required field
                  </mat-error>

                  <mat-error
                    *ngIf="verificationForm?.controls.password?.touched && verificationForm?.controls.password?.errors?.pattern">
                    min length 6 chars, include lower case (a-z), upper case (A-Z) and a number (0-9)
                  </mat-error>

                  <mat-label>New Password</mat-label>

                  <status-icons *ngIf="verificationForm?.controls.password?.touched"
                    [iconStatus]="verificationForm.get('password').status">
                  </status-icons>

                </mat-form-field>


              </form>

              <div class="error-response-box" *ngIf="pageStatus.isError"
                [@enter]="{ value: '', params: { duration: 150, delay: 0 } }"
                [@fadeOutLeftOnLeave]="{ value: '', params: { duration: 100, delay: 0, translate: '50%' } }">
                <mat-icon class="clickable" (click)="pageStatus.isError = false">cancel</mat-icon>
                {{pageStatus.errorMessage}}
              </div>


            </div>
            <div class="button-tray fx-col" >
              <button class="lg-button login-button" mat-flat-button color="accent"
                [disabled]="pageStatus.isSubmitting || !verificationForm.valid"
                (click)="verifyCode(verificationForm)" style="width: 100%;">
                <ng-container *ngIf="!pageStatus.isSubmitting;else spinning">Submit</ng-container>
                <ng-template #spinning><span class="loading-spinner"><i
                      class="fas fa-spinner fa-spin"></i></span>
                </ng-template>
              </button>
              <button style="font-size: 14px;width: 100%;text-align: center;" mat-button
                (click)="cancelVerification()" [disabled]="pageStatus.isSubmitting">Cancel</button>
            </div>
          </ng-container>
        </div>


        <div  class="alt-options-container fx-row fx-justify-center"
          *ngIf="pageStatus.isLoading === false">
          <span>Existing user?</span>&nbsp;<a class="alt-link clickable" (click)="$event.preventDefault()"
            (click)="navigateByUrl('/login')">Sign in</a>
        </div>

      </ng-template>


  </cc-floating-card>

  <div class="app-version">
    <span class="text-right version-details"> Version: {{appversion}} <span *ngIf="isDev">(DEV)</span></span>
  </div>

</div>


<ng-template #loading>
  <div class="fx-col fx-center">
    <div class="loading-spinner-1" style="width:90px;margin-top:20px;">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
      <div class="bounce4"></div>
    </div>
  </div>
</ng-template>


<ng-template #spinner>
  <div>
    <span class="loading-icon">
      <div class="loader-spinner"></div>
    </span>
  </div>
</ng-template>