<div class="section-wrapper">

  <tab-title-lined>PBX Extension Configuration</tab-title-lined>

  <tab-section-action [isHidden]="extPasswordUpdate?.pristine">
    <button *ngIf="extPasswordUpdate?.pristine" mat-flat-button aria-label="Sync to PBX" class="save-button"
    style="margin-right: 8px;" [disabled]="pageStatus.isSubmitting" [matTooltip]="'Sync settings from the PBX'" (click)="syncExtension()">
    <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Sync From PBX</ng-container>
  </button>

   <button mat-flat-button *ngIf="!extPasswordUpdate?.pristine" style="margin-right: 4px;"
    (click)="cancelForm()">Cancel</button>

  <button mat-flat-button aria-label="Save changes" class="save-button"
    [disabled]="extPasswordUpdate?.pristine ||  pageStatus.isSubmitting || extPasswordUpdate.invalid"
    (click)="saveExtensionPassword(extPasswordUpdate)">
    <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
  </button> 
  </tab-section-action>



  <div class="section-content">



    <form class="fx-col" [formGroup]="extPasswordUpdate"  [ngClass]="{'readonly-block': pageStatus.isSubmitting || !(viewAs === 'sysAdmin' || viewAs === 'isAdmin')}">
    
    <div class="information-banner fx-row fx-justify-start fx-align-center fx-width-60 fx-width-100-md" *ngIf="number?.state !== 'OK'"
      [ngClass]="{'warning': number?.state === 'Unknown', 'error': number?.state === 'Error'}"   style="margin-bottom: 40px;">
      <div>
        <mat-icon svgIcon="warning"></mat-icon>
      </div>
      <div class="message-body">
        <ng-container *ngIf="number?.event_data; else defaultmessage">{{number?.event_data}}</ng-container>
        <ng-template #defaultmessage>{{number?.state}}</ng-template>
      </div>
    </div>

      <s-grid-items-container>
        <s-grid-item>
          <div role="label">Ext</div>
          <div role="value">{{number?.sip_extension_number}}</div>
        </s-grid-item>
        <s-grid-item>
          <div role="label">Username</div>
          <div role="value">{{number?.sip_user_name}}</div>
        </s-grid-item>
        <s-grid-item>
          <div role="label">Voicemail</div>
          <div role="value">{{number?.sip_voicemail_number}}</div>
        </s-grid-item>
      </s-grid-items-container>

     

      <div class="fx-row fx-width-100 fx-col-md fx-gap-2">

        <div class="fx-col fx-width-60 fx-width-100-sm">



          <!-- class="obfuscate-key" -->
          <mat-form-field appearance="outline" floatLabel="always" class="custom-form-field-v3 inner-shadow" [hidden]="number?.is_pipxt_extensionless">
            <input autocomplete="off" spellcheck="false"  type="text" matInput
              placeholder="********" formControlName="sip_password">
            <mat-label>SIP Password</mat-label>
            <mat-hint align="end">This will update the PBX secret. Allowed symbols: ! % * _ -
              </mat-hint>
            <!-- <mat-error align="end" *ngIf="extPasswordUpdate?.controls.sip_password?.hasError('maxlength')">Maximum length 30 characters has been exceeded</mat-error>
            <mat-error align="end" *ngIf="extPasswordUpdate?.controls.sip_password?.hasError('minlength')">Minimum length 12 characters</mat-error> -->
            <mat-error align="end" *ngIf="extPasswordUpdate?.controls.sip_password?.hasError('pattern')">Password conditions not met</mat-error>
            <mat-icon matSuffix svgIcon="info" 
            [matTooltip]="'Setting a new password here will also update the secret on the PBX. Minimum Length 12 , Maximum length 20. Include uppercase, lowercase and a symbol. Allowed symbols: ! % * _ -'"
            matTooltipClass="tooltipcustom tooltipmultiline"
            ></mat-icon>
          </mat-form-field>


          <button mat-flat-button class="delete-button" [matTooltip]="'Remove extension settings and revert to PiPmobile. This will also unlink the organisation, unless there is an existing user linked to the number.'" (click)="revertToPiPmobile()">Revert to
            PiPmobile</button>
        </div>


      </div>


    </form>

  </div>
</div>


<ng-template #savingSpinner>
  <div>
    <div style="top: 6px;" class="loader-spinner"></div>
  </div>
</ng-template>