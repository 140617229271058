import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatIconModule, MatDividerModule, MatButtonModule } from '@app/material/material-essentials.module';
import { MaterialModule } from '@app/material/material.module';
import { ConfirmBillingStateComponent } from './confirm-billing-state-modal.component';
import { ConfirmBillingStateModalService} from './confirm-billing-state-modal.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrganisationService } from '@app/services/pipcall/organisation.service';

import { modalServiceModule } from '@app/services/modal-service/modal-service.module';

@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        MatDialogModule,
        MatIconModule,
        MatDividerModule,
        MatButtonModule,
        FormsModule,
        modalServiceModule
    ],
    declarations: [ConfirmBillingStateComponent],
    entryComponents: [ConfirmBillingStateComponent],
    exports: [ConfirmBillingStateComponent],
    providers: [ConfirmBillingStateModalService, OrganisationService]
})
export class ConfirmBillingStateModalModule { }
