/* eslint-disable max-len */
import { Component, OnInit, isDevMode, ChangeDetectorRef , HostListener, Renderer2, ViewChild} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthService } from '@app/services/auth-service/auth.service';
import { FormGroup, Validators, FormBuilder, ValidationErrors, FormControl, Form, AbstractControl, FormsModule } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@app/material/material-essentials.module';
import { ModalService } from '@app/services/modal-service/modal.service';
import { finalize } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient } from '@angular/common/http';

import { AppTitleService } from '@app/services/helpers/update-title.service';
import { AppInsightService } from '@app/services/helpers/app-insights.service';



@Component({
    selector: 'app-signup-page',
    templateUrl: './signup.component.html',
    styleUrls: ['../signup.component.scss'],
    animations: []
})
export class SignupRequestComponent implements OnInit {

    public isDev: boolean;
    public appversion: string = environment.appversion;
    public copyrightMessage = environment.copyrightMessage;

    signUpForm: FormGroup;

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isError: false,
        errorMessage: '',
        step: 0
    }

    public signup_request_id = '';
    isTouched = false;
    domainBlacklist = [];

    public isMobileView = false;

    constructor(
        public dialog: MatDialog,
        private appInsightService: AppInsightService,
        private snackBar: MatSnackBar,
        private modalService: ModalService,
        public auth: AuthService,
        public router: Router,
        public route: ActivatedRoute,
        private appTitleService: AppTitleService,
        private http: HttpClient,
        private renderer2: Renderer2
    ) {

        this.appTitleService.setTitle('PiPcall: Signup');
        this.appInsightService.logPageView('Signup: form');
    }


    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobileView = window.innerWidth <= 959;
    }

    ngOnInit() {
        this.isMobileView = window.innerWidth <= 959;
        this.renderer2.setStyle(document.body, 'background-color', 'var(--c-primary);');

        const destination_id = this.route.snapshot.queryParams['su_destination_id'] ? this.route.snapshot.queryParams['su_destination_id'] : null;
        const type = this.route.snapshot.queryParams['su_type'] ? this.route.snapshot.queryParams['su_type'] : null;

        if (destination_id && type) {
            //set these in session storage
            sessionStorage.setItem('su_destination_id', destination_id);
            sessionStorage.setItem('su_type', type);
        }
    }

    ngAfterViewInit() {
    }

    navigateByUrl(url) {
        this.router.navigateByUrl(url);
    }

    navigateToHome() {
        window.location.href = 'https://pipcall.com';
    }
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

}
