/* eslint-disable max-len */
import { Component, OnInit, isDevMode, ChangeDetectorRef , HostListener, Renderer2, ViewChild} from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '@app/services/auth-service/auth.service';
import { FormGroup, Validators, FormBuilder, ValidationErrors, FormControl, Form, AbstractControl, FormsModule } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@app/material/material-essentials.module';
import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { finalize } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient } from '@angular/common/http';

import { AppTitleService } from '@app/services/helpers/update-title.service';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { fadeInUpOnEnterAnimation, fadeInRightOnEnterAnimation, fadeOutLeftOnLeaveAnimation, fadeInLeftOnEnterAnimation, fadeOutRightOnLeaveAnimation } from 'angular-animations';
import { CookieService } from 'ngx-cookie-service';
import { SignupService } from '@app/services/pipcall/signup.service';
import { GA4Service } from '@app/services/google-analytics-service';
import { SessionService } from '@app/services/session.service';
import { SharedService } from '@app/services/shared-data.service/shared-data.service';


@Component({
    selector: 'card-verify-signup',
    templateUrl: './card_verify_signup.component.html',
    styleUrls: ['./card_verify_signup.component.scss'],
    animations: [
        fadeOutLeftOnLeaveAnimation({ duration: 250, translate: '50%' })
    ]
})
export class CardVerifySignupComponent implements OnInit {

    public isDev: boolean;
    public appversion: string = environment.appversion;

    verificationForm: FormGroup;

    pageStatus = {
        isLoading: false,
        isSubmitting: false,
        isError: false,
        isSuccess: false,
        errorMessage: '',
        notValid: false,
        step: 0
    }


    public signup_request_email = '';


    constructor(
        public dialog: MatDialog,
        private appInsightService: AppInsightService,
        private snackBar: MatSnackBar,
        public auth: AuthService,
        public router: Router,
        public route: ActivatedRoute,
        private appTitleService: AppTitleService,
        private cookieService: CookieService,
        private signupService: SignupService,
        private _fb: FormBuilder,
        private gA4Service: GA4Service,
        private modalService: ModalService,
        private ref: ChangeDetectorRef,
        private sessionService: SessionService,
        private sharedService: SharedService
    ) {
        this.appTitleService.setTitle('PiPcall: Verify your email');
        this.appInsightService.logPageView('Signup: verify');
    }

    get f () { return this.verificationForm.controls }
    get hasExistingToken(): boolean {
        // If a valid access token exists OR a valid refresh token exists, return true
        return this.cookieService.check('access_token') && this.auth.isAuthenticated() ? true : this.cookieService.check('refresh_token') ? true : false; //could replace with id_token
    }

    ngOnInit() {
        if (this.hasExistingToken) { this.navigateByUrl('/redirect'); }

        this.verificationForm = this._fb.group({
            code: ['', [Validators.required]],
            organisation_signup_request_id: ['', [Validators.required]]
        });

        //get id from url
        this.route.params.subscribe(params => {
            const id = params['id'];
            this.verificationForm.controls.organisation_signup_request_id.patchValue(id);
            id ? this.getSignupRequest(id) :  this.pageStatus.notValid = true;
        });
    }

    ngAfterViewInit() {
    }

    onCodeChanged(code: string) {
    }
    // this called only if user entered full code
    onCodeCompleted(code: string) {
        this.verificationForm.controls.code.patchValue(code);
        this.verifyCode()
    }

    getSignupRequest(request_id: string) {
        this.signupService.getSignupRequest(request_id)
            .subscribe(
                (resp) => {
                    this.signup_request_email = resp.body ? resp.body : null;
                },
                (err) => {
                    this.pageStatus.notValid = true;
                }
            );
    }

    verifyCode() {
        if (!this.verificationForm.valid) {return this.openSnackBar('We ran into a problem', 'X') }

        this.appInsightService.logEvent('Signup: verify');
        this.pageStatus.isSubmitting = true;
        this.pageStatus.isError = false;
        this.signupService.postSignupVerifyCode(this.verificationForm.value.organisation_signup_request_id, this.verificationForm.value.code)
            .pipe(
                finalize(() => { this.pageStatus.isSubmitting = false })
            )
            .subscribe(
                (resp) => {
                    // this.gA4Service.sign_up_pipmobile_stage3_complete(); //REPLACE THIS WITH THE NEW EVENT
                    const token = resp.body?.access_token;
                    this.pageStatus.isSuccess = true;
                    this.appInsightService.logEvent('Signup : code verified', { 'email': this.signup_request_email });
                    this.auth.setSession(token, resp.body?.refresh_token, resp.body?.id_token);
                    this.sharedService.innit("Verification");
                    this.sessionService.setSession();

                    setTimeout(() => {
                        //sucessful signup. Introduce a delay to allow db to catch up.
                        return this.navigateToHome(500, resp.body?.organisation_id);
                    }, 2000);

                }, (err) => {
                    switch (err.status) {
                        case 400:
                            this.pageStatus.errorMessage = '400: Verification code is invalid';
                            break;
                        case 409:
                            this.pageStatus.errorMessage = '409: There was a conflict when creating the account';
                            break;
                        case 410:
                            this.pageStatus.errorMessage = '410: Signup request has expired, please go back and resubmit the form';
                            break;
                        default:
                            //this.pageStatus.errorMessage = err.error?.Message ? err.error?.Message : "Something went wrong, signup aborted";
                            this.pageStatus.errorMessage = err.error ? err.error : "Something went wrong, signup aborted";
                            break;
                    }
                    this.ref.detectChanges();
                    this.pageStatus.isSubmitting = false;
                    return this.pageStatus.isError = true;
                }
            )
    }

    navigateToHome(waittime: number, id?: string) {
        setTimeout(() => {
            //url for signup example localhost:4200/signup?su_type=country&su_destination_id=62bcbc91-4891-ef11-88cd-000d3a7ef68b
            this.cookieService.set('signup_complete', 'true', 3 / 24); //stop signup abuse

            if (sessionStorage.getItem('su_destination_id') && sessionStorage.getItem('su_type')) {
                const _id = sessionStorage.getItem('su_destination_id');
                const _type = sessionStorage.getItem('su_type');
                sessionStorage.removeItem('su_destination_id'); //clear up cookies
                sessionStorage.removeItem('su_type'); //clear up cookies

                this.navigateToProduct(_type, _id); //not yet working.
            } else {
                console.log('verify_signup].navigate to organisation', id);

                id ? this.router.navigateByUrl(`/organisation/${id}`) : this.router.navigateByUrl('/organisation');
            }
            // /organisation/7c8fdfc2-5e8d-eb11-b566-281878fb5f60/order/data_esim/(products:country_id/62bcbc91-4891-ef11-88cd-000d3a7ef68b)
        }, waittime);
    }

    navigateToProduct(type: 'country' | 'region' | string, id: string) {
        console.log('navigateToProduct outlet', type, id);
        const navigation = {
            outlets: {
                country: null,
                region: null,
                products: null
            }
        };
        if (type === 'country') {navigation.outlets.products = ['country_id', id]
        } else if (type === 'region') { navigation.outlets.region = ['region_id', id]};

        this.router.navigate([`/organisation/${id}/order/data_esim`, navigation]);
    }

    debug() {
        this.pageStatus.isSuccess = true;
    }
    cancelVerification() {
        const data: ModalConfirmData = {
            title: 'Cancel',
            content: 'Are you sure you want to cancel and return to the signup page?',
            confirmButtonLabel: "I am sure",
            closeButtonLabel: "Close",
            choice: true,
            disableClose: false
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.router.navigateByUrl('/signup');
            }
        });
    }

    navigateByUrl(url: string, id?: string) {
        this.router.navigateByUrl(url);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

}
