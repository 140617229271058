
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild , ChangeDetectorRef, SimpleChanges} from '@angular/core';

import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MatDialog} from '@app/material/material-essentials.module';

import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { AuthService } from '@app/services/auth-service/auth.service';
import 'rxjs/add/operator/switchMap';
import { debounceTime, distinctUntilChanged, startWith, map, tap, delay, switchMap, takeUntil } from 'rxjs/operators';
import { merge, fromEvent, Subject } from "rxjs";
import { finalize , filter} from "rxjs/operators"
import { PortalApiV2HelperService, QueryParams } from '@app/services/helpers/portal-api-v2-helper';
import { MatSelectChange } from '@angular/material/select';
import { FormatDate } from '@app/pipes/formatDate';
import { StaticDataService } from '@app/services/shared-data.service/staticdata.sevice';
import { responseModel } from '@app/models/response.model';
import { DialogPosition } from "@angular/material/dialog";
import { SaveQueryModalComponent} from './save-query-modal/save-query-modal.component';
import { SharedService } from '@app/services/shared-data.service/shared-data.service';
import { UserDataService, SavedQuery } from '@app/services/pipcall/user-data.service';
import { ExcelService } from '@app/services/helpers/export-as-excel.service';

export class AvailableColumns {
    value: string;
    viewValue: string;
    isHidden: boolean;
    type?: string;
    autoComplete?: string;
    showOnMobile?: boolean;
}

export class SearchFilterSingle {
    isActive: boolean;
    col: string;
    operator: string;
    value: string;
    isChecked: boolean;
    type?: string;
}

export class SearchSettings {
    filteredColumns: any[];
    sort: {active: string, direction: string};
}
export class AvailableOperators  {


    //if isType is matched, then that operator is available for that type
    public static readonly availableOperators = [
        {value: 'contains', viewValue: 'contains', isType: ['string', 'number'], tooltip: 'Does contain the filter value'},
        {value: 'notcontains', viewValue: 'not contains', isType: ['string', 'number'], tooltip: 'Does not contain the filter value'} ,
        {value: 'startsWith', viewValue: 'starts with', isType: ['string', 'number'], tooltip: 'First characters will match the filter value'},
        {value: 'eq', viewValue: 'equals', isType: ['string', 'date', 'number', 'double', 'boolean', 'guid'], tooltip: 'Must be exact match'},
        {value: 'ne', viewValue: 'does not equal', isType: ['string', 'date', 'number', 'boolean', 'guid'], tooltip: 'Must not be an exact match'},
        {value: 'ge', viewValue: '>=', isType: ['date', 'number'], tooltip: 'Greater than or equal to the filter value'},
        {value: 'gt', viewValue: '>', isType: ['date', 'number'], tooltip: 'Greater than the filter value'},
        {value: 'le', viewValue: '<=', isType: ['date', 'number'],  tooltip: 'Less than or equal to the filter value'},
        {value: 'lt', viewValue: '<', isType: ['date', 'number'], tooltip: 'Less than the filter value'},
        {value: 'isnull', viewValue: 'is null', isType: ['string', 'number', 'double', 'boolean', 'guid'], tooltip: 'Is empty/null, you can set it to false to show results that are NOT empty/null'},
    ]

    //add a type to the operator
}


@Component({
    selector: 'app-table-toolbar',
    templateUrl: './table-toolbar.component.html',
    styleUrls: ['./table-toolbar.component.scss']
})
export class TableToolbarComponent implements OnInit {
    public _subsciption: Subscription;
    public _subsciption2: Subscription;
    public savedQueryVersion = "1.0.0";

    @Input()  table_id: string;
    @Input()  _availableColumns: AvailableColumns[];
    @Input() viewAs: string; //sysAdmin, orgAdmin, user
    @Input()  dataSource: MatTableDataSource<any>;
    @Input()  sort: MatSort;


    //columns
    @Output() updateColumns = new EventEmitter<AvailableColumns[]>();
    public _filteredColumns: AvailableColumns[] = [];

    @Output() updateSort = new EventEmitter<{active: string, direction: string}>();

    //filters
    @Input() isDisabled: {}; //pagestatus submitting
    @Output() applyFilter = new EventEmitter<string>();

    public isFilterVisible = false;
    public showSaveOption = false; //if filter has been executed set to true

    public availableOperators = AvailableOperators.availableOperators;


    public searchArray: SearchFilterSingle[] = [
        {isActive: false, col: '', operator: '', value: '', isChecked: true}
    ]

    states: string[] = [];
    providers: string[] = [];
    pbxPlatforms: string[] = [];
    pipvariants: string[] = ['pipxt', 'pipmobile'];


    public pageStatus = {
        loadingUserQuery: false,
        loadingSharedQuery: false,
        filterIsDirty: false,
        table_state: ''
    }

    public columnsShowAll = false;

    //saved query
    @ViewChild('savedMenuTrigger') savedMenuTrigger;
    // public mySavedQueries = new Array<SavedQuery>();
    public myQueries = new Array<SavedQuery>();
    public sharedQueries = new Array<SavedQuery>();

    //my user
    user_id: string;
    organisation_id: string;

    constructor(
        private router: Router,
        private _formBuilder: FormBuilder,
        public dialog: MatDialog,
        private portalApiV2HelperService: PortalApiV2HelperService,
        private ref: ChangeDetectorRef,
        private staticDataService: StaticDataService,
        private sharedService: SharedService,
        private authService: AuthService,
        private userDataService: UserDataService,
        private snackBar: MatSnackBar,
        private excelService: ExcelService
    ) {
    }



    ngOnInit() {
        //set the default displayed columns
        this.sharedService.innit('table-toolbar');

        this.searchArray.forEach((item, index) => {
            this.searchArray[index].col = this._availableColumns[0].value;
            this.searchArray[index].operator = this.availableOperators[0].value;
        });

        this.fetchOrganisationStates().subscribe((data) => {
            this.states = data?.body.organisation_state_list.map(s => s.name);
        });

        this.fetchProviders().subscribe((data) => {
            this.providers = data?.body.provider_list.map(s => s.name);
        });
        this.getPBXPlatforms().subscribe((data) => {
            this.pbxPlatforms = data?.body.pbx_platform_list.map(s => s.id);
        });


        //set user profile
        this._subsciption = this.sharedService.activeOrgAsObservable.first().subscribe(defaultOrg => {
            this.organisation_id = defaultOrg?.id;
            this.getSavedQueries();
        });

        this._subsciption2 = this.sharedService.userProfileAsObservable.first().subscribe(userProfile => {
            this.user_id = userProfile?.id;
        });

    }

    setColumns() {
        this._filteredColumns = [];
        this._availableColumns.forEach((col, index) => {
            col.isHidden ?  null : this._filteredColumns.push(col);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        // changes['dataSource']?.currentValue !== changes['dataSource']?.previousValue ? do something if there is new data : null;
        changes['_availableColumns']?.currentValue !== changes['_availableColumns']?.previousValue ?   this.setColumns() : null;
    }


    getSavedQueries() {
        this.getUserQueries();
        this.getSharedQueries();
    }

    getUserQueries() {

        if (this.table_id && this.organisation_id && this.user_id) {
            this.pageStatus.loadingUserQuery = true;
            this.userDataService.getSavedQueries(this.table_id, this.organisation_id, false, this.user_id)
                .pipe(finalize(() => { this.pageStatus.loadingUserQuery = false; }
                ))
                .subscribe((resp) => {
                    console.log("[table-toolbar].getUserQueries()", resp);
                    this.myQueries = resp.body?.data ? resp.body.data : [];
                    this.ref.detectChanges();
                }, (err) => {
                    this.openSnackBar('Error loading saved queries', 'OK');
                });
        }

    }
    getSharedQueries() {

        if (this.table_id && this.organisation_id) {
            this.pageStatus.loadingSharedQuery = true;
            this.userDataService.getSavedQueries(this.table_id, this.organisation_id, true)
                .pipe(finalize(() => { this.pageStatus.loadingSharedQuery = false; }
                ))
                .subscribe((resp) => {
                    console.log("[table-toolbar].getSharedQueries()", resp);
                    this.sharedQueries = resp.body?.data ? resp.body.data : [];
                    this.ref.detectChanges();
                }, (err) => {
                    this.openSnackBar('Error loading shared queries', 'OK');
                });
        }

    }

    fetchOrganisationStates(): Observable<responseModel> {
        return this.staticDataService.getOrganisationStates()
    }

    fetchProviders(): Observable<responseModel> {
        return this.staticDataService.getProviders()
    }

    getPBXPlatforms(): Observable<responseModel> {
        return this.staticDataService.getPbxPlatforms()
    }

    resetAppliedIcons() {
        this.searchArray.map(i => i.isActive = false)
    }

    removeLine(index) {
        //remove a line from searchArray
        if (this.searchArray.length === 1) {
            this.isFilterVisible = false;
            this.applyFilter.emit('');
        } else {
            this.searchArray.splice(index, 1);
        }
        this.pageStatus.filterIsDirty = true;
        this.ref.detectChanges();
    }

    addLine() {
        //insert a line into searchArray
        const line: SearchFilterSingle = {isActive: false, col: this._availableColumns[0].value, operator: this.availableOperators[0].value, value: '', isChecked: true};
        this.searchArray.push(line);
        this.pageStatus.filterIsDirty = true;
        this.ref.detectChanges();
    }

    applySingleLine(index) {
        this.pageStatus.table_state = 'applySingleLine filter'
        //first clear all applied buttons
        this.searchArray.forEach((item, i) => {
            this.searchArray[i].isActive = false;
        });
        let searchvalue = ''
        if (this.searchArray[index].col === 'number' || this.searchArray[index].col === 'sip_extension_cli' || this.searchArray[index].col === 'mobile') {
            searchvalue = this.searchArray[index].value.replace('+', '00');
        } else {
            searchvalue = this.searchArray[index].value;
        }
        //urlencode the string searchvalue

        const searchString = this.portalApiV2HelperService.getSearchString(encodeURIComponent(searchvalue),  this.searchArray[index].col,  this.searchArray[index].operator);
        // const searchString = this.portalApiV2HelperService.getSearchString(this.searchArray[index].value.replace('+', '00'),  this.searchArray[index].col,  this.searchArray[index].operator);


        this.searchArray[index].isActive = true;
        this.applyFilter.emit(searchString);
        this.filterTouched(false);
        this.showSaveOption = true;
        this.ref.detectChanges();
    }

    checkboxUpdate(event, index) {
        console.log('event', event, index);
        this.searchArray[index].isChecked = event;
        console.log(this.searchArray);
    }

    isColumnOfType(type: string, col: string): boolean {
        //type = 'string' | 'state' | 'date';
        return this._availableColumns.some(i => i.value === col && i?.type === type);
    }

    isAutoComplete(autoComplete: 'organisation_state' | 'pbx_platform' | 'provider', colValue: string): boolean {
        //autocomplete = 'organisation_state' | 'pbx_platform' | 'provider';
        if (!autoComplete) {return false}
        return this._availableColumns.some(i => i.value === colValue && i?.autoComplete === autoComplete);
    }

    isOperatorTypeValid(col: string, OperatorType: string ) {
        // return this._availableColumns.some(i => (i.value === col && i?.type === undefined) || (i?.type.includes(OperatorType)));
        return this._availableColumns.some(i => (i.value === col && (i?.type === undefined || i?.type === 'any')) || (i.value === col && OperatorType.includes(i?.type)) );
    }

    updateDateFormat(val, index) {
        const formatedDate = new FormatDate().transform(val);
        this.searchArray[index].value = formatedDate;
    }

    filterNow() {
        //applyAll
        //ignore unchecked lines
        const tmpArray = [];
        this.resetAppliedIcons();
        this.searchArray.forEach((item, index) => {
            if (item.isChecked === true) {
                (item.col === 'number' || item.col === 'sip_extension_cli' || item.col === 'mobile') ? item.value = item.value.replace('+', '00') : null;
                const searchString = this.portalApiV2HelperService.getSearchString(item.value, item.col, item.operator);
                this.searchArray[index].isActive = true;
                tmpArray.push(searchString);
            }
        });

        const finalString = tmpArray.join('');

        console.log("[table-toolbar] filterNow() searchArray", this.searchArray);
        console.log("table-toolbar] filterNow() finalString", finalString);
        this.applyFilter.emit(finalString);
        this.showSaveOption = true;
        this.filterTouched(false);
        this.pageStatus.table_state = 'Apply all filters';
        this.ref.detectChanges();
    }

    toggleFilter() {
        this.showSaveOption = false; //reset to false
        if (this.isFilterVisible) {
            //reset and hide the filter
            this.isFilterVisible  = false;
            this
            this.applyFilter.emit('');
            this.pageStatus.table_state = 'hide filter';
        } else {
            //reset and show the filter
            this.searchArray = [
                {isActive: false, col: this._availableColumns[0].value, operator: this.availableOperators[0].value, value: '', isChecked: true}
            ];
            this.applyFilter.emit('');
            this.isFilterVisible  = true;
            this.pageStatus.table_state = 'show filter';
        }
    }

    resetColSelection() {
        this.columnsShowAll = false;
        this._availableColumns.forEach(column => {
            column.isHidden = true;
        });
        this._availableColumns[0].isHidden = false;
        this.updateColumns.emit(this._availableColumns);
    }

    filterColumnSelectAllUpdate(isChecked: boolean) {
        if (isChecked) {
            this._availableColumns.forEach(column => {
                column.isHidden = false;
            });
            // this._filteredColumns = [...this._availableColumns];
            this.updateColumns.emit(this._availableColumns);
        }
    }

    filterColumnUpdate() {
        this.columnsShowAll = false;
        this._filteredColumns.map(col => col.isHidden = false);
        this.updateColumns.emit(this._filteredColumns);
    }

    saveQueryMenu(event, searchArray: SearchFilterSingle[]) {

        const searchSettings: SearchSettings = {
            filteredColumns: this._filteredColumns,
            sort: {active: this.sort?.active, direction: this.sort?.direction}
        };

        console.log("====>searchSettings", searchSettings)

        // console.log(event.clientX);
        // console.log(event.clientY);
        const xpos = event.clientX - 320;
        const po: DialogPosition = { left: xpos + 'px', top: event.clientY + 'px'};

        const dialogRef = this.dialog.open(SaveQueryModalComponent, {
            hasBackdrop: true,
            disableClose: false,
            data: {query: searchArray, user_id: this.user_id, organisation_id: this.organisation_id, table_id: this.table_id,  settings: searchSettings, version: this.savedQueryVersion},
            position: po,
            panelClass: ['pipcall-modal-container', 'save-query-container']
        });

        //event emitter in modal
        // const sub = dialogRef.componentInstance.onAdd.subscribe(() => {
        //     // do something
        //   });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                console.log("SAVE DIALOG CLOSED");
                //do something
            }
        });
    }

    updateTypeOnSelectionChange(colname: string , searchArrayIndex: number) {
        //If search column is changed, set the operator to the first available operator for that type
        //get the type of the column
        const type = this._availableColumns.find(i => i.value === colname).type;
        //get the first available operator for that type
        const firstOperator = this.availableOperators.find(i => i.isType.includes(type));
        //set the operator
        this.searchArray[searchArrayIndex].operator = firstOperator.value;
        this.filterTouched();
        this.ref.detectChanges();
    }

    loadSavedQuery(queriesArray: SavedQuery[] , id: string) {
        //show the filter
        this.isFilterVisible  = true;
        //reset the filter
        this.searchArray.length = 0; //clear search array

        //search the saved queries array for one that matches this id
        const savedQuery = queriesArray.find(i => i.id === id);

        //return the query
        const queryParsed = JSON.parse(decodeURIComponent(savedQuery?.query));
        this.searchArray = queryParsed; //deep copy the query

        //return table settings
        const settingsParsed: SearchSettings = savedQuery.settings ? JSON.parse(decodeURIComponent(savedQuery?.settings)) : null;
        if (settingsParsed && settingsParsed?.filteredColumns) {
            this.updateColumns.emit(settingsParsed?.filteredColumns);
            settingsParsed?.sort ? this.updateSort.emit(settingsParsed?.sort) : null;
        }

        //close the saved query mat menu
        this.savedMenuTrigger.closeMenu();
        //apply the filter
        this.filterNow();
        this.pageStatus.table_state = 'loaded saved query';
    }



    filterTouched(state?: boolean) {
        console.log("Filter touched", state);
        //if state is true or undefined then set to true. If state is false then set to false
        this.pageStatus.filterIsDirty = state === false ? false : true;
    }

    deleteSavedQuery(id: string, query) {

        if (id === undefined || (query?.is_protected === true && query?.user_id !== this.user_id) ) {
            return;
        }

        this.userDataService.deleteSavedQuery(id).subscribe(res => {
            console.log('[table-toolbar] .deleteSavedQuery() resp', res);

            // handle result here (if success remove from the array); or refresh the array.
            //remove query with this ID from the array
            // const index = this.mySavedQueries.findIndex(i => i.id === id);
            // this.mySavedQueries.splice(index, 1);
            this.getSavedQueries();
            this.openSnackBar('Deleted', 'OK');
        }, (err) => {
            console.log('[table-toolbar] .deleteSavedQuery() err', err);
            this.openSnackBar('Error deleting query', 'OK');
        });
    }
    refreshData() {
        console.log('refresh data');
        this.filterNow()
    }

    downloadData(): void {

        const filteredData = this.filterDataByColumns(this.dataSource.filteredData, this._filteredColumns);

        const data = this.dataSource.filteredData;
        const filename = this.table_id;
        const today = new Date().toLocaleDateString();
        const fileName = filename + '-' + today;

        filteredData ? this.excelService.exportAsExcelFile(filteredData, fileName, filename) : null;
    }

    filterDataByColumns(data: any[], columns: any[]): any[] {
        return data.map(item => {
            const filteredItem: any = {};
            columns.forEach(column => {
                if (item.hasOwnProperty(column.value)) {
                    const propertyName = column.viewValue || column.value; // Use viewValue if available, else use value
                    filteredItem[propertyName] = item[column.value];
                }
            });
            return filteredItem;
        });
    }



    ngOnDestroy() {
        this._subsciption.unsubscribe();
        this._subsciption2.unsubscribe();
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
}
