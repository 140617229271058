<ng-container *ngIf="pageStatus?.isLoading; else loaded">
  <div class="fx-col fx-center" [style.margin-top.px]="50">
    <span class="loading-message">
      <div class="loading-spinner-1" style="width:90px;">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
        <div class="bounce4"></div>
      </div>
    </span>
  </div>
</ng-container>

<ng-template #loaded>

  <ng-container *ngIf="userProfile; else noContent">

    <div class="page-container flex-centered">
      <div class="m-content-container">

        <div class="user-view-avatar-container">

          <div class="usr-avatar-68 clickable" style="position: relative;" (click)="changeProfilePicture()">
            <div class="edit-me-on-hover">
              <p style="text-align: center;padding-top: 26px;color:white;font-size: inherit;">Change</p>
            </div>
            <img appGravatar [firstname]="userProfile?.first_name" [lastname]="userProfile?.last_name"
              [email]="userProfile?.email">
          </div>

          <div class="content-page-title-wrapper">
            <h1 (click)="copyInputToClipboard(userProfile?.id)">{{userProfile?.id}}</h1>
            <h2 style="margin-bottom:0px;">{{userProfile?.first_name}} {{userProfile?.last_name}}</h2>
          </div>

        </div>

        <div class="content-body">

          <div class="fx-width-100">

            <div class="m-content-body-wrapper">

              <ng-container *ngIf="!this.pageStatus.isLoading && !this.pageStatus.noContent; else loading">

                <ng-container *ngIf="pageStatus.activeTab === 'overview'">
                  <app-user-profile-tabs-overview [organisation_id]="organisation_id" [userProfile]="userProfile"
                    [user_id]="userProfile?.id" [viewAs]="viewAs" [organisationProfile]="organisationProfile"
                    (updateParent)="refreshData($event)"></app-user-profile-tabs-overview>
                </ng-container>

                <!-- <ng-container *ngIf="pageStatus.activeTab === 'usage'">
                  <app-user-profile-tabs-usage [organisation_id]="organisation_id" [userProfile]="userProfile"
                    [user_id]="userProfile?.id" [viewAs]="viewAs" [organisationProfile]="organisationProfile"
                    (updateParent)="refreshData($event)"></app-user-profile-tabs-usage>
                </ng-container> -->
                <!-- 
                <ng-container *ngIf="pageStatus.activeTab === 'system'">
                  <app-user-profile-tabs-system [organisation_id]="organisation_id" [userProfile]="userProfile"
                    [user_id]="userProfile?.id" [viewAs]="viewAs" [organisationProfile]="organisationProfile"
                    (updateParent)="refreshData($event)" (updateParentModal)="updateParentEvent($event)">
                  </app-user-profile-tabs-system>
                </ng-container> -->

              </ng-container>




            </div>
          </div>

        </div>

      </div>

    </div>



  </ng-container>

  <ng-template #noContent>
    <div>
      could not get content
    </div>

  </ng-template>


</ng-template>