import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


//modules (imports)
import { MaterialModule } from '@app/material/material.module';
import { CustomPipesModule } from '@app/pipes/pipes.module'
import { FormBasicControlsModule} from '@app/components/toolbar/form-basic-controls/form-basic-controls.module';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';


//tabs
import { UserProfileTabOverviewModule } from '@app/components/tabs/user-profile-overview/user-profile-overview.module';
import { UserProfileTabSystemModule } from '@app/components/tabs/user-profile-system/user-profile-system.module';
import { UserAdminControlsModule } from '@app/components/admin-controls/user-admin-controls/user-admin-controls.module';

//services
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { AppTitleService } from '@app/services/helpers/update-title.service'
import { UserService } from '@app/services/pipcall/user.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';

//declarations
import { UserProfileBottomSheetComponent } from './userprofile-bsheet-modal.component';
import { UpdateUserProfilePictureModalModule} from '@app/components/modals/update-userprofile-picture-modal/update-userprofile-picture-modal.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UserProfileTabSystemModule,
        UserProfileTabOverviewModule,
        RouterModule,
        ReactiveFormsModule,
        CustomPipesModule,
        MaterialModule,
        FormBasicControlsModule,
        UserAdminControlsModule,
        GravatarDirectiveModule,
        UpdateUserProfilePictureModalModule
    ],
    declarations: [UserProfileBottomSheetComponent],
    providers: [HttpProxy, AppInsightService, AppTitleService, UserService, OrganisationService],
    exports: [UserProfileBottomSheetComponent],
    entryComponents: []
})
export class UserProfileBottomSheetModule {
}
