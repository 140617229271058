
//core requirements
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


//modules - core
import { GravatarDirectiveModule} from '@app/directives/gravatar/gravatar.module';
import { MaterialModule } from '@app/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomPipesModule } from '@app/pipes/pipes.module';
import { NgxEditorModule } from 'ngx-editor';

//services
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { HelpCenterContentService, FAQS } from '@app/services/help-center-content.service';
import { ModalService } from '@app/services/modal-service/modal.service';
import { SharedService } from '@app/services/shared-data.service/shared-data.service';

//declare components
import { HelpCenterRightMenuComponent } from './help-center-right-menu.component';
import { HelpCenterSearchModule } from '@app/components/help-center-search/help-center-search.module';
import { ContentService } from '@app/services/pipcall/content.service';

import { HelpEditPaneluModule} from '@app/components/hc-edit-panel/hc-edit-panel.module';
@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        FlexLayoutModule,
        GravatarDirectiveModule,
        CustomPipesModule,
        HelpCenterSearchModule,
        NgxEditorModule,
        HelpEditPaneluModule
    ],
    declarations: [HelpCenterRightMenuComponent],
    exports: [HelpCenterRightMenuComponent],
    providers: [HttpProxy, AppInsightService, HelpCenterContentService, ContentService, ModalService, SharedService],
    entryComponents: [HelpCenterContentService]
})
export class HelpCenterRightMenuModule { }
