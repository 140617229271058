
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MAT_DATE_LOCALE } from '@app/material/material-essentials.module';
//requirements
import {MatNativeDateModule, } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';


import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module'

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { StaticDataService } from '@app/services/shared-data.service/staticdata.sevice';
import { DirectoryService } from '@app/services/pipcall/directory.service';
import { OrgDirectoriesPanelComponent } from './org-directories-panel.component'

//modules
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';
// import { HelpCenterRightMenuModule } from '@app/components/right-menu-overlay/help-center-right-menu/help-center-right-menu.module';
import { SideNavRightModule } from '@app/template/sidenav-right/sidenav-right.module';
@NgModule({
    imports: [
        CommonModule,
        MatNativeDateModule,
        MatDatepickerModule,
        FormsModule,
        SideNavRightModule,
        ReactiveFormsModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        GravatarDirectiveModule,
        UniversalComponentsModule
    ],
    declarations: [OrgDirectoriesPanelComponent],
    exports: [OrgDirectoriesPanelComponent],
    providers: [AppInsightService, OrganisationService, StaticDataService, DirectoryService],
    entryComponents: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OrgDirectoriesPanelModule { }
