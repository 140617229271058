import { NgModule } from '@angular/core';


import { NormalizeToInternational, NormalizeTo00 } from './formatNumber';
import { FormatDate } from './formatDate';
import { KeysPipe } from './keys';
import { ReturnFirst } from './returnFirst';
import { RemoveSpaces } from './removeSpaces.pipe';
import { MinuteSecondsPipe, SecondsToHHMMSSPipe } from './minuteSeconds.pipe';
import { DateSuffix } from './dateSuffix.pipe';
import { DateTimeAgo } from './timeago.pipe';
import { InvoiceDueDays } from './invoiceDueDays.pipe';
import { SafeHtmlPipe } from './safeHtml.pipe';
import { ByteFormatPipe, BytesToGbPipe, GbToBytesPipe } from './bytesConvert.pipe';
import { EpochToDatePipe } from './epochToDate.pipe';

@NgModule({
    imports: [],
    declarations: [EpochToDatePipe, BytesToGbPipe, GbToBytesPipe, ByteFormatPipe, NormalizeToInternational, NormalizeTo00, FormatDate, KeysPipe, ReturnFirst, RemoveSpaces, MinuteSecondsPipe, SecondsToHHMMSSPipe, DateSuffix, DateTimeAgo, InvoiceDueDays, SafeHtmlPipe],
    exports: [EpochToDatePipe, BytesToGbPipe, GbToBytesPipe, ByteFormatPipe, NormalizeToInternational, NormalizeTo00, FormatDate, KeysPipe, ReturnFirst, RemoveSpaces, MinuteSecondsPipe, SecondsToHHMMSSPipe, DateSuffix, DateTimeAgo, InvoiceDueDays, SafeHtmlPipe],
    providers: [NormalizeToInternational, NormalizeTo00, GbToBytesPipe, BytesToGbPipe, EpochToDatePipe]
})
export class CustomPipesModule { }
