<div class="modal-basic-wrapper">
  <button class="modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>

  <ng-container *ngIf="pageStatus.isLoading || !esim; else displayContent">
    <div class="loading-wrapper">Loading...<div class="loader-spinner"></div>
    </div>
  </ng-container>

  <ng-template #displayContent>

    <!-- <div style="display: flex; flex-direction: row; gap:4px;" *ngIf="isDev">
      <button [matMenuTriggerFor]="debugMenu">Debug</button>
    </div> -->

    <div class="data-esim-modal-title" *ngIf="esim && !pageStatus.isLoading">
      <div class="data-esim-modal-title-1">
        <div class="flag-container" *ngIf="flag">
          <img class="country-flag md"
            [src]="'https://pipcallportalstorage.blob.core.windows.net/flags-of-the-world/svg/' + flag + '.svg'"
            (error)="($event.target.src = 'https://pipcallportalstorage.blob.core.windows.net/flags-of-the-world/svg/missing.svg')"
            [alt]="'Flag of ' + esim?.license_name" />
        </div>
        <h1 class="title-font" [matTooltip]="esim?.license_name">{{esim?.license_name}}</h1>
      </div>
      <div class="data-esim-modal-title-2">{{esim?.state}} <mat-icon svgIcon="info" class="icon-size-22"
          [matTooltip]="getTooltipStatusText()"></mat-icon>
      </div>
    </div>

    <div class="modal-title-row-section">
      <div class="title-font esim-content-title title-font title-color" (click)="copyInputToClipboard(esim.id)">eSIM
        <span
          *ngIf="(esim?.state === 'ACTIVE') && (esim?.data_usage_remaining !== esim?.data_allowance | gbToBytes)">Data
          used</span></div>


      <ng-container *ngIf="esim && !pageStatus.isLoading">

        <ng-container *ngIf="esim?.state === 'EXPIRED'">
          <div style="color: var(--c-error); text-transform: uppercase;">{{esim?.contract_name}} NOW EXPIRED</div>
        </ng-container>

        <ng-container *ngIf="esim?.state === 'READY' || esim?.state === 'PURCHASED' || esim?.state === 'PENDING'">
          <div class="data-allowance"><span class="data-value">{{esim?.data_allowance}}</span>&nbsp;GB</div>
        </ng-container>

        <ng-container *ngIf="esim?.state === 'INSTALLED'">
            <div class="data-allowance"><span class="data-value">{{esim?.data_allowance}}</span>&nbsp;GB for <span
                class="data-value">{{esim?.duration_days}}</span> days</div>
        </ng-container>

        <ng-container *ngIf="esim?.state === 'ACTIVE'">
          <div class="data-used">
            <div class="data-value-container fx-row">
              <div><span
                  [innerHTML]="getDataUsed(esim?.data_usage_remaining, esim?.data_allowance | gbToBytes) | byteFormat"></span>
              </div>
              <div><span>&nbsp;/&nbsp;{{esim?.data_allowance}}</span>&nbsp;<span style="font-size: 0.8em;">GB</span>
              </div>
            </div>
            <div
              *ngIf="esim?.state === 'ACTIVE' || (esim?.data_usage_remaining !== esim?.data_allowance | gbToBytes)">
              <mat-progress-bar color="primary" mode="determinate" [value]="dataPercentageUsed">
              </mat-progress-bar>
            </div>
          </div>
        </ng-container>
    
              <!-- <ng-template #dataInUse>
                <div class="data-used">
                  <div class="data-value-container fx-row">
                    <div><span
                        [innerHTML]="getDataUsed(esim?.data_usage_remaining, esim?.data_allowance | gbToBytes) | byteFormat"></span>
                    </div>
                    <div><span>&nbsp;/&nbsp;{{esim?.data_allowance}}</span>&nbsp;<span style="font-size: 0.8em;">GB</span>
                    </div>
                  </div>
                  <div
                    *ngIf="esim?.state === 'ACTIVE' || (esim?.data_usage_remaining !== esim?.data_allowance | gbToBytes)">
                    <mat-progress-bar color="primary" mode="determinate" [value]="dataPercentageUsed">
                    </mat-progress-bar>
                  </div>
                </div>
              </ng-template> -->

      </ng-container>


    </div>

    <ng-container *ngIf="esim && esim?.state === 'PURCHASED' || esim?.state === 'PENDING'">
      <ng-template [ngTemplateOutlet]="displayValue"
        [ngTemplateOutletContext]="{label: 'Order status', value: 'Please wait while we are setting up your eSIM. It should be available soon.'}"></ng-template>
    </ng-container>

    <ng-container *ngIf="(esim && esim?.state !== 'PURCHASED' && esim?.state !== 'PENDING') && !showQR">
      <div class="display-value-wrapper" [ngClass]="{'multiple-destinations': esim.countries?.length > 1}">
        <!-- make margin conditional based on length of countries? -->
        <div class="display-value-label">Supported Countries</div>
        <div class="display-value-content">
          <ng-container *ngIf="!pageStatus.isLoading">
            <span *ngFor="let country of esim.countries; let i = index;">
              {{country?.name}}<span *ngIf="esim.countries.length > 1 && i !== esim.countries.length-1">,</span>
            </span>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <div class="data-esim-main-content">



      <div class="data-esim-main-content-1" *ngIf="!showQR">

        <ng-container
          *ngIf="esim?.state === 'PURCHASED' || esim?.state === 'PENDING' || esim?.state === 'READY' || esim?.state === 'INSTALLED'">
          <ng-template [ngTemplateOutlet]="displayValue"
            [ngTemplateOutletContext]="{label: 'Time allowance', value: esim?.duration_days + ' days'}"></ng-template>
        </ng-container>

        <ng-container *ngIf="esim?.state === 'ACTIVE'">
          <ng-template [ngTemplateOutlet]="displayValue"
            [ngTemplateOutletContext]="{label: 'Time remaining', value: getRemainingTime(esim.expires_at)}"></ng-template>
        </ng-container>

        <ng-container
          *ngIf="esim?.state === 'READY' || esim?.state === 'INSTALLED' || esim?.state === 'EXPIRED' || esim?.state === 'ACTIVE'">
          <ng-template [ngTemplateOutlet]="displayValue"
            [ngTemplateOutletContext]="{label: 'Expires', value:esim?.expires_at | date: 'yyyy-MM-dd HH:mm'}"></ng-template>
        </ng-container>

        <ng-template *ngIf="esim" [ngTemplateOutlet]="displayValue"
          [ngTemplateOutletContext]="{label: 'Purchased', value:esim?.purchased_at | date: 'yyyy-MM-dd HH:mm'}"></ng-template>

        <div class="display-value-wrapper"
          *ngIf="esim?.state === 'ACTIVE' || esim?.state === 'INSTALLED' || esim?.state === 'READY' ">
          <div class="display-value-label fx-row fx-align-center" style="gap: 4px;">
            <div>Reuse</div> <mat-icon matTooltip="eSIM can only be reinstalled to the same device." svgIcon="info"
              class="icon-size-18"></mat-icon>
          </div>
          <div class="display-value-content">
            {{esim?.reuse_remaining}} of {{esim?.reuse_max}}
          </div>
        </div>


        <ng-container *ngIf="esim?.state === 'EXPIRED' && esim.user_email">

          <ng-template [ngTemplateOutlet]="displayValue"
            [ngTemplateOutletContext]="{label: 'User', value: esim?.user_email}"></ng-template>
        </ng-container>

      </div>

      <!-- SEND QR CODE FIRST TIME. NO EMAIL EXISTS. STATE IS READY -->
      <ng-container
        *ngIf="esim && !pageStatus.isLoading && (esim.state === 'READY' || esim.state === 'INSTALLED'  || esim.state === 'ACTIVE')">

        <div class="data-esim-main-content-2">
          <div class="display-value-wrapper">
            <div class="display-value-label" style="display: flex;align-content: center;gap: 4px;" *ngIf="!showQR">
              <!-- ngswitch -->
              <ng-container [ngSwitch]="esim?.state">
                <ng-container *ngSwitchCase="'READY'">
                  <span class="fx-row" *ngIf="!esim.user_email">Send QR code&nbsp;<mat-icon
                      [matTooltip]="getSimInstallationTooltip()" svgIcon="info" class="icon-size-18"></mat-icon></span>
                  <span class="fx-row" *ngIf="esim.user_email">Activation code&nbsp;<mat-icon
                      [matTooltip]="getSimInstallationTooltip()" svgIcon="info" class="icon-size-18"></mat-icon></span>
                </ng-container>

                <ng-container *ngSwitchCase="'INSTALLED'">Installed&nbsp;<mat-icon
                    [matTooltip]="getSimInstallationTooltip()" svgIcon="info"
                    class="icon-size-18"></mat-icon></ng-container>
                <ng-container *ngSwitchCase="'ACTIVE'">Installed&nbsp;<mat-icon
                    [matTooltip]="getSimInstallationTooltip()" svgIcon="info"
                    class="icon-size-18"></mat-icon></ng-container>
              </ng-container>
            </div>

            <div class="display-value-content" *ngIf="!showQR">
              <ng-container [ngSwitch]="esim?.state">
                <ng-container *ngSwitchCase="'READY'">
                  <span *ngIf="!esim.user_email">Ready to send activation Instructions and QR code to user.</span>
                  <span *ngIf="esim.user_email">Instructions to activate the eSIM and a QR code has been sent to
                    user.</span>
                </ng-container>
                <ng-container *ngSwitchCase="'INSTALLED'">The user has installed the eSIM.</ng-container>
                <ng-container *ngSwitchCase="'ACTIVE'">The user has installed the eSIM.</ng-container>

              </ng-container>

            </div>

            <div class="qr-code-container fx-col" *ngIf="showQR">
              <div class="qr-code-img-wrapper">
                <img [src]="esimBaseUrl+ 'qrcode-' + esim?.id + '.png'">
              </div>
              <div style="font-size: 10px;margin-bottom: 6px;">{{esim?.sim_profile}}</div>
              <div (click)="showQR = !showQR" class="hide-qr-btn">Hide QR code</div>
            </div>

            <div *ngIf="(esim?.state === 'ACTIVE' || esim?.state === 'INSTALLED' || esim?.user_email) && !showQR"
              class="install-instructions" [ngClass]="{'margin-bottom-16': !esim.user_email}"
              (click)="showQR = !showQR">View QR code</div>

            <form [formGroup]="emailForm"
              [hidden]="((esim.state === 'INSTALLED' || esim.state === 'ACTIVE') && !esim.user_email) || showQR">
              <mat-form-field appearance="outline" class="custom-form-field-v3 no-form-label border-radius-8"
                style="width: 100%; font-size: 16px;"
                [ngClass]="{'readonly-block': esim?.state === 'ACTIVE' || esim?.state === 'INSTALLED'}">
                <input formControlName="email" autocomplete="off" matInput placeholder="Enter email..."
                  aria-label="email address" maxlength="150" tabindex="1">

                <button (click)="onSendOrResend()" [disabled]="resendTimer"
                  *ngIf="!esim.user_email && esim?.state !== 'ACTIVE' && esim?.state !== 'INSTALLED'" mat-button
                  matSuffix class="send-btn" aria-label="send QR code by email">Send</button>

                <button (click)="onSendOrResend()" [disabled]="resendTimer" *ngIf="esim.user_email" mat-button matSuffix
                  class="resend-btn" aria-label="send QR code by email">
                  <ng-container *ngIf="esim.user_email === emailForm.value.email">Resend</ng-container>
                  <ng-container *ngIf="esim.user_email !== emailForm.value.email">Send</ng-container>
                </button>

                <!-- <mat-error *ngIf="emailForm?.controls.email?.touched && emailForm?.controls.email?.errors?.required">Required Field</mat-error> -->
                <mat-error *ngIf="emailForm?.controls.email?.touched && emailForm?.controls.email?.errors?.email">Not a
                  valid email</mat-error>
                <mat-hint align="end" *ngIf="resendTimer">Resend code in {{ resendTimer }} seconds</mat-hint>
              </mat-form-field>
            </form>

            <div class="esim-euicc-profile-state">
              <ng-container
                *ngIf="esim.telna_state === 'NOT_ACTIVE' &&  (esim.euicc_profile_state === 'INSTALLED' || esim.euicc_profile_state === 'ENABLED' || esim.euicc_profile_state === 'DISABLED')">
                <div class="profile-status enabled"><mat-icon svgIcon="travel"></mat-icon>&nbsp;Ready to fly, plan will
                  activate at destination.</div>
              </ng-container>

              <ng-container
                *ngIf="esim.telna_state === 'ACTIVE' &&  (esim.euicc_profile_state === 'INSTALLED' || esim.euicc_profile_state === 'ENABLED' || esim.euicc_profile_state === 'DISABLED')">
                <div class="profile-status enabled"><mat-icon svgIcon="check_circle_fill"></mat-icon>&nbsp;Plan is
                  active</div>
              </ng-container>

              <ng-container *ngIf="esim.euicc_profile_state === 'ENABLED'">
                <div class="profile-status enabled"><mat-icon svgIcon="check_circle_fill"></mat-icon>&nbsp;Is enabled on
                  device</div>
              </ng-container>

              <ng-container *ngIf="esim.euicc_profile_state === 'DISABLED'">
                <div class="profile-status disabled"><mat-icon svgIcon="clear_filled"></mat-icon>&nbsp;Is disabled on
                  device</div>
              </ng-container>

              <ng-container *ngIf="esim.euicc_profile_state === 'UNAVAILABLE'">
                <div class="profile-status disabled"><mat-icon svgIcon="clear_filled"></mat-icon>&nbsp;eSIM is no longer
                  available</div>
              </ng-container>

              <ng-container *ngIf="esim.euicc_profile_state === 'ERROR'">
                <div class="profile-status disabled"><mat-icon svgIcon="clear_filled"></mat-icon>&nbsp;Has an error
                </div>
              </ng-container>

              <ng-container *ngIf="esim.euicc_profile_state === 'DELETED'">
                <div class="profile-status disabled"><mat-icon svgIcon="clear_filled"></mat-icon>&nbsp;Deleted from
                  device</div>
              </ng-container>

            </div>
          </div>
        </div>
      </ng-container>



    </div>


    <ng-container *ngIf="esim && !pageStatus.isLoading">
      <div class="data-esim-footer-content">
        <ng-container *ngIf="esim?.state !== 'PURCHASED' && esim?.state !== 'PENDING' ">

          <div class="note-saving-indicator">
            <ng-container *ngIf="pageStatus.note.isSaving">Saving...<div class="loader-spinner"></div></ng-container>
            <ng-container *ngIf="!pageStatus.note.isSaving && pageStatus.note.isSaved">Saved!</ng-container>
            <ng-container *ngIf="!pageStatus.note.isSaving && pageStatus.note.isSaveError">There was an error trying to
              save
              note</ng-container>
          </div>
          <mat-form-field floatLabel="always" appearance="outline" class="custom-form-field-v3 inner-shadow"
            style="width: 100%;" [ngClass]="{'readonly-block': pageStatus.isLoading}">
            <textarea #noteTextarea [formControl]="userNoteInputCtrl" type="text" matInput rows="2"
              placeholder="Add a note..."></textarea>
          </mat-form-field>

        </ng-container>


        <div style="text-align: center;">

          <!-- <a *ngIf="esim?.state !== 'EXPIRED'" href="https://pipcall.com/solution/esim/how-it-works" target="_blank"
            matTooltip="See how it works">See Help</a> -->
            
          <!-- //replace with a video -->

          <!-- <button *ngIf="esim?.state === 'EXPIRED'" mat-button class="error-btn"
            matTooltip="Delete the esim from your account">DELETE THIS ESIM</button> -->
        </div>

      </div>
    </ng-container>

  </ng-template>
</div>


<ng-template #displayValue let-label="label" let-value="value">
  <div class="display-value-wrapper">
    <div class="display-value-label">{{label}}</div>
    <div class="display-value-content">
      {{value}}
    </div>
  </div>
</ng-template>


<mat-menu #debugMenu="matMenu" class="v3-mat-menu" xPosition="before">
  <button mat-menu-item [matMenuTriggerFor]="esim_state">esim_state</button>
  <button mat-menu-item [matMenuTriggerFor]="telna_state">telna_state</button>
  <button mat-menu-item [matMenuTriggerFor]="euicc_profile_state">euicc_profile_state</button>
  <button mat-menu-item [matMenuTriggerFor]="userEmail">user email</button>
</mat-menu>

<mat-menu #esim_state="matMenu" class="v3-mat-menu" xPosition="before">
  <button mat-menu-item (click)="esim.state = 'PURCHASED'">PURCHASED</button>
  <button mat-menu-item (click)="esim.state = 'PENDING'">PENDING</button>
  <button mat-menu-item (click)="esim.state = 'READY'">READY</button>
  <button mat-menu-item (click)="esim.state = 'INSTALLED'">INSTALLED</button>
  <button mat-menu-item (click)="esim.state = 'ACTIVE'">ACTIVE</button>
  <button mat-menu-item (click)="esim.state = 'EXPIRED'">EXPIRED</button>
</mat-menu>

<mat-menu #telna_state="matMenu" class="v3-mat-menu" xPosition="before">
  <button mat-menu-item (click)="esim.telna_state = 'NOT_ACTIVE'">NOT_ACTIVE</button>
  <button mat-menu-item (click)="esim.telna_state = 'ACTIVE'">ACTIVE</button>
  <button mat-menu-item (click)="esim.telna_state = 'TERMINATED'">TEMINATED</button>
</mat-menu>

<mat-menu #euicc_profile_state="matMenu" class="v3-mat-menu" xPosition="before">
  <button mat-menu-item (click)="esim.euicc_profile_state = 'RELEASED'">RELEASED</button>
  <button mat-menu-item (click)="esim.euicc_profile_state = 'DOWNLOADED'">DOWNLOADED</button>
  <button mat-menu-item (click)="esim.euicc_profile_state = 'INSTALLED'">INSTALLED</button>
  <button mat-menu-item (click)="esim.euicc_profile_state = 'ERROR'">ERROR</button>
  <button mat-menu-item (click)="esim.euicc_profile_state = 'UNAVAILABLE'">UNAVAILABLE</button>
  <button mat-menu-item (click)="esim.euicc_profile_state = 'ENABLED'">ENABLED</button>
  <button mat-menu-item (click)="esim.euicc_profile_state = 'DISABLED'">DISABLED</button>
  <button mat-menu-item (click)="esim.euicc_profile_state = 'DELETED'">DELETED</button>
</mat-menu>

<mat-menu #userEmail="matMenu" class="v3-mat-menu" xPosition="before">
  <button mat-menu-item (click)="esim.user_email = 'test@test.com'">EMAIL EXISTS</button>
  <button mat-menu-item (click)="esim.user_email = ''">NO EMAIL</button>
</mat-menu>