<div class="bottom-sheet-content-container">

  <div class="close-button-wrapper">
    <button class="bs-toolbar-close-btn" mat-flat-button color="primary" (click)="dismiss()" mat-button>Close</button>
  </div>

  <div class="page-container">
    <div class="m-content-container bottom-sheet-container">

      <div class="content-page-title-wrapper">
        <h1>Licence</h1>
        <h2>
          {{licence?.license_name}} <span style="font-size: 0.7em;">
            <span *ngIf="licence?.licensed_user_type === 'User'">({{licence?.first_name}} {{licence?.last_name}})</span>
            <span *ngIf="licence?.licensed_user_type === 'Invitation'">({{licence?.email}})</span>
          </span>
        </h2>
      </div>


      <div class="content-body">

        <div class="fx-width-100">
          <div class="tabs-header"  *ngIf="viewAs === 'sysAdmin'">

            <div *ngIf="viewAs === 'sysAdmin'" [ngClass]="{'active':  pageStatus.activeTab === 'system' }" (click)="pageStatus.activeTab = 'system'">System</div>

            <div [ngClass]="{'active':  pageStatus.activeTab ==='overview' }" (click)="pageStatus.activeTab = 'overview'">Overview</div>


          </div>


          <div class="m-content-body-wrapper">
            
            <ng-container *ngIf="!this.pageStatus.isLoading && !this.pageStatus.noContent; else loading">

              <ng-container *ngIf="pageStatus.activeTab === 'overview'">
                <app-licence-details-tabs-overview  [organisationProfile]="organisationProfile" [viewAs]="viewAs" [licence]="licence" (updateParentModal)="updateParentEvent($event)"  (updateParent)="refreshData($event)"></app-licence-details-tabs-overview>
              </ng-container>

              <ng-container *ngIf="pageStatus.activeTab === 'system'">
                <app-licence-details-tabs-system  *ngIf="viewAs === 'sysAdmin'"[organisationProfile]="organisationProfile" [viewAs]="viewAs" [licence]="licence" (updateParentModal)="updateParentEvent($event)"  (updateParent)="refreshData($event)"></app-licence-details-tabs-system>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="!this.pageStatus.isLoading && this.pageStatus.noContent">
              No user found
            </ng-container>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>

<ng-template #loading>
  <div class="fx-col fx-center">
    <div class="loading-spinner-1" style="width:90px;margin-top:20px;">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
      <div class="bounce4"></div>
    </div>
  </div>
</ng-template>
