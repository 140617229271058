//core
import { Component, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable, MatSnackBar, MatDialog } from '@app/material/material-essentials.module';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { switchMap, debounceTime, tap, finalize, takeUntil, map, filter } from 'rxjs/operators';

import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray } from '@angular/forms';
import { Subject, Subscription, Observable, throwError } from "rxjs";

import { UserProfile } from '@app/models/user-profile.model';
import { UserService } from '@app/services/pipcall/user.service';
import { UserDetailUpdate } from '@app/models/user-profile.model';

import { ValidateMobileNotTaken, ValidateBusinessNumberNotMobile } from '@app/validators/async-mobile.validator';
import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';
import { SignupService } from '@app/services/pipcall/signup.service';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { InviteUserModalComponentpipxt } from '@app/components/modals/invite-user-modal-pipxt/invite-user-modal.component'
import { LicenseModel, Licensedata } from '@app/models/license.model';
import { PaymentsService, } from '@app/services/pipcall/payments.service';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { NumberService} from '@app/services/pipcall/number.service';
import { DdiNumber } from '@app/models/ddi.model';

import { NormalizeTo00 } from '@app/pipes/formatNumber';
import { PbxPlatform, Providers } from '@app/models/ddi.model';
import { StaticDataService } from '@app/services/shared-data.service/staticdata.sevice';
import { OrgUserSearchSelectComponent } from '@app/components/form-field/ff-org-user-search-select/ff-org-user-search-select.component';

import { ModalService } from '@app/services/modal-service/modal.service';
import { ModalConfirmData } from '@app/services/modal-service/confirm/confirm.component';
import { AllocateDdiToOrganisationModalComponent } from '@app/components/modals/allocate-ddi-to-organisation-modal/allocate-ddi-to-organisation-modal.component';
import { DdiAllocationRequest, DdiProvisioningRequest, PiPxtUpdate, DdiUpdate, SipProvisioningRequest } from '@app/models/ddi.model';
import { PBXHostListModel, TenantListModel, PBXService } from '@app/services/pipcall/pbx.service';
import { interval, combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'tab-ddi-system-system',
    templateUrl: './ddi-system.component.html',
    styleUrls: ['./ddi-system.component.scss'],
    animations: [
        fadeInOnEnterAnimation(),
        fadeOutOnLeaveAnimation()
    ]
})
export class DDISystemComponent {


    @Input() viewAs: string;
    @Input() number: DdiNumber;
    @Input() organisation_id: string; //required
    @Input() number_id: string;
    @Input() availableUserList: UserProfile[];

    @Output() updateParent = new EventEmitter();
    @Output() closeParentModal = new EventEmitter();

    noContent = false;
    _destroy$ = new Subject<void>();
    public sharedDataSubscription: Subscription;

    pageStatus = {
        isSubmitting: false,
        isLoading: true,
        isLoadingLicence: true
    }

    providersList: Array<Providers>;
    pbxList: PbxPlatform[];

    //forms
    ddiUpdate: FormGroup;
    pipvariant: FormGroup
    extensionUpdate: FormGroup;

    pbxHostList: PBXHostListModel[] = [];
    pbxHostListLoaded = false;
    @ViewChild(OrgUserSearchSelectComponent) orgUserSearchSelectComponent: OrgUserSearchSelectComponent;

    showLegacyForm = false;


    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        private fb: FormBuilder,
        private organisationService: OrganisationService,
        private userService: UserService,
        private signupService: SignupService,
        private normalizeNumberService: NormalizeNumberService,
        private numberService: NumberService,
        private ref: ChangeDetectorRef,
        private paymentsService: PaymentsService,
        private licenseDataService: LicenseDataService,
        private normalizeTo00: NormalizeTo00,
        public staticDataService: StaticDataService,
        private modalService: ModalService,
        private pbxService: PBXService
    ) {
        this.providersList = new Array<Providers>();
        this.pbxList = new Array<PbxPlatform>();


        const regex = new RegExp('^[a-zA-Z0-9\ \-]+$');

        this.ddiUpdate = this.fb.group({
            pbx_platform_id: ['', Validators.required],
            provider: ['', Validators.required],
            is_reserved: [false, Validators.required],
            is_blocked: [false, Validators.required],
            is_sms_enabled: [false, Validators.required],
            no_answer: [''],
            cli_override: ['', Validators.minLength(14)],
            notes: [''],
            label: [''],
        });

        this.pipvariant = this.fb.group({
            service_id: ['', Validators.required],
            pip_variant: ['', Validators.required]
        });

        this.extensionUpdate = this.fb.group({
            is_pipxt_extensionless: [null]
        });



    }

    ngOnChanges(changes: SimpleChanges) {
        (changes['number']?.currentValue !== changes['number']?.previousValue) &&  !changes['number']?.isFirstChange() ?  this.setInitialFormValues(this.number) : console.log("no number provided");
    }


    ngOnInit() {
        this.staticDataService.innit();
        this.getStaticData();

        if (this.number) {
            this.setInitialFormValues(this.number);
            this.ref.detectChanges();
        }

        if ( !this.number ) {
            this.getDetails();
        }

    }




    formatCliNumber(event) {
        const number = event;
        if (number?.length > 10) {
            const formated = this.normalizeTo00.transform(number, 'uk44');
            this.ddiUpdate.controls['cli_override'].patchValue(formated, { emitEvent: false });
        }
    }

    getStaticData() {
        this.staticDataService?.pbxHostListAsObservable.subscribe((pbxHostList) => {
            this.pbxHostList = pbxHostList;
            console.log("=============================pbxHostList", this.pbxHostList);
            this.pbxHostListLoaded = true;
        });
        this.staticDataService?.pbxListAsObservable.subscribe(pbxList => this.pbxList = pbxList);
        this.staticDataService?.providerListAsObservable.subscribe(providersList => this.providersList = providersList);
        console.log("providersList", this.providersList);

        if (this.pbxList == null || this.providersList == null || this.pbxHostList) { this.staticDataService.innit(); }
        this.ref.detectChanges();
    }

    setInitialFormValues(number: DdiNumber) {
        //name form
        this.setFormValue(this.ddiUpdate, 'pbx_platform_id', number.pbx_platform_id);
        this.setFormValue(this.ddiUpdate, 'provider', number.provider);
        this.setFormValue(this.ddiUpdate, 'is_blocked', number.is_blocked);
        this.setFormValue(this.ddiUpdate, 'is_reserved', number.is_reserved);
        this.setFormValue(this.ddiUpdate, 'is_sms_enabled', number.is_sms_enabled);
        this.setFormValue(this.ddiUpdate, 'no_answer', number.no_answer);
        this.setFormValue(this.ddiUpdate, 'cli_override', number.cli_override);
        this.setFormValue(this.ddiUpdate, 'notes', number.notes);
        this.setFormValue(this.ddiUpdate, 'label', number.label);

        this.setFormValue(this.pipvariant, 'service_id', number.number);
        this.setFormValue(this.pipvariant, 'pip_variant', number.pip_variant);

        this.setFormValue(this.extensionUpdate, 'is_pipxt_extensionless', number?.is_pipxt_extensionless);
        this.pageStatus.isLoading = false;
        this.ref.detectChanges();
    }

    cancelForm() {
        this.ddiUpdate.reset();
        this.extensionUpdate.reset();
        this.setInitialFormValues(this.number);
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    getDetails() {
        //get profile if one was not passed
        console.log("GET DETAILS");
        this.pageStatus.isLoading = true;

        // this.numberService.getDdiById(this.number_id)
        this.numberService.getDdiById_v2(this.number_id)
            .pipe(
                finalize(() => {
                    this.pageStatus.isLoading = false;
                    this.ref.detectChanges(); }),
                takeUntil(this._destroy$)
            )
            .subscribe((resp) => {

                if (resp.status === 200 ) {
                    this.number = resp.body?.data[0];
                    this.setInitialFormValues(this.number);
                }
            },
            (err) => {
                console.log(err);
                this.openSnackBar(`An error occured getting user details [${err.status}] `, "dismiss");
            })

    }

    saveVariantChange(f: FormGroup) {


        const data: ModalConfirmData = {
            title: 'WARNING !',
            content: `You are about to manually change the variant. This will bypass any processes and checks in place, when using the prefered 'convert to extension' option. Proceed only if you know what you are doing.`,
            confirmButtonLabel: "Continue",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: true,
            customClass: 'red-button'
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.pageStatus.isSubmitting = true;

                this.numberService.patchVariant(f.value.service_id, f.value.pip_variant)
                    .pipe(
                        finalize(() => {
                            this.pageStatus.isSubmitting = false;
                        })
                    )
                    .subscribe((resp) => {
                        this.openSnackBar('Saved', "dismiss");
                        this.triggerUpdate();
                        this.ref.detectChanges();

                    }, (err) => {
                        this.openSnackBar(`There was an error [${err?.status}]`, "dismiss");
                        console.log(err); })

                return;
            }
            console.log('answer was No');
        });
    }

    organisation_deallocate() {
        //Treat as EXTENSION
        const data: ModalConfirmData = {
            title: 'Are you sure?',
            content: `This will unlink [${this.number?.sip_extension_number}] from the organisation [${this.number?.organisation_name}].`,
            confirmButtonLabel: "Unlink",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: true,
            customClass: 'red-button'
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.pageStatus.isSubmitting = true;

                this.numberService.deallocateDdiFromOrganisation(this.number?.number, this.number?.organisation_id)
                    .pipe(
                        finalize(() => {
                            this.pageStatus.isSubmitting = false;
                        })
                    )
                    .subscribe((resp) => {
                        this.openSnackBar('Saved', "dismiss");
                        this.triggerUpdate();
                        this.ref.detectChanges();

                    }, (err) => {
                        this.openSnackBar(`There was an error [${err?.status}]`, "dismiss");
                        console.log(err); })

                return;
            }
            console.log('answer was No');
        });

    }


    organisation_allocate(moveOrg?: boolean) {
        //use force to push through extension transfer
        const data = {number: this.number, moveOrg: moveOrg};

        const dialogRef = this.dialog.open(AllocateDdiToOrganisationModalComponent, {
            panelClass: 'pipcall-modal-container',
            data: data
            // width: '680px',
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log("result from select: ", result);
            if (result) {
                this.triggerUpdate();
            }
        });
    }

    //Organisation CONTROLS=======================end


    //USER CONTROLS=======================start

    openInvitePiPXTModal(number?: DdiNumber): void {
        number = number ? number : null;

        const data = {
            "organisation_id": number?.organisation_id, "preallocated_number": number
        };

        const dialogRef = this.dialog.open(InviteUserModalComponentpipxt, {
            panelClass: 'pipcall-modal-container',
            data
        });

        dialogRef.componentInstance.updateEmit.subscribe((resp) => {
            console.log("update emit");
            if (resp === true) {
                this.triggerUpdate();
                this.ref.detectChanges();
            }
        });


        dialogRef.afterClosed().subscribe(result => {
            console.log("INVITE CLOSE", result);
            if (result === true) {
                this.triggerUpdate();
                this.ref.detectChanges();
            }
        });
    }

    user_deallocate() {

        const data: ModalConfirmData = {
            title: 'Are you sure?',
            content: `This will unlink [${this.number?.number}] from the user [${this.number.user_full_name}].`,
            confirmButtonLabel: "Unlink",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: true,
            customClass: 'red-button'
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.pageStatus.isSubmitting = true;
                this.numberService.deallocateDdi(this.number?.number)
                    .pipe(
                        finalize(() => {
                            this.pageStatus.isSubmitting = false;
                        })
                    )
                    .subscribe((resp) => {
                        this.openSnackBar('Saved', "dismiss");
                        this.triggerUpdate();
                        this.ref.detectChanges();

                    }, (err) => {
                        this.openSnackBar(`There was an error [${err?.status}]`, "dismiss");
                        console.log(err); })

                return;
            }
            console.log('answer was No');
        });

    }


    //USER CONTROLS=======================end

    assignToUser(ddi: DdiNumber , user_id: string, name: string, organisation_id: string) {
        //add loading indicators for number status

        this.numberService.allocateDdi(
            ddi.number, user_id, organisation_id, name
        )
            .pipe(
                finalize(() => this.triggerUpdate())
            ).subscribe((resp) => {
                if (resp.status === 200 || resp.status === 201) {
                    const number = ddi?.pip_variant === 'pipxt' ? ddi?.sip_extension_number : ddi?.number;
                    this.openSnackBar(`${number} was linked to ${name}`, "dismiss");
                    // this.filteredList.splice(this.filteredList.findIndex(n => n.id === user_id ), 1);
                    this.triggerUpdate();
                }
            }, (err) => {
                this.openSnackBar('something went wrong', "dismiss");
                console.log(err) })

    }



    saveDDiUpdate(f: FormGroup) {
        console.log("SAVE ddi:", f.value);
        if (f.valid) {
            return this.submitForm(this.numberService.putDdiUpdate(this.number_id, f.value));
        }
    }

    // saveExtensionUpdate(f: FormGroup) {
    //     console.log("SAVE extension:", f);



    //     const updateForm: PiPxtUpdate = new PiPxtUpdate();
    //     updateForm.is_pipxt_extensionless = f.value.is_pipxt_extensionless;

    //     if (f.valid) {
    //         return this.submitForm(this.numberService.patchPipxtUpdateSysAdmin(this.number?.number, updateForm));
    //     }
    // }

    submitForm(form: Observable<any>) {
        this.pageStatus.isSubmitting = true;
        form
            .pipe(
                finalize(() => {
                    this.pageStatus.isSubmitting = false,
                    this.ref.detectChanges()
                }),
            )
            .subscribe((resp) => {
                console.log("success", resp);
                this.openSnackBar('Saved', 'dismiss');
                this.triggerUpdate();
            },
            (err) => {
                console.log(err);
                this.pageStatus.isSubmitting = false,
                this.openSnackBar(`An error occured [${err.status}]`, "dismiss");
            }
            );
    }
    propagateNumber(number: string) {
        this.pageStatus.isSubmitting = true;
        this.numberService.propagateNumber(number)
            .pipe(
                finalize(() => {
                    this.pageStatus.isSubmitting = false;
                    this.ref.detectChanges();
                }),
                takeUntil(this._destroy$)
            )
            .subscribe(
                (resp) => {
                    if (resp.status === 200 || resp.status === 201) {
                        this.openSnackBar(`${number} has been sent for propogation`, "dismiss");
                        setTimeout(() => {
                            this.triggerUpdate();
                        }, 16000);

                    }
                }, (err) => {
                    console.log(err);
                    this.openSnackBar("There was a problem with this request", "dismiss");
                }
            )
    }

    deleteNumberConfirm(row: DdiNumber) {
        const data: ModalConfirmData = {
            title: 'Are you sure?',
            content: 'This will remove the number permanently from PiPcall',
            confirmButtonLabel: "Delete",
            closeButtonLabel: "Cancel",
            choice: true,
            disableClose: false
        }

        this.modalService.openConfirmModal(data, (answer: boolean) => {
            if (answer) {
                this.numberService.deleteSingleDDi(row?.number)
                    .subscribe((resp) => {
                        this.openSnackBar(`${row?.number} has been deleted`, "Dismiss");
                        this.triggerUpdate();
                    }, (err) => {
                        this.openSnackBar("An unspecified error occured", "Dismiss");
                    })
            }
        });
    }

    copyInvitationUrl(id: string) {
        let urlString: string;
        if (window.location.hostname === 'localhost') {
            urlString = window.location.protocol + window.location.hostname + ':' + window.location.port + '/verify/invitation/' + id
        } else {
            urlString = window.location.protocol + window.location.hostname + '/verify/invitation/' + id
        }
        this.copyInputToClipboard(urlString);
    }

    copyInputToClipboard(value) {
    //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }

    triggerUpdate() {
        console.log("TRIGGER UPDATE");
        this.updateParent.emit(true);
    }

    refreshPage() {
        this.pageStatus.isLoading = true;
        this.triggerUpdate();
        this.ngOnInit();
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }
    ngOnDestroy() {
        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

}
