import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';
import { MdePopoverModule } from '@material-extended/mde';



//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { CookieService } from 'ngx-cookie-service'
import { InvitationService } from '@app/services/pipcall/invitation.service';
import { UserService } from '@app/services/pipcall/user.service';

//component
import { AdminUpgradeInvitationToPipxtComponent } from './admin-upgrade-invitation-to-pipxt.component';

//modules
import { VerifyMobileV3ModalModule } from '@app/components/modals/verify-mobile-v3-modal/verify-mobile-v3-modal.module';
import { TermsAndConditionsModalModule } from '@app/components/modals/terms-and-conditions-modal/terms-and-conditions-modal.module'
import { SelectPackageModalModule} from '@app/components/modals/select-package-modal/select-package-modal.module';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        VerifyMobileV3ModalModule,
        MdePopoverModule,
        TermsAndConditionsModalModule,
        SelectPackageModalModule
    ],
    declarations: [AdminUpgradeInvitationToPipxtComponent],
    exports: [AdminUpgradeInvitationToPipxtComponent],
    providers: [AppInsightService, OrganisationService, CookieService, InvitationService, UserService ],
    entryComponents: []
})
export class AdminUpgradeInvitationToPipxtModule { }
