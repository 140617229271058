
import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, Output, EventEmitter, Input, isDevMode, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
// import { SidenavService } from './sidenav.service';
import { sideNavMenuConfignew, SideNavItem } from './sidenav-menu-config';
import { AuthService } from '../../services/auth-service/auth.service';
import { trigger, transition, animate, style, useAnimation, state } from '@angular/animations'
import { SharedService } from '@app/services/shared-data.service/shared-data.service';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { OrganisationModel } from '@app/models/organisation.model';
import { OrganisationUser } from '@app/models/user-profile.model';
import { Subject } from "rxjs";
import { FirstPaymentModal } from '@app/components/modals/first-payment-modal/first-payment-modal.component';
import { MAT_DIALOG_DATA, MatDialog } from '@app/material/material-essentials.module';
import { DownloadLinksModalComponent } from '@app/components/modals/download-links-modal/download-links-modal.component';
import { UserProfile } from '@app/models/user-profile.model';
import { CookieService } from 'ngx-cookie-service';
import { map, debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { TermsAndConditionsComponent } from '@app/components/modals/terms-and-conditions-modal/terms-and-conditions-modal.component';
export class SideBarNavModel {
    label: string;
    matIcon?: string;
    svgIcon?: string;
    route: string;
    isStartsExpanded?: boolean;
    divider?: boolean;
    showIf?: string[];
    customIcon?: string;
}

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('fadeInOut', [
            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({ opacity: 1 })),

            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({ opacity: 0 }),
                animate(600)
            ]),
        ]),
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
            ])
        ]),
    ]
})
export class SidenavComponent implements OnInit {

    @Output() closeNavOnEvent = new EventEmitter<boolean>();
    @Input() isHandSet: boolean;

    _destroy$ = new Subject<void>();

    // public viewToggleValue:string = 'user';
    public activeRoute: string;
    // public navState: NavState;


    public sidebarMenu: Array<SideBarNavModel>; //to Do . create class/interface for sidebarmenu
    public sidebarMenuAdv: Array<SideBarNavModel>; //menu for admin etc
    public sidebarMenuOwner: Array<SideBarNavModel>;
    public eventsObs;
    public sideNavMenuConfig_: SideNavItem[];

    //Bottom Nav
    // myProfile: any = '';
    userMeta: any;
    checkoutEvent = false;
    public userProfile: UserProfile;
    public firstname: string;
    public fullname: string;
    public isSysAdmin: boolean;
    public myOrgList: OrganisationUser[];
    public viewAs: string; //how to view screen
    public activeOrg: OrganisationModel;
    public isMobile = false;
    public isDev: boolean;
    public trialDays: number;


    //submenu
    isExpanded = true;
    showSubmenu = false;
    isShowing = false;
    showSubSubMenu = false;

    //submenu usage
    isExpanded_usage = true;
    showSubmenu_usage = false;
    isShowing_usage = false;
    showSubSubMenu_usage = false;

    // pipxt = false;

    constructor(
    // private sidenavService: SidenavService,
        private route: Router,
        private changeDetectorRef: ChangeDetectorRef,
        public auth: AuthService,
        private sharedService: SharedService,
        public dialog: MatDialog,
        private licenseDataService: LicenseDataService,
        private cookieService: CookieService
    ) {
        this.isDev = isDevMode();
        this.activeOrg = new OrganisationModel(); //initiate defaultOg
        this.myOrgList = new Array<OrganisationUser>(); //initiate Org list
        this.isSysAdmin = this.auth.is_system_admin();
    }

    ngOnInit() {
        this.sharedService.innit('sidenav');
        this.licenseDataService.innit('sidenav');

        this.sharedService.viewAsObservable.pipe(
            distinctUntilChanged()
        ).subscribe((viewAs) => {
            this.viewAs = viewAs;
        });


        //set the navigation options
        this.setSidebarContents();

        //set profile and bottom content
        // this.sharedService.viewAsObservable.subscribe(viewAs => this.viewAs = viewAs);
        this.sharedService.activeOrgAsObservable.subscribe((activeOrg) => {
            this.activeOrg = activeOrg;
            this.trialDays = this.setTrialDate(this.activeOrg.trial_end_date);
        });

        this.sharedService.orgListAsObservable.subscribe(orgList => {
            this.myOrgList = orgList
        });

        this.sharedService.checkoutEventAsObservable.subscribe(checkoutEvent => {
            this.checkoutEvent = checkoutEvent;
        })

        if (!this.cookieService.check('impersonateUser') && this.auth.checkCookieItem('_userProfile') && this.cookieService.get('_userProfile') !== '') {
            const _profileCookie = this.cookieService.get('_userProfile');
            setTimeout(() => {
                this.userProfile = JSON.parse(_profileCookie);
            }, 1000);
            // this.userProfile = this.cookieService.get('_userProfile');
        }

        this.sharedService.userProfileAsObservable.subscribe(userProfile => {
            this.userProfile = userProfile;
        });

        // this.sidenavLeftService.getNavState().subscribe((navState: NavState) => {
        //     this.navState = navState;
        // });
        this.route.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                if (this.isHandSet) {
                    this.closeNavOnEvent.emit(true);
                }
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        // (changes['isHandSet']?.currentValue !== changes['isHandSet']?.previousValue && !changes['isHandSet'].firstChange) ? console.log(this.isHandSet, 'test-true') :  console.log(this.isHandSet, 'test-false');
    }


    ngOnDestroy() {
        this.eventsObs.unsubscribe();
    }


    ngAfterViewInit() {
        this.updateUrl();
    }


    updateUrl() {
        this.eventsObs = this.route.events
            .filter((event) => event instanceof NavigationEnd)
            .subscribe(((event: NavigationEnd) => {
                this.activeRoute = event.urlAfterRedirects;
                console.log("active route via subscribe:", this.activeRoute);
                this.changeDetectorRef.detectChanges();
            }));
    }

    manuallyReadUrl() {
    //to bypass issue with active route not being set on redirect
    //return current url
    //to do split on / & : etc

        let baseUrl = window.location.protocol + '//' + window.location.hostname;

        //if local host add the port and :
        window.location.port ? baseUrl += ':' + window.location.port : null;
        console.log("baseURL is ", baseUrl);
        const fullUrl = location.href;
        const route = fullUrl.replace(baseUrl, '');
        return route; //subtract the baseUrl from the entire url
    }


    checkIfActive(menuRoute: string) {
        if (this.activeRoute && this.activeRoute.startsWith(menuRoute)) {
            return true;
        } else {
            return false;
        }
    }

    //is this in use?
    // toggleViewAsRole() {
    //     if (this.viewAs === 'sysAdmin') {
    //         this.route.navigateByUrl('manage/organisation');
    //         //navigating to here will set role as user
    //     } else {
    //         this.route.navigateByUrl('admin/dashboard');
    //         //navigating to here will set role as admin (if you have permission)
    //     }
    // }

    switchToView(role: any) {
    //reset impersonate when switch to admin view called.
        switch (role) {
            case 'sysAdmin':
                if (this.cookieService.check('impersonateUser')) {
                    this.sharedService.resetImpersonateUser();
                } else {
                    this.sharedService.changeViewAsRole(role);
                    this.route.navigateByUrl('admin/dashboard');
                }
                break;
            case 'user':
                this.sharedService.changeViewAsRole(role);
                this.route.navigateByUrl('manage/organisation');
                break;
            default:
                break;
        }
    }

    openFirstPayment() {
        const dialogRef = this.dialog.open(FirstPaymentModal, {
            panelClass: 'pipcall-fullscreen-modal-container',
            data: {organisation_id: this.activeOrg.id}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                window.location.reload();
            }
        });
    }

    openTermsConditionsModal() {
        const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
            panelClass: 'pipcall-modal-container',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                //do action on modal close
            }
        });

    }

    openDownloadLink() {
        const dialogRef = this.dialog.open(DownloadLinksModalComponent, {
            panelClass: 'pipcall-modal-container',
            width: '420px',
        });

    }

    onNavigate(url) {
        window.open(url, "_blank");
    }


    setTrialDate(trialEndDate) {
        const today = new Date();
        // const createdAt = new Date(this.orgProfile.created_at);
        const trialEnds = new Date(trialEndDate);
        trialEnds.setHours(23, 59, 59, 59);
        const diff = trialEnds.getTime() - today.getTime();
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24)) - 1; //added minus 1 to adjust for first day partial.
        return trialEnds >= today ? diffDays : -1;
    }


    signOut() {
        this.sharedService.clearSession();
        this.auth.logout();
    }

    //submenu
    mouseenter() {
        if (!this.isExpanded) {
            this.isShowing = true;
        }
    }

    mouseleave() {
        if (!this.isExpanded) {
            this.isShowing = false;
        }
    }

    private setSidebarContents(): void {
        // this.sidebarMenu = sideNavMenuConfig['user'];
        // this.sidebarMenuOwner = sideNavMenuConfig['isAdmin'];
        // this.isSysAdmin ? this.sidebarMenuAdv = sideNavMenuConfig['sysAdmin'] : null;
        this.sideNavMenuConfig_ = Array<SideNavItem>();
        this.sideNavMenuConfig_ = sideNavMenuConfignew;
    }

}

