import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//components
import { ProfileComponent } from '@app/pages/profile/profile.component';
import { PageNotFoundGlobalComponent } from './pages/page-not-found/page-not-found.component';
import { AuthGuardService as AuthGuard } from './services/auth-service/auth-guard-service';
import { SelectiveStrategy } from './services/selective-strategy.service';
import { AdminGuard } from '@app/services/auth-service/admin.guard';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { DemoComponent } from './pages/demo/demo.component';
import { CardContainerTemplateComponent } from './pages/demo/card-container-template/card-container-template.component';
import { AccessDeniedComponent } from '@app/pages/access-denied/access-denied.component';

import { TemplateRightMenu } from '@app/components/right-menu-overlay/right-menu-contents-template/contents-template.component';


import { PiPmobileSignupComponent } from '@app/pages/pipmobile-signup/pipmobile-signup.component';
import { LoginV2Component } from '@app/pages/loginv2/login.component';

import { SetpasswordComponent } from '@app/pages/setpassword/setpassword.component';
import { SetpasswordV2Component } from '@app/pages/setpasswordv2/setpassword.component';
import { HomeComponent } from '@app/pages/home/home.component';

import { ExpoSignupComponent } from '@app/pages/expo-signup/expo-signup.component';
import { ExpoDashboardComponent } from '@app/pages/expo-dashboard/expo-dashboard.component';

// import { AdvSearchComponent } from '@app/pages/adv-search/adv-search.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full',
        canActivate: [],
        data: {
            customLayout: true,
            preload: true
        }
    },
    {
        path: 'login',
        component: LoginV2Component,
        pathMatch: 'full',
        canActivate: [],
        data: {
            customLayout: true,
            preload: true
        }
    },
    {
        path: 'accountsignup',
        component: PiPmobileSignupComponent,
        pathMatch: 'full',
        canActivate: [],
        data: {
            customLayout: true,
            preload: true
        }
    },
    {
        path: 'mobilepbxsignup',
        component: PiPmobileSignupComponent,
        pathMatch: 'full',
        canActivate: [],
        data: {
            customLayout: true,
            preload: true
        }
    },
    {
        path: 'signup',
        loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule),
        data: {
            customLayout: true,
            breadcrumb: 'Signup',
        }
    },
    {
        path: 'setpassword',
        component: SetpasswordV2Component,
        pathMatch: 'full',
        canActivate: [],
        data: {
            customLayout: true,
            preload: true
        }
    },
    {
        path: 'forgot',
        component: SetpasswordV2Component,
        pathMatch: 'full',
        canActivate: [],
        data: {
            customLayout: true,
            preload: true
        }
    },
    {
        path: 'redirect',
        pathMatch: 'full',
        component: RedirectComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Home',
        },
    },
    {
        path: 'verify',
        loadChildren: () => import('./pages/verify/verify.module').then(m => m.VerifyModule),
        data: {
            customLayout: true,
            breadcrumb: 'Verify',
        }
    },
    {
        path: 'admin',
        data: {
            customLayout: false,
            preload: true,
            breadcrumb: 'Admin',
        },
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard, AdminGuard]
    },
    {
        path: 'expo-dashboard',
        component: ExpoDashboardComponent,
        canActivate: [AuthGuard, AdminGuard],
        pathMatch: 'full',
        data: {
            customLayout: true,
            preload: false
        }
    },
    {
        path: 'expo-signup',
        component: ExpoSignupComponent,
        canActivate: [AuthGuard, AdminGuard],
        pathMatch: 'full',
        data: {
            customLayout: true,
            preload: false
        }
    },
    {
        path: 'manage/organisation/:id/:extra',
        redirectTo: 'organisation/:id/:extra',
        pathMatch: 'full'
    },
    {
        path: 'manage/organisation/:id',
        redirectTo: 'organisation/:id',
        pathMatch: 'full'
    },
    {
        path: 'manage/organisation',
        redirectTo: 'organisation',
        pathMatch: 'full'
    },
    {
        path: 'organisation',
        data: {
            customLayout: false,
            isAnimatedBackdrop1: false,
            preload: true,
            breadcrumb: 'organisation',
        },
        loadChildren: () => import('./pages/manage/manage.module').then(m => m.ManageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'profile',
        component: ProfileComponent,
        data: {
            customLayout: false,
            breadcrumb: 'My Profile',
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'release',
        canActivate: [AdminGuard],
        data: {
            customLayout: false,
            preload: false,
            breadcrumb: 'Release'
        },
        loadChildren: () => import('@app/pages/release-notes/release-notes.module').then(m => m.ReleaseNotesModule),
    },
    {
        path: 'demo',
        component: DemoComponent,
        canActivate: [AuthGuard],
        data: {
            customLayout: false,
            breadcrumb: 'Demo',
        },
        children: [
            { path: 'demorightmenu', component: TemplateRightMenu, outlet: 'side' }
        ]
    },
    {
        path: 'demo/cardcontainertemplate',
        component: CardContainerTemplateComponent,
        canActivate: [AuthGuard],
        data: {
            customLayout: false,
            breadcrumb: 'Card Container Template',
        }
    },
    {
        path: 'help',
        data: {
            customLayout: false,
            preload: false,
            breadcrumb: 'Help',
        },
        loadChildren: () => import('./pages/help-center/help-center.module').then(m => m.HelpCenterModule),
        canActivate: [AuthGuard, AdminGuard]
    },
    {
        path: 'accessdenied',
        component: AccessDeniedComponent,
        data: {
            customLayout: true
        }
    },
    {
        path: 'pagenotfound',
        component: PageNotFoundGlobalComponent,
        data: {
            customLayout: true
        }
    },
    {
        path: '**',
        component: PageNotFoundGlobalComponent,
        data: {
            customLayout: true
        }
    }
];


@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: SelectiveStrategy })],
    exports: [RouterModule],
    providers: [
    ]
})
export class AppRoutingModule { }


// {
//     path: 'pricing',
//     component: PricingPageComponent,
//     canActivate: [AuthGuard],
//     data: {
//         customLayout: false,
//         breadcrumb: 'Pricing',
//     },
// },

// {
//     path: 'adv-search',
//     component: AdvSearchComponent,
//     data: {
//         customLayout: false,
//         breadcrumb: 'adv-search',
//     },
//     canActivate: [AuthGuard, AdminGuard]
// },
