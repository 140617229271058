
<mat-toolbar>
      <mat-toolbar-row  class="toolbar-max-width-1440 fx-row fx-justify-space-between fx-align-center">
          <!-- signup screen toolbar -->
          <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="navigateToHome()">
              <mat-icon class="icon-size-22 main-logo" svgIcon="home"></mat-icon>
          </button>
          <span  class="clickable" routerLink="/">PiPcall Portal</span>
          
          <span class="topbar-spacer"></span>
          
          <button mat-button routerLinkActive="nav-item-active"
          routerLink="/login">
              LOGIN
          </button>
      </mat-toolbar-row>
</mat-toolbar>

<div class="top-banner fx-row fx-justify-space-between fx-align-center">
    <div class="banner-content fx-col fx-justify-center fx-align-start">
        <img class="pip-logo" src="/assets/brand/pipcall-logo-white-flat.png">
        <div class="banner-copy-1">The UK’s most advanced mobile service</div>
    </div>

  </div>
