<div class="container bg-image">
  <mat-card class="view-content" style="padding:0px;">
    <mat-card-header>
    </mat-card-header>
    <mat-card-content class="text-center fx-row fx-col-sm fx-center">


      <div class="image-container fx-col fx-justify-center fx-align-center" >
        <img class="pipcall-logo"  src="https://pipcallportalstorage.blob.core.windows.net/images/pip_logo_solo_white_sm.png"
        alt="PiPcall logo">
      </div>


      <div class="verify-message text-center fx-col fx-center">

        <ng-container *ngIf="loading;else loaded">
          <span id="firstMsg">
            <div class="loader-spinner"></div> Please wait ....
          </span>
        </ng-container>
        <ng-template #loaded>
          <ng-container *ngIf="success;else failed">

            <ng-container *ngIf="source=='cart' || source =='invitation';else sourceOther">
              <div>
                <span class="subtitle-12">Welcome to PiPcall</span>

                <ng-container *ngIf="businessnumber;else nobusinessnumber">
                  <ul class="no-bullets">
                    <li>The business number {{businessnumber}} is now connected to your PiPcall account.</li>
                    <li>All outbound calls made from PiPcall will display this number.</li>
                    <li>To forward inbound calls made to {{businessnumber}} to your PiPcall number {{pipnumber}}, click
                      <a href="https://www.pipcall.com/blog/make-the-most-of-your-free-trial" target="_blank">here</a>.
                    </li>
                  </ul>
                </ng-container>

                <ng-template #nobusinessnumber>
                  <div>Your new business number is <span style="white-space: nowrap;">{{pipnumber| normalizeToInternational}}</span>.</div>
                </ng-template>


               <div style="margin-top: 30px;padding-left: 20px;padding-right: 20px;">
                  You can now download the PiPcall app.<br> <i>You will need your mobile handset number, thats the number you just verified, to sign into and activate the PiPcall app</i>
               </div>

                <div class="download-links" [style.margin-bottom.px]="'10'" style="margin-top: 30px;">
                  <a [style.margin.px]="15"
                    href="https://itunes.apple.com/app/apple-store/id1410293712?pt=1588242&ct=Trialists%20App%20Download&mt=8"><img
                      src="assets/img/appstore-sm.png" alt="App Store" target="_blank"></a>

                  <a [style.margin.px]="15" href="https://play.google.com/store/apps/details?id=com.teliqo.pipcall "><img
                      src="assets/img/googleplay-sm.png" alt="Play Store"></a>
                </div>


              </div>
            </ng-container>


            <ng-template #sourceOther>
              <div>
                <span [style.font-size.px]="'16'">
                  <h2>Business Number Successfully Connected</h2>
                </span>

                <ul>
                  <li>Your business number {{businessnumber}} is now connected to your PiPcall account.</li>
                  <li>All outbound calls made from PiPcall will display this number.</li>
                  <li>To forward inbound calls from your business number to your temporary PiPcall number
                    ({{pipnumber}}), click
                    <a href="https://www.pipcall.com/blog/make-the-most-of-your-free-trial" target="_blank">here</a>.
                  </li>
                </ul>

                <p>You can now close this window and continue to enjoy PiPcall !</p>

              </div>
            </ng-template>


          </ng-container>
          <ng-template #failed>
            <h5>Well, that doesn’t look right.</h5>
            <p [style.margin-top.px]="'30'">It looks like your token is incorrect. Let us help fix it by reaching
              out to <a href="mailto:support@pipcall.com" target="_blank">PiPcall Support</a> or call us on 0330 094 8080 if you
              need
              help.
            </p>
          </ng-template>

        </ng-template>



      </div>


    </mat-card-content>
  </mat-card>
</div>
