import { Injectable, ModuleWithProviders, NgModule, ApplicationRef } from '@angular/core';
import { of } from 'rxjs/observable/of';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { debounce, debounceTime, tap, finalize, first, take, switchMap, } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";

// import 'rxjs/add/operator/switchMap';
// import { AuthService } from '@app/services/auth-service/auth.service';

export class UserIdentification {
    user_id: string;
    name: string;
    picture: string;
    email: string;
    email_verified: boolean;
    org: string;
    roles: string[];
    exp: number; //unix timestap
    expirationDate: Date;
}

// const isExpired = Date.now() >= token.exp * 1000;

@Injectable({
    providedIn: 'root'
})
export class SessionService {


    public userIdentificationAsObservable: Observable<any>; //updates when userIdentification changes. Components can subscribe to this to get the latest userIdentification
    private userIdentification = new BehaviorSubject<UserIdentification>(null);

    constructor(
        private router: Router,
        private cookieService: CookieService,
    ) {
        this.userIdentification.next(null);
        this.userIdentificationAsObservable = this.userIdentification.asObservable().filter(resp => !!resp);
        console.log('[session service contstuctor]');
        this.setSession();
    }

    setSession() {
        console.log('[setting session service]');
        const decoded = this.cookieService.check("id_token") ? this.getDecodedToken(this.cookieService.get("id_token")) : null;
        if (decoded) {
            const _user = {
                user_id: decoded.sub,
                name: decoded.name,
                picture: decoded.picture,
                email: decoded.email,
                email_verified: decoded.email_is_verified,
                org: decoded['https://pipcall.com/org'],
                roles: decoded['https://pipcall.com/roles'],
                exp: decoded.exp,
                expirationDate: new Date(decoded.exp * 1000)
            }
            this.userIdentification.next(_user);
        }
    }


    // ==========================
    private getDecodedToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    clearEntireSession() {
        sessionStorage.clear();
        this.userIdentification.next(null);
    }
}
