<ng-container *ngIf="organisation_id; else notFound">

  <ng-container *ngIf="pageStatus.isLoading">
    <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
      <div style="font-size: 4px;" class="loader-spinner"></div>loading...
    </div>
  </ng-container>


  <ng-container [hidden]="pageStatus.isLoading">
    <div *ngIf="noContent  === true">Error getting content</div>

    <div [@fadeInOnEnter] class="tab-child-view-container" *ngIf="noContent  === false">

      <div *ngIf="organisationProfile?.state === 'Closed'" class="section-info-bar" fxLayoutAlign="space-between center"
        fxLayout="row" style="background:var(--c-error);color:white;height: 50px;">Account is closed</div>
      <div *ngIf="organisationProfile?.state === 'Declined'" class="section-info-bar"
        fxLayoutAlign="space-between center" fxLayout="row" style="background:var(--c-warn);color:white;height: 50px;">
        Trial has now ended</div>



      <div class="section-wrapper">

        <tab-title-lined>Billing & Invoices</tab-title-lined>

        <div class="section-content">

          <div fxLayout="row" fxFlex="100%" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">

            <s-grid-items-container>
              <s-grid-item [labelWidthpx]="160">
                <div role="label">Customer Status:</div>
                <div role="value"
                  [ngClass]="{'green-text' : organisationProfile?.state === 'Active', 'orange-text' : organisationProfile?.state === 'Trial',  'red-text' : organisationProfile?.state !== 'Trial' && organisationProfile?.state !== 'Active'}">
                  {{organisationProfile?.state}}
                </div>
              </s-grid-item>

              <s-grid-item [labelWidthpx]="160" *ngIf="organisationProfile?.is_pipmobile_enabled">
                <div role="label">Billing Day:</div>
                <div role="value">
                  <ng-container *ngIf="organisationProfile?.billing_day && organisationProfile?.billing_day > 0">
                    {{organisationProfile?.billing_day | dateSuffix}}</ng-container>
                  <ng-container *ngIf="organisationProfile?.state === 'Trial'"><i>Currently in trial</i><button
                      style="font-size: 12px;line-height: 2em;margin-left: 10px;text-align: center;"
                      *ngIf="organisationProfile?.state === 'Trial' && organisationProfile?.is_pipmobile_enabled"
                      mat-flat-button color="accent" (click)="openFirstPayment()">Upgrade</button></ng-container>
                </div>
              </s-grid-item>


              <s-grid-item [labelWidthpx]="160">
                <div role="label">Product:</div>
                <div role="value">
                  <span *ngIf="organisationProfile?.is_pipmobile_enabled">PiPmobile</span>
                  <span  *ngIf="organisationProfile?.is_pipmobile_enabled && organisationProfile?.is_pipxt_enabled">&nbsp;|&nbsp;</span>
                  <span *ngIf="organisationProfile?.is_pipxt_enabled">Mobile+</span>
                  <span  *ngIf="organisationProfile?.is_esim_enabled && (organisationProfile?.is_pipxt_enabled || organisationProfile?.is_pipmobile_enabled)">&nbsp;|&nbsp;</span>
                  <span *ngIf="organisationProfile?.is_esim_enabled">eSIM for business</span>
                </div>
              </s-grid-item>


            </s-grid-items-container>


            <div class="information-banner warning" fxLayoutAlign="start center" fxFlex="100%"
              *ngIf="organisationProfile?.is_pipmobile_enabled && is_auto_billing_enabled === false && organisationProfile?.state !== 'Trial'">
              <div>
                <mat-icon svgIcon="warning"></mat-icon>
              </div>
              <div class="message-body"
                matTooltip="No payment will be taken at the next billing date until automatic billing is resumed."
                [matTooltipClass]="'tooltipcustom tooltipmultiline'">Automatic billing has been disabled
              </div>
            </div>

          </div>
        </div>
      </div>

      <ng-container *ngIf="organisationProfile?.is_pipxt_enabled">

        <!-- <div class="section-divider"></div> -->
        <div class="section-wrapper">
          <tab-title-lined>Billing Details</tab-title-lined>


          <div class="section-action" [hidden]="organisationBillingDetails?.pristine">
            <div>
              <button mat-flat-button *ngIf="!organisationBillingDetails?.pristine" style="margin-right: 4px;"
                [disabled]="pageStatus.isSubmitting" (click)="cancelForm()">Cancel</button>

              <button mat-flat-button aria-label="Save changes" class="save-button"
                [disabled]="organisationBillingDetails?.pristine ||  pageStatus.isSubmitting || organisationBillingDetails?.invalid || (f.billing_contact_email.value === '' && f.company_number.value === '' && f.registered_address.value === '')"
                (click)="saveBillingForm(organisationBillingDetails)">
                <ng-container *ngIf="!pageStatus.isSubmitting; else savingSpinner">Save Changes</ng-container>
              </button>
            </div>
          </div>



          <div class="section-content">
            <div fxLayout="column" fxFlex="100%" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">
              <form [formGroup]="organisationBillingDetails"
                [ngClass]="{'readonly-block': pageStatus.isSubmitting || (organisationProfile.state !== 'Active' && organisationProfile.state !== 'Trial' && organisationProfile.state !== 'In Arrears')}">
                <div fxLayout="column" fxFlex="100%">

                  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-md="20px"
                    style="justify-items: center;gap: 20px;">

                    <div class="tab-form-field-wrapper" style="justify-items: center" fxFlexOrder.lt-lg="2">
                      <mat-form-field appearance="outline" class="custom-form-field-v3 inner-shadow">
                        <input type="text" matInput placeholder="Company number" formControlName="company_number">
                        <status-icons
                          *ngIf="organisationBillingDetails?.controls.company_number?.touched && organisationBillingDetails?.controls.company_number?.value !== ''"
                          [iconStatus]="organisationBillingDetails.get('company_number').status">
                        </status-icons>
                        <mat-label>Company Number</mat-label>
                      </mat-form-field>
                    </div>

                  </div>

                  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-md="20px"
                    style="justify-items: center;gap: 20px;">
                    <div class="tab-form-field-wrapper" style="justify-items: center" fxFlexOrder.lt-lg="2">

                      <mat-form-field appearance="outline" class="custom-form-field-v3 inner-shadow">
                        <input type="text" matInput placeholder="Billing Email" formControlName="billing_contact_email">
                        <status-icons
                          *ngIf="organisationBillingDetails?.controls.billing_contact_email?.touched && organisationBillingDetails?.controls.billing_contact_email?.value !== ''"
                          [iconStatus]="organisationBillingDetails.get('billing_contact_email').status">
                        </status-icons>
                        <mat-label>Billing Email (if different from main contact)</mat-label>
                      </mat-form-field>
                    </div>


                  </div>

                  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-md="20px"
                    style="justify-items: center;gap: 20px;">

                    <div class="tab-form-field-wrapper" style="justify-items: center" fxFlexOrder.lt-lg="2">

                      <mat-form-field appearance="outline" class="custom-form-field-v3 inner-shadow">
                        <textarea matInput type="text" matInput placeholder="Registered company address (for invoicing)"
                          formControlName="registered_address" style="min-height: 80px;"></textarea>
                        <status-icons
                          *ngIf="organisationBillingDetails?.controls.registered_address?.touched && organisationBillingDetails?.controls.registered_address?.value !== ''"
                          [iconStatus]="organisationBillingDetails.get('registered_address').status">
                        </status-icons>
                        <mat-label>Registered Company Address</mat-label>
                      </mat-form-field>
                    </div>

                    <!-- <div fxLayout="column" fxFlex="40%" fxFlex.lt-lg="100%" fxFlexOrder="2" fxFlexOrder.lt-lg="1">
                      <div>Address used for invoicing</div>
                    </div> -->

                  </div>


                </div>
              </form>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="((organisationProfile?.state === 'Active' || organisationProfile?.state === 'In Arrears') && organisationProfile?.is_pipmobile_enabled) || organisationProfile?.is_esim_enabled">

        <!-- <div class="section-divider"></div> -->

        <div class="section-wrapper" *ngIf="organisationProfile?.is_esim_enabled || organisationProfile?.is_pipmobile_enabled">
          <tab-title-lined>Active Card</tab-title-lined>

          <div class="section-content">

            <div fxLayout="column" fxLayoutGap="20px">

                <ng-container *ngIf="pageStatus.loadingCard === true; else loadedCard">
                  loading..
                </ng-container>

                <ng-template #loadedCard>

                  <div *ngIf="!carddetails" class="highlight-background" fxLayoutAlign="start center"
                    fxFlex="100%">
                    <div>
                      <mat-icon svgIcon="warning"></mat-icon>
                    </div>
                    <div class="message-body">&nbsp;No payment card exists for this organisation
                    </div>
                  </div>

                  <ng-container *ngIf="carddetails !== null">
                    <div class="cc-payment-card-container">

                      <div class="pcc-icon-container">
                        <mat-icon svgIcon="credit_card"></mat-icon>
                      </div>

                      <div class="pcc-card-details">
                        <strong>{{carddetails?.brand | titlecase}}</strong> ending in
                        <strong>{{carddetails?.last_4_digits}}</strong>
                      </div>

                      <div class="pcc-expiry-date">
                        <div><strong>Exp:&nbsp;</strong>{{carddetails?.exp_month}} / {{carddetails?.exp_year}}</div>
                      </div>

                    </div>

                    <div class="pcc-update-card-btn" *ngIf="pageStatus.loadingCard === false && (viewAs === 'sysAdmin' || viewAs === 'isAdmin')">
                      <button
                      mat-flat-button class="blue-button" (click)="openUpdateForm()">Update Card</button>
                    </div>

                  </ng-container>
                </ng-template>
            

              <div class="card-action-container" style="justify-content: flex-end; display: flex; flex-direction: row;"
                *ngIf="pageStatus.loadingCard === false && (viewAs === 'sysAdmin' || viewAs === 'isAdmin') && !carddetails">
                <button *ngIf="pageStatus.loadingCard === false && (viewAs === 'sysAdmin' || viewAs === 'isAdmin')"
                  mat-flat-button class="blue-button" (click)="openUpdateForm()">Add Card</button>
              </div>

            </div>
          </div>
        </div>

      </ng-container>


      <ng-container *ngIf="(organisationProfile?.is_pipmobile_enabled && organisationProfile.state !=='Trial') || invoiceList?.length > 0">



        <!-- <div class="section-divider"></div> -->
        <div class="section-wrapper">
          <tab-title-lined>Invoices</tab-title-lined>

          <div class="section-action">
            <div>
              <button *ngIf="viewAs === 'sysAdmin'" mat-flat-button aria-label="Restore an invoice"
                style="background: var(--c-url);color: white;" (click)="restoreDeletedInvoice()">
                <mat-icon svgIcon="undo"></mat-icon> Restore An Invoice [Sys Admin]
              </button>
            </div>
          </div>

          <div class="section-content">

            <div fxLayout="column" fxFlex="100%">


              <ng-container *ngIf="pageStatus?.loadingInvoice === true; else loadedInvoices">
                loading..
              </ng-container>


              <ng-template #loadedInvoices>
                <div style="font-size: 1em" *ngIf="!invoiceList || invoiceList?.length === 0">There are no invoices to
                  display</div>


                <div class="invoice-card-container">
                  <div *ngFor="let invoice of invoiceList" class="invoice-card-wrapper"
                    (click)="openInvoice(invoice?.id, viewAs)">

                    <div class="title" [ngClass]="{'failed':invoice?.state === 'Failed'}">

                      <span class="invoice-date-field">{{invoice?.due_date | date:'yyyy MMMM dd '}}&nbsp;
                      </span>

                      <mat-icon *ngIf="invoice?.state === 'Failed'" style="color:(--c-warn);" svgIcon="warning"
                        matTooltip="Payment on this invoice has failed. [{{invoice?.state_reason}}]"></mat-icon>
                      <span *ngIf="!invoice?.is_paid && invoice?.state !== 'Failed'"
                        matTooltip="Payment is due on this invoice"
                        style="background: var(--c-accept); color: white;padding-left: 4px;padding-right:4px;font-size:10px;">due</span>

                    </div>

                    <div class="status" [ngClass]="{'failed':invoice?.state === 'Failed'}">
                      <ng-container *ngIf="invoice?.state === 'Failed'; else showState">
                        <div style="color: var(--c-error)" [matTooltipClass]="'tooltipcustom tooltipmultiline'"
                          matTooltip="Payment on this invoice has failed. [{{invoice?.state_reason}}]">
                          {{invoice?.state}}
                        </div>
                      </ng-container>

                      <ng-template #showState>
                        <div *ngIf="invoice?.subtype === 'Pro forma'"><span>&nbsp;{{invoice?.subtype}}</span></div>
                        <div style="color: var(--c-accept)"
                          *ngIf="invoice?.subtype !== 'Pro forma' && (invoice?.state === 'Open' || invoice?.state === 'Paid')">
                          <span>&nbsp;{{invoice?.state}}</span>
                        </div>
                      </ng-template>
                    </div>

                    <div class="actions">
                      {{invoice?.total_gross/100 | currency:'GBP':'symbol':'1.2-2'}}&nbsp;&nbsp;

                      <button class="download-btn" [ngClass]="{'active':invoice?.reference?.startsWith('INV')}"
                        mat-icon-button matTooltip="Download PDF"
                        (click)="downloadPdf(invoice?.reference, $event)"><mat-icon
                          svgIcon="download"></mat-icon></button>

                      <button class="threee-dot-menu" *ngIf="viewAs==='sysAdmin'" mat-icon-button [matMenuTriggerFor]="invoiceMenu"
                        (click)="stopPropagation($event)" [matMenuTriggerData]="{row: invoice}">
                        <mat-icon class="clickable" svgIcon="more_vert"></mat-icon>
                      </button>

                    </div>

                  </div>
                </div>

        

              </ng-template>



            </div>


          </div>
        </div>

      </ng-container>

    </div>

  </ng-container>


</ng-container>



<ng-template #notFound>
  <div>
    Error displaying details
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div style="margin-top: 30px;margin-bottom: 30px;text-align: center;">
    <div style="font-size: 4px;" class="loader-spinner"></div>loading...
  </div>
</ng-template>



<mat-menu #invoiceMenu="matMenu" class="v3-mat-menu">
  <ng-template matMenuContent let-row="row">
    <ng-container *ngIf="viewAs === 'sysAdmin'">

      <div class="divider">Sys Admin</div>


      <button *ngIf="row?.state==='Failed'" mat-menu-item
        matTooltip="Retry taking payment at next billing cycle. This only applies to failed invoices that are not type:initial"
        (click)="retryInvoice(row?.id)" [disabled]="row?.state !=='Failed' || row?.type === 'Initial'"><mat-icon
          svgIcon="refresh"></mat-icon>Retry Payment</button>

      <button class="red-item red-item-full" mat-menu-item matTooltip="Delete this invoice" (click)="deleteInvoice(row)"
        [disabled]="row?.total_paid !== 0 || row?.subtype !== 'Pro forma'"><mat-icon svgIcon="trash"></mat-icon>Delete
        Invoice</button>

      <div mat-menu-item class="no-hover">
        <p>Only invoices that have not been paid and are Pro forma can be deleted through the portal.</p>
        <p *ngIf="row?.state ==='Failed' && row?.type === 'Initial'">Cannot retry invoices that are in initial state.
        </p>
      </div>
    </ng-container>

  </ng-template>


</mat-menu>



<ng-template #savingSpinner>
  <div style="height: 2em;">
    <div class="loader-spinner"></div>
  </div>
</ng-template>