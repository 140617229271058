<ng-container *ngIf="isPricingLoaded; else loadingspinner">
  <div class="fx-row fx-justify-center add-on-package" [ngClass]="{'isModeSelect': isModeSelect}">
  
    <ng-container *ngTemplateOutlet="priceCard;context:{'data':returnLicenseByType(addOnIntType), 'type':'standard'}"></ng-container>
  
  </div>
</ng-container>


<ng-template #priceCard let-data="data" let-type="type">

  <div class="fx-col fx-width-30 fx-center">
    <div class="add-on-price">£{{data.price/100 | currency:'GBP':'':'1.2-2'}}</div>
    <div class="add-on-desc">per {{data.quantity}}&nbsp;{{data.unit}}</div>
  </div>

  <div  class="add-on-description fx-width-70">
    <h5 mdePopoverTriggerOn="hover" [mdePopoverTriggerFor]="addOnHintPopover" #popoverTrigger="mdePopoverTrigger">
      {{data.name}} <mat-icon svgIcon="info"></mat-icon>
    </h5>

    <p>Additional packages can be purchased for use across all user licences. You can exclude
      users from using add-on packages.</p>

    <mde-popover #addOnHintPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
      <mat-card style="max-width: 360px;min-width:300px;">
        <mat-card-content>
          <div  class="desc-txt fx-col">

            <p *ngIf="data.type === 'intl_call'">Includes calls to all EU27 landline and mobile numbers as well as USA, Canada,
              Israel, Switzerland,
              Australia, New Zealand and other geographic and mobile numbers. See our <a tabindex="-1"
                href="https://www.pipcall.com/calldestinations" target="_blank">full list of call destinations</a>.
            </p>

          </div>

        </mat-card-content>
      </mat-card>
    </mde-popover>
  </div>

</ng-template>

<ng-template #loadingspinner>
  <span class="loading-spinner">
    <div class="loading-spinner-1" style="width:90px;margin-top:20px;">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
      <div class="bounce4"></div>
    </div>
  </span>
</ng-template>