import { NgModule } from '@angular/core';

//requirements
import { CommonModule } from '@angular/common';
import { HttpProxy } from '@app/services/http-proxy.service';
import { MaterialModule } from '@app/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomPipesModule} from '@app/pipes/pipes.module';
import { StatusIconsModule } from '@app/components/status-icons/status-icons.module';

//services
import { RouterModule } from '@angular/router';
import { AppInsightService } from '@app/services/helpers/app-insights.service';
import { OrganisationService } from '@app/services/pipcall/organisation.service';
import { UserService } from '@app/services/pipcall/user.service';
import { PaymentsService } from '@app/services/pipcall/payments.service';
import { LicenseDataService } from '@app/services/shared-data.service/license-data.service';
import { SignupService } from '@app/services/pipcall/signup.service';
import { NormalizeNumberService } from '@app/services/helpers/normalize-number.service';
import { InviteUserModalComponentpipxt } from '@app/components/modals/invite-user-modal-pipxt/invite-user-modal.component'
import { SharedDataServiceModule } from '@app/services/shared-data.service/shared-data.service.module';
import { UniversalComponentsModule } from '@app/components/universal/universal.module';

//component
import { DDISystemComponent } from './ddi-system.component'


import { PhonenumberValidator } from '@app/validators/phonenumberValidator.directive';

// modules
import { MdePopoverModule } from '@material-extended/mde';
import { GravatarDirectiveModule } from '@app/directives/gravatar/gravatar.module';
import { modalServiceModule } from '@app/services/modal-service/modal-service.module';


// select compomnents
import { OrgUserSearchSelectModule } from '@app/components/form-field/ff-org-user-search-select/ff-org-user-search-select.module';
import { OrgSearchSelectModule } from '@app/components/form-field/ff-org-search-select/ff-org-search-select.module';

//modals

import { AllocateDdiToOrganisationModalModule } from '@app/components/modals/allocate-ddi-to-organisation-modal/allocate-ddi-to-organisation-modal.module';

import { ExtensionsConfigPanelLegacyModule } from '@app/components/tabs-panel/extension-config-panel-legacy/extension-config-panel-legacy.module';
import { ExtensionsConfigPanelModule } from '@app/components/tabs-panel/extension-config-panel/extension-config-panel.module';
import { ExtensionsTenantPanelModule } from '@app/components/tabs-panel/extension-tenant-panel/extension-tenant-panel.module';

import { PBXService } from '@app/services/pipcall/pbx.service'

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        CustomPipesModule,
        RouterModule,
        StatusIconsModule,
        MdePopoverModule,
        SharedDataServiceModule,
        OrgUserSearchSelectModule,
        OrgSearchSelectModule,
        GravatarDirectiveModule,
        modalServiceModule,
        AllocateDdiToOrganisationModalModule,
        ExtensionsConfigPanelLegacyModule,
        ExtensionsConfigPanelModule,
        ExtensionsTenantPanelModule,
        UniversalComponentsModule
    ],
    declarations: [DDISystemComponent],
    exports: [DDISystemComponent],
    providers: [AppInsightService, InviteUserModalComponentpipxt, OrganisationService, UserService, NormalizeNumberService, PBXService, SignupService, PhonenumberValidator, PaymentsService, LicenseDataService],
    entryComponents: []
})
export class DDITabSystemModule { }
