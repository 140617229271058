<div class="_page-wrapper">
    <ng-container *ngIf="pageStatus.isLoading === false">

        <div class="full-page-view fx-col fx-justify-start fx-align-center">
   
            <ng-container *ngIf="progress === 'complete'">
                <div class="firework"></div>
                <div class="firework"></div>
                <div class="firework"></div>
            </ng-container>

            <div class="fixed-top-bar">
                <div class="title-1" style="font-weight: 600;">EXPO LIVE</div>
                <div class="title-1 target-title fx-row fx-center" >
                    <div class="goal-item" [ngClass]="{'cold': progress === 'cold', 'warm': progress === 'warm', 'hot': progress === 'hot', 'complete': progress === 'complete'}">Today: {{totalVerified}}</div>
                    <div style="color: white;    width: 40px;">&nbsp;/&nbsp;</div>
                    <div class="goal-item complete">Goal: {{dashboardConfig.targetGoal}}</div>
                </div>

                <div><button mat-button (click)="refreshData()" style="color: #c1c1c1">Refresh</button></div>
            </div>

            <!-- [@listAnimation]="signupData.length -->
            <div class="inner-container fx-col">

                <ng-container *ngIf="!pageStatus.isSubmitting; else loading">

                    <div class="signup-container fx-row fx-justify-start fx-align-center" 
                    *ngFor=" let item of signupData" [ngClass]="{'migrated': item?.state === 'Migrated'}" (click)="openDashModal(item)" >

                    <div class="new" *ngIf="item?.state === 'New' && isFromLast10Minutes(item?.created_at)">
                        <span>NEW</span>
                    </div>

                    <div class="new" *ngIf="item?.state === 'Migrated'" style="width: 45px;text-align: center;">
                        <span><mat-icon matTooltip="Migrated successfully" svgIcon="check_circle_fill"></mat-icon></span>
                        <span><mat-icon  matTooltip="App Launched" *ngIf="item?.first_launch_date" svgIcon="launch"></mat-icon></span>
                    </div>


                    <div class="field-container user-name-container fx-col fx-justify-center fx-align-start">
                        <div class="overflow-elipsis user-name" style="max-width: calc(100% - 8px)"
                            matTooltip="User Name: {{item?.first_name}} {{item?.lastname}}">
                            {{item?.first_name}}&nbsp;{{item?.last_name}}</div>
                        <div class="overflow-elipsis sub-detail" style="max-width: calc(100% - 8px);"
                            matTooltip="Organisation: {{item?.organisation_name}}">{{item?.organisation_name}}</div>
                    </div>



                    <div class="field-container details-container fx-col fx-justify-center fx-align-start">
                        <div class="mobile">{{item?.mobile | normalizeToInternational}}<span *ngIf="item.state === 'Migrated' && item.is_mobile_verified === false " class="warn-triangle"><mat-icon matTooltip="Mobile has not been verified. This may be because this was a forced migration." svgIcon="warning" color="warn"></mat-icon></span></div>
                        <div class="overflow-elipsis sub-detail email">{{item?.email}}</div>
                    </div>


                    <div class="field-container fx-col fx-justify-center fx-align-start" style="color: #9a9a9a;width: 100%;">
                      
                        <div style="width: 100%; text-align:right;position:relative;">
                            <mat-icon style="position: relative;top: 6px;" *ngIf="item?.notes" [matTooltip]="item?.notes" svgIcon="note" class="icon-size-28"></mat-icon>
                            {{item?.created_at | date: 'HH:mm'}}</div>
                    </div>
                </div>

                <div class="empty-spacer"></div>
                </ng-container>
               
                <div class="scroll-to-top-btn" (click)="scrollToTop()">
                    <mat-icon svgIcon="arrow_upward"></mat-icon>
                    <span>Scroll to Top</span>
                </div>
            </div>

        </div>
        <div class="info-float">
            <div>Total: {{signupData?.length}}</div>
            <div>Verified {{totalVerified}}</div>
            <div>Unverified: {{signupData?.length - totalVerified}}</div>
            <div>Show number of days: {{dashboardConfig.showNumberOfDays}}</div>
        </div>
    </ng-container>
    


</div>


<ng-template #loading>
    <div class="fx-col fx-center">
      <div class="loading-spinner-1" style="width:90px;margin-top:20px;">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
        <div class="bounce4"></div>
      </div>
    </div>
  </ng-template>
  

  <ng-template #spinner>
    <div>
        <span class="loading-icon"><div class="loader-spinner"></div></span>
    </div>
  </ng-template>
  
