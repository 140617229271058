<div class="pip-modal-content">
  <button class="pip-modal-close_btn" mat-mini-fab color="primary" (click)="closeModal()" aria-label="close modal">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>

  <div class="pip-modal-header">

    <div>
      <span *ngIf="number?.organisation_name" class="subtitle-16">{{number?.organisation_name}}</span>
      <h5 style="color:var(--c-title)">{{number?.number | normalizeToInternational}}<span *ngIf="number?.pip_variant === 'pipxt'">&nbsp;({{number?.sip_extension_cli | normalizeToInternational}})</span></h5>
    </div>

  </div>

  <div class="pip-modal-body fx-col fx-gap-08" >

    <ng-container *ngIf="!number?.organisation_id || data?.moveOrg; else error ">

      <div class="fx-col">
        <div>Select organisation to link number to</div>
      </div>

      <div class="fx-row fx-gap-08 fx-justify-start fx-align-center" *ngIf="!data?.moveOrg ||  (data?.moveOrg && !movetoHolding)">
        <div xLayoutAlign="center">
          <mat-icon svgIcon="user" style="margin-bottom: 16px;"></mat-icon>
        </div>
        <app-ff-org-search-select  class="fx-width-100"
        (_selectedOrganisation)="organisation_selectUpdate($event)"
          [disabled]="pageStatus.isSubmitting" [disabled]="movetoHolding"></app-ff-org-search-select>
      </div>


      <div class="fx-row fx-gap-08 fx-justify-start" *ngIf="data?.moveOrg && number.pip_variant === 'pipxt'" class="reserved-message" [ngClass]="{'margin-top-20':  (data?.moveOrg && movetoHolding)}">
        <mat-checkbox   (change)="movetoNumberHolding($event)"  matTooltipPosition="below" matTooltipShowDelay="200"
        matTooltip="This will move the number to the mobile+ extensions for trial holding"
        [style.font-size.px]="14">Move to <i>Mobile+ extensions for trial holding</i></mat-checkbox>

      </div>


      <div class="fx-row fx-gap-08 fx-justify-start" *ngIf="number?.is_reserved && !data?.moveOrg" class="reserved-message" >
        <mat-checkbox [checked]="isForce"   (change)="isForceChange($event)" matTooltipPosition="below" matTooltipShowDelay="200"
        matTooltip="This number is reserved. Please check if you wish to allocate this to an organisation"
        [style.font-size.px]="14">I acknowledge this is a reserved number</mat-checkbox>

      </div>

      <div class="fx-row fx-justify-end fx-align-center fx-gap-05" >
        <button (click)="closeModal()" mat-flat-button>Cancel</button>
        <button
          [disabled]="organisationAllocationForm.controls['organisation_id'].value === '' || organisationAllocationForm.pristine || !organisationAllocationForm.valid || pageStatus.isSubmitting || (number?.is_reserved && organisationAllocationForm.controls['force'].value !== true)"
          mat-flat-button class="save-button" (click)="saveForm(organisationAllocationForm)">Save</button>

      </div>

    </ng-container>


    <ng-template #error>
     <div>Error: An organisation already exists for this number.</div>
    </ng-template>

  </div>

  <div class="pip-modal-footer">
    <!-- <button mat-flat-button color="primary">Test</button> -->
  </div>
</div>

<ng-template #spinner>
  <span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>
</ng-template>
