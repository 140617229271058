
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material/material.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SaveQueryModallModule } from './save-query-modal/save-query-modal.module';
import { PortalApiV2HelperService } from '@app/services/helpers/portal-api-v2-helper';


//components (declarations)
import { TableToolbarComponent } from './table-toolbar.component';
import { StaticDataService } from '@app/services/shared-data.service/staticdata.sevice';
import { UserDataService } from '@app/services/pipcall/user-data.service';
// import { AuthService } from '@app/services/auth-service/auth.service';
import { ExcelService } from '@app/services/helpers/export-as-excel.service';
@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SaveQueryModallModule
    ],
    declarations: [TableToolbarComponent],
    exports: [TableToolbarComponent],
    providers: [PortalApiV2HelperService, StaticDataService, UserDataService, ExcelService],
    entryComponents: []
})
export class TableToolbarModule {
}

