//core
import { Component, Inject, NgZone, OnInit, Input, EventEmitter, ElementRef, isDevMode, Output, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@app/material/material-essentials.module';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { switchMap, debounceTime, tap, finalize, takeUntil } from 'rxjs/operators';
import { Subscription, Observable, throwError, Subject } from 'rxjs';
import { EsimTelnaService, DataEsim } from '@app/services/pipcall/esim_telna.service';
import { FormGroup, Validators, FormBuilder, Form, FormControl, FormArray, MaxLengthValidator } from '@angular/forms';
import { GbToBytesPipe } from '@app/pipes/bytesConvert.pipe';
import { EpochToDatePipe } from '@app/pipes/epochToDate.pipe';
import { environment } from '@src/environments/environment';

@Component({
    templateUrl: './data-esim-details-modal.component.html',
    styleUrls: ['./data-esim-details-modal.component.scss'],
    animations: [
    ]
})
export class DataEsimDetailsModal {
    isDev: boolean;
    noteinputSubscription: Subscription;
    userNoteInputCtrl = new FormControl();

    public pageStatus = {
        isLoading: true,
        isSubmitting: false,
        note: {
            isSaving: false,
            isSaved: false,
            isSaveError: false,
        }
    }
    public flag: string = null;
    esim: DataEsim;
    emailForm: FormGroup;
    dataPercentageUsed = 0;
    showQR = false;
    //resendTimer
    resendTimer: number | null = null; // Countdown timer
    resendInterval: any; // Store the interval to clear it later

    esimBaseUrl;
    @ViewChild('noteTextarea') textarea!: ElementRef;

    constructor(
        private dialogRef: MatDialogRef<DataEsimDetailsModal>,
        private fb: FormBuilder,
        private http: HttpClient,
        private ref: ChangeDetectorRef,
        private _fb: FormBuilder,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA)
        public data: {id: string, organisation_id: string}, //contains data injected into modal.
        private esimTelnaService: EsimTelnaService,
        private gBtoBytePipe: GbToBytesPipe,
        private epochToDatePipe: EpochToDatePipe,
        private ngZone: NgZone,
    ) {
        this.isDev = isDevMode();

        this.emailForm = this._fb.group({
            id:  ['', [Validators.required]],
            email: ['', [Validators.email, Validators.required]]
        });

        //this ensures clicks on backdrop to close modal, also runs closeModal() method.
        dialogRef.disableClose = true;
        dialogRef.backdropClick().subscribe(() => {
            this.closeModal()
        })
        this.esimBaseUrl = environment.esim_qr_code_storage_base_url;
    }


    ngOnInit() {
        if (this.data?.id) {
            this.pageStatus.isLoading = true;
            this.getEsimByID(this.data.id);
            //run this every 3 minutes until modal closed.
            setInterval(() => {
                this.getEsimByID(this.data.id);
            }, 180000);
        }

        //consider error route - no id display.
    }
    getFlag(license_name: string) {
        return this.esimTelnaService.getRegionFlag(license_name);
    }

    getDataUsed(remaining: number, total: number): number {
        return total - remaining;
    }

    getEsimByID(id: string) {
        this.esimTelnaService.getDataEsimsByID(this.data.organisation_id, id)
            .subscribe((resp: HttpResponse<DataEsim>) => {
                console.log('data', resp);
                this.esim = resp.body;
                this.userNoteInputCtrl.setValue(this.esim?.customer_notes);
                this.flag =  this.esim.is_bundle ? this.getFlag(this.esim.license_name) : this.esim.countries[0]?.code;
                this.setFormValues(this.esim);
                this.subscribeToNoteChanges();

                // 585 / 1024 * 100
                const used = this.gBtoBytePipe.transform(this.esim?.data_allowance) - this.esim.data_usage_remaining;
                console.log('used', used);

                this.dataPercentageUsed = ( used / this.gBtoBytePipe.transform(this.esim?.data_allowance) ) * 100;
                this.pageStatus.isLoading = false;
            }, (err) => {
                console.log('error', err);
                this.pageStatus.isLoading = false;
            });
    }

    setFormValues(esim: DataEsim) {
        this.setFormValue(this.emailForm, 'id', esim?.id);
        this.setFormValue(this.emailForm, 'email', esim?.user_email);
    }

    setFormValue(form: FormGroup, parameter: string,  value: any) {
        form.controls[parameter].setValue(value, {emitEvent: false});
        form.markAsPristine();
    }

    subscribeToNoteChanges() {
        this.noteinputSubscription = this.userNoteInputCtrl.valueChanges
            .pipe(
                debounceTime(900),
                tap(() => this.pageStatus.note.isSaving = true),
            )
            .subscribe(value => {
                this.pageStatus.note.isSaved = false;
                this.pageStatus.note.isSaveError = false;
                this.onNoteChange(value);
            });
    }

    getTimeRemainingInSeconds(targetDateTime: Date): number {
        return Math.floor((targetDateTime.getTime() - Date.now()) / 1000);  // Calculate difference directly in seconds
    }
    getRemainingTime(expiry_date: string): string {
        const targetDate = new Date(expiry_date);  // Use expiry_date passed in
        const time_remaining = this.getTimeRemainingInSeconds(targetDate);

        if (time_remaining <= 0) {
            return 'Expired';
        }

        const days = Math.floor(time_remaining / (24 * 60 * 60));  // Total days
        const hours = Math.floor((time_remaining % (24 * 60 * 60)) / (60 * 60));  // Remaining hours after removing days
        const minutes = Math.floor((time_remaining % (60 * 60)) / 60);  // Remaining minutes after removing hours

        // Constructing the output based on which time units are relevant
        if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''}` + (hours > 0 ? ` ${hours} hour${hours > 1 ? 's' : ''}` : '');
        }
        if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''}` + (minutes > 0 ? ` ${minutes} minute${minutes > 1 ? 's' : ''}` : '');
        }
        if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''}`;
        }

        return 'Expired';
    }

    onNoteChange(note: string) {
        console.log('note changed', note);
        this.esimTelnaService.patchEsimNote(this.data.organisation_id, this.esim.id, note)
            .subscribe((resp) => {
                console.log('note saved', resp);
                this.noteSaved();
            }
            , (err) => {
                console.log('note error', err);
                this.noteError();
            });
    }

    noteSaved() {
        //animate the saved options
        this.pageStatus.note.isSaving = false;
        this.pageStatus.note.isSaved = true;
        this.pageStatus.note.isSaveError = false;
        setTimeout(() => {
            this.pageStatus.note.isSaved = false;
        }, 1000);
    }

    noteError() {
        this.pageStatus.note.isSaving = false;
        this.pageStatus.note.isSaved = false;
        this.pageStatus.note.isSaveError = true;
        setTimeout(() => {
            this.pageStatus.note.isSaveError = false;
        }, 3000);
    }

    onSendOrResend() {
        if (!this.emailForm.valid) { return }

        this.startResendTimer(); // Start the timer when the button is clicked
        this.esimTelnaService.postSendEmailToInstalleSIM(this.data.organisation_id, this.emailForm.value.id, this.emailForm.value.email)
            .subscribe((resp) => {
                this.openSnackBar("Sent", "X");
                this.esim.user_email = this.emailForm.value.email;
            },
            (err) => {
                console.log('error', err);
                this.openSnackBar("Error sending email", "X");
            });
    }

    // Method to start the countdown
    startResendTimer() {
        this.resendTimer = 60; // Set initial countdown value to 60 seconds

        // Use setInterval to update the countdown every second
        this.resendInterval = setInterval(() => {
            if (this.resendTimer && this.resendTimer > 0) {
                this.resendTimer--;
            } else {
                this.clearResendTimer(); // Clear the timer once it reaches 0
            }
        }, 1000); // 1000ms = 1 second
    }

    // Method to clear the timer when countdown finishes
    clearResendTimer() {
        clearInterval(this.resendInterval);
        this.resendTimer = null; // Hide the timer message
    }

    getTooltipStatusText() {
        return `
        PURCHASED
        We have received your order.

        PENDING
        Your order is being processed.
        
        READY
        Your order is ready to be installed. Send the QR code to the user.
        
        INSTALLED
        eSIM has been installed on the user's device. The email can no longer be changed.
        
        ACTIVE
        The plan has taken effect, this happens when the user lands in the destination country. The expiry date will be updated.

        EXPIRED
        The sim is no longer available. The plan has been consumed or use period has expired.
        `;
    }

    getSimInstallationTooltip() {
        const activated_date = this.esim.activated_date > 0 ?  this.epochToDatePipe.transform(this.esim.activated_date) : '-';
        const diagnosticInfo = `
        Diagnostic Information:
        euicc_profile_state: ${this.esim.euicc_profile_state}
        plan_state: ${this.esim.telna_state}
        activated_date: ${activated_date}
        `

        if (this.esim.state === 'INSTALLED') {
            return `
        The sim has been installed on the user's device. The plan activates when the user lands in the destination country. The SIM can no longer be installed on a different device.
        ${diagnosticInfo}
        `;
        }

        if (this.esim.state === 'ACTIVE') {
            return `
        The eSIM was installed and the plan has been made active. The plan will expire once the data or time allowance is consumed.
        ${diagnosticInfo}
        `;
        }

        if (this.esim.state === 'READY') {
            return `
        The eSIM has been provisioned and is available to send to a user. The user can install the eSIM on their device using the QR code or manual instructions sent to their email.
        ${diagnosticInfo}
        `;
        }
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        })
    }

    closeModal() {
        this.dialogRef.close(this.esim);
    }
    copyInputToClipboard(value) {
        //copy to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.openSnackBar(`copied ${value}`, "dismiss");
    }
    onDestory() {
        this.noteinputSubscription.unsubscribe();
    }
}
