<div class="pricing-container fx-col fx-justify-center">

  <ng-container *ngIf="isPricingLoaded; else loadingspinner">


    <table style="width:100%" class="price-table">

      <tr>
        <th *ngFor="let item of pricingList"
          [ngClass]="{'disabled': item.type.includes('talk_and_text') && !isEnabled['talk_and_text'] || !item.type.includes('talk_and_text') && !isEnabled['talk'], 'type-text' : item.isMobile, 'type-talk' : !item.isMobile}">

          <div class="title-txt">{{item?.display}}</div>
          
          <div class="desc-txt" *ngIf="!item.type.includes('talk_and_text')">Dedicated landline number with unlimited
            calls to UK numbers*
          </div>

          <div class="desc-txt" *ngIf="item.type.includes('talk_and_text')">Dedicated mobile number with unlimited calls and texts to UK numbers*
          </div>

        </th>

      </tr>

      <tr *ngFor="let type of intervalTypes">

        <td *ngFor="let item of pricingList" (click)="selectLicence(item[type])"
          [ngClass]="{'disabled': !isEnabled[item.type], 'no-select': !isModeSelect}">

          <div class="top-text" [ngClass]="item.isMobile ? 'type-text' : 'type-talk'">
            {{item[type].display}}
          </div>
          <div>
            <ng-container
              *ngTemplateOutlet="price;context:{'pounds':item[type].pounds, 'pence':item[type].pence, 'total':item[type].yearlyPrice, 'type': type}">
            </ng-container>
          </div>
        </td>

      </tr>


    </table>

  </ng-container>
  <ng-template #loadingspinner>
    <span class="loading-spinner">
      <div class="loading-spinner-1" style="width:90px;margin-top:20px;">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
        <div class="bounce4"></div>
      </div>
    </span>
  </ng-template>
</div>



<ng-template #price let-pounds="pounds" let-pence="pence" let-total="total" let-type=type>
  <div class="pricing">
    <span style="font-size: 1em;">£</span><span style="font-size: 1.5em">{{pounds}}</span>.<span
      style="font-size: 1em;">{{pence}}</span>
  </div>
  <ng-container *ngIf="total && type === 'annually'">
    <div>

      <span class="total">({{total | currency:'GBP':'symbol':'1.0-0'}})</span>
    </div>
  </ng-container>
</ng-template>
